import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import moment from "moment-timezone";

import * as authActions from "../../redux/auth/handlers";
import {StateType} from "../../redux/types";

import {resumeSubscription} from "../../http/cart";
import {getAllSubscriptions} from "../../http/admin";

import DateService from "../../service/Date.service";

import Order from "../../model/Order";
import OrderItem from "../../model/OrderItem";
import OrderStatus from "../../model/OrderStatus";

import {sortOrdersByDateDecrease} from "../../utils/helpers";

import CancelledSubscriptions from "./components/CancelledSubscriptions";
import PastSubscriptions from "./components/PastSubscriptions";
import DialogComponent, {DialogProps} from "../Dialog";
import PendingUsers from "./components/PendingUsers";
import PromoCodes from "./components/PromoCodes";
import ImageViewComponent from "../ImageView";
import Reports from "./components/Reports";
import LoaderComponent from "../Loader";

import IDsvg from "../../static/images/icon-id.svg";
import logoBlackSvg from "../../static/images/logo-black.svg";

import "./styles.scss";

type ActiveTab =
	| "Paused"
	| "Active"
	| "Past"
	| "Pending"
	| "Promo"
	| "PendingUsers"
	| "Reports"
	| "Cancelled";

const AdminDashboard = () => {
	const dispatch = useDispatch();
	const {profile} = useSelector(({auth}: StateType) => ({
		profile: auth.profile
	}));

	const [isLoading, setIsLoading] = useState<boolean>(true);
	// const [subscriptions, setSubscriptions] = useState<Order[]>([]);
	const [pausedSubs, setPausedSubs] = useState<Order[]>([]);
	const [activeSubs, setActiveSubs] = useState<Order[]>([]);
	const [pendingSubs, setPendingSubs] = useState<Order[]>([]);
	const [pastSubs, setPastSubs] = useState<Order[]>([]);
	const [cancelledSubs, setCancelledSubs] = useState<Order[]>([]);
	const [dialog, setDialog] = useState<DialogProps | null>(null);
	const [imageViewProps, setImageViewProps] = useState<string | "waiting" | "none" | null>(null);
	const [imageType, setImageType] = useState<string>("");
	const [activeTab, setActiveTab] = useState<ActiveTab>("Paused");

	useEffect(() => {
		loadAllSubscriptions();
	}, []);

	const loadAllSubscriptions = async () => {
		try {
			setIsLoading(true);

			const active = await getAllSubscriptions([OrderStatus.active, OrderStatus.completed]);
			setActiveSubs(active.data);

			const paused = await getAllSubscriptions([OrderStatus.paused, OrderStatus.pausedByPatient, OrderStatus.pausedRxTransfer]);
			setPausedSubs(paused.data);

			const pending = await getAllSubscriptions([OrderStatus.waitingDoctor, OrderStatus.waitingRxCheck]);
			setPendingSubs(pending.data);

			const cancelled = await getAllSubscriptions([OrderStatus.cancelled]);
			setCancelledSubs(cancelled.data);

			const past = await getAllSubscriptions([OrderStatus.delivering, OrderStatus.rejected]);
			setPastSubs(past.data);

			// setSubscriptions(active.data);

			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
		}
	};

	const handleResumeSubscription = async (id: number) => {
		try {
			setIsLoading(true);
			await resumeSubscription(id);
			await loadAllSubscriptions();
			notify("Order was successfully updated");
		} catch (err) {
			setIsLoading(false);
			notify("Something went wrong, please try again");
		}
	};

	const notify = (text: string) => {
		setDialog({
			text,
			buttons: {
				OK: {
					class: "dark",
					callback: () => {
						setDialog(null);
					}
				}
			}
		});
	};

	const handleOpenViewID = (type: string, imageView: string) => {
		setImageType(type);
		setImageViewProps(imageView);
	};

	const handleCloseViewID = (): void => {
		setImageType("");
		setImageViewProps(null);
	};

	const handleLogoClick = () => {
		window.scrollTo(0, 0);
	};

	const handleLogoutClick = () => {
		dispatch(authActions.logout());
	};

	const renderTab = () => {
		switch (activeTab) {
			case "Paused": {
				const pausedSubscriptions = pausedSubs
					.filter(
						(subscription) =>
							subscription.status === OrderStatus.paused ||
							subscription.status === OrderStatus.pausedRxTransfer ||
							subscription.status === OrderStatus.pausedByPatient ||
							!!subscription.orders.filter((order) => order.status === OrderStatus.paused).length
					)
					.sort(sortOrdersByDateDecrease);

				return pausedSubscriptions.length === 0 ? (
					<div className="order-row">There are no paused subscriptions.</div>
				) : (
					pausedSubscriptions.map((subscription) => {
						const pausedOrders = subscription.orders?.filter(
							(order) =>
								order.status === OrderStatus.paused ||
								order.status === OrderStatus.pausedRxTransfer ||
								order.status === OrderStatus.pausedByPatient
						);

						return (
							<div key={subscription.id}>
								<div className="order-row prefiles">
									<div className="content">
										<div className="row1">
											<div className="id">#{subscription.id}</div>
											<div className="date">
												{moment(subscription.date).format("MMM D, YYYY, HH:mm")}
											</div>
											<div className="items">
												{subscription.items.reduce((acc: string, item: OrderItem) => {
													return acc + (!acc ? "" : ", ") + item.productDosage;
												}, "")}
											</div>
											<div className="document-view-buttons">
												{subscription.user?.insuranceDocument && (
													<div>
														<div
															className="view-id"
															onClick={() =>
																handleOpenViewID("Insurance", subscription.user!.insuranceDocument!)
															}
														>
															<img src={IDsvg} alt="id" />
															<span>view insurance</span>
														</div>
													</div>
												)}
												{subscription.user?.idDocument && (
													<div>
														<div
															className="view-id"
															onClick={() => handleOpenViewID("ID", subscription.user!.idDocument!)}
														>
															<img src={IDsvg} alt="id" />
															<span>view ID</span>
														</div>
													</div>
												)}
											</div>
										</div>
										{subscription.user && (
											<div className="row2">
												Name{" "}
												<div className="patient-name">
													{subscription.user.firstName + " " + subscription.user.lastName}
												</div>
												Birthday{" "}
												<div className="patient-birthday">
													{DateService.formatBirthday(subscription.user.birthday)}
												</div>
												Phone: <div className="patient-phone">{subscription.user.phone}</div>
												Email: <div className="patient-email">{subscription.user.email}</div>
											</div>
										)}
										<div className="row3">
											<span>
												{subscription.pauseDate
													? `Pause date: ${subscription.pauseDate}`
													: "Unknown pause date"}
											</span>
										</div>
									</div>
									{[OrderStatus.paused, OrderStatus.pausedRxTransfer].includes(
										subscription.status
									) && (
										<div
											className="button reject"
											onClick={() => handleResumeSubscription(subscription.id!)}
										>
											RESUME
										</div>
									)}
								</div>
								{!!pausedOrders?.length &&
									pausedOrders.map((order) => (
										<div key={order.id} className="order-row nested">
											<div className="content">
												<div className="row1">
													<div className="id">{`#${order.number}-${order.subNumber}`}</div>
													<div className="date">
														{moment(order.date).format("MMM D, YYYY, HH:mm")}
													</div>
													<div className="items">
														{order.items.reduce((acc: string, item: OrderItem) => {
															return acc + (!acc ? "" : ", ") + item.productDosage;
														}, "")}
													</div>
												</div>
												<div className="row2">
													{subscription.status === OrderStatus.pausedByPatient
														? `Pause reason: ${
																subscription.notes ? subscription.notes : "no reason"
														  }`
														: "Credit card failure"}
												</div>
											</div>
										</div>
									))}
							</div>
						);
					})
				);
			}
			case "Active": {
				const activeOrders: Order[] = [];
				const activeStatuses = [OrderStatus.waitingPharmacist, OrderStatus.packaging];

				activeSubs.forEach((subscription) =>
					subscription.orders.forEach((order) => {
						if (activeStatuses.includes(order.status)) {
							activeOrders.push(order);
						}
					})
				);

				activeOrders.sort(sortOrdersByDateDecrease);

				return activeOrders.length === 0 ? (
					<div className="order-row">There are no active orders.</div>
				) : (
					activeOrders.map((order) => {
						const user = activeSubs.find((subscription) => +subscription.id === +order.number)
							?.user;

						return (
							<div key={order.id}>
								<div className="order-row">
									<div className="content">
										<div className="row1">
											<div className="id">{`#${order.number}-${order.subNumber}`}</div>
											<div className="date">{moment(order.date).format("MMM D, YYYY, HH:mm")}</div>
											<div className="items">
												{order.items.reduce((acc: string, item: OrderItem) => {
													return acc + (!acc ? "" : ", ") + item.productDosage;
												}, "")}
											</div>
											<div className="document-view-buttons">
												{user?.insuranceDocument && (
													<div>
														<div
															className="view-id"
															onClick={() =>
																handleOpenViewID("Insurance", user!.insuranceDocument!)
															}
														>
															<img src={IDsvg} alt="id" />
															<span>view insurance</span>
														</div>
													</div>
												)}
												{user?.idDocument && (
													<div>
														<div
															className="view-id"
															onClick={() => handleOpenViewID("ID", user!.idDocument!)}
														>
															<img src={IDsvg} alt="id" />
															<span>view ID</span>
														</div>
													</div>
												)}
											</div>
										</div>
										{user && (
											<div className="row2">
												Name{" "}
												<div className="patient-name">{user.firstName + " " + user.lastName}</div>
												Birthday{" "}
												<div className="patient-birthday">
													{DateService.formatBirthday(user.birthday)}
												</div>
												Phone: <div className="patient-phone">{user.phone}</div>
												Email: <div className="patient-email">{user.email}</div>
											</div>
										)}
										<div className="row3">
											<span>{order.status}</span>
										</div>
									</div>
								</div>
							</div>
						);
					})
				);
			}
			case "Past": {
				return (
					<PastSubscriptions subscriptions={pastSubs} handleOpenViewID={handleOpenViewID} />
				);
			}
			case "Pending": {
				const pendingSubscriptions = pendingSubs
					.filter((subscription) =>
						[OrderStatus.waitingDoctor, OrderStatus.waitingRxCheck].includes(subscription.status)
					)
					.sort(sortOrdersByDateDecrease);

				return pendingSubscriptions.length === 0 ? (
					<div className="order-row">There are no pending subscriptions.</div>
				) : (
					pendingSubscriptions.map((subscription) => {
						return (
							<div key={subscription.id}>
								<div className="order-row">
									<div className="content">
										<div className="row1">
											<div className="id">#{subscription.id}</div>
											<div className="date">
												{moment(subscription.date).format("MMM D, YYYY, HH:mm")}
											</div>
											<div className="items">
												{subscription.items.reduce((acc: string, item: OrderItem) => {
													return acc + (!acc ? "" : ", ") + item.productDosage;
												}, "")}
											</div>
											<div className="document-view-buttons">
												{subscription.user?.insuranceDocument && (
													<div>
														<div
															className="view-id"
															onClick={() =>
																handleOpenViewID("Insurance", subscription.user!.insuranceDocument!)
															}
														>
															<img src={IDsvg} alt="id" />
															<span>view insurance</span>
														</div>
													</div>
												)}
												{subscription.user?.idDocument && (
													<div>
														<div
															className="view-id"
															onClick={() => handleOpenViewID("ID", subscription.user!.idDocument!)}
														>
															<img src={IDsvg} alt="id" />
															<span>view ID</span>
														</div>
													</div>
												)}
											</div>
										</div>
										{subscription.user && (
											<div className="row2">
												Name{" "}
												<div className="patient-name">
													{subscription.user.firstName + " " + subscription.user.lastName}
												</div>
												Birthday{" "}
												<div className="patient-birthday">
													{DateService.formatBirthday(subscription.user.birthday)}
												</div>
												Phone: <div className="patient-phone">{subscription.user.phone}</div>
												Email: <div className="patient-email">{subscription.user.email}</div>
											</div>
										)}
										<div className="row3">
											<span>{subscription.status}</span>
										</div>
									</div>
								</div>
							</div>
						);
					})
				);
			}
			case "Promo": {
				return <PromoCodes />;
			}
			case "PendingUsers": {
				return <PendingUsers />;
			}
			case "Reports": {
				return <Reports />;
			}
			case "Cancelled": {
				return (
					<CancelledSubscriptions
						subscriptions={cancelledSubs}
						handleOpenViewID={handleOpenViewID}
						loadAllSubscriptions={loadAllSubscriptions}
						notify={notify}
						setIsLoading={setIsLoading}
					/>
				);
			}
		}
	};

	return (
		<div className="admin-dashboard-component prefiles">
			<div className="menu-section">
				<div className="box">
					<div className="left">
						<h1>
							Hi, {profile!.firstName} {profile!.lastName}
						</h1>
					</div>
					<div className="logo" onClick={handleLogoClick}>
						<span>
							<img className="AW-logo-black" src={logoBlackSvg} alt="Winston The Premier Men's Health Virtual Clinic" />
						</span>
					</div>
					<div className="right-buttons">
						<span>
							<div className="logout" onClick={handleLogoutClick}>
								<p>Log out</p>
							</div>
						</span>
					</div>
				</div>
			</div>
			<div className="content-section">
				<div className="sidebar">
					<ul>
						<li
							className={activeTab === "Paused" ? "active" : ""}
							onClick={() => setActiveTab("Paused")}
						>
							Paused Orders
						</li>
						<li
							className={activeTab === "Active" ? "active" : ""}
							onClick={() => setActiveTab("Active")}
						>
							Active Orders
						</li>
						<li
							className={activeTab === "Past" ? "active" : ""}
							onClick={() => setActiveTab("Past")}
						>
							Past Orders
						</li>
						<li
							className={activeTab === "Pending" ? "active" : ""}
							onClick={() => setActiveTab("Pending")}
						>
							Pending Orders
						</li>
						<li
							className={activeTab === "Promo" ? "active" : ""}
							onClick={() => setActiveTab("Promo")}
						>
							Promo Codes
						</li>
						<li
							className={activeTab === "PendingUsers" ? "active" : ""}
							onClick={() => setActiveTab("PendingUsers")}
						>
							Pending Users
						</li>
						<li
							className={activeTab === "Reports" ? "active" : ""}
							onClick={() => setActiveTab("Reports")}
						>
							Reports
						</li>
						<li
							className={activeTab === "Cancelled" ? "active" : ""}
							onClick={() => setActiveTab("Cancelled")}
						>
							Cancelled Orders
						</li>
					</ul>
				</div>
				<div className="admin-failed-orders-component">
					{isLoading ? <LoaderComponent /> : renderTab()}
					{imageViewProps !== null && (
						<ImageViewComponent
							imageViewProps={imageViewProps}
							close={handleCloseViewID}
							title={`${imageType} image`}
						/>
					)}
					{dialog && <DialogComponent {...dialog} />}
				</div>
			</div>
		</div>
	);
};

export default AdminDashboard;
