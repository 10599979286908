import React from "react";

export type ProductCategory = "ED" | "HL" | "AC" | "CS" | "AL";

export const ProductCategoriesList: ProductCategory[] = ["ED", "HL", "AC", "CS", "AL"];

export const ServedProductCategories: ProductCategory[] = ["ED", "HL", "AC", "CS", "AL"];

export const ProductCategoryName = {
	ED: "Erectile Dysfunction",
	HL: "Hair Loss",
	AC: "Acne",
	CS: "Cold Sores",
	AL: "Allergies"
};

export const ProductCategoryImageForm = {
	ED: "PILLS",
	HL: "FOAM",
	AC: "GEL",
	CS: "CREAM",
	AL: "PILLS"
};

export const ProductCategoryCircles = {
	ED: (
		<div className="circles-bg">
			<div
				className="circle opacity-2"
				style={{
					width: "300px",
					height: "300px",
					top: "-170px",
					left: "-120px"
				}}
			/>
			<div
				className="circle opacity-1"
				style={{
					width: "230px",
					height: "230px",
					bottom: "27px",
					right: "-20px"
				}}
			/>
		</div>
	),
	HL: (
		<div className="circles-bg">
			<div
				className="circle opacity-2"
				style={{
					width: "150px",
					height: "150px",
					top: "-30px",
					left: "-30px"
				}}
			/>
			<div
				className="circle opacity-1"
				style={{
					width: "230px",
					height: "230px",
					top: "-30px",
					right: "-20px"
				}}
			/>
			<div
				className="circle opacity-2"
				style={{
					width: "150px",
					height: "150px",
					bottom: "20px",
					right: "-75px"
				}}
			/>
		</div>
	),
	AC: (
		<div className="circles-bg">
			<div
				className="circle opacity-2"
				style={{
					width: "230px",
					height: "230px",
					top: "-25px",
					right: "-30px"
				}}
			/>
			<div
				className="circle opacity-1"
				style={{
					width: "230px",
					height: "230px",
					bottom: "-50px",
					left: "-45px"
				}}
			/>
		</div>
	),
	CS: (
		<div className="circles-bg">
			<div
				className="circle opacity-2"
				style={{
					width: "150px",
					height: "150px",
					top: "-25px",
					right: "30px"
				}}
			/>
			<div
				className="circle opacity-1"
				style={{
					width: "230px",
					height: "230px",
					bottom: "-10px",
					left: "-40px"
				}}
			/>
		</div>
	),
	AL: (
		<div className="circles-bg">
			<div
				className="circle opacity-2"
				style={{
					width: "300px",
					height: "300px",
					top: "-170px",
					left: "-120px"
				}}
			/>
			<div
				className="circle opacity-1"
				style={{
					width: "230px",
					height: "230px",
					bottom: "27px",
					right: "-20px"
				}}
			/>
		</div>
	),
};

export const ProductCategoryShortName = {
	ED: "ed",
	HL: "hl",
	AC: "ac",
	CS: "cs",
	AL: "al"
};

export const ProductCategoryNotes = {
	ED: [
		"*Sildenafil comes in a 4, 8 or 12 dose supply.",
		"*Tadalafil comes in either a 4, 8 or 12 dose supply or as a daily dose/28 day supply."
	],
	HL: ["*Hair Loss medications can be purchased in 1 or 3 month supplies."],
	AC: ["*Acne medication comes in a 1 month supply."],
	CS: ["*Cold sore medication comes in a 1 outbreak supply."],
	AL: ["*Rupall comes in either a daily supply of 30 tablets dispensed monthly or 90 tablets dispensed every 3 months. "]

};

export const ProductCategoryLearnMoreLink = {
	ED: "ed-101",
	HL: "hair-loss-101",
	AC: "acne-101",
	CS: "cold-sores-101",
	AL: "allergy-101"
};

export const ProductCategoryDescription = {
	ED: (
		<>
			<p>
				Erectile dysfunction is a very common medical condition for men and the likelihood of having
				it only increases with age. Essentially, twenty percent of people in their 20s experience
				ED, 30 percent of those in their 30s and so on. Winston is here to help boost your
				performance at any age!
			</p>
			<p>
				At Winston we offer two of the most commonly prescribed ED medications on the market:
				Tadalafil (Active ingredient in Cialis) and Sildenafil (Active ingredient in Viagra).
			</p>
		</>
	),
	HL: (
		<>
			<p>
				2 out of every 3 men will experience some form of hair loss by the time they are 35. Don’t
				worry, we have a weapon to fight against nature's unwanted plan for you.{" "}
			</p>
			<p>
				Male pattern baldness is the most common form of hair loss and follows two predictable
				routes: a receding hairline and thinning at the crown. Winston has two options for treatment
				to counteract the effects of hereditary hair loss by revitalizing hair follicles so you can
				regrow thicker, fuller hair.
			</p>
		</>
	),
	AC: (
		<p>
			Don’t let acne limit your swagger. We want you to look your best and feel confident in your
			own skin. Acne is not fun for anyone, but thanks to science there is a treatment that can
			help.
		</p>
	),
	CS: (
		<p>
			Oral herpes (also known as cold sores) are painful sores on or around the lips. Cold sores are
			usually spread when someone touches a cold sore or touches infected fluid. Common transfer
			methods include sharing eating utensils or razors, kissing an infected person, or touching
			that person's saliva. 48% of North Americans ages 14-49 have oral herpes. Cold sores can be
			very disruptive to your life and a risk to your partner’s health. You can take control of
			outbreaks with the proper information, treatment plan, and scientifically proven medication.
		</p>
	),
	AL:(
		<>
		<p>Sometimes, the best way to manage your seasonal allergy symptoms is to prepare ahead of 
		time for your symptoms. This includes using the right medication at the right time before your 
		allergic symptoms and not only when your symptoms are bad.</p>
		<p>Winston's licensed doctors prescribe safe and effective medications such as Rupall for 
		symptoms of allergic rhinitis, hay fever, or seasonal allergies. </p>
		</>
	)
};
