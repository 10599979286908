import React, {
	forwardRef,
	Ref,
	useImperativeHandle,
	useState,
	useRef,
	KeyboardEvent,
	ChangeEvent,
	useEffect
} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {StateType} from "../../redux/types";
import {Province, ProvinceName, ServedProvinceList} from "../../model/Province";
import ValidationService from "../../service/Validation.service";
import MessageConst from "../../const/Message.const";
import * as patientActions from "../../redux/patient/handlers";
import LoaderComponent from "../Loader";
import * as appActions from "../../redux/app/handlers";
import "./styles.scss";

interface RegisterProps {
	isRegisterButtonShown: boolean;
	handleLoginModeClick: () => void;
	onAddedCallback?: () => void;

}

export interface RegisterRefObject {
	doRegister: () => Promise<void>;
}

const Register = forwardRef((props: RegisterProps, ref: Ref<RegisterRefObject>) => {
	const {isLoading,profile} = useSelector(({auth}: StateType) => ({
		isLoading: auth.authLoading,
		profile: auth.profile,

	}));
	const dispatch = useDispatch();
	// const {pathname} = useLocation();
	const navigate = useNavigate();
	const firstNameInput = useRef<HTMLInputElement>(null);
	const lastNameInput = useRef<HTMLInputElement>(null);
	const phoneInput = useRef<HTMLInputElement>(null);
	const birthDateInput = useRef<HTMLInputElement>(null);
	const birthYearInput = useRef<HTMLInputElement>(null);
	const provinceSelect = useRef<HTMLSelectElement>(null);
	const yearSelect =useRef<HTMLSelectElement>(null)
	const [firstName, setFirstName] = useState<string>(profile?.firstName || "");
	const [lastName, setLastName] = useState<string>(profile?.lastName|| "");
	const [phone, setPhone] = useState<string>(profile?.phone|| "");
	const [birthdate, setBirthday] = useState<string>(profile?.birthday?.split("-")[2]||"");
	const [birthmonth, setMonth] = useState<string>(profile?.birthday?.split("-")[1]||"");
	const [birthyear, setYear] = useState<string>(profile?.birthday?.split("-")[0]||"");
	const [validationMessage, setValidationMessage] = useState<string>("");
	const [province, setProvince] = useState<Province | undefined>(profile?.province ||undefined);
	
	useImperativeHandle(ref, () => ({doRegister}));
	
	const handleKeyPress = (
		event: KeyboardEvent<HTMLInputElement> | KeyboardEvent<HTMLSelectElement>
	) => {
		if (event.key === "Enter") {
			doRegister();
		}
	};

	useEffect(()=>{
		sessionStorage.setItem("emtyPopup","close")
	})


	const handleChangeFirstName = (e: ChangeEvent<HTMLInputElement>) => {
		const newFirstName: string = e.target.value;

		setFirstName(newFirstName);

		if (ValidationService.validateRequired(newFirstName)) {
			setValidationMessage("");
		}
	};

	const handleChangeLastName = (e: ChangeEvent<HTMLInputElement>) => {
		const newLastName: string = e.target.value;

		setLastName(newLastName);

		if (ValidationService.validateRequired(newLastName)) {
			setValidationMessage("");
		}
	};

	const handleBirthDate = (e: ChangeEvent<HTMLInputElement>) => {
		setValidationMessage("");
		let newBirthDate:string = e.target.value;
		setBirthday(newBirthDate);
		if(Number(newBirthDate)>31){
			setValidationMessage("Invalid date");
		}
	}

	const handleBirthMonth = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedBirthMonth = event.target.value;

		setMonth(selectedBirthMonth);	
		
		if(selectedBirthMonth){
			setValidationMessage("")
		}
	}

	const handleBirthYear = (e: ChangeEvent<HTMLInputElement>) => {
		setValidationMessage("");
		const newBirthYear:string = e.target.value;

		setYear(newBirthYear);
		if(newBirthYear.length!=4){
			setValidationMessage("Invalid Year format");
		}

		if(Number(newBirthYear) > Number(new Date().getFullYear() - 18)){
			setValidationMessage("Invalid Year");
		}else{
			let noOfDays = new Date(Number(newBirthYear), Number(birthmonth), 0).getDate();
			if(Number(birthdate) > noOfDays){
					setValidationMessage("Are you entering correct date?")
			}
		}
	}

	const handleChangePhone = (event: ChangeEvent<HTMLInputElement>): void => {
		const newPhone: string = ValidationService.phoneConverter(event.target.value);

		setPhone(newPhone);

		if (ValidationService.validatePhone(newPhone)) {
			setValidationMessage("");
		}
	};
	  
	
	const handleProvinceChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		const selectedProvince = event.currentTarget.value as Province;

		setProvince(selectedProvince);

		if (selectedProvince) {
			setValidationMessage("");
		}
	};
	
	const datePrefix=()=>{
		if(birthdate.length==1){
			let date = "0"+birthdate
			setBirthday(date);	
			
		}else if(birthdate.length==0){
			let date = ""
			setBirthday(date)
		}
		else{
			setBirthday(birthdate);
		}

	}
	const doRegister = async (): Promise<void> => {		
		if (!ValidationService.validateRequired(firstName)) {
			if (firstNameInput.current) {
				firstNameInput.current.focus();
			}

			setValidationMessage(MessageConst.validation.firstNameError);

			return;
		}

		if (!ValidationService.validateRequired(lastName)) {
			if (lastNameInput.current) {
				lastNameInput.current.focus();
			}

			setValidationMessage(MessageConst.validation.lastNameError);

			return;
		}

		if (!ValidationService.validateRequired(birthdate) && 
			!ValidationService.validateRequired(birthmonth) && 
			!ValidationService.validateRequired(birthyear)) {			
			if(birthDateInput.current){
				birthDateInput.current.focus();
			}
			setValidationMessage(MessageConst.validation.birthdayError);
			return ;
		}
		else if(Number(birthdate)>31){
			setValidationMessage("Invalid date");
			return;
		}else if(!birthmonth){
			if(yearSelect.current){
				yearSelect.current.focus();
			}
			setValidationMessage("Invalid Month");
			return;
		}else if(Number(birthyear) > Number(new Date().getFullYear() - 18)){
			setValidationMessage("Invalid Year");
			return;
		}
		else if(birthyear.length<4){
			setValidationMessage("Invalid Year format");
		}else{
			let noOfDays = new Date(Number(birthyear), Number(birthmonth), 0).getDate();
			if(Number(birthdate) > noOfDays){
				setValidationMessage("Are you entering correct date?")
				return;
			}
		}			

		if (!province) {
			if (provinceSelect.current) {
				provinceSelect.current.focus();
			}

			setValidationMessage(MessageConst.validation.provinceError);

			return;
		}

		if (!ValidationService.validatePhone(phone)) {
			if (phoneInput.current) {
				phoneInput.current.focus();
			}

			setValidationMessage(MessageConst.validation.phoneError);

			return;
		}

		const newProfile = {
			...profile,
			firstName,
			lastName,
			province,
			birthday:(birthyear+"-"+birthmonth+"-"+birthdate),
			phone
		};

		let callback = props.onAddedCallback;
		if(sessionStorage.getItem("checkout") == "false"){
			callback = () => {
				navigate("/profile");
				dispatch(appActions.setIsCheckout(true));
			}
			sessionStorage.removeItem("checkout");
		}		

		dispatch(patientActions.updateProfileRequest({profile: newProfile}));
		if(callback) {
			callback();
		}

		// setEditValidationMessage("");
		// setEditMode(false);
	};

	const onNumberOnlyChange = (event: any) => {
		const keyCode = event.keyCode || event.which;

		if (
			keyCode === 8 ||  // Backspace
			keyCode === 46 || // Delete
			keyCode >= 37 && keyCode <= 40 || // Arrow keys
			(keyCode >= 48 && keyCode <= 57) || // Numbers 0-9
			(keyCode >= 96 && keyCode <= 105)   // Numpad numbers 0-9
		) {
			return;
		}

		event.preventDefault();
	};


	return (
		<div className="register-component">
			{isLoading ? (
				<LoaderComponent />
			) : (
				<div id="lr">															
					<div className="section-2">						
						<div className="container-18 w-container">
						<div className="w-row">
							<div className="w-col w-col-3"></div>
							<div className="column-50 w-col w-col-6">
							<h1 className="heading-3">Account information</h1>
							<p className="paragraph-7">Provide a few more details to create your profile.</p>
							<div className="w-form">
								<form id="email-form" name="email-form" data-name="Email Form"><label className="field-label">Full legal name</label>
								<p className="paragraph-4">To receive your prescription your name must be exactly as shown on your ID.</p>
								<input 
								type="text" 
								className="text-field-5 w-input" 
								name="First-Name" 
								data-name="First Name" 
								placeholder="First Name" 
								id="First-Name" 
								ref={firstNameInput}
								value={firstName}
								onChange={handleChangeFirstName}
								onKeyDown={handleKeyPress}
								disabled={profile?.firstName?true:false}
								 />
								 <input 
								 type="text" 
								 className="text-field-6 w-input" 
								 name="Last-Name" 
								 data-name="Last Name" 
								 placeholder="Last name" 
								 id="Last-Name" 
								 ref={lastNameInput}
								 value={lastName}
								 onChange={handleChangeLastName}
								 onKeyDown={handleKeyPress} 
								 disabled={profile?.lastName?true:false}
								 />
								<div className="text-block">Date of Birth</div>							
								<div className="columns w-row">			
									<div className="column w-col w-col-4">										
									<div className="div-block-18">
										<input 
										type="text" 
										className="text-field-7 w-input" 
										name="Day-of-Birth" 
										data-name="Day of Birth" 
										placeholder="DD" 
										id="Day-of-Birth"
										min={2}
										minLength={2}
										maxLength={2}
										size = {31}
										onBlur={datePrefix}
										ref={birthDateInput}
										value={birthdate}
										onChange = {handleBirthDate}
										onKeyDown={onNumberOnlyChange}
										required
										disabled={profile?.birthday?true:false}
										 /></div>
									</div>
									<div className="w-col w-col-4">
									<div className="div-block-21">
									<select 
							         	id="Day-of-Month" 
								        name="Day of Month" 
								        required data-name="Day of Month" 
								        className="select-field-3 w-select select-month"
								        ref={yearSelect}
									    onChange={handleBirthMonth}
									    value={birthmonth}
								     	onKeyDown={handleKeyPress}
										disabled={profile?.birthday?true:false}
								    >
									<option key={"undefined"} value={undefined} disabled={!!birthmonth}>
										MM
									</option>
									<option value="01">January</option>
									<option value="02">February</option>
									<option value="03">March</option>
									<option value="04">April</option>
									<option value="05">May</option>
									<option value="06">June</option>
									<option value="07">July</option>
									<option value="08">August</option>
									<option value="09">September</option>
									<option value="10">October</option>
									<option value="11">November</option>
									<option value="12">December</option>									
								</select>
											</div>
									</div>
									<div className="w-col w-col-4">
									<div className="div-block-22">
										<input 
										type="text" 
										className="text-field-8 w-input" 
										name="Year-of-Birth" 
										data-name="Year of Birth" 
										placeholder="YYYY" 
										id="Year-of-Birth"
										minLength={4} 
										maxLength={4}
										value={birthyear}
										ref={birthYearInput}
										onKeyDown={onNumberOnlyChange}
										onChange = {handleBirthYear}
										required
										disabled={profile?.birthday?true:false}
										 /></div>
									</div>
								</div>
								<div className="text-block-3">Province</div>
								<select 
								id="Province" 
								name="Province" 
								required data-name="Province" 
								className="select-field-3 w-select"
								disabled={profile?.province?true:false}
								ref={provinceSelect}
									onChange={handleProvinceChange}
									value={province}
									onKeyDown={handleKeyPress}
								>
									<option key={"undefined"} value={undefined} disabled={!!province}>
										Please select the province
									</option>
									{ServedProvinceList.map((item) => (
										<option key={item} value={item}>
											{ProvinceName[item]}
										</option>
									))}
								
									
								</select>
								<div className="text-block-4">Phone</div>
								<p className="paragraph-5">Your doctor will use this number to contact you.</p>
								<input 
								type="text" 
								className="text-field-15 w-input"
							    name="Phone-number" 
								data-name="Phone number" 
								placeholder="___- ___- ___" 
								id="Phone-number" 
								ref={phoneInput}
								value={phone}
								disabled={profile?.phone?true:false}
								onChange={handleChangePhone}
								onKeyDown={handleKeyPress} />
								<div className="text-block-5">ENTER 10 DIGITS WITHOUT THE COUNTRY CODE</div>
								</form>
								
								{/* validation column */}
								<div className={validationMessage?"w-form-fail-show validation":"w-form-fail"}>
									<div>{validationMessage}</div>
								</div>

							</div>
							{props.isRegisterButtonShown && (
								<button onClick={doRegister} className="button-11 w-button m-0px-t"><strong>Save and Continue</strong></button>
							)}
							</div>
							<div className="w-col w-col-3"></div>
						</div>
						</div>
					</div>
					
				</div>

			)}
		</div>
	);
});

export default Register;
