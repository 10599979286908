import React, {
	ChangeEvent,
	Ref,
	useRef,
	useImperativeHandle,
	useState,
	KeyboardEvent,
	forwardRef,
	useEffect
} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate, useLocation} from "react-router-dom";

import * as httpAuth from "../../http/auth";

import * as authActions from "../../redux/auth/handlers";
import {StateType} from "../../redux/types";

import MessageConst from "../../const/Message.const";

import ValidationService from "../../service/Validation.service";

import LoaderComponent from "../Loader";

import logo from "../../static/images/logo-winston.png";

import "./styles.scss";

interface ForgotPassProps {
	onRegisterModeClick: () => void;
	handleLoginModeClick: () => void;
	isShowResetBtn: boolean;
}

type ForgotModeType = "FORGOT" | "EMAIL_SENT" | "RESET";

export interface ForgotRefObject {
	doReset: () => Promise<void>;
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const ForgotPass = forwardRef((props: ForgotPassProps, ref: Ref<ForgotRefObject>) => {
	const {isLoading, authValidationMessage, authForgotMode} = useSelector(({auth}: StateType) => ({
		isLoading: auth.authLoading,
		authValidationMessage: auth.forgotPwdValidationMessage,
		authForgotMode: auth.forgotMode
	}));
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const query = useQuery();
	const token = query.get("token");
	const emailInput = useRef<HTMLInputElement>(null);
	const passwordInput = useRef<HTMLInputElement>(null);
	const repeatPasswordInput = useRef<HTMLInputElement>(null);
	const [checkingToken, setCheckingToken] = useState<boolean>(true);
	const [changingPass, setChangingPass] = useState<boolean>(false);
	const [forgotMode, setForgotMode] = useState<ForgotModeType>("FORGOT");
	const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [repeatPassword, setRepeatPassword] = useState<string>("");
	const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
	const [isRepeatPasswordShown, setIsRepeatPasswordShown] = useState<boolean>(false);
	const [isPasswordsMatches, setIsPasswordsMatches] = useState<boolean>(false);
	const [validationMessage, setValidationMessage] = useState<string>("");

	useEffect(() => {
		setValidationMessage(authValidationMessage);
	}, [authValidationMessage]);
	useEffect(() => {
		setForgotMode(authForgotMode);
	}, [authForgotMode])

	useImperativeHandle(ref, () => ({doReset}));

	useEffect(() => {
		const validateTokenRequest = async () => {
			if (token) {				
				try {
					await httpAuth.validateToken(token);
					setForgotMode("RESET");
				} catch (err) {
					setForgotMode("FORGOT");
				}
			}

			setCheckingToken(false);
		};

		validateTokenRequest();
	}, [token]);
	useEffect(() => {
		const checkPasswordsMatch = () => {
			if (!ValidationService.validatePassword(password)) {
				setIsPasswordsMatches(false);

				return;
			}

			if (!ValidationService.validatePasswordEquality(password, repeatPassword)) {
				setIsPasswordsMatches(false);

				return;
			}

			setIsPasswordsMatches(true);
		};

		checkPasswordsMatch();
	}, [password, repeatPassword]);

	const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			if (forgotMode === "RESET") {
				submitNewPass();
			} else if (forgotMode === "FORGOT") {
				doReset();
			}
		}
	};

	const handleEmailChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const email = event.target.value.trim();
		setEmail(email);
	};

	const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
		const password = e.target.value;

		setPassword(password);

		if (ValidationService.validatePassword(password)) {
			setValidationMessage("");
		}
	};

	const handleChangeRepeatPassword = (e: ChangeEvent<HTMLInputElement>) => {
		const repeatPassword = e.target.value;

		setRepeatPassword(repeatPassword);

		if (ValidationService.validatePasswordEquality(password, repeatPassword)) {
			setValidationMessage("");
		}
	};

	const doReset = async (): Promise<void> => {
		if (!email) {
			if (emailInput.current) {
				emailInput.current.focus();
			}

			setValidationMessage(MessageConst.validation.emailRequired);
			return;
		}

		if (!ValidationService.validateEmail(email)) {
			if (emailInput.current) {
				emailInput.current.focus();
			}

			setValidationMessage(MessageConst.validation.emailError);
			return;
		}

		dispatch(authActions.forgotPasswordRequest({email}));
	};

	const submitNewPass = async (): Promise<void> => {
		if (!ValidationService.validatePassword(password)) {
			if (passwordInput.current) {
				passwordInput.current.focus();
			}

			setValidationMessage(MessageConst.validation.passwordError);

			return;
		}

		if (!ValidationService.validatePasswordEquality(password, repeatPassword)) {
			if (repeatPasswordInput.current) {
				repeatPasswordInput.current.focus();
			}

			setValidationMessage(MessageConst.validation.passwordErrorEquality);

			return;
		}
		console.log("validated");
		if (token) {
			try {
				console.log("setting new passwrod");
				setChangingPass(true);

				const response = await httpAuth.setNewPassword(token, password);
				const authToken = response.data;
				console.log("auth token", authToken);
				dispatch(authActions.setToken(authToken));
				setChangingPass(false);
				dispatch(authActions.getProfileRequest({mode: "forgot"}));
			} catch (err) {
				const error = err as any;
				const {message} = error.response.data;

				setChangingPass(false);
				setPassword("");
				setRepeatPassword("");
				setValidationMessage(message);
			}
		}
	};

	const handleEyeClick = (mode: String) => () => {
		if (mode === "repeatPassword") {
			setIsRepeatPasswordShown(!isRepeatPasswordShown);
		} else {
			setIsPasswordShown(!isPasswordShown);
		}
	};

	const renderHeader = () => {
		switch (forgotMode) {
			case "EMAIL_SENT":
				return "Check Your Email";
			case "RESET":
				return "Continue to Reset Your Password";
			default:
				return "Reset Your Password";
		}
	};
	const renderMicroHeader = () => {
		switch (forgotMode) {
			case "EMAIL_SENT":
				return (
					<>
						You’ll receive an email shortly with a link to reset your password.
						<br />
						<br />
						Please contact us at <a href="mailto:welisten@askwinston.ca">
							welisten@askwinston.ca
						</a>{" "}
						if you need additional assistance.
					</>
				);
			case "RESET":
				return "Enter a new password.";
			default:
				return "Please enter your email address to receive an email to reset your password.";
		}
	};

	return (
		<div className="forgot-pass-component">
			{(isLoading || checkingToken || changingPass) && <LoaderComponent />}
			{!isLoading && (
				<div className="forgot-pass-wrapper">
					<div className="forgot-content">
						<div className="forgot-left">
							<div className="shop-link">
								<Link to="/shop" className="text">Shop</Link>
							</div>
							<div className="forgot-left-image">
								<div className="forgot-logo-img">
									<img src={logo} alt="" />
								</div>
							</div>
						</div>
						<div className="forgot-right">
							<div className="content">
							<h3 className="header">{renderHeader()}</h3>
							<div className="micro-header">
								<span>{renderMicroHeader()}</span>
							</div>
							{forgotMode === "FORGOT" && (
								<label className="email">
									<span>Email Address</span>
									<input
										ref={emailInput}
										type="text"
										value={email}
										onChange={handleEmailChange}
										onKeyPress={handleKeyPress}
									/>
								</label>
							)}
							{forgotMode === "RESET" && (
								<>
									<label className="password">
										<span>Password</span>
										<input
											ref={passwordInput}
											type={isPasswordShown ? "text" : "password"}
											value={password}
											onChange={handleChangePassword}
											onKeyPress={handleKeyPress}
										/>
										{isPasswordsMatches && <span className="check" />}
										<span
											className={"eye" + (!isPasswordShown ? " crossed" : "")}
											onClick={handleEyeClick("password")}
										></span>
									</label>
									<label className="password">
										<span>Confirm Password</span>
										<input
											ref={repeatPasswordInput}
											type={isRepeatPasswordShown ? "text" : "password"}
											value={repeatPassword}
											onChange={handleChangeRepeatPassword}
											onKeyPress={handleKeyPress}
										/>
										{isPasswordsMatches && <span className="check" />}
										<span
											className={"eye" + (!isRepeatPasswordShown ? " crossed" : "")}
											onClick={handleEyeClick("repeatPassword")}
										></span>
									</label>
								</>
							)}

							{validationMessage && (
								<div className="alert">{validationMessage}</div>
							)}

							{forgotMode === "FORGOT" && (
								<div className="register">
									<span>
										Not a Winston customer?{" "}
										<button onClick={props.onRegisterModeClick}>Register</button> Today
									</span>
								</div>
							)}
						</div>

						{props.isShowResetBtn && forgotMode === "FORGOT" && (
							<button className="reset-button dark" onClick={doReset}>
								RESET PASSWORD
							</button>
						)}
						{forgotMode === "RESET" && (
							<button className="reset-button dark" onClick={submitNewPass}>
								SUBMIT
							</button>
						)}

						{forgotMode !== "RESET" && (
							<button className="back-to-login-button" onClick={props.handleLoginModeClick}>
								BACK TO LOGIN
							</button>
						)}
						</div>
					</div>
					
				</div>
			)}
		</div>
	);
});
export default ForgotPass;
