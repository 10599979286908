import React, {
	useState,
	useRef,
	useEffect,
	useCallback,
	Fragment,
	useMemo,
	forwardRef,
	useImperativeHandle,
	Ref,
	RefObject
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import * as appActions from "../../redux/app/handlers";
import { StateType } from "../../redux/types";
import * as patientActions from "../../redux/patient/handlers";
import * as cartActions from "../../redux/cart/handlers";
import AddBillingCardComponent, { AddBillingCardRefObject } from "../AddBillingCard";
import Login, { LoginRefObject } from "../Login";
import CartComponent from "../PatientCart";
import ShippingAddressesComponent from "../ShippingAddresses";
import AddShippingAddress, { AddShippingAddressRefObject } from "../AddShippingAddress";
import RegisterComponent, { RegisterRefObject } from "../Register";
import ForgotPass, { ForgotRefObject } from "../ForgotPass";
import ConfirmComponent from "./components/Confirm";
import LoaderComponent from "../Loader";
import ConsultationSchedule, { dayTimeSlotT, IConsultationScheduleCardRefObject } from "./components/ConsultationSchedule";
import logo from "../../static/images/winston-logo-small.png";
import person from "../../static/images/person.png";
import component from "../../static/images/component.png";
import event from "../../static/images/event.png";
import creditCard from "../../static/images/credit-card.png";
import Verification from "./components/Verification";
import "./styles.scss";
import SecurityLabel from "../Shared/SecurityLabel";
import { clearValidationMessage } from "../../redux/cart/handlers";

interface OrderProcessProps {
	onCurrentStepChange: (step: number) => void;
	sidebarRef: RefObject<HTMLDivElement>;
}

export interface OrderProcessRef {
	handleNextStep: () => void;
}

const stepUrl = [
	"/cart",
	"/signin",
	"/account",
	"/shipping",
	"/doctor-consult",
	"/id",
	"/payment",
	"/confirm"
];
export type AuthModeType = "LOGIN" | "REGISTER" | "FORGOT";

const OrderProcess = forwardRef(
	({ onCurrentStepChange, sidebarRef }: OrderProcessProps, ref: Ref<OrderProcessRef>) => {
		const dispatch = useDispatch();
		const navigate = useNavigate();
		const { pathname } = useLocation();
		const loginRef = useRef<LoginRefObject>(null);
		const registerRef = useRef<RegisterRefObject>(null);
		const forgotRef = useRef<ForgotRefObject>(null);
		const addBillingCardRef = useRef<AddBillingCardRefObject>(null);
		const addShippingAddressRef = useRef<AddShippingAddressRefObject>(null);
		const addDoctorConsult = useRef<IConsultationScheduleCardRefObject>(null);
		// let expressDeliverySession = sessionStorage.getItem("isExpressDelivery")!=null?
		// 							(sessionStorage.getItem('isExpressDelivery')=='false'?false:true):false;
		const [isExpressDelivery, setIsExpressDelivery]  = useState<boolean>(false);
		// const isProfileNext  = useRef(false);

		const [step, setStep] = useState<number>(1);
		const [authMode, setAuthMode] = useState<AuthModeType>("REGISTER");

		const [addAddressMode, setAddAddressMode] = useState<boolean>(false);
		const [addCardMode, setAddCardMode] = useState<boolean>(false);
		const [validationMessage, setValidationMessage] = useState<string>("");
		const [showUploadIdMsg, setShowUploadIdMsg] = useState<boolean>(false);

		const [consultation, setConsultation] = useState<null | {
			consultationDateTime: string;
			slotId: number;			
			selDate: Date
			selTime: dayTimeSlotT
		}>(null);

	const cartValidationMessage = useSelector((state: StateType) => state.cart.validationMessage);

	useEffect(()=>{
		setValidationMessage(cartValidationMessage);
	}, [cartValidationMessage]);

		const {
			profile,
			selectedShippingAddress,
			selectedBillingCard,
			selectedProducts,
			selectedPromoCode,
			isLoading,
			isCheckout			
		} = useSelector(({ auth, patient, cart, app }: StateType) => ({
			profile: auth.profile,
			selectedShippingAddress: patient.selectedShippingAddress,
			selectedBillingCard: patient.selectedBillingCard,
			selectedPromoCode: patient.selectedPromoCode,
			selectedProducts: cart.selectedProducts,
			isLoading: cart.makingOrder,			
			isCheckout: app.isCheckout
		}));

		// const hasCard = profile && profile.billingCards.length > 0;

		const getPrevSteps = (nextStep: number): number[] =>
			Array.from(Array(nextStep).keys()).map((v: number) => v + 1);

		const isStepReady = useCallback(
			(s: number): boolean => {
				switch (s) {
					case 1:
						return selectedProducts.length !== 0 || !isCheckout;
					case 2:
						return !!profile;
					case 3:											
						return !!profile?.phone && !!profile.firstName;						
						// return true;
					case 4:
						return !!selectedShippingAddress;
						// return true;
					case 5:
						return !!consultation?.slotId;
						// return true;
					case 6:
						// return !!profile?.idDocument;
						return true;
					case 7:
						return !!selectedBillingCard;	
						// return false					
					default:
						return true;
				}
			},
			[profile, selectedProducts, selectedBillingCard, selectedShippingAddress, consultation]
		);
		const handleEditMode=()=>{
			setAddCardMode(false)
			dispatch(patientActions.selectBillingCard(null));
			navigate("/cart")
		}
		

		const handleSetCardMode = () =>{
			setAddCardMode(true);
			dispatch(patientActions.selectBillingCard(null));
		}
		const handleNextStep = useCallback(() => {			
			let newStep = step;
			while (newStep < 8) {
				newStep++;
				if (!isStepReady(newStep) || newStep === 8) {
					break;
				}
			}
		
			setStep(newStep);
			onCurrentStepChange(newStep);
			navigate(stepUrl[newStep - 1]);
		}, [step, navigate, isStepReady, onCurrentStepChange]);

		useImperativeHandle(ref, () => ({
			handleNextStep
		}));

		useEffect(() => {
			if (profile && step === 2) {
				handleNextStep();
			}
		}, [profile, handleNextStep, step]);

		useEffect(() => {
			if (sidebarRef?.current) {
				sidebarRef.current.scrollTo(0, 0);
			}
		}, [authMode, sidebarRef]);

		useEffect(() => {
			handleStepProcess();
		}, [
			pathname,
			profile,
			selectedBillingCard,
			selectedShippingAddress,
			addAddressMode,
			addCardMode,
			isStepReady,
			dispatch,
			onCurrentStepChange
		]);

		const handleStepProcess = () => {			
			const stepNumber = stepUrl.findIndex((url) => url === pathname);

			for (let i = 1; i <= stepNumber + 1; i++) {
				// Auto select default shipping address
				if (
					i === 4 &&
					profile &&
					!selectedShippingAddress &&
					profile.shippingAddresses.length !== 0 &&
					!addAddressMode
				) {
					const primaryIndex = profile.shippingAddresses.findIndex((address) => address.isPrimary);

					dispatch(
						patientActions.selectShippingAddress(profile.shippingAddresses[primaryIndex] || null)
					);
				}

				// Auto select default billing address
				if (
					i === 7 &&
					profile &&
					!selectedBillingCard &&
					profile.billingCards.length !== 0 &&
					!!(profile.billingCards.findIndex((card) => card.isPrimary) + 1) &&
					!addCardMode
				) {
					const primaryIndex = profile.billingCards.findIndex((card) => card.isPrimary);

					dispatch(patientActions.selectBillingCard(profile.billingCards[primaryIndex] || null));
				}

				if (!isStepReady(i)) {
					setStep(i);

					onCurrentStepChange(i);

					if (pathname !== stepUrl[i - 1]) {
						navigate(stepUrl[i - 1]);
					}

					return;
				} else if (i === 2 && isStepReady(i) && pathname === "/login") {
					setStep(i);
					onCurrentStepChange(i);
					navigate("/account");
				} else {
					setStep(i);
					onCurrentStepChange(i);
				}
			}
		}

		const handleSubscription = useCallback(async (): Promise<void> => {
			dispatch(clearValidationMessage());
			if (
				selectedProducts.length !== 0 &&
				selectedShippingAddress &&
				selectedShippingAddress.id &&
				selectedBillingCard &&
				selectedBillingCard.id
			) {
				let isExpressDelivery:boolean = sessionStorage.getItem("isExpressDelivery")=="true"?true:false;				
				let utmSource = window.sessionStorage.getItem("utm_source");

				const cart = {
					text: selectedPromoCode ? selectedPromoCode.code : "",
					utmSource: utmSource,
					expressShipping:isExpressDelivery
				};
				
				sessionStorage.removeItem("isExpressDelivery");
				dispatch(
					cartActions.makeSubscriptionRequest({
						cart: { ...cart, consultationSlotId: consultation?.slotId }
					})
				);
			
			}
		}, [
			selectedProducts,
			selectedShippingAddress,
			selectedPromoCode,
			dispatch,
			consultation,
			navigate,
			selectedBillingCard
		]);

		const handleRegisterModeClick = (): void => {
			setAuthMode("REGISTER");
		};

		const handleLoginModeClick = (): void => {
			setAuthMode("LOGIN");
		};
		const handleForgotModeClick = (): void => {
			// setAuthMode("FORGOT");
			navigate("/forgot-password");
		};

		const handleNextClick = useCallback(async (): Promise<void> => {
		
			switch (step) {
				case 1:
					if (isStepReady(step)) {
						handleNextStep();
					}
					break;
				case 2:					
					if (profile) {
						return handleNextStep();
					}
					if (authMode === "REGISTER" && registerRef.current) {						
						return await registerRef.current.doRegister();
					}
					if (authMode === "LOGIN" && loginRef.current) {
						return await loginRef.current.doLogin();
					}
					if (authMode === "FORGOT" && forgotRef.current) {
						return await forgotRef.current.doReset();
					}
					break;
				case 3:
					// isProfileNext.current = true;	
					if(profile?.firstName){
						return handleNextStep() ;
					}	 											
				    if (!isStepReady(step) && registerRef.current) {
						return await registerRef.current.doRegister();
					}
					handleNextStep()
					break;
				case 4:					
					if (!isStepReady(step) && addShippingAddressRef.current) {						
						return await addShippingAddressRef.current.submit();						
					}
					handleNextStep();
					break;
				case 7:					
					if (!isStepReady(step) && addBillingCardRef.current) {						
						return await addBillingCardRef.current.submit();
					}
					handleNextStep();
					break;
				case 6:
				     if (!isStepReady(step)) {
					setShowUploadIdMsg(true);
					return;
			     	}
				   handleNextStep();
					break;
				case 5:
					if (addDoctorConsult.current && addDoctorConsult.current.validate()) {
						const {
							value: slotId,
							selectedDate,
							label: selectedTime,							
						} = addDoctorConsult.current.getTimeSlotId();
						const date = moment(selectedDate).format("MMMM Do, YYYY");
						
						setConsultation({ slotId, consultationDateTime: `${date} at ${selectedTime}`, selDate:selectedDate, selTime:{label:selectedTime, value:slotId}} );						
						sessionStorage.setItem("doctorConsultation", `${date} at ${selectedTime}`);
						// Save selected date and time to redux state
						dispatch(
							cartActions.addConsultationDateRequest({
								date: selectedDate.toISOString(),
								time: selectedTime,
							})
						);
						
						// handleNextStep();
					}
					
					break;
			}
		}, [handleNextStep, authMode, isStepReady, profile, step]);

		const handleBackClick = useCallback(() => {
			if (step === 4) {
				setStep(3);
				onCurrentStepChange(3);
				navigate(stepUrl[0]);
				return;
			}
			const newStep = step - 1;
			setStep(newStep);
			onCurrentStepChange(newStep);
			navigate(stepUrl[newStep - 1]);
		}, [navigate, onCurrentStepChange, step]);

		// const handleCartIconClick = () => {
		// 	if (isStepReady(1)) {
		// 		setStep(1);
		// 		onCurrentStepChange(1);
		// 		navigate("/cart");
		// 	}
		// };

		const handleAccountIconClick = () => {
			if (isStepReady(3) && !profile) {
				setStep(3);
				onCurrentStepChange(3);
				navigate("/account");
			}
			

			// if (isStepReady(3)) {
			// 	setStep(3);
			// 	onCurrentStepChange(3);
			// 	navigate("/account");
			// }
		};

		const handleShippingIconClick = () => {
			const nextStep = 4;
			if (getPrevSteps(nextStep).every(isStepReady)) {
				setStep(nextStep);
				onCurrentStepChange(nextStep);
				navigate("/shipping");
			}
		};

		const handlePaymentIconClick = () => {
			const nextStep = 7;
			if (getPrevSteps(nextStep).every(isStepReady)) {
				setStep(nextStep);
				onCurrentStepChange(nextStep);
				navigate("/payment");
			}
		};

		// const handleIdentificationIconClick = () => {
		// 	const nextStep = 6;
		// 	if (getPrevSteps(nextStep).every(isStepReady)) {
		// 		setStep(nextStep);
		// 		onCurrentStepChange(nextStep);
		// 		navigate("/id");
		// 	}
		// };

		const handleConsultationScheduleIconClick = () => {
			const nextStep = 5;
			if (getPrevSteps(nextStep).every((s) => isStepReady(s))) {
				setStep(nextStep);
				onCurrentStepChange(nextStep);
				navigate("/doctor-consult");
			}
		};

		// const handleConfirmIconClick = () => {
		// 	const nextStep = 8;
		// 	if (getPrevSteps(nextStep).every(isStepReady)) {
		// 		setStep(nextStep);
		// 		onCurrentStepChange(nextStep);
		// 		navigate("/confirm");
		// 	}
		// };

		const [isActive, setActive] = useState(false);

		const toggleClass = () => {
			setActive(!isActive);
		};

		const renderStepsNav = (
			<Fragment>

				{/* header checkout process here */}
				<div id="login" className="top-op-header">
					<div className="login-component">
						<div>
							<div className="navbar-3 w-nav" >
								<div className="container-17 w-container">
									<nav role="navigation" className={isActive ? 'nav-menu-2 w-nav-menu show' : 'nav-menu-2 w-nav-menu'}>
										<div className="res-menu">

											<div data-hover="1" data-delay="0" className="w-dropdown">
												<div className="shop-3 w-dropdown-toggle">
													<Link to="/contact-us" className="help-link">
														<div className="text-block-68">Help</div>
													</Link>
												</div>
												<nav className="w-dropdown-list">
													<Link to="/contact-us" className="link-block-9 w-inline-block help-link">
														<div className="text-block-68">Help</div>
													</Link>
												</nav>
											</div>
										</div>
									</nav>
									<div onClick={toggleClass} className={isActive ? 'menu-button w-nav-button bg' : 'menu-button w-nav-button'}>
										<div className="icon w-icon-nav-menu"></div>
									</div>
									<Link onClick={()=>{dispatch(appActions.setIsCheckout(true));}} to="/" className="link-block-7 w-inline-block">
										<img src={logo} width="151" alt="logo" className="image-22" />
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* header checkout process ends here */}

				{/* checkout process levels starts here */}
				
				<div id="shipping" className="top-op-imgprocess">
					<div className="section-pi" >
					{step !==8 ?
						<div className="container-5 w-container">
							<div className="w-row">
								<div className="w-col w-col-3"></div>
								<div className="column-52 w-col w-col-6">
								
									<div className="div-block-45">
										<Link to="/account" className={"w-inline-block " + (step === 3 ? " imgActive" : "imgNonActive")} onClick={handleAccountIconClick}>
											<img src={person} width="39" alt="person" className="image-5" />
										</Link>
										<Link to="/shipping" className={"w-inline-block " + (step === 4 ? " imgActive" : "imgNonActive")} onClick={handleShippingIconClick}>
											<img src={component} width="39" alt="component" />
										</Link>
										<Link to="/doctor-consult" className={"w-inline-block " + (step === 5 ? " imgActive" : "imgNonActive")} onClick={handleConsultationScheduleIconClick}>
											<img src={event} width="39" alt="event" />
										</Link>
										<Link to="payment" className={"w-inline-block " + (step === 7 ? " imgActive" : "imgNonActive")} onClick={handlePaymentIconClick}>
											<img src={creditCard} width="39" alt="credit" />
										</Link>

									</div>
								
								</div>
								<div className="w-col w-col-3"></div>
							</div>
						</div>
						:null}
					</div>
				</div>				
				{/* checkout process levels ends here */}

			</Fragment>
		);

		const renderAuthComponents = () => {
			switch (authMode) {
				case "REGISTER":
					sessionStorage.setItem("checkout", "true");					
					return (
						<Login
							ref={loginRef}
							isLoginButtonShown={false}
							handleLoginModeClick={handleLoginModeClick}
							onRegisterModeClick={handleRegisterModeClick}
							handleForgotModeClick={handleForgotModeClick}
							onAddedCallback={handleNextClick}
						/>
					);
				case "FORGOT":
					return (
						<ForgotPass
							onRegisterModeClick={handleRegisterModeClick}
							handleLoginModeClick={handleLoginModeClick}
							isShowResetBtn={false}
							ref={forgotRef}
						/>
					);

				default:
					sessionStorage.setItem("checkout", "true");
					return (
						<Login
							ref={loginRef}
							isLoginButtonShown={false}
							handleLoginModeClick={handleLoginModeClick}
							onRegisterModeClick={handleRegisterModeClick}
							handleForgotModeClick={handleForgotModeClick}
							onAddedCallback={handleNextClick}
						/>
					);
			}
		};

		const renderStep = (s: number) => {
			switch (s) {
				case 1: {
					return <CartComponent 
							handleNext = {handleNextClick}
							/>;
				}
				case 2: {					
					return !profile && renderAuthComponents();
				}

				case 3: {
					return <RegisterComponent
						ref={registerRef}
						isRegisterButtonShown={false}
						handleLoginModeClick={handleLoginModeClick}
						onAddedCallback={() => {
							handleNextStep();																
						}}
					/> 
				}

				case 4: {
					return (
						<div>
							{profile && (
								<div id="shipping">
									<div className="section-4">

										<div className="container-19 w-container">
											<div className="w-row">
												<div className="w-col w-col-3"></div>
												<div className="column-53 w-col w-col-6">
													<h1 className="heading-4">Shipping address</h1>
													<p className="paragraph-8">Please provide your shipping address.</p>
													{profile.shippingAddresses.length ? (
														<Fragment>													
															<div className="shipping-addresses-wrapper">
																<ShippingAddressesComponent
																	isSelectable={true}
																	isDeletable={false}
																	mode="checkout"
																/>
															</div>
															<label className="radio-button-field-2 shipping w-radio">
																<div className={(!selectedShippingAddress ? 'radio-button-2' : 'radio-button') + " w-form-formradioinput w-form-formradioinput--inputType-custom shipping w-radio-input"}>
																</div>
																<input type="radio" className="radioButton" data-name="Radio" id="radio" name="shipping-address"
																	checked={!selectedShippingAddress}
																	onChange={() => {
																		setAddAddressMode(true);
																		dispatch(patientActions.selectShippingAddress(null));
																	}}
																/>
																<span className="radio-button-label shipping w-form-label">Add a new shipping address</span>
															</label>
															{!selectedShippingAddress && (
																<div className="new-shipping-address-container">
																	<AddShippingAddress
																		ref={addShippingAddressRef}
																		isProfileMode={false}
																		onAddedCallback={() => {
																			setAddAddressMode(false);
																			handleNextStep();
																		}}
																		isSelectNewAddress
																		mode="checkout"
																	/>
																</div>
															)}
														</Fragment>
													) : (
														<AddShippingAddress
															ref={addShippingAddressRef}
															isProfileMode={false}
															onAddedCallback={() => {
																setAddAddressMode(false);
																handleNextStep();
															}}
															isSelectNewAddress
															mode="checkout"
														/>
													)}
													<h1 className="heading-14 shipping">
														{/* Want your product faster? */}
													</h1>
													<div className="form-block-6 x w-form">
														<form id="email-form-2" name="email-form-2" data-name="Email Form 2">
															{/* <label className="radio-button-field shipping w-radio">
																<div className={(isExpressDelivery ? 'radio-button-2' : '') + " w-form-formradioinput w-form-formradioinput--inputType-custom radio-button shipping w-radio-input"}></div>
																<input type="radio" data-name="Radio" id="radio" name="radio" value="Radio"
																	checked={isExpressDelivery}
																	style={{ opacity: '0', position: "absolute", zIndex: -1 }}
																	onChange={() => {
																		setIsExpressDelivery(true);
																		sessionStorage.setItem("isExpressDelivery", "true");
																	}} />
																<span className={(isExpressDelivery ? 'radio-button-label' : 'radio-button-label-2') + " shipping w-form-label"}>$9.99 for next day express shipping</span>
															</label> */}

															<label className="radio-button-field-2 shipping w-radio">
																<div className={(!isExpressDelivery ? 'radio-button-2' : '') + " w-form-formradioinput w-form-formradioinput--inputType-custom radio-button shipping w-radio-input"} ></div>
																<input type="radio" data-name="Radio" id="radio" name="radio" value="Radio"
																	checked={!isExpressDelivery}
																className="radioButton"
																	onChange={() => {
																		setIsExpressDelivery(false);
																		sessionStorage.setItem("isExpressDelivery", "false");
																	}} />
																<span className={(!isExpressDelivery ? 'radio-button-label' : 'radio-button-label-2') + " shipping w-form-label"}>Free standard shipping</span>
															</label>
														</form>
													</div>
												</div>
											</div>
											<div className="w-col w-col-3"></div>
										</div>
									</div>
								</div>
							)}
						</div>
					);
				}
				case 7: {
					return (
						<div>
							{profile && (
								<div>
									
									<div className="order-process-component__addBillingCard">
										<AddBillingCardComponent
											isProfileMode={false}
											ref={addBillingCardRef}
											onAddedCallback={() => {
												setAddCardMode(false);
												handleNextStep();
											}}
											handleEditMode={handleEditMode}
											isSelectNewCard
											mode="checkout"
											billingCards = {profile.billingCards.length}
											selectedBillingCard = {selectedBillingCard}
											setAddCardMode = {handleSetCardMode}
											handleNext = {handleNextClick}
										/>
									</div>
									
								</div>
							)}
						</div>
					);
				}
				case 6: {
					return (						
						<Verification
							showValidationMessage={showUploadIdMsg}
							doctorConsultDate={consultation?.consultationDateTime}
						/>
					);
				}
				case 5: {
					return (
						<ConsultationSchedule
							ref={addDoctorConsult}
							selectedProductsCategories={selectedProducts.map(
								(item) => item.product.problemCategory
							)}
							handleNextClick={handleNextClick}
							handleNextStep={handleNextStep}
							doctorConsultDate={consultation}
						/> 
					);
					
				}
				case 8: {
					return (
						<ConfirmComponent
							selectedProducts={selectedProducts}
							doctorConsultDate={consultation?.consultationDateTime}
						/>
					);
				}
				default: {
					return null;
				}
			}
		};

		const renderButtons = useMemo(
			() => (
				<Fragment>
					<div id="lr">
						<div className="container-18 w-container">
							<div className="w-row">
								<div className="w-col w-col-3"></div>
								<div className="column-50 w-col w-col-6">
									{step !== 8 && step !== 1 && step !== 5 && step !== 7 ? (
										<button disabled={!isStepReady(step) && ![2, 3, 4].includes(step)}
											onClick={handleNextClick} className="button-11 w-button"><strong>Save and Continue</strong></button>
									) : null}

									{step === 8 ? (
										<button onClick={handleSubscription} className="button-11 w-button"><strong><strong>Submit Order</strong></strong></button>
									) : null}

									{/* {step ===6 ? (
										<button onClick={handleNextClick} className="button-11 w-button">Book the Time</button>
									) : null} */}

									{/* {step === 7 ? (
										<button onClick={handleNextClick} className="button-11 w-button">Continue</button>
									) : null} */}

								</div>
								<div className="w-col w-col-3"></div>
							</div>
						</div>
					</div>
				</Fragment>
			),
			[handleBackClick, handleSubscription, isStepReady, step, handleNextClick]
		);

		// const renderPrivacyPolicyBlock = () => {
		// 	return (
		// 		<div className="privacy-policy-block">
		// 			Important: By clicking “Next”, you agree to submit your photos for identity
		// 			verification. For more info, see <Link to="/privacy-policy" target={"_blank"}>Privacy Policy</Link>.
		// 		</div>
		// 	);
		// };

		// const renderFinalNoteBlock = () => {
		// 	return (
		// 		<div className="privacy-policy-block gray2">
		// 			Important: By clicking “$0 Due Now’ you agree that:
		// 			<br />
		// 			If prescribed for treatment, you will be enrolled in our convenient auto-refill delivery program
		// 			and charged on each scheduled prescription refill date. You may pause, cancel,
		// 			or use your auto-refills at any time.
		// 		</div>
		// 	);
		// };

		return (
			<div className="order-process-component">
				{isLoading ? (
					<LoaderComponent />
				) : (
					<div className="steps-content-buttons-wrapper">
						{step !== 1 &&  step !== 2 && <div className="steps">{renderStepsNav}</div>}
						<div className="content">

							{/* {validationMessage && (
								<div className="alert">{validationMessage}</div>
							)} */}

							<div className="content-sub-alert">{renderStep(step)}</div>
						</div>

						{step !== 1 && step !==2 && (
							<div className="back-next-buttons">{renderButtons}</div>
						)}

						{/* {step === 5 && renderPrivacyPolicyBlock()}
						{step === 8 && renderFinalNoteBlock()} */}

						<SecurityLabel />
					</div>
				)}
			</div>
		);
	}
);

export default OrderProcess;
