import React, {useState, ChangeEvent} from "react";

import {setTrackOrder} from "../../../../http/orders";

import ValidationService from "../../../../service/Validation.service";

import MessageConst from "../../../../const/Message.const";

import PopUp from "../../../PopUp";
import Order from "../../../../model/Order";
import CustomInput from "../../../CustomInput";
import LoaderComponent from "../../../Loader";

import "./styles.scss";
import CustomSelect from "../../../CustomSelect";

interface ShipOrderPopUpProps {
	order: Order;
	onClose: () => void;
	notify: (msg: string) => void;
	loadOrders: () => void;
	couriers: string[];
}
type CourierOption = {
	label: string;
	value: string;
};

const ShipOrderPopUp = ({order, onClose, notify, loadOrders, couriers}: ShipOrderPopUpProps) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [trackingNumber, setTrackingNumber] = useState<string>("");
	const [validationMessage, setValidationMessage] = useState<string>("");
	const [courier, setCourier] = useState<CourierOption>();

	const handleChangeTrackingNumber = (e: ChangeEvent<HTMLInputElement>) => {
		const rxNumber = ValidationService.trackingNumberConverter(e.target.value);

		setTrackingNumber(rxNumber);
	};

	const handleSubmit = async () => {
		if (!trackingNumber) {
			setValidationMessage(MessageConst.validation.trackNumberError);

			return;
		}

		if (!courier?.value) {
			setValidationMessage(MessageConst.validation.courierError);

			return;
		}

		try {
			setLoading(true);
			await setTrackOrder(order.id, {trackingNumber, courier: courier.value});
			notify("Order has been successfuly updated");
		} catch (err: any) {
			if ([400, 401, 403].includes(err.response.status)) {
				const res = err.response.data;
				notify(res.message);
			} else {
				notify(MessageConst.error.unknownError);
			}
		} finally {
			onClose();
			loadOrders();
		}
	};

	const courierOptions: CourierOption[] = couriers.map((c) => ({value: c, label: c}));

	return (
		<PopUp>
			{loading && <LoaderComponent showShadow />}
			<div className="ship-order-wrapper">
				<div className="title">{`Enter tracking number for an order #
					${order.number + "-" + order.subNumber.toString().padStart(2, "0")}:`}</div>
				<CustomSelect
					label="Courier"
					placeholder="Select courier"
					options={courierOptions}
					value={courier}
					onChange={(selected: CourierOption) => {
						if (!Array.isArray(selected) && selected) {
							setCourier(selected);
						}
					}}
				/>
				<CustomInput
					label="Track number"
					value={trackingNumber}
					onChange={handleChangeTrackingNumber}
				/>
				{validationMessage && <div className="validation-error">{validationMessage}</div>}
				<div className="action-buttons">
					<button onClick={onClose}>Cancel</button>
					<button onClick={handleSubmit}>Submit</button>
				</div>
			</div>
		</PopUp>
	);
};

export default ShipOrderPopUp;
