import React from "react";

import Order from "../../model/Order";
import OrderItem from "../../model/OrderItem";

import DateService from "../../service/Date.service";

import IDsvg from "../../static/images/icon-id.svg";

import "./styles.scss";
import moment from "moment-timezone";
import OrderStatus from "../../model/OrderStatus";

interface PharmacistPendingOrdersProps {
	order: Order;
	handleOpenViewID: (type: string, viewID: string) => void;
	handleApproveShipping: (order: Order) => void;
	handleShipped: (order: Order) => void;
	handleReject: (order: Order) => void;
	isWaitingApprove?: boolean;
}

const PharmacistPendingOrderItem = ({
																			order,
																			handleOpenViewID,
																			handleApproveShipping,
																			handleShipped,
																			handleReject,
																			isWaitingApprove
																		}: PharmacistPendingOrdersProps) => {
	const {
		date,
		shippingAddressLine1,
		shippingAddressLine2,
		shippingAddressCity,
		shippingAddressProvince,
		shippingAddressPostalCode,
		pharmacyRxId,
		prescription,
		items,
		user
	} = order;

	return (
		<div className={`content ${order.promoCode ? "content__withPromocode" : ""}`}>
			<div className="column-a">
				<div className="column-a1">
					<div className="top">
						<div className="column-a11">
							<div className="field">
								Order number:
								<span>{order.number + "-" + order.subNumber.toString().padStart(2, "0")}</span>
							</div>
							<div className="field">
								Date:<span>{moment(date).format("MMM D, YYYY, HH:mm")}</span>
							</div>
							<div className="field">
								Shipping info:
								<div className="address">
									<div>{`${shippingAddressLine1}${
										shippingAddressLine2 ? ", " : ""
									}${shippingAddressLine2}`}</div>
									<div>{`${shippingAddressCity}, ${shippingAddressProvince}, ${shippingAddressPostalCode}`}</div>
								</div>
							</div>
							{order.shippingPrice>0 &&
								<div className="field express-shipping">
									Express Shipping
									{/* <span>Express Shipping</span> */}
								</div>
							}
							{!!order.promoCode && <div className="field promocode">
								Promocode: <span>{order.promoCode}</span>
							</div>}
						</div>
						<div className="column-a12">
							{pharmacyRxId && (
								<div className="field">
									Pharmacy RX ID:<span>{pharmacyRxId}</span>
								</div>
							)}
							{prescription && order.status !== OrderStatus.waitingRxCheck && (
								<>
									<div className="field">
										RX valid to:
										<span>{DateService.formatDoctorsDateTime(prescription.toDate)}</span>
									</div>
									<div className="field">
										Refills remaining:<span>{prescription.refillsLeft}</span>
									</div>
								</>
							)}
							{order.status === OrderStatus.waitingRxCheck && (
								<>
									<div className="field">
										Provided RX:
										<span>{order.rxTransferNumber}</span>
									</div>
									<div className="field">
										Pharmacy Name:
										<span>{order.pharmacyNameAndAddress}</span>
									</div>
									<div className="field">
										Pharmacy Phone:
										<span>{order.pharmacyPhone}</span>
									</div>
								</>
							)}
						</div>
					</div>
					<div className="bottom">
						{items.map((item: OrderItem) => (
							<div key={item.productDosage} className="field items">
								{item.productDosage}
								<span>{"$" + (0.01 * item.productPrice).toFixed(2)}</span>
							</div>
						))}
					</div>
				</div>
				{user && (
					<div className="column-a2">
						<div
							className={`top ${
								(order.user?.idDocument || order.user?.insuranceDocument) && "bottom-border"
							}`}
						>
							<div className="field">
								Name:<span>{user.firstName + " " + user.lastName}</span>
							</div>
							<div className="field">
								Birthday:<span>{DateService.formatBirthday(user.birthday)}</span>
							</div>
							<div className="field">
								Phone:<span>{user.phone}</span>
							</div>
							<div className="field">
								E-mail:<span>{user.email}</span>
							</div>
						</div>
						{order.user?.idDocument && (
							<div className="bottom">
								<div
									className="field view-id"
									onClick={() => handleOpenViewID("ID", order.user!.idDocument!)}
								>
									<img src={IDsvg} alt="id"/>
									<span>VIEW ID</span>
								</div>
							</div>
						)}
						{order.user?.insuranceDocument && (
							<div className="bottom">
								<div
									className="field view-id"
									onClick={() => handleOpenViewID("Insurance", order.user!.insuranceDocument!)}
								>
									<img src={IDsvg} alt="id"/>
									<span>VIEW INSURANCE</span>
								</div>
							</div>
						)}
						{order.rxDocumentId && (
							<div className="bottom">
								<div
									className="field view-id"
									onClick={() => handleOpenViewID("Rx", order.rxDocumentId!.toString())}
								>
									<img src={IDsvg} alt="id"/>
									<span>VIEW RX</span>
								</div>
							</div>
						)}
					</div>
				)}
			</div>
			<div className="column-b">
				<div
					className={"button good-1" + (isWaitingApprove ? "" : " disabled")}
					onClick={() => isWaitingApprove && handleApproveShipping(order)}
				>
					APPROVE
				</div>
				<div
					className={"button good-2" + (!isWaitingApprove ? "" : " disabled")}
					onClick={() => !isWaitingApprove && handleShipped(order)}
				>
					SHIPPED
				</div>
				{/* <div
					className={"button bad" + (isWaitingApprove ? "" : " disabled")}
					onClick={() => isWaitingApprove && handleReject(order)}
				>
					REJECT
				</div> */}
			</div>
		</div>
	);
};

export default PharmacistPendingOrderItem;
