import React from "react";
import {useSelector, useDispatch} from "react-redux";

import * as patientActions from "../../../../redux/patient/handlers";
import {StateType} from "../../../../redux/types";

import {ReactComponent as CheckIcon} from "../../../../static/images/checkIcon.svg";

import "./styles.scss";

const ShippingAddresses = () => {
	const dispatch = useDispatch();
	const {profile} = useSelector(({auth}: StateType) => ({
		profile: auth.profile
	}));

	const handleSetPrimaryAddressClick = (id: number | undefined) => () => {
		if (id) {
			dispatch(patientActions.setPrimaryShippingAddressRequest(id));
		}
	};

	return (
		<div>
			{profile?.shippingAddresses.map((address) => (
				<div className="shipping-address-item" key={address.id}>
					<div className="address-info">
						<div className="street">
							<div className="street-title">Address</div>
							<div className="street-text">{address.addressLine1}</div>
						</div>
						<div className="suite">
							<div className="suite-title">Suite/Apt</div>
							<div className="suite-text">{address.addressLine2 || "N/A"}</div>
						</div>
						<div className="city">
							<div className="city-title">City</div>
							<div className="city-text">{address.addressCity}</div>
						</div>
						<div className="province">
							<div className="province-title">Province</div>
							<div className="province-text">{address.addressProvince}</div>
						</div>
						<div className="country">
							<div className="country-title">Country</div>
							<div className="country-text">{address.addressCountry}</div>
						</div>
						<div className="postal">
							<div className="postal-title">Postal Code</div>
							<div className="postal-text">{address.addressPostalCode}</div>
						</div>
					</div>
					<div className="primary">
						{address.isPrimary ? (
							<div className="primary-block">
								<div className="check-icon">
									<CheckIcon />
								</div>
								<div className="primary-text">Primary address</div>
							</div>
						) : (
							<button className="set-primary" onClick={handleSetPrimaryAddressClick(address.id)}>
								Set Primary
							</button>
						)}
					</div>
				</div>
			))}
		</div>
	);
};

export default ShippingAddresses;
