import {StateType} from "./types";

export const getToken = (state: StateType) => state.auth.token;

export const getProfile = (state: StateType) => state.auth.profile;

export const getProductItems = (state: StateType) => state.product.productItems;

export const getSelectedProducts = (state: StateType) => state.cart.selectedProducts;

export const getProfilePageToOpen = (state: StateType) => state.app.profilePageToOpen;
