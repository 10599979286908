import React, {
	ChangeEvent,
	useRef,
	useState,
	KeyboardEvent,
	forwardRef,
	useEffect
} from "react";
import {  CodeResponse, useGoogleLogin } from "@react-oauth/google";
import MessageConst from "../../../const/Message.const";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../../redux/auth/handlers";
import { clearNextPath } from "../../../redux/app/handlers";
import { Link, useNavigate, useLocation } from "react-router-dom";
import google from "../../../static/images/google.png";
import { clearSignUpValidationMessage } from "../../../redux/auth/handlers";
import ValidationService from "../../../service/Validation.service";
import { StateType } from "../../../redux/types";
import axios from "axios";


interface SignupProps{
doRegister: ()=> void;
}

const Signup = forwardRef ((props:SignupProps)=>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
	const { pathname } = useLocation();
    const emailInput = useRef<HTMLInputElement>(null);
	const passwordInput = useRef<HTMLInputElement>(null);
    const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const authValidationMessage = useSelector((state: StateType) => state.auth.signUpValidationMessage);
    const [validationMessage, setValidationMessage] = useState<string>("");
    const [isAgreed, setIsAgreed] = useState<boolean>(false);
    const isLogin = useRef<boolean>(false);
	
	useEffect(()=>{
		dispatch(authActions.setSignUpValidationMessage(""));
	}, [])

	useEffect(()=>{
		setValidationMessage(authValidationMessage);
	}, [authValidationMessage]);

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			props.doRegister();
		}
	};
    const handleNewEmail = (e: ChangeEvent<HTMLInputElement>) => {
		const newEmail: string = e.target.value.trim();

		setEmail(newEmail);

		if (ValidationService.validateEmail(newEmail)) {
			setValidationMessage("");
		}
	};
	const handleNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value);

		if (ValidationService.validatePassword(e.target.value)) {
			setValidationMessage("");
		}
	};
	const handleNavigation = (path:any) => {
		navigate(path);
	}
    const doRegister = async (): Promise<void> => {
		if (!ValidationService.validateEmail(email)) {
			if (emailInput.current) {
				emailInput.current.focus();
			}

			setValidationMessage(MessageConst.validation.emailError);

			return;
		}

		if (!ValidationService.validatePassword(password)) {
			if (passwordInput.current) {
				passwordInput.current.focus();
			}

			setValidationMessage(MessageConst.validation.passwordError);

			return;
		}
		if (!isAgreed) {
			setValidationMessage(MessageConst.validation.termsOfUseError);

			return;
		}
		let utmSource = window.sessionStorage.getItem("utm_source");
		dispatch(clearSignUpValidationMessage());
		dispatch(
			authActions.registerRequest({
				email,
			    password,
				isAgreed,
				utmSource,
				isLoginMode: pathname === "/login"
			})
		)
	}
    const handleIsAgreed = (event: ChangeEvent<HTMLInputElement>) => {
		const newAgreedState: boolean = event.target.checked;

		if (!newAgreedState) {
			setValidationMessage(MessageConst.validation.termsOfUseError);
		} else {
			setValidationMessage("");
		}

		setIsAgreed(newAgreedState);
	};
    const onSuccessSignup = async (res: CodeResponse) => {
		let tokenId = res.code;
		let utmSource = window.sessionStorage.getItem("utm_source");
		if(isLogin.current==true){			
			dispatch(
				authActions.loginRequest({
					email,
					password,
					isLoginMode: pathname === "/login",
					tokenId
				})
			);
		}else{
		
			dispatch(
				authActions.registerRequest({
					email,
					password,
					isAgreed,
					utmSource,
					isLoginMode: pathname === "/login",
					tokenId
				})
			)
		}		
		// res.disconnect();
	}
    const onFailureSignup = (res: any) => {
		console.log('[Login Failed] res:', res);
	}

	
	const signIn  = useGoogleLogin({
		onSuccess: onSuccessSignup,
		onError: onFailureSignup,
		flow: 'auth-code'
	});

	const handleGoogleSignUp = () =>{
		isLogin.current = false;
		signIn();
	}

	const handleGoogleSignIn = () =>{
		isLogin.current = true;
		signIn();
	}
    return(
        <div className="div-block-40">
        <h1 className="heading">Join Winston</h1>
        <p className="paragraph">Let’s start with a few details to set up your space</p>
        <div className="w-form">
            <form id="email-form" name="email-form" data-name="Email Form" method="get" className="form">
                <input
                    type="email"
                    className="text-field w-input"
                    placeholder="Email address"
                    id="name"
                    ref={emailInput}
                    value={email}
                    onChange={handleNewEmail}
					onKeyDown={handleKeyPress}
                     />
                <input
                    type="password"
                    className="text-field-2 w-input"
                    placeholder="Password"
                    id="email"
                    ref={passwordInput}
                    value={password}
                    onChange={handleNewPassword}
					onKeyDown={handleKeyPress}
                     />
                {/* {validationMessage && (
                  <div className="alert">{validationMessage}</div>
                 )} */}
                <label className="w-checkbox checkbox-field">
                    <input
                        type="checkbox"
                        id="checkbox"
                        name="checkbox"
                        data-name="Checkbox"
                        required className="w-checkbox-input"
                        onChange={handleIsAgreed} checked={isAgreed}
                    />
					<span className="checkbox-label w-form-label">
                        I agree to the
                     <span>
                            <Link to="/terms-of-use" target="_blank">
                                {" "}
                             Terms of Use
                             </Link>{" "}
                              and{" "}
                            <Link to="/privacy-policy" target="_blank">
                                Privacy Policy
                             </Link>
                      </span>
                     </span></label>

                     {/* validation column  */}
                    <div className={validationMessage?"w-form-fail-show":"w-form-fail"}>
                    	<div dangerouslySetInnerHTML={{ __html: validationMessage }}></div>
                     </div>	

                <span onClick={doRegister}  className="button-8 w-button"><strong>Create Account</strong></span>
                     <div className="div-block-41"><img src={google} width="27" alt="" className="image-19" />
                   <button type="button" onClick={handleGoogleSignUp} className="heading-30">Sign up with Google</button>
                      </div>

    
             </form>

            </div>

          </div>
       )
})
export default Signup;
