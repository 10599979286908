import React, {Component} from "react";
import Profile from "../../model/Profile";
import logoBlackSvg from "../../static/images/logo-black.svg";
import DoctorPendingOrdersComponent from "../DoctorPendingOrders";
import PatientsListComponent from "../PatientsList";
import "./styles.scss";
import DoctorConsultations from "../DoctorConsultations";

interface DoctorDashboardProps {
	profile: Profile;
	onLogoutClick: () => void;
}

interface DoctorDashboardState {
	selectedTab: DoctorTab;
}

type DoctorTab = "PendingOrders" | "Patients" | "Consultations";

export default class DoctorDashboardComponent extends Component<DoctorDashboardProps,
	DoctorDashboardState> {
	public constructor(props: DoctorDashboardProps) {
		super(props);
		this.state = {
			selectedTab: "PendingOrders"
		};
	}

	public render() {
		const {selectedTab} = this.state;
		return (
			<div className="doctor-dashboard-component prefiles">
				<div className="menu-section">
					<div className="box">
						<div className="left">
							<h1>
								Hi, Dr. {this.props.profile.firstName} {this.props.profile.lastName}
							</h1>
						</div>
						<div className="logo" onClick={this.handleLogoClick}>
							<span>
								<img className="AW-logo-black" src={logoBlackSvg} alt="Winston The Premier Men's Health Virtual Clinic"/>
							</span>
						</div>
						<div className="right-buttons">
							<span>
								<div className="logout" onClick={this.props.onLogoutClick}>
									<p>Log out</p>
								</div>
							</span>
						</div>
					</div>
				</div>

				<div className="tabs-section">
					<span
						className={"link" + (selectedTab === "PendingOrders" ? " active" : "")}
						onClick={this.handlePendingOrdersClick}
					>
						Pending Orders
					</span>
					<span
						className={"link" + (selectedTab === "Patients" ? " active" : "")}
						onClick={this.handlePatientsClick}
					>
						Patients
					</span>

					<span
						className={"link" + (selectedTab === "Consultations" ? " active" : "")}
						onClick={this.handleConsultationsClick}
					>
						Consultations
					</span>
				</div>

				<div className="content-section">
					{selectedTab === "PendingOrders" && <DoctorPendingOrdersComponent/>}

					{selectedTab === "Patients" && (
						<PatientsListComponent
							profile={this.props.profile}
							isOrdersTabShow={true}
							isMessageTabShow={true}
						/>
					)}

					{
						selectedTab === "Consultations" && (
							<DoctorConsultations />
						)
					}
				</div>
			</div>
		);
	}

	private handleLogoClick = () => {
		window.scrollTo(0, 0);
	};

	private handlePendingOrdersClick = () => {
		this.setState({
			selectedTab: "PendingOrders"
		});
	};

	private handlePatientsClick = () => {
		this.setState({
			selectedTab: "Patients"
		});
	};

	private handleConsultationsClick = () => {
		this.setState({
			selectedTab: "Consultations"
		});
	};
}
