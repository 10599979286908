import React from "react";
import {useNavigate} from "react-router-dom";

import {deleteTransferRxProgress} from "../../../../http/patient";

import "./styles.scss";

interface ContinueTransferProps {
	resumeTransfer: () => void;
}

const ContinueTransfer = ({resumeTransfer}: ContinueTransferProps) => {
	const navigate = useNavigate();

	const dropSaved = async () => {
		await deleteTransferRxProgress();

		navigate("/profile/transfer-rx/");
	};

	return (
		<div>
			<div className="continue-transfer-wrapper">
				<div className="title">Welcome Back!</div>

				<div className="content">
					<p>We noticed that you already have a prescription transfer started.</p>
					<p>Do you want to resume your previous transfer prescription request or start over?</p>
				</div>
				<div className="button-links">
					<button onClick={resumeTransfer}>Resume</button>
					<button onClick={dropSaved}>Start Over</button>
				</div>
			</div>
		</div>
	);
};

export default ContinueTransfer;
