import React, {Component} from "react";
import "./styles.scss";
import LoaderComponent from "../Loader";
import crossSvg from "../../static/images/cross.svg";
import MdPostConsultNote from "../../model/MdPostConsultNote";
import DialogComponent, {DialogProps} from "../Dialog";

interface PostConsultProps {
	orderId: number;
	close: () => void;
	finish: (orderid: number, postConsultPayload: MdPostConsultNote) => void;
}

interface PostConsultState {
	isLoading: boolean;
	payload: MdPostConsultNote;
	dialogProps: DialogProps | null;
}

export default class PostConsultComponent extends Component<PostConsultProps, PostConsultState> {
	constructor(props: Readonly<PostConsultProps>) {
		super(props);
		this.state = {
			isLoading: false,
			payload: {
				counseledOnSideEffects: 0,
				counseledOnSymptomsForEmergency: 0,
				patientsConcernsAddressed: 0,
				requestedCounselByPharmacy: 1,
				doctorsCustomNotes: ""
			} as MdPostConsultNote,
			dialogProps: null
		};
	}

	public render() {
		return (
			<div className="post-consult-component prefiles">
				{this.state.isLoading ? (
					<LoaderComponent />
				) : (
					<div className="box">
						<div className="close" onClick={this.props.close}>
							<img src={crossSvg} alt="" />
						</div>
						<div className="messages-container">
							<div className="title">
								<p>MD POST-CONSULT NOTE</p>
							</div>
							<div className="question">
								<div className="question-title">
									1. Patient was counseled on the most common side effects of the prescribed
									medication
								</div>
								<div className="radio-question">
									<label className="input-container">
										Yes
										<input
											type="radio"
											name="counseledOnSideEffects"
											checked={this.state.payload.counseledOnSideEffects === 1}
											onChange={() =>
												this.setState((state) => {
													state.payload.counseledOnSideEffects = 1;
													return state;
												})
											}
										/>
										<span className="checkmark"></span>
									</label>
									<label className="input-container">
										No
										<input
											type="radio"
											name="counseledOnSideEffects"
											checked={this.state.payload.counseledOnSideEffects === 0}
											onChange={() =>
												this.setState((state) => {
													state.payload.counseledOnSideEffects = 0;
													return state;
												})
											}
										/>
										<span className="checkmark"></span>
									</label>
								</div>
							</div>
							<div className="question">
								<div className="question-title">
									2. Patient was counseled on signs or symptoms requiring an urgent visit to a
									clinic or emergency department
								</div>
								<div className="radio-question">
									<label className="input-container">
										Yes
										<input
											type="radio"
											name="counseledOnSymptomsForEmergency"
											checked={this.state.payload.counseledOnSymptomsForEmergency === 1}
											onChange={() =>
												this.setState((state) => {
													state.payload.counseledOnSymptomsForEmergency = 1;
													return state;
												})
											}
										/>
										<span className="checkmark"></span>
									</label>
									<label className="input-container">
										No
										<input
											type="radio"
											name="counseledOnSymptomsForEmergency"
											checked={this.state.payload.counseledOnSymptomsForEmergency === 0}
											onChange={() =>
												this.setState((state) => {
													state.payload.counseledOnSymptomsForEmergency = 0;
													return state;
												})
											}
										/>
										<span className="checkmark"></span>
									</label>
								</div>
							</div>
							<div className="question">
								<div className="question-title">
									3. Patient had all medical questions/concerns addressed by the prescribring
									physician
								</div>
								<div className="radio-question">
									<label className="input-container">
										Yes
										<input
											type="radio"
											name="patientsConcernsAddressed"
											checked={this.state.payload.patientsConcernsAddressed === 1}
											onChange={() =>
												this.setState((state) => {
													state.payload.patientsConcernsAddressed = 1;
													return state;
												})
											}
										/>
										<span className="checkmark"></span>
									</label>
									<label className="input-container">
										No
										<input
											type="radio"
											name="patientsConcernsAddressed"
											checked={this.state.payload.patientsConcernsAddressed === 0}
											onChange={() =>
												this.setState((state) => {
													state.payload.patientsConcernsAddressed = 0;
													return state;
												})
											}
										/>
										<span className="checkmark"></span>
									</label>
								</div>
							</div>
							<div className="question">
								<div className="question-title">
									4. Are there any custom notes doctor wants to include in the prescription notes
									section
								</div>
								<div className="text-question">
									<textarea
										placeholder="Your answer"
										value={this.state.payload.doctorsCustomNotes}
										onChange={({target: {value}}) =>
											this.setState((state) => {
												state.payload.doctorsCustomNotes = value;
												return state;
											})
										}
									></textarea>
								</div>
							</div>
							<div className="question">
								<div className="question-title">
									5. Did the patient request additional counseling to be conducted by the Pharmacy
								</div>
								<div className="radio-question">
									<label className="input-container">
										Yes
										<input
											type="radio"
											name="requestedCounselByPharmacy"
											checked={this.state.payload.requestedCounselByPharmacy === 1}
											onChange={() =>
												this.setState((state) => {
													state.payload.requestedCounselByPharmacy = 1;
													return state;
												})
											}
										/>
										<span className="checkmark"></span>
									</label>
									<label className="input-container">
										No
										<input
											type="radio"
											name="requestedCounselByPharmacy"
											checked={this.state.payload.requestedCounselByPharmacy === 0}
											onChange={() =>
												this.setState((state) => {
													state.payload.requestedCounselByPharmacy = 0;
													return state;
												})
											}
										/>
										<span className="checkmark"></span>
									</label>
								</div>
							</div>
							<div className="finish" onClick={() => this.handleFinishClick(this.props.orderId)}>
								Submit Notes
							</div>
						</div>
					</div>
				)}
				{this.state.dialogProps && <DialogComponent {...this.state.dialogProps} />}
			</div>
		);
	}

	private handleFinishClick = (orderId: number): void => {
		if (this.isDataComplete()) {
			this.props.finish(orderId, this.state.payload);
		} else {
			this.notify('You must manually answer "Yes" to the first 3 questions.');
		}
	};

	private isDataComplete = (): boolean => {
		const {
			counseledOnSideEffects,
			counseledOnSymptomsForEmergency,
			patientsConcernsAddressed
		} = this.state.payload;
		return (
			!!counseledOnSideEffects && !!counseledOnSymptomsForEmergency && !!patientsConcernsAddressed
		);
	};

	private notify = (text: string) => {
		this.setState({
			dialogProps: {
				text,
				buttons: {
					OK: {
						class: "dark",
						callback: () => {
							this.setState({
								dialogProps: null
							});
						}
					}
				}
			}
		});
	};
}
