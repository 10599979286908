import React, {useState} from "react";

import {ReactComponent as CheckIcon} from "../../static/images/checkIcon.svg";

import "./styles.scss";

const CustomCheckbox = (props: any) => {
	const [focused, setFocused] = useState<boolean>(false);

	return (
		<label className="custom-checkbox">
			<div className={`checkbox ${focused ? "focused" : ""}`}>{props.checked && <CheckIcon />}</div>
			<input
				type="checkbox"
				onChange={props.onChange}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
			/>
		</label>
	);
};

export default CustomCheckbox;
