import React, { useEffect } from "react";

import {
	ServedFactsCategories,
	FactsCategoryShortName,
	FactsCategoryName,
	FactsCategory,
	FactsCategoryArticles,
	FactsCategoryCircles
} from "../../model/TheFactsCategories";

import JumpNavigation from "../JumpNavigation";

import "./styles.scss";
import {Link} from "react-router-dom";
import { Title } from "react-meta-elements";

const TheFacts = () => {
	const scrollToCategory = (category: string, isSmooth: boolean) => {
		const element = document.getElementById(category);

		if (element) {
			window.scrollTo({top: element.offsetTop - 130, behavior: isSmooth ? "smooth" : "auto"});
		}
	};
	useEffect(()=>{
		let search = window.location.search;
		if(search!=""){
			window.sessionStorage.setItem("utm_source", search.split('?')[1]);
		}
	}, [])

	const renderFactsCategories = () => {
		const renderArticles = (category: FactsCategory) =>
			FactsCategoryArticles[category].map((article, index) => (
				<div className="item-article" key={article.link}>
					<div className="text-info">
						<h3 className="article-title">{article.title}</h3>
						<div className="article-description">{article.description}</div>
						<Link to={`/article/${article.link}`} className="article-link">
							Learn more
						</Link>
					</div>
					{FactsCategoryCircles[index]}
				</div>
			));

		return ServedFactsCategories.map((category) => (
			<div className="category-item" id={category} key={category}>
				<h2 className="title">{FactsCategoryName[category]}</h2>
				<div className="category-item-articles">{renderArticles(category)}</div>
			</div>
		));
	};

	return (
		<>
			<Title>
				Learn more about Winston | Start consultation 
				</Title>
				
		<div className="learn-component prefiles">
			<div className="top-header">
				<div className="text-info">
					<h1 className="title">The Facts</h1>
					<div className="description">
						Winston wants to take the guess work out of improving your health. We break down
						everything you need to know so that you can be confident in your decisions.
					</div>
				</div>
			</div>
			<JumpNavigation
				items={ServedFactsCategories}
				shortNames={FactsCategoryShortName}
				names={FactsCategoryName}
				onItemClick={scrollToCategory}
				mode="facts"
			/>
			<div className="facts-categories-items">{renderFactsCategories()}</div>
		</div>
		</>
	);
};

export default TheFacts;
