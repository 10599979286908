import {all} from "redux-saga/effects";

import authSagas from "./auth/sagas";
import productSagas from "./product/sagas";
import patientSagas from "./patient/sagas";
import cartSagas from "./cart/sagas";

export default function* root() {
	yield all([...authSagas, ...productSagas, ...patientSagas, ...cartSagas]);
}
