import React from "react";
import "./styles.scss";
// import iconLock from "../../../static/images/lock-icon.svg";

const SecurityLabel = () => {
    return (
        <div className={"SecurityLabel"}>
            <div className={"container"}>
                {/* <img src={iconLock} className={"icon"}  alt={""}/> */}
                {/* <span className={"label"}>256-BIT TLS SECURITY</span> */}
            </div>
        </div>
    );
};

export default SecurityLabel;
