import React, {ChangeEvent, Component} from "react";
import Profile from "../../model/Profile";
import PatientService from "../../service/Patient.service";
import DoctorOrdersComponent from "../DoctorOrders";
import LoaderComponent from "../Loader";
import "./styles.scss";
import DateService from "../../service/Date.service";

interface PatientsListProps {
	profile: Profile;
	isOrdersTabShow: boolean;
	isMessageTabShow: boolean;
}

interface PatientsListState {
	isLoading: boolean;
	patients: Profile[];
	filteredPatientName: string;
	filteredPatients: Profile[];
	ordersPatient?: Profile;
	isFetching: boolean;
}

export default class PatientsListComponent extends Component<PatientsListProps, PatientsListState> {
	public constructor(props: Readonly<PatientsListProps>) {
		super(props);
		this.state = {
			isLoading: true,
			patients: [],
			filteredPatientName: "",
			filteredPatients: [],
			isFetching: false,
		};
	}

	public async componentDidMount() {
		this.setState({ isLoading: true, isFetching: false });

    try {
        let page = 0;
        let total = 1; 
        let allPatients: Profile[] = this.state.patients || []; 

        while (page < total) {
			this.setState({ isFetching: true });
            const response: Response = await PatientService.getAll(page);

            if (response.ok) {
                const { patients, totalPages, currentPage } = await response.json();

                allPatients = [...allPatients, ...patients];
                allPatients.sort((a: any, b: any) => (a.id as number) - (b.id as number));

                this.setState(prevState => ({
                    isLoading: false,
                    patients: allPatients,
                    filteredPatients: allPatients,
					isFetching: false
                }));

				total = totalPages;
                page = currentPage + 1;
            } else {
                throw new Error("Failed to fetch patients data.");
            }
        }
    } catch (error) {
        console.error("Error fetching patients:", error);
        this.setState({ isLoading: false });
    }
	}

	public render() {
		return (
			<div className="patients-list-component">
				{this.state.isLoading ? (
					<LoaderComponent />
				) : (
					<>
						<div className="search-row">
							<span>Search patient:</span>
							<input
								type="text"
								value={this.state.filteredPatientName}
								onChange={this.handleSearchInputChange}
							/>
						</div>
						{this.state.filteredPatients.length === 0 ? (
							<div className="order-row">There are no patients.</div>
						) : (
							this.state.filteredPatients.map((patient) => {
								return (
									<div key={patient.id} className="order-row">
										<div className="content">
											<div className="row2">
												Name{" "}
												<div className="patient-name">
													{patient.firstName + " " + patient.lastName}
												</div>
												Birthday{" "}
												<div className="patient-birthday">
													{DateService.formatBirthday(patient.birthday)}
												</div>
												{this.props.isOrdersTabShow && (
													<div
														className="button orders"
														onClick={() => this.handleOrdersClick(patient as Profile)}
													>
														ORDERS
													</div>
												)}
											</div>
										</div>
									</div>
								);
							})
						)}
						
					</>
				)}
				{this.props.isOrdersTabShow && this.state.ordersPatient && (
					<DoctorOrdersComponent
						patient={this.state.ordersPatient}
						close={this.handleOrdersClose}
					/>
				)}
			</div>
		);
	}

	private handleOrdersClick = (selectedPatient: Profile): void => {
		this.setState({ordersPatient: selectedPatient});
	};

	private handleOrdersClose = (): void => {
		this.setState({ordersPatient: undefined});
	};

	private handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
		this.setState({
			filteredPatientName: event.target.value,
			filteredPatients: this.getSearch(this.state.patients, event.target.value)
		});
	};

	private getSearch = (patients: Profile[], str: string): Profile[] => {
		const searchWords: string[] = str?.trim().toLowerCase().split(/\x20+/);
		return patients.filter((patient) =>
			searchWords.every(
				(word) =>
					patient?.firstName?.toLowerCase().includes(word) ||
					patient?.lastName?.toLowerCase().includes(word)
			)
		);
	};
}
