import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from "redux-saga";
import { useNavigate } from 'react-router-dom';

import rootSaga from "./sagas";
import rootReducer from "./reducers";

const sagaMiddleware = createSagaMiddleware();

const reduxStore = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
	devTools: true, 
  });
  
  sagaMiddleware.run(rootSaga);
  
export default reduxStore;
export {sagaMiddleware};