import React, {Component} from "react";
import Order from "../../model/Order";
import OrderItem from "../../model/OrderItem";
import Profile from "../../model/Profile";
import DateService from "../../service/Date.service";
import OrderService from "../../service/Order.service";
import UserService from "../../service/User.service";
import crossSvg from "../../static/images/cross.svg";
import LoaderComponent from "../Loader";
import "./styles.scss";

interface DoctorOrdersProps {
	patient: Profile;
	close: () => void;
}
interface DoctorOrdersState {
	isLoading: boolean;
	orders: Order[];
}
export default class DoctorOrdersComponent extends Component<DoctorOrdersProps, DoctorOrdersState> {
	constructor(props: Readonly<DoctorOrdersProps>) {
		super(props);
		this.state = {
			isLoading: true,
			orders: []
		};
	}

	public async componentDidMount() {
		const response: Response = await OrderService.getDoctorOrders(this.props.patient.id as number);
		if (response.ok) {
			const orders: Order[] = await response.json();
			orders.sort((a, b) => (b.id as number) - (a.id as number));
			this.setState({
				isLoading: false,
				orders
			});
		} else {
			this.setState({
				isLoading: false
			});
		}
	}

	public render() {
		return (
			<div className="doctor-orders-component prefiles">
				{this.state.isLoading ? (
					<LoaderComponent />
				) : (
					<div className="box">
						<div className="close" onClick={this.props.close}>
							<img src={crossSvg} alt="" />
						</div>
						<div className="name">Orders of {UserService.fullName(this.props.patient)}</div>
						{this.state.orders.map((order: Order) => {
							return (
								<div key={order.id} className="order-row">
									<div className="id">
										#{order.number + "-" + order.subNumber.toString().padStart(2, "0")}
									</div>
									<div className="date">{DateService.formatDoctorsDate(order.date)}</div>
									<div className="items">
										{order.items.reduce((acc: string, item: OrderItem) => {
											return acc + (!acc ? "" : ", ") + item.productDosage;
										}, "")}
									</div>
									<div className="status">{order.status.replace("_", " ")}</div>
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}
