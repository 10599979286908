import React, {useState} from "react";
import "./styles.scss";

const Tooltip = ({text}: {text: string | React.ReactElement}) => {
	const [isVisible, setIsVisible] = useState<boolean>(false);

	const handleSetVisible = () => setIsVisible(s => !s);

	return (
		<div className={`Tooltip ${isVisible ? "" : ""}`}>
			<i onClick={handleSetVisible} className="Tooltip__icon"/>
			<div className={`Tooltip__top ${isVisible ? "Tooltip__top_visible" : ""}`}>
				<div className="Tooltip__content">
					{text}
					<span className="Tooltip__crossWrapper">
					<i onClick={handleSetVisible} className="Tooltip__cross"/>
					</span>
				</div>
				<i className="Tooltip__arrow"/>
			</div>
		</div>
	);
};

export default Tooltip;