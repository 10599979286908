import React, {Component} from "react";
import "./styles.scss";
import logoBlackSvg from "../../static/images/logo-black.svg";
import Profile from "../../model/Profile";
import PharmacistPendingOrdersComponent from "../PharmacistPendingOrders";
import PatientsListComponent from "../PatientsList";

interface PharmacistDashboardProps {
	profile: Profile;
	onLogoutClick: () => void;
}

interface PharmacistDashboardState {
	selectedTab: PharmacistTab;
}

type PharmacistTab = "PendingOrders" | "Patients";

export default class PharmacistDashboardComponent extends Component<
	PharmacistDashboardProps,
	PharmacistDashboardState
> {
	public constructor(props: PharmacistDashboardProps) {
		super(props);
		this.state = {
			selectedTab: "PendingOrders"
		};
	}

	public render() {
		return (
			<div className="pharmacist-dashboard-component">
				<div className="menu-section">
					<div className="box">
						<div className="left">
							<h1>
								Hi, {this.props.profile.firstName} {this.props.profile.lastName}
							</h1>
						</div>
						<div className="logo" onClick={this.handleLogoClick}>
							<span>
								<img className="AW-logo-black" src={logoBlackSvg} alt="Winston The Premier Men's Health Virtual Clinic" />
							</span>
						</div>
						<div className="right-buttons">
							<span>
								<div className="logout" onClick={this.props.onLogoutClick}>
									<p>Log out</p>
								</div>
							</span>
						</div>
					</div>
				</div>

				<div className="tabs-section">
					<span
						className={"link" + (this.state.selectedTab === "PendingOrders" ? " active" : "")}
						onClick={this.handlePendingOrdersClick}
					>
						Pending Orders
					</span>
					{/* <span className={"link" + (this.state.selectedTab === "Patients" ? " active" : "")} onClick={this.handlePatientsClick}>Patients</span> */}
				</div>

				<div className="content-section">
					{this.state.selectedTab === "PendingOrders" && <PharmacistPendingOrdersComponent />}

					{this.state.selectedTab === "Patients" && (
						<PatientsListComponent
							profile={this.props.profile}
							isOrdersTabShow={false}
							isMessageTabShow={false}
						/>
					)}
				</div>
			</div>
		);
	}

	private handleLogoClick = () => {
		window.scrollTo(0, 0);
	};

	private handlePendingOrdersClick = () => {
		this.setState({
			selectedTab: "PendingOrders"
		});
	};

	private handlePatientsClick = () => {
		this.setState({
			selectedTab: "Patients"
		});
	};
}
