import React, {useState, useEffect} from "react";

import http from "../../http";

import LoaderComponent from "../Loader";

import crossSvg from "../../static/images/cross.svg";

import "./styles.scss";

interface ImageViewProps {
	imageViewProps: string | number | undefined; // 540px X 340px
	title: string;
	close: () => void;
}

const ImageView = (props: ImageViewProps) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isPDF, setIsPDF] = useState<boolean>(false);
	const [document, setDocument] = useState<string>();

	useEffect(() => {
		const getDocument = async () => {
			setIsLoading(true);

			const response = await http.get(`/document/${props.imageViewProps}/view`, {
				responseType: "blob"
			});

			setIsLoading(false);

			if (response.headers["content-type"] === "application/pdf") {
				setIsPDF(true);
			} else {
				setIsPDF(false);
			}

			const urlCreator = window.URL || window.webkitURL;
			const imageUrl = urlCreator.createObjectURL(response.data);

			setDocument(imageUrl);
		};
		if (props.imageViewProps) {
			getDocument();
		}
	}, [props]);

	return (
		<div className="image-view-component ">
			<div className="box">
				<div className="close" onClick={props.close}>
					<img src={crossSvg} alt="" />
				</div>
				<div className="title">{props.title}</div>
				<div className="container">
					{isLoading && <LoaderComponent />}
					{props.imageViewProps === "none" && <div>There is no image</div>}
					{props.imageViewProps !== ("none" || "waiting") && !isPDF && (
						<img src={document} alt="" />
					)}
					{props.imageViewProps !== ("none" || "waiting") && isPDF && (
						<iframe src={document} title="ID Image" />
					)}
				</div>
			</div>
		</div>
	);
};

export default ImageView;
