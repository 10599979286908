import React from "react";

import "./styles.scss";

interface CustomInputProps {
	label?: string;
	[key: string]: any;
}

const CustomInput = ({label, ...props}: CustomInputProps) => {
	return (
		<div className="custom-input-wrapper">
			{label && <div className="label">{label}</div>}
			<input className="custom-input" {...props} />
		</div>
	);
};

export default CustomInput;
