import React from "react";
import {useSelector, useDispatch} from "react-redux";

import * as patientActions from "../../../../redux/patient/handlers";
import {StateType} from "../../../../redux/types";

import {getCardBrandImage} from "../../../../utils/helpers";

import {ReactComponent as CheckIcon} from "../../../../static/images/checkIcon.svg";

import "./styles.scss";

const BillingCards = () => {
	const dispatch = useDispatch();
	const {profile} = useSelector(({auth}: StateType) => ({
		profile: auth.profile
	}));

	const handleSetPrimaryCardClick = (id: number | undefined | string) => () => {
		if (id) {
			dispatch(patientActions.setPrimaryBillingCardRequest(id));
		}
	};

	return (
		<div>
			{profile?.billingCards
				.filter((card) => !!card.isValid)
				.map((card) => (
					<div className="billing-card-item" key={card.id}>
						<div className="card-info">
							<div className="brand">
								<div className="brand-title">Brand</div>
								<div className="brand-image">
									<img src={getCardBrandImage(card.brand)} alt="" />
								</div>
							</div>
							<div className="number">
								<div className="number-title">Card Number</div>
								<div className="number-text">{`**** **** **** ${card.last4}`}</div>
							</div>
							<div className="exp">
								<div className="exp-title">Expiration Date</div>
								<div className="exp-text">
									{card.expMonth && card.expYear ? `${card.expMonth}/${card.expYear}` : "N/A"}
								</div>
							</div>
						</div>
						<div className="primary">
							{card.isPrimary ? (
								<div className="primary-block">
									<div className="check-icon">
										<CheckIcon />
									</div>
									<div className="primary-text">Primary card</div>
								</div>
							) : (
								<button className="set-primary" onClick={handleSetPrimaryCardClick(card.id!)}>
									Set Primary
								</button>
							)}
						</div>
						<div className="address-info">
							<div className="street">
								<div className="street-title">Address</div>
								<div className="street-text">{card.addressLine1}</div>
							</div>
							<div className="suite">
								<div className="suite-title">Suite/Apt</div>
								<div className="suite-text">{card.addressLine2 || "N/A"}</div>
							</div>
							<div className="city">
								<div className="city-title">City</div>
								<div className="city-text">{card.addressCity}</div>
							</div>
							<div className="province">
								<div className="province-title">Province</div>
								<div className="province-text">{card.addressProvince}</div>
							</div>
							<div className="country">
								<div className="country-title">Country</div>
								<div className="country-text">{card.addressCountry}</div>
							</div>
							<div className="postal">
								<div className="postal-title">Postal Code</div>
								<div className="postal-text">{card.addressPostalCode}</div>
							</div>
						</div>
					</div>
				))}
		</div>
	);
};

export default BillingCards;
