import React, {useState, useEffect, ReactElement} from "react";
import {Collapse} from "react-collapse";

import {getPatientSubscriptions} from "../../../../http/patient";
import {getMyBooking} from "../../../../http/schedule";

import OrderService from "../../../../service/Order.service";

import OrderStatus from "../../../../model/OrderStatus";
import Order from "../../../../model/Order";

import LoaderComponent from "../../../Loader";
import SubscriptionInfoItem from "../SubscriptionInfoItem";
import OrderInfoItem from "../OrderInfoItem";

import "./styles.scss";
import {doctorScheduleEvent} from "../../../DoctorListSchedule";
import moment from "moment";
import PopUp from "../../../PopUp";
import MessageConst from "../../../../const/Message.const";

type Consultation = {event: {id: number; doctor: string}; date: string};

const OrdersInfo = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [activeSubscriptions, setActiveSubscriptions] = useState<Order[]>([]);
	const [pausedSubscriptions, setPausedSubscriptions] = useState<Order[]>([]);
	const [pastSubscriptions, setPastSubscriptions] = useState<Order[]>([]);
	const [pastSubscriptionsOrders, setPastSubscriptionsOrders] = useState<Order[]>([]);
	const [openActive, setOpenActive] = useState<boolean>(true);
	const [openPaused, setOpenPaused] = useState<boolean>(true);
	const [openPast, setOpenPast] = useState<boolean>(false);
	const [dialog, setDialog] = useState<ReactElement | null>(null);
	const [showSkipRefillDialog, setShowSkipRefillDialog] = useState<Order | null>(null);
	const [showEarlyRefillDialog, setShowEarlyRefillDialog] = useState<Order | null>(null);
	const [showPauseDialog, setShowPauseDialog] = useState<Order | null>(null);
	const [showResumeDialog, setShowResumeDialog] = useState<Order | null>(null);
	const [showResumeCardFailedDialog, setShowResumeCardFailedDialog] = useState<Order | null>(null);
	const [isSecondPauseStep, setIsSecondPauseStep] = useState<boolean>(false);
	const [consultations, setConsultations] = useState<Consultation[]>([]);
	const [pauseReason, setPauseReason] = useState<string>("");
	const [otherPauseReason, setOtherPauseReason] = useState<string>("");
	const [validationMessage, setValidationMessage] = useState<string>("");

	const sortById = (a: Order, b: Order) => +b.id - +a.id;
	const sortByNumber = (a: Order, b: Order) => +b.number - +a.number;
	const sortBySubnumber = (a: Order, b: Order) => +b.subNumber - +a.subNumber;

	const loadSubscriptions = async () => {
		const sortActive = (a: Order, b: Order): number => {
			if (a.status === OrderStatus.active && b.status !== OrderStatus.active) {
				return -1;
			} else if (a.status !== OrderStatus.active && b.status === OrderStatus.active) {
				return 1;
			} else {
				return 0;
			}
		};

		setLoading(true);

		try {
			const {data: subscriptions} = await getPatientSubscriptions();
			await getMyBooking().then(({data}: {data: doctorScheduleEvent[]}) => {
				setConsultations(
					data.reduce((arr, {date, schedule}) => {
						Object.entries(schedule).map(([time, event]) => {
							const fullDate = moment(`${date} ${time}`);
							const currentDateTime = moment();
							if (currentDateTime.isBefore(fullDate)) {
								arr.push({
									event: event[0],
									date: fullDate.format("MMMM Do YYYY hh:mm A")
								});
							}
							return [time, event];
						});
						return arr;
					}, [] as Consultation[])
				);
			});

			subscriptions.sort((a: Order, b: Order) => {
				return a.date < b.date ? 1 : -1;
			});

			const finalStatuses: OrderStatus[] = [
				OrderStatus.delivering,
				OrderStatus.rejected,
				OrderStatus.finished,
				OrderStatus.cancelled
			];

			const activeSubscriptions: Order[] = subscriptions
				.filter(
					(subscription: Order) =>
						![
							...finalStatuses,
							OrderStatus.paused,
							OrderStatus.pausedRxTransfer,
							OrderStatus.pausedByPatient,
							OrderStatus.cancelled
						].includes(subscription.status)
				)
				.sort(sortActive);

			const pausedSubscriptions: Order[] = subscriptions.filter((subscription: Order) =>
				[OrderStatus.paused, OrderStatus.pausedRxTransfer, OrderStatus.pausedByPatient].includes(
					subscription.status
				)
			);

			const pastSubscriptions: Order[] = subscriptions.filter((subscription: Order) =>
				finalStatuses.includes(subscription.status)
			);

			const pastSubscriptionOrders: Order[] = [];

			subscriptions.forEach((subscription: Order) => {
				subscription.orders.forEach((orderItem) => {
					if ([...finalStatuses].includes(orderItem.status)) {
						pastSubscriptionOrders.push(orderItem);
					}
				});
			});

			pastSubscriptions.sort(sortById);
			pastSubscriptionOrders.sort(sortBySubnumber);
			pastSubscriptionOrders.sort(sortByNumber);

			setPausedSubscriptions(pausedSubscriptions);
			setActiveSubscriptions(activeSubscriptions);
			setPastSubscriptions(pastSubscriptions);
			setPastSubscriptionsOrders(pastSubscriptionOrders);
		} catch (err) {
			console.log(err);
		}

		setLoading(false);
	};

	useEffect(() => {
		loadSubscriptions();
	}, []);

	useEffect(() => {
		if (otherPauseReason) {
			setOtherPauseReason("");
		}
	}, [pauseReason]);

	useEffect(() => {
		setPauseReason("");
	}, [isSecondPauseStep]);

	const renderActiveSubscriptions = () =>
		activeSubscriptions.map((subscription) => {
			const activeSubscriptionOrders = subscription.orders.filter(
				(orderItem) =>
					orderItem.status !== OrderStatus.delivering &&
					orderItem.status !== OrderStatus.rejected &&
					orderItem.status !== OrderStatus.cancelled
			);

			return (
				<SubscriptionInfoItem
					subscription={subscription}
					key={subscription.id}
					subscriptionOrders={activeSubscriptionOrders}
					setShowSkipRefillDialog={setShowSkipRefillDialog}
					setShowEarlyRefillDialog={setShowEarlyRefillDialog}
					setShowPauseDialog={setShowPauseDialog}
				/>
			);
		});

	const renderPausedSubscriptions = () =>
		pausedSubscriptions.map((subscription) => {
			const pausedSubscriptionOrders = subscription.orders.filter(
				(orderItem) =>
					orderItem.status === OrderStatus.paused ||
					orderItem.status === OrderStatus.pausedRxTransfer ||
					orderItem.status === OrderStatus.pausedByPatient
			);

			return (
				<SubscriptionInfoItem
					subscription={subscription}
					key={subscription.id}
					subscriptionOrders={pausedSubscriptionOrders}
					setShowResumeDialog={setShowResumeDialog}
					setShowResumeCardFailedDialog={setShowResumeCardFailedDialog}
				/>
			);
		});

	const renderPastSubscriptions = () => (
		<>
			{pastSubscriptions.map((subscription) => {
				return (
					<SubscriptionInfoItem
						subscription={subscription}
						key={subscription.id}
						subscriptionOrders={[]}
					/>
				);
			})}
			{pastSubscriptionsOrders.map((order) => {
				return <OrderInfoItem order={order} key={order.id} soloItem />;
			})}
		</>
	);

	const skipRefill = async (subscriptionId: number) => {
		setLoading(true);

		const response: Response = await OrderService.skipRefill(subscriptionId);

		if (response.ok) {
			await loadSubscriptions();
			setShowSkipRefillDialog(null);
			setDialog(
				<div className="dialog-wrapper">
					<div className="dialog-content">
						<div className="description medium">
							Your request to skip your next refill has been received! Your next refill will be
							skipped, and further refills will resume the following month.
						</div>
						<div className="btns one-btn">
							<button className="yes-btn" onClick={() => setDialog(null)}>
								OK
							</button>
						</div>
					</div>
				</div>
			);
		}

		setLoading(false);
	};

	const getRefillEarlier = async (subscriptionId: number) => {
		setLoading(true);

		const response: Response = await OrderService.getRefillEarlier(subscriptionId);
		const result = await response.json();

		if (response.ok) {
			await loadSubscriptions();
			setShowEarlyRefillDialog(null);
			if (
				result.status === "PAUSED" ||
				!!result.orders.filter((order: Order) => order.status === OrderStatus.paused).length
			) {
				setDialog(
					<div className="dialog-wrapper">
						<div className="dialog-content">
							<div className="description medium">
								Payment information is not valid. The order has been paused
							</div>
							<div className="btns one-btn">
								<button className="yes-btn" onClick={() => setDialog(null)}>
									OK
								</button>
							</div>
						</div>
					</div>
				);
			} else {
				setDialog(
					<div className="dialog-wrapper">
						<div className="dialog-content">
							<div className="description medium">
								Your request to receive your next refill early has been received! The pharmacist
								will review and once approved we will process and ship your next refill. You will
								receive an email confirmation once your order has shipped.
							</div>
							<div className="btns one-btn">
								<button className="yes-btn" onClick={() => setDialog(null)}>
									OK
								</button>
							</div>
						</div>
					</div>
				);
			}
		}

		setLoading(false);
	};

	const handlePauseSubscription = async (subscriptionId: number) => {
		setIsSecondPauseStep(true);

		const response: Response = await OrderService.pauseSubscription(subscriptionId);

		if (response.ok) {
			await loadSubscriptions();
		}
	};

	const handleAddPauseNotes = async (subscriptionId: number) => {
		if (!pauseReason || (pauseReason === "Other" && !otherPauseReason)) {
			setValidationMessage(MessageConst.validation.pauseReasonError);
			return;
		}

		setLoading(true);
		const reason = pauseReason === "Other" ? otherPauseReason : pauseReason;
		const response: Response = await OrderService.addPauseSubscriptionNote(subscriptionId, reason);

		if (response.ok) {
			setShowPauseDialog(null);
			setIsSecondPauseStep(false);
		}

		setLoading(false);
	};

	const handleResumeSubscription = async (subscriptionId: number) => {
		setLoading(true);
		const response: Response = await OrderService.resumeSubscription(subscriptionId);
		const result = await response.json();
		if (response.ok) {
			await loadSubscriptions();
			setShowResumeDialog(null);

			if (
				result.status === "PAUSED" ||
				!!result.orders.filter((order: Order) => order.status === OrderStatus.paused).length
			) {
				setDialog(
					<div className="dialog-wrapper">
						<div className="dialog-content">
							<div className="title">Error occurred</div>
							<div className="description">
								We can’t re-activate your subscription due to a credit card failure. Please update
								your credit card and payment information.
							</div>

							<div className="btns one-btn">
								<button className="yes-btn" onClick={() => setDialog(null)}>
									OK
								</button>
							</div>
						</div>
					</div>
				);
			}
		} else {
			setDialog(
				<div className="dialog-wrapper">
					<div className="dialog-content">
						<div className="title">Error occurred</div>
						<div className="description">
							We can’t re-activate your subscription due to a credit card failure. Please update
							your credit card and payment information.
						</div>
						<div className="btns one-btn">
							<button className="yes-btn" onClick={() => setDialog(null)}>
								OK
							</button>
						</div>
					</div>
				</div>
			);
		}

		setLoading(false);
	};

	const handleResumeCardFailedSubscription = async (subscriptionId: number) => {
		setLoading(true);
		const response: Response = await OrderService.resumeCardFailedSubscription(subscriptionId);
		const result = await response.json();
		setShowResumeCardFailedDialog(null);

		if (response.ok) {
			await loadSubscriptions();

			if (
				result.status === "PAUSED" ||
				!!result.orders.filter((order: Order) => order.status === OrderStatus.paused).length
			) {
				setDialog(
					<div className="dialog-wrapper">
						<div className="dialog-content">
							<div className="title">Error occurred</div>
							<div className="description">
								We can’t re-activate your subscription due to a credit card failure. Please update
								your credit card and payment information.
							</div>

							<div className="btns one-btn">
								<button className="yes-btn" onClick={() => setDialog(null)}>
									OK
								</button>
							</div>
						</div>
					</div>
				);
			}
		} else {
			setDialog(
				<div className="dialog-wrapper">
					<div className="dialog-content">
						<div className="title">Error occurred</div>
						<div className="description">
							We can’t re-activate your subscription due to a credit card failure. Please update
							your credit card and payment information.
						</div>
						<div className="btns one-btn">
							<button className="yes-btn" onClick={() => setDialog(null)}>
								OK
							</button>
						</div>
					</div>
				</div>
			);
		}

		setLoading(false);
	};

	return (
		<div className="orders-info-wrapper">
			{loading && !showPauseDialog && <LoaderComponent showShadow={true} />}
			{!!activeSubscriptions.length && (
				<div className="subscription-cancel-info">
					<span>
						If you would like to cancel a subscription, please contact us at{" "}
						<a href="mailto:WeListen@askwinston.ca">WeListen@askwinston.ca</a>.
					</span>
				</div>
			)}
			{!!consultations.length && (
				<div className="active-orders-wrapper">
					<div className="title">Scheduled Consultations</div>
					<div className="consultations">
						{consultations.map((consultation) => (
							<span key={consultation.event.id} className="consultationItem">
								<b>
									#{consultation.event.id} Doctor {consultation.event.doctor}
								</b>
								: {consultation.date}
							</span>
						))}
					</div>
				</div>
			)}
			{!!activeSubscriptions.length && (
				<div className="active-orders-wrapper">
					<div className={`title ${openActive ? "opened" : ""}`}>
						<span>Active Orders</span>
						<span className="open-icon" onClick={() => setOpenActive(!openActive)}>
							{openActive ? "-" : "+"}
						</span>
					</div>
					<Collapse isOpened={true}>{openActive && renderActiveSubscriptions()}</Collapse>
				</div>
			)}
			{!!pausedSubscriptions.length && (
				<div className="paused-orders-wrapper">
					<div className={`title ${openPaused ? "opened" : ""}`}>
						<span>Paused Orders</span>
						<span className="open-icon" onClick={() => setOpenPaused(!openPaused)}>
							{openPaused ? "-" : "+"}
						</span>
					</div>
					<Collapse isOpened={true}>{openPaused && renderPausedSubscriptions()}</Collapse>
				</div>
			)}
			{(!!pastSubscriptions.length || !!pastSubscriptionsOrders.length) && (
				<div className="past-orders-wrapper">
					<div className={`title ${openPast ? "opened" : ""}`}>
						<span>Past Orders</span>
						<span className="open-icon" onClick={() => setOpenPast(!openPast)}>
							{openPast ? "-" : "+"}
						</span>
					</div>
					<Collapse isOpened={true}>{openPast && renderPastSubscriptions()}</Collapse>
				</div>
			)}

			{dialog && <PopUp>{dialog}</PopUp>}
			{showSkipRefillDialog && (
				<PopUp>
					<div className="dialog-wrapper">
						<div className="dialog-content">
							<div className="title">Skip your next refill?</div>
							<div className="description">
								If you skip this refill, the next refill will be on{" "}
								{moment(showSkipRefillDialog.nextOrderDate).add(1, "month").format("YYYY-MM-DD")}{" "}
								and will continue until your prescription expires on{" "}
								{moment(showSkipRefillDialog.prescription?.toDate).format("YYYY-MM-DD")}.
							</div>
							<div className="btns">
								<button className="yes-btn" onClick={() => skipRefill(showSkipRefillDialog.id)}>
									YES
								</button>
								<button className="no-btn" onClick={() => setShowSkipRefillDialog(null)}>
									NO
								</button>
							</div>
						</div>
					</div>
				</PopUp>
			)}
			{showEarlyRefillDialog && (
				<PopUp>
					<div className="dialog-wrapper">
						<div className="dialog-content">
							<div className="title">Get an early refill?</div>
							<div className="description">
								If you are taking your medication more often than expected you can get your next
								refill earlier than the original refill date once approved and processed.
							</div>
							<div className="btns">
								<button
									className="yes-btn"
									onClick={() => getRefillEarlier(showEarlyRefillDialog.id)}
								>
									YES
								</button>
								<button className="no-btn" onClick={() => setShowEarlyRefillDialog(null)}>
									NO
								</button>
							</div>
						</div>
					</div>
				</PopUp>
			)}
			{showResumeDialog && (
				<PopUp>
					<div className="dialog-wrapper">
						<div className="dialog-content">
							<div className="title">Re-activate my subscription</div>
							<div className="description">
								{moment(showResumeDialog.nextOrderDate).diff(moment(), "days") > 5
									? "Re-activating your subscription will be in effect immediately and processed on time for your next refill."
									: "Re-activating your subscription will be in effect immediately."}
							</div>
							<div className="btns">
								<button
									className="yes-btn"
									onClick={() => handleResumeSubscription(showResumeDialog.id)}
								>
									YES
								</button>
								<button className="no-btn" onClick={() => setShowResumeDialog(null)}>
									NO
								</button>
							</div>
						</div>
					</div>
				</PopUp>
			)}
			{showResumeCardFailedDialog && (
				<PopUp>
					<div className="dialog-wrapper">
						<div className="dialog-content">
							<div className="title">Re-activate my subscription</div>
							<div className="description">
								If you re-activate your subscription, your order will be processed immediately.
								Please click Yes to confirm.
							</div>
							<div className="btns">
								<button
									className="yes-btn"
									onClick={() => handleResumeCardFailedSubscription(showResumeCardFailedDialog.id)}
								>
									YES
								</button>
								<button className="no-btn" onClick={() => setShowResumeCardFailedDialog(null)}>
									NO
								</button>
							</div>
						</div>
					</div>
				</PopUp>
			)}
			{showPauseDialog && !isSecondPauseStep && (
				<PopUp>
					<div className="dialog-wrapper">
						<div className="dialog-content">
							<div className="title">Pause your subscription?</div>
							<div className="description">
								Your subscription will be paused indefinitely until you choose to reactivate again
								within the expiration date.
								<br />
								Reminder: Your prescription will expire on{" "}
								{moment(showPauseDialog.prescription?.toDate).format("YYYY-MM-DD")}.
							</div>
							<div className="btns">
								<button
									className="yes-btn"
									onClick={() => handlePauseSubscription(showPauseDialog.id)}
								>
									YES
								</button>
								<button className="no-btn" onClick={() => setShowPauseDialog(null)}>
									NO
								</button>
							</div>
						</div>
					</div>
				</PopUp>
			)}
			{showPauseDialog && isSecondPauseStep && (
				<PopUp>
					<div className="dialog-wrapper">
						<div
							className="close-icon"
							onClick={() => {
								setShowPauseDialog(null);
								setIsSecondPauseStep(false);
							}}
						>
							X
						</div>
						<div className="dialog-content">
							<div className="title">Could you tell us why?</div>
							<div className="why-radio-btns">
								<label>
									<div
										className={`radio-mark ${
											pauseReason === "I still have medication" ? "checked" : ""
										}`}
									/>
									<span className="value">I still have medication</span>
									<input
										type="radio"
										name="why"
										value="I still have medication"
										onChange={(e) => setPauseReason(e.target.value)}
									/>
								</label>
								<label>
									<div
										className={`radio-mark ${pauseReason === "Too expensive" ? "checked" : ""}`}
									/>
									<span className="value">Too expensive</span>
									<input
										type="radio"
										name="why"
										value="Too expensive"
										onChange={(e) => setPauseReason(e.target.value)}
									/>
								</label>
								<label>
									<div
										className={`radio-mark ${
											pauseReason === "Didn’t work for me" ? "checked" : ""
										}`}
									/>
									<span className="value">Didn’t work for me</span>
									<input
										type="radio"
										name="why"
										value="Didn’t work for me"
										onChange={(e) => setPauseReason(e.target.value)}
									/>
								</label>
								<label>
									<div
										className={`radio-mark ${
											pauseReason === "I got a reaction from the medication" ? "checked" : ""
										}`}
									/>
									<span className="value">I got a reaction from the medication</span>
									<input
										type="radio"
										name="why"
										value="I got a reaction from the medication"
										onChange={(e) => setPauseReason(e.target.value)}
									/>
								</label>
								<label>
									<div className={`radio-mark ${pauseReason === "Other" ? "checked" : ""}`} />
									<span className="value">Other</span>
									<input
										type="radio"
										name="why"
										value="Other"
										onChange={(e) => setPauseReason(e.target.value)}
									/>
								</label>
								{pauseReason === "Other" && (
									<textarea
										value={otherPauseReason}
										onChange={(e) => setOtherPauseReason(e.target.value)}
										className="other-pause-reason"
										placeholder="Please describe your reason"
									/>
								)}
							</div>
							{validationMessage && <div className="reason-validation">{validationMessage}</div>}
							<div className="btns one-btn">
								<button className="yes-btn" onClick={() => handleAddPauseNotes(showPauseDialog.id)}>
									Submit
								</button>
							</div>
						</div>
					</div>
				</PopUp>
			)}
		</div>
	);
};

export default OrdersInfo;
