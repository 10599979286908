import React, {Component} from "react";
import "./styles.scss";

export interface InputProps {
	inputPlaceholder?: string;
	inputValidator?: (input: string) => boolean;
	inputValidationError?: string;
	inputTitle?: string;
}

export interface DialogProps {
	text: string | JSX.Element;
	showInput?: boolean;
	inputValidationErrorButtonKey?: string;
	inputs?: InputProps[];
	buttons: {
		[key: string]: {
			class: "light" | "dark";
			callback: (inputValue?: string[]) => void;
		};
	};
}

interface DialogState {
	inputValue: string[];
	inputValidationError: string[];
}

export default class DialogComponent extends Component<DialogProps, DialogState> {
	constructor(props: Readonly<DialogProps>) {
		super(props);
		this.state = {
			inputValue: [],
			inputValidationError: []
		};
	}
	public render() {
		const buttons: JSX.Element[] = [];
		Object.keys(this.props.buttons).forEach((buttonText) => {
			buttons.push(
				<span
					key={buttonText}
					className={
						"button " +
						this.props.buttons[buttonText].class +
						(this.props.inputValidationErrorButtonKey === buttonText &&
						!!this.state.inputValidationError.length
							? " disabled"
							: "")
					}
					onClick={() => {
						this.handleButtonClick(buttonText);
					}}
				>
					{buttonText}
				</span>
			);
		});

		return (
			<div className="dialog-component">
				<div className="bg"></div>
				<div className="content-wrapper">
					<div className="content">
						<div className="content-inner">
							{this.props.text &&
								(typeof this.props.text === "string" ? (
									<div className="text" dangerouslySetInnerHTML={{__html: this.props.text}}></div>
								) : (
									<div className="text">{this.props.text}</div>
								))}

							{this.props.showInput &&
								this.props.inputs?.map((inp, index) => (
									<div className="text-input" key={index}>
										{inp.inputTitle && <div className="input-title">{inp.inputTitle}</div>}
										<textarea
											placeholder={inp.inputPlaceholder || "Type here..."}
											value={this.state.inputValue[index]}
											onChange={this.handleInputChange(index)}
										></textarea>
										<div className="validation-error">{this.state.inputValidationError[index]}</div>
									</div>
								))}
							<div className="buttons">{buttons}</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	private handleButtonClick = (buttonText: string) => {
		if (
			this.props.inputValidationErrorButtonKey !== buttonText ||
			!this.state.inputValidationError.length
		) {
			this.props.buttons[buttonText].callback(this.state.inputValue);
		}
	};

	private handleInputChange = (index: number) => (
		event: React.ChangeEvent<HTMLTextAreaElement>
	) => {
		const inputValue = event.target.value;
		let inputValidationError: string = "";

		if (
			this.props.inputs![index].inputValidator &&
			!this.props.inputs![index].inputValidator!(inputValue)
		) {
			inputValidationError = this.props.inputs![index].inputValidationError || "Invalid input";
		}

		const newInputsValue = [...this.state.inputValue];
		let newInputsValidationErrors = [...this.state.inputValidationError];

		newInputsValue[index] = inputValue;

		if (inputValidationError) {
			newInputsValidationErrors[index] = inputValidationError;
		}  if (newInputsValidationErrors[index]) {
			newInputsValidationErrors = [];
		}

		this.setState({
			inputValue: newInputsValue,
			inputValidationError: newInputsValidationErrors
		});
	};
}
