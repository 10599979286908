import Cart from "../model/Cart";
import MdPostConsultNote from "../model/MdPostConsultNote";
import HttpService from "./Http.service";

export default class OrderService {
	public static checkoutCart(cart: Cart): Promise<Response> {
		return HttpService.post("/order", JSON.stringify(cart));
	}

	public static reorderCart(id: number, cart: Cart): Promise<Response> {
		return HttpService.post("/order/" + id, JSON.stringify(cart));
	}

	public static getPatientOrders(): Promise<Response> {
		// PATIENT
		return HttpService.get("/subscription");
	}

	public static verifyPromoCode(code: string): Promise<Response> {
		return HttpService.get("/order/promo-code/" + code);
	}

	public static getDoctorPendingOrders(): Promise<Response> {
		// DOCTOR
		return HttpService.get("/subscription/waiting-doctor");
	}

	public static getDoctorOrders(patientId: number): Promise<Response> {
		// DOCTOR
		return HttpService.get("/order/user/" + patientId);
	}

	public static getPharmacistPendingOrders(): Response | Promise<Response> {
		// PHARMACIST
		return HttpService.get("/order/waiting-pharmacist-and-packaging");
	}

	public static approveOrderByDoctor(id: number, body: MdPostConsultNote): Promise<Response> {
		return HttpService.put("/subscription/" + id + "/start", JSON.stringify(body));
	}

	public static packagingOrder(
		id: number,
		payload: {
			text: string;
			amount: number;
		}
	): Promise<Response> {
		return HttpService.put("/order/" + id + "/packaging", JSON.stringify(payload));
	}

	public static rejectOrder(id: number, payload: string): Promise<Response> {
		return HttpService.put("/order/" + id + "/reject", JSON.stringify({text: payload}));
	}

	public static rejectSubscription(id: number, payload: string): Promise<Response> {
		return HttpService.put("/subscription/" + id + "/reject", JSON.stringify({text: payload}));
	}

	public static cancelOrder(id: number): Promise<Response> {
		return HttpService.put("/order/" + id + "/cancel", JSON.stringify({}));
	}

	public static setTrackingNumber(id: number, trackingNumber: string): Promise<Response> {
		return HttpService.put("/order/" + id + "/delivering", JSON.stringify({text: trackingNumber}));
	}

	public static cancelPatientsAllOrders(): Promise<Response> {
		return HttpService.put("/order/cancel", JSON.stringify({}));
	}

	public static getAppointmentsDate(): Promise<Response> {
		// PATIENT
		return HttpService.get("/order/appointment-date");
	}

	public static putAppointmentDate(id: number, dateString: string): Promise<Response> {
		// PATIENT
		return HttpService.put("/order/" + id + "/appointment-date", dateString);
	}

	public static skipRefill(id: number): Promise<Response> {
		// PATIENT
		return HttpService.put("/subscription/" + id + "/skip", "");
	}

	public static getRefillEarlier(id: number): Promise<Response> {
		// PATIENT
		return HttpService.post("/subscription/" + id + "/send", "");
	}
	public static pauseSubscription(id: number): Promise<Response> {
		// PATIENT
		return HttpService.put("/subscription/" + id + "/pause", "");
	}
	public static addPauseSubscriptionNote(id: number, note: string): Promise<Response> {
		// PATIENT
		return HttpService.put("/subscription/" + id + "/pause-notes", JSON.stringify({text: note}));
	}
	public static resumeSubscription(id: number): Promise<Response> {
		// PATIENT
		return HttpService.put("/subscription/" + id + "/resume-paused-by-patient", "");
	}
	public static resumeCardFailedSubscription(id: number): Promise<Response> {
		// PATIENT
		return HttpService.put("/subscription/" + id + "/resume", "");
	}
}
