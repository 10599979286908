import React, {useEffect} from "react";
import {Link} from "react-router-dom";

import "./styles.scss";

const SuccessTransfer = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<div className="success-transfer-wrapper">
				<div className="title">Submission Confirmation</div>
			</div>
			<div className="success-transfer-wrapper">
				<div className="title">Your Prescription Has Been Submitted!</div>
				<div className="content">
					<p>
						We will begin processing your transfer request. In the event we require further
						information or need to verify any details, we will contact you at the email and/or phone
						number provided.
					</p>
					<p>
						You will receive an email confirmation shortly regarding your prescription transfer.
					</p>
				</div>
				<div className="button-links">
					<Link to="/shop">
						<button>Shop</button>
					</Link>
					<Link to="/learn">
						<button>Learn</button>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default SuccessTransfer;
