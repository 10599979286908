import React from "react";

import BillingCards from "../BillingCards";
import AddBillingCard from "../AddBillingCard";

import "./styles.scss";

const BillingInfo = () => {
	return (
		<div className="billing-info-wrapper">
			<h1 className="title">Billing Information</h1>
			<BillingCards />
			<AddBillingCard />
		</div>
	);
};

export default BillingInfo;
