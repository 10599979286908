import React from "react";
import "./styles.scss";
import { useSelector } from "react-redux";
import { StateType } from "../../../../redux/types";
import { calculateCart,  calculateDiscount, formatCartItemPrice, formatMoney, getProductImage } from "../../../../utils/helpers";
import { Link } from "react-router-dom";

interface CartSummaryProps {
	useLastSubscription: boolean;
	showDueNow: boolean;
}

const CartSummary = (props: CartSummaryProps) => {
	
	const {
		selectedProducts, promoCode,
	} = useSelector(({ cart, patient }: StateType) => ({
		promoCode: props.useLastSubscription ?
			patient.lastUsedPromoCode :
			patient.selectedPromoCode,
		selectedProducts: props.useLastSubscription ?
			cart.lastSuccessSubscription.selectedProducts :
			cart.selectedProducts,
	}));	

	let isExpressDelivery = sessionStorage.getItem("isExpressDelivery")==="true"?true:false;
	console.log(promoCode?.value);
	return (
		<div id="confirm">
			<Link to="/cart" className="edit-link">
				<div className="text-block-24">Edit</div>
			</Link>
			{selectedProducts.map((product, index) => (
			<div className="div-block-26" key={index}>
				<div className="w-row" key={index}>
					<div className="w-col w-col-5"><img src={getProductImage(product.product.name)} width="271" alt="" className="image-11" /></div>
					<div className="w-col w-col-7">
						<h1 className="heading-21">{product.product.name}</h1>
						<div className="text-block-44">{product.quantity.quantity}</div>
						<div className="columns-27 w-row">
							<div className="w-col w-col-6">
								<div className="text-block-45"><strong className="bold-text-6">Auto-refill </strong><br /></div>
							</div>
							<div className="w-col w-col-6">
								<div className="text-block-46"><strong>{formatCartItemPrice(product.quantity, product.count)}</strong></div>
							</div>
						</div>
						<div className="text-block-50">(you can pause, skip refill or cancel anytime)</div>
					</div>
				</div>
			</div>
			))}
			<div className="div-block-15">
				<div className="columns-39 w-row">
					<div className="w-col w-col-6">
						<div>Online doctor consultation</div>
					</div>
					<div className="w-col w-col-6">
						<div className="text-block-25">FREE</div>
					</div>
				</div>
				<div className="columns-21 w-row">
					<div className="column-18 w-col w-col-6">
						<div>{!isExpressDelivery? 'Standard Shipping (2-3 days)': 'Express Shipping'}</div>
					</div>
					<div className="w-col w-col-6">
						<div className="text-block-26">{!isExpressDelivery? 'FREE':'$9.99'}</div>
					</div>
				</div>
				<div className="columns-20 w-row">
					<div className="w-col w-col-6">
						<div>Promo code: {promoCode?.code}</div>
					</div>
					<div className="w-col w-col-6">
						<div className="text-block-29">{formatMoney(calculateDiscount(selectedProducts, promoCode))}</div>
					</div>
				</div>
				<div className="columns-18 w-row">
					<div className="w-col w-col-6">
						<div>Tax</div>
					</div>
					<div className="w-col w-col-6">
						<div className="text-block-27">{formatMoney(0)}</div>
					</div>
				</div>
				<div className="columns-19 w-row">
					<div className="w-col w-col-6">
						<div>Total due when prescribed and shipped</div>
					</div>
					<div className="w-col w-col-6">
						<div className="text-block-28">
							{formatMoney(calculateCart(selectedProducts, promoCode, isExpressDelivery))}
						</div>
					</div>
				</div>
			</div>
			<div className="div-block-16">
			{props.showDueNow && (
				<div className="columns-40 w-row">
					<div className="w-col w-col-6">
						<div className="text-block-33"><strong>Due Now</strong></div>
					</div>
					<div className="w-col w-col-6">
						<div className="text-block-32"><strong>{formatMoney(0)}</strong></div>
					</div>
				</div>
			)}
				<div className="columns-28 w-row">
					<div className="w-col w-col-8">
						<div className="text-block-34">You will only be charged once the treatment is shipped.</div>
					</div>
					<div className="w-col w-col-4"></div>
				</div>
			</div>
		</div>
	);
};

CartSummary.defaultProps = {
	useLastSubscription: false,
	showDueNow: true,
};

export default CartSummary;
