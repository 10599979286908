import OrderService from "../../service/Order.service";
import ServerError from "../../model/ServerError";
import React, {useState} from "react";
import MdPostConsultNote from "../../model/MdPostConsultNote";
import {DialogProps} from "../../components/Dialog";


export const useDoctorOrders = (fetch?: () => Promise<void>) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [postConsultOrderId, setPostConsultOrderId] = useState<number | undefined>(undefined);
	const [imageViewProps, setImageViewProps] = useState<string | "waiting" | "none" | null>(null);
	const [imageType, setImageType] = useState<string>("");
	const [dialog, setDialog] = useState<DialogProps | null>(null);

	const handleRejectClick = (orderId: number) => {
		setDialog({
			text: (
				<div>
					Do you really want to cancel order #{orderId}?
					<br/>
					If so, please explain the cancellation reason:
				</div>
			),
			showInput: true,
			inputs: [{}],
			buttons: {
				Yes: {
					class: "dark",
					callback: async (values) => {
						const reason = values![0];
						setDialog(null);
						setIsLoading(true);

						const response: Response = await OrderService.rejectSubscription(
							orderId,
							reason as string
						)
							.finally(() => setIsLoading(false));

						if (response.ok) {
							notify("Order has been successfuly rejected");
						} else if ([400, 401, 403].includes(response.status)) {
							const res: ServerError = await response.json();
							notify(res.message);
						}
						if (fetch) {
							await fetch().finally(() => setIsLoading(false));
						}
					}
				},
				No: {
					class: "light",
					callback: () => {
						setDialog(null);
					}
				}
			}
		});
	};

	const handleApproveClick = (orderId: number): void => {
		setPostConsultOrderId(orderId);
	};

	const handleOpenViewID = (type: string, imageView: string) => {
		setImageType(type);
		setImageViewProps(imageView);
	};

	const handleCloseViewID = (): void => {
		setImageType("");
		setImageViewProps(null);
	};

	const handlePostConsultClose = (): void => {
		setPostConsultOrderId(undefined);
	};

	const handlePostConsultFinish = async (
		orderId: number,
		postConsultPayload: MdPostConsultNote
	) => {
		setIsLoading(true);
		setPostConsultOrderId(undefined);

		const response: Response = await OrderService.approveOrderByDoctor(orderId, postConsultPayload)
			.finally(() => setIsLoading(false));

		if (response.ok) {
			notify("Order has been successfuly approved");
		} else if ([400, 401, 403].includes(response.status)) {
			const res: ServerError = await response.json();
			notify(res.message);
		}

		if (fetch) {
			await fetch().finally(() => setIsLoading(false));
		}
	};

	const notify = (text: string) => {
		setDialog({
			text,
			buttons: {
				OK: {
					class: "dark",
					callback: () => {
						setDialog(null);
					}
				}
			}
		});
	};

	return {
		state: {
			postConsultOrderId,
			imageViewProps,
			imageType,
			isLoading,
			dialog
		},
		handleApproveClick,
		handleRejectClick,
		handleOpenViewID,
		handlePostConsultClose,
		handleCloseViewID,
		handlePostConsultFinish,
		setIsLoading
	};
};