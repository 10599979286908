import React from "react";
import {useSelector, useDispatch} from "react-redux";

import {StateType} from "../../redux/types";
import * as patientActions from "../../redux/patient/handlers";

import BillingCard from "../../model/BillingCard";

import "./styles.scss";

interface BillingCardsProps {
	isSelectable: boolean;
	isDeletable: boolean;
	mode: "checkout" | "profile";
}

const BillingCards = (props: BillingCardsProps) => {
	const dispatch = useDispatch();
	const {selectedBillingCard, profile} = useSelector(({patient, auth}: StateType) => ({
		selectedBillingCard: patient.selectedBillingCard,
		profile: auth.profile
	}));

	const handleSelectedCardChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (props.isSelectable) {
			const card = profile?.billingCards.find((item) => item.id === e.target.value) || null;
			dispatch(patientActions.selectBillingCard(card));
		}
	};

	// const handleSetPrimaryCardClick = (id: string | undefined) => () => {
	// 	if (id) {
	// 		dispatch(patientActions.setPrimaryBillingCardRequest(id));
	// 	}
	// };

	return (
		<div className="billing-cards-component">
			<div className="billing-cards-component__content">
				{profile?.billingCards.length !== 0 && props.mode === "profile" && (
					<div className="micro-header">Your credit cards</div>
				)}
				{profile?.billingCards
					.filter((card) => !!card.isValid)
					.map((card: BillingCard) => (
						<div
							className={"billing-card" + (props.isDeletable ? " row-margin" : "")}
							key={card.id}
							
						>

							<label className="radio-button-field-2 w-radio">
								{props.isSelectable && (	
									<>
									<div className={((!!selectedBillingCard && card.id === selectedBillingCard.id)?'radio-button':'radio-button-2') + " w-form-formradioinput w-form-formradioinput--inputType-custom  w-radio-input"}></div>
									<input 
									type="radio" 
									data-name="Radio" 
									id="radio-2" 
									name="billing-card"
									onChange={handleSelectedCardChange}
									value={card.id}
									checked={!!selectedBillingCard && card.id === selectedBillingCard.id}
									style={{opacity:'0', position:"absolute", zIndex:-1}} />
									</>
								)}
									<span className="radio-button-label-2 w-form-label">
										<span>
											**** {card.last4}, {card.name}
										</span>
										<div className="card-address">
											{card.addressLine1}, {card.addressLine2}
											{card.addressLine2 ? ", " : ""} {card.addressCity}, {card.addressProvince},{" "}
											{card.addressCountry}, {card.addressPostalCode}
										</div>
									</span>
							</label>
							{/* {card.isPrimary && <div className="primary-text">Primary</div>}
							{!card.isPrimary && props.mode === "profile" && (
								<button className="set-primary" onClick={handleSetPrimaryCardClick(card.id)}>
									Set as Primary Card
								</button>
							)} */}
						</div>
					))}
			</div>
		</div>
	);
};

export default BillingCards;
