import React, {useState, useRef, KeyboardEvent, Ref, forwardRef, useImperativeHandle} from "react";
import {useDispatch, useSelector} from "react-redux";
import {StateType} from "../../redux/types";
import * as patientActions from "../../redux/patient/handlers";
import {Province, ServedProvinceList, ProvinceName} from "../../model/Province";
import MessageConst from "../../const/Message.const";
import ValidationService from "../../service/Validation.service";


import LoaderComponent from "../Loader";
import "./styles.scss";

interface AddShippingAddressProps {
	isProfileMode: boolean;
	isSelectNewAddress: boolean;
	mode: "profile" | "checkout";	
	onAddedCallback?: () => void;
}

export interface AddShippingAddressRefObject {
	submit: () => Promise<void>;
}

const AddShippingAddress = forwardRef(
	(props: AddShippingAddressProps, ref: Ref<AddShippingAddressRefObject>) => {
		const dispatch = useDispatch();
		const addressLine1Input = useRef<HTMLInputElement>(null);
		const addressLine2Input = useRef<HTMLInputElement>(null);
		const addressCityInput = useRef<HTMLInputElement>(null);
		const addressProvinceSelect = useRef<HTMLSelectElement>(null);
		const addressPostalCodeInput = useRef<HTMLInputElement>(null);
		const [addressLine1, setAddressLine1] = useState<string>("");
		const [addressLine2, setAddressLine2] = useState<string>("");
		const [addressCity, setAddressCity] = useState<string>("");
		const [addressPostalCode, setAddressPostalCode] = useState<string>("");
		const [addressCountry] = useState<string>("Canada");
		const [validationMessage, setValidationMessage] = useState<string>("");
		const [addressProvince, setAddressProvince] = useState<Province | undefined>("ON");		

		const { isLoading} = useSelector(({ patient}: StateType) => ({
			// profile: auth.profile,
			isLoading: patient.addingShippingAddress
		}));

		useImperativeHandle(ref, () => ({submit}));

		const handleKeyPress = (
			event: KeyboardEvent<HTMLInputElement> | KeyboardEvent<HTMLSelectElement>
		) => {
			if (event.key === "Enter") {
				submit();
			}
		};

		const [showSuit, setShowSuit] = useState(false);

		const submit = async (): Promise<void> => {									
			if (!ValidationService.validateRequired(addressLine1)) {
				if (addressLine1Input.current) {
					addressLine1Input.current.focus();
				}

				setValidationMessage(MessageConst.validation.address.streetError);

				return;
			}

			if (!ValidationService.validateRequired(addressCity)) {
				if (addressCityInput.current) {
					addressCityInput.current.focus();
				}

				setValidationMessage(MessageConst.validation.address.cityError);

				return;
			}
			

			if (!addressProvince) {
				if (addressProvinceSelect.current) {
					addressProvinceSelect.current.focus();
				}

				setValidationMessage(MessageConst.validation.provinceError);

				return;
			}

			if (!ValidationService.validateRequired(addressPostalCode)) {
				if (addressPostalCodeInput.current) {
					addressPostalCodeInput.current.focus();
				}

				setValidationMessage(MessageConst.validation.address.postalCodeError);

				return;
			}

			if (!ValidationService.validatePostalCode(addressPostalCode)) {
				if (addressPostalCodeInput.current) {
					addressPostalCodeInput.current.focus();
				}

				setValidationMessage(MessageConst.validation.address.postalCodeError);

				return;
			}

			const newShippingAddress = {
				addressLine1,
				addressLine2,
				addressCity,
				addressProvince,
				addressPostalCode,
				addressCountry
			};
			let callback = props.onAddedCallback;
			dispatch(
				patientActions.addShippingAddressRequest({
					...newShippingAddress,
					selectNewAddress: props.isSelectNewAddress,
					mode: props.mode,
				})
			);
			if(callback && !isLoading) {
				callback();
			}


			setAddressLine1("");
			setAddressLine2("");
			setAddressCity("");
			setAddressProvince(undefined);
			setAddressPostalCode("");
			setValidationMessage("");
		};

		const handleLine1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
			const addressLine1: string = ValidationService.addressConverter(e.target.value);
			setAddressLine1(addressLine1);
		};

		const handleLine2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
			const addressLine2: string = ValidationService.addressConverter(e.target.value);
			setAddressLine2(addressLine2);
		};

		const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			const addressCity: string = ValidationService.addressConverter(e.target.value);
			setAddressCity(addressCity);
		};

		const handleProvinceChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
			const selectedProvince = event.currentTarget.value as Province;
			setAddressProvince(selectedProvince);
		};

		const handlePostalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			const addressPostalCode = ValidationService.postalCodeConverter(e.target.value);
			setAddressPostalCode(addressPostalCode);
		};

		return (
			<div className="add-shipping-address-component">
				
				{(isLoading) && <LoaderComponent showShadow isFixed />}
						<div className="w-form">
							<form id="email-form" name="email-form" data-name="Email Form" className="form-5"><label className="field-label-2">Standard delivery is on us!</label>
							<input type="text" className="text-field-11 w-input" name="name" data-name="Name" placeholder="Street and number" id="name" 
								ref={addressLine1Input}
								value={addressLine1}
								onChange={handleLine1Change}
								onKeyPress={handleKeyPress}
							/>
							<div className="w-row">
								<div className="w-col w-col-6">
								<div className="div-block-2"><input type="text" className="text-field-14 w-input" name="field" data-name="Field" placeholder="Postal code" id="field" required 
									ref={addressPostalCodeInput}
									value={addressPostalCode}
									onChange={handlePostalCodeChange}
									onKeyPress={handleKeyPress}
								/></div>
								</div>
								<div className="w-col w-col-6">
								<div className="div-block-9"><input type="text" className="text-field-13 w-input" name="field-2" data-name="Field 2" placeholder="Town/City" id="field-2" required 
									ref={addressCityInput}
									value={addressCity}
									onChange={handleCityChange}
									onKeyPress={handleKeyPress}
								/></div>
								</div>
							</div>
							{showSuit?
							<input type="text" className="text-field-11 w-input" name="name" data-name="Name" placeholder="Suite/Apt/Unit" id="name" 
								ref={addressLine2Input}
								value={addressLine2}
								onChange={handleLine2Change}
								onKeyPress={handleKeyPress}
							/>
							:null}
							{!showSuit?
							<span onClick={()=>setShowSuit(true)} className="link">+  Suite/Apt/Unit</span>
							:null}
							<div className="columns-3 w-row">
								<div className="w-col w-col-6">
								<div className="div-block-5">
								<select
									ref={addressProvinceSelect}
									onChange={handleProvinceChange}
									value={addressProvince}
									className="province-select"
									onKeyPress={handleKeyPress}
								>
									{/* <option key={"undefined"} value={undefined} disabled={!!addressProvince}>
										Please select the province
									</option> */}
									{ServedProvinceList.map((province) => (
										<option key={province} value={province}>
											{ProvinceName[province]}
										</option>
									))}
								</select>
								</div>
								</div>
								<div className="w-col w-col-6">
								<div className="div-block-6">
									<div className="text-block-8">{addressCountry}</div>
								</div>
								</div>
							</div>
							</form>
							
							{/* validation column */}
							<div className={validationMessage?"w-form-fail-show":"w-form-fail"}>
								<div>{validationMessage}</div>
							</div>						
						</div>
						<div className="is-set-to-primary-text">
								<span>PLEASE NOTE : </span>
								New address will be set as default primary address</div>
						
						{props.isProfileMode && (
							<button onClick={submit} className="button-18 w-button">Save and Continue</button>
						)}
						</div>
						
		);
	}
);

export default AddShippingAddress;
