import {ActionType} from "../types";
import { Action, createSlice } from '@reduxjs/toolkit';
import ShippingAddress from "../../model/ShippingAddress";
import BillingCard from "../../model/BillingCard";
import PromoCode from "../../model/PromoCode";

export interface PatientState {
	selectedShippingAddress: ShippingAddress | null;
	selectedBillingCard: BillingCard | null;
	selectedPromoCode: PromoCode | null;
	lastUsedShippingAddress: ShippingAddress | null;
	lastUsedBillingCard: BillingCard | null;
	lastUsedPromoCode: PromoCode | null;
	addingShippingAddress: boolean;
	addingBillingCard: boolean;
	verifyingPromoCode: boolean;
	updatingProfile: boolean;
	validationMessage: string;
	promoValidation: {type: "success"|"error", text: string} | null
}

const initialState: PatientState = {
	selectedShippingAddress: null,
	selectedBillingCard: null,
	selectedPromoCode: null,
	lastUsedShippingAddress: null,
	lastUsedBillingCard: null,
	lastUsedPromoCode: null,
	addingShippingAddress: false,
	addingBillingCard: false,
	verifyingPromoCode: false,
	updatingProfile: false,
	validationMessage: "",
	promoValidation: {type: "success", text: ""}
};

const patientSlice = createSlice({
	name: 'patient', 
	initialState,
	reducers: {
	  addShippingAddressRequest(state: PatientState, action: ActionType) {
		state.addingShippingAddress = true; 
	  },
	  addShippingAddressSuccess(state: PatientState) {
		state.addingShippingAddress = false;
	  },
	  addShippingAddressFailure(state: PatientState) {
		state.addingShippingAddress = false;
	  },
	  addBillingCardRequest(state: PatientState, action: ActionType) {
		state.addingBillingCard = true;
	  },
	  addBillingCardSuccess(state: PatientState) {
		state.addingBillingCard = false;
	  },
	  addBillingCardFailure(state: PatientState) {
		state.addingBillingCard = false;
	  },
	  verifyPromoCodeRequest(state: PatientState, action: ActionType) {
		state.verifyingPromoCode = true;
	  },
	  verifyPromoCodeSuccess(state: PatientState, action: ActionType) {
		state.verifyingPromoCode = false;
		state.selectedPromoCode = action.payload;
	  },
	  verifyPromoCodeFailure(state: PatientState) {
		state.verifyingPromoCode = false;
		state.selectedPromoCode = null;
	  },
	  updateProfileRequest(state: PatientState, action: ActionType) {
		state.updatingProfile = true;
	  },
	  updateProfileSuccess(state: PatientState) {
		state.updatingProfile = false;
	  },
	  updateProfileFailure(state: PatientState) {
		state.updatingProfile = false;
	  },
	  selectShippingAddress(state: PatientState, action: ActionType) {
		state.selectedShippingAddress = action.payload;
		state.lastUsedShippingAddress = action.payload ? { ...action.payload } : null;
	  },
	  selectBillingCard(state: PatientState, action: ActionType) {
		state.selectedBillingCard = action.payload;
		state.lastUsedBillingCard = action.payload ? { ...action.payload } : null;
	  },
	  selectPromoCode(state: PatientState, action: ActionType) {
		state.selectedPromoCode = action.payload;
		state.lastUsedPromoCode = action.payload ? { ...action.payload } : null;
	  },
	  setPrimaryShippingAddressRequest(state: PatientState, action: ActionType) {

	  },
	  setPrimaryBillingCardRequest(state: PatientState, action: ActionType) {

	  },
	  setPrimaryShippingAddressSuccess(state: PatientState) {

	  },
	  setPrimaryShippingAddressFailure(state: PatientState) {

	  },
	  setPrimaryBillingCardSuccess(state: PatientState) {

	  },
	  setPrimaryBillingCardFailure(state: PatientState) {

	  },
	  uploadIdSuccess(state: PatientState) {
		state.updatingProfile = false;
	  },
	  uploadIdFailure(state: PatientState) {
		state.updatingProfile = false;
	  },
	  uploadInsuranceSuccess(state: PatientState) {
		state.updatingProfile = false;
	  },
	  uploadInsuranceFailure(state: PatientState) {
		state.updatingProfile = false;
	  },
	  uploadIdRequest(state: PatientState, action: ActionType) {
		state.updatingProfile = true;
	  },
	  uploadInsuranceRequest(state: PatientState, action: ActionType) {
		state.updatingProfile = true;
	  },
	  setValidationMessage(state: PatientState, action: ActionType) {
		state.validationMessage = action.payload;
	  },
	  clearValidationMessage(state: PatientState) {
		state.validationMessage = "";
	  },
	  setPromoValidation(state: PatientState, action: ActionType) {
	  	state.promoValidation = action.payload;	
	  },
	  clearPromoValidation(state: PatientState, action: ActionType) {
		state.promoValidation = null;
	  }  
	}
  });

  export const {
	addShippingAddressRequest,
	addShippingAddressSuccess,
	addShippingAddressFailure,
	addBillingCardRequest,
	addBillingCardSuccess,
	addBillingCardFailure,
	verifyPromoCodeRequest,
	verifyPromoCodeSuccess,
	verifyPromoCodeFailure,
	updateProfileRequest,
	updateProfileSuccess,
	updateProfileFailure,
	selectShippingAddress,
	selectBillingCard,
	selectPromoCode,
	setPrimaryShippingAddressRequest,
	setPrimaryBillingCardRequest,
	setPrimaryShippingAddressSuccess,
	setPrimaryShippingAddressFailure,
	setPrimaryBillingCardSuccess,
	setPrimaryBillingCardFailure,
	uploadIdSuccess,
	uploadIdFailure,
	uploadInsuranceSuccess,
	uploadInsuranceFailure,
	uploadIdRequest,
	uploadInsuranceRequest,
	setValidationMessage,
	clearValidationMessage,
	setPromoValidation,
	clearPromoValidation
  } = patientSlice.actions;
  
  export default patientSlice.reducer; 