import React, { useState, Ref, forwardRef } from "react";
import ReactDatePicker from "react-datepicker";
import moment from "moment-timezone";

import { getShippedOrdersReport, getSubscriptionExpiryReport, getUsersNoOrdersReport, getSubscriptionRenewedReport } from "../../../../http/admin";

import "./styles.scss";

const Reports = () => {
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);
	const [startExpiryDate, setStartExpiryDate] = useState<Date | null>(null);
	const [endExpiryDate, setEndExpiryDate] = useState<Date | null>(null);
	const [startRenewedDate, setStartRenewedDate] = useState<Date | null>(null);
	const [endRenewedDate, setEndRenewedDate] = useState<Date | null>(null);




	const CustomDateInput = forwardRef(({ value, onClick }: any, ref: Ref<HTMLButtonElement>) => (
		<button className="custom-date-input" onClick={onClick} ref={ref}>
			{value || "Select date"}
		</button>
	));

	const handleGetShippedOrdersReport = async () => {
		if (startDate && endDate) {
			const start = moment(startDate).format("DD/MM/yyyy");
			const end = moment(endDate).format("DD/MM/yyyy");
			const result = await getShippedOrdersReport(start, end);
			const url = window.URL.createObjectURL(new Blob([result.data]));
			const link = document.createElement("a");

			link.href = url;
			link.setAttribute("download", `shipped-orders-report.xlsx`);
			document.body.appendChild(link);
			link.click();
		}
	};
	const handleGetUsersNoOrdersReport = async () => {
		const result = await getUsersNoOrdersReport();
		const url = window.URL.createObjectURL(new Blob([result.data]));
		const link = document.createElement("a");

		link.href = url;
		link.setAttribute("download", `users-without-orders-report.xlsx`);
		document.body.appendChild(link);
		link.click();
	};
	const handleGetSubscriptionExpiryReport = async () => {
		if (startExpiryDate && endExpiryDate) {
			const start = moment(startExpiryDate).format("DD/MM/yyyy");
			const end = moment(endExpiryDate).format("DD/MM/yyyy");
			const result = await getSubscriptionExpiryReport(start, end);
			const url = window.URL.createObjectURL(new Blob([result.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `subscription-expiration-report.xlsx`);
			document.body.appendChild(link);
			link.click();
		}
	};

	const handleGetSubscriptionRenewedReport = async () => {
		if (startRenewedDate && endRenewedDate) {
			const start = moment(startRenewedDate).format("DD/MM/yyyy");
			const end = moment(endRenewedDate).format("DD/MM/yyyy");
			const result = await getSubscriptionRenewedReport(start, end);
			const url = window.URL.createObjectURL(new Blob([result.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `subscription-renewal-report.xlsx`);
			document.body.appendChild(link);
			link.click();
		}
	};

	return (
		<div className="reports-admin prefiles">
			<div className="title">Shipped Orders Report</div>
			<div className="shipped-orders-report">
				<span className="date">Date from:</span>
				<ReactDatePicker
					selected={startDate}
					onChange={(date) => {
						if (!Array.isArray(date)) {
							setStartDate(date);
						}
					}}
					minDate={moment("2020-07-08").toDate()}
					maxDate={moment().toDate()}
					dateFormat="MM/dd/yyyy"
					customInput={<CustomDateInput />}
				/>
				<span className="date">Date to:</span>
				<ReactDatePicker
					selected={endDate}
					onChange={(date) => {
						if (!Array.isArray(date)) {
							setEndDate(date);
						}
					}}
					minDate={startDate ? moment(startDate).toDate() : moment("2020-07-08").toDate()}
					maxDate={moment().toDate()}
					dateFormat="MM/dd/yyyy"
					customInput={<CustomDateInput />}
				/>
				<button
					className="reset-btn"
					onClick={() => {
						setStartDate(null);
						setEndDate(null);
					}}
				>
					Reset Dates
				</button>
				<button className="download-button" onClick={handleGetShippedOrdersReport}>
					Download Report
				</button>
			</div>
			<div className="users-no-orders-report">
				<div className="title">Users with no Subscriptions Report</div>
				<button className="download-button" onClick={handleGetUsersNoOrdersReport}>
					Download Report
				</button>
			</div>
			<div className="title pt">Subscription Expiration within 30 days Report</div>
			<div className="shipped-orders-report">
				<span className="date">Date from:</span>
				<ReactDatePicker
					selected={startExpiryDate}
					onChange={(date) => {
						if (!Array.isArray(date)) {
							setStartExpiryDate(date);
						}
					}}
					// minDate={moment("2020-07-08").toDate()}
					// maxDate={moment().toDate()}
					dateFormat="MM/dd/yyyy"
					customInput={<CustomDateInput />}
				/>
				<span className="date">Date to:</span>
				<ReactDatePicker
					selected={endExpiryDate}
					onChange={(date) => {
						if (!Array.isArray(date)) {
							setEndExpiryDate(date);
						}
					}}
					minDate={moment(startExpiryDate).toDate()}
					// maxDate={moment().toDate()}
					dateFormat="MM/dd/yyyy"
					customInput={<CustomDateInput />}
				/>
				<button
					className="reset-btn"
					onClick={() => {
						setStartExpiryDate(null);
						setEndExpiryDate(null);
					}}
				>
					Reset Dates
				</button>
				<button className="download-button" onClick={handleGetSubscriptionExpiryReport}>
					Download Report
				</button>
			</div>

			<div className="title pt">Subscription Renewed Report</div>
			<div className="shipped-orders-report">
				<span className="date">Date from:</span>
				<ReactDatePicker
					selected={startRenewedDate}
					onChange={(date) => {
						if (!Array.isArray(date)) {
							setStartRenewedDate(date);
						}
					}}
					// minDate={moment("2020-07-08").toDate()}
					// maxDate={moment().toDate()}
					dateFormat="MM/dd/yyyy"
					customInput={<CustomDateInput />}
				/>
				<span className="date">Date to:</span>
				<ReactDatePicker
					selected={endRenewedDate}
					onChange={(date) => {
						if (!Array.isArray(date)) {
							setEndRenewedDate(date);
						}
					}}
					minDate={moment(startRenewedDate).toDate()}
					// maxDate={moment().toDate()}
					dateFormat="MM/dd/yyyy"
					customInput={<CustomDateInput />}
				/>
				<button
					className="reset-btn"
					onClick={() => {
						setStartRenewedDate(null);
						setEndRenewedDate(null);
					}}
				>
					Reset Dates
				</button>
				<button className="download-button" onClick={handleGetSubscriptionRenewedReport}>
					Download Report
				</button>
			</div>
		</div>
	);
};

export default Reports;
