import React from "react";
import "./index.scss";
import {useDispatch, useSelector} from "react-redux";
import {StateType} from "../../../redux/types";
import * as modalActions from "../../../redux/modal/handlers";

const Modal = () => {
	const dispatch = useDispatch();
	const {header, text} = useSelector(
		({modal}: StateType) => ({
			header: modal.header,
			text: modal.text,
		})
	);

	const clickOK = () => {
		dispatch(modalActions.hideModal());
	};

	return (
		<>
			<div className="modal-component">
				<div className="modal-component__layout" />
				<div className="modal-component__external">
					<div className="modal-component__internal">
						<h2>{header}</h2>
						<p className="modal-component__text">
							{text}
						</p>
						<div className="modal-component__button" onClick={clickOK}>
							OK
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Modal;
