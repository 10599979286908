import React, {useMemo, useState} from "react";
import DoctorSchedule from "../DoctorSchedule";
import "./style.scss";
import DoctorListSchedule from "../DoctorListSchedule";

const DoctorConsultations = () => {
	const [isEdit, setIsEdit] = useState(false);
	const body = useMemo(() => isEdit ? <DoctorSchedule/> :
		<DoctorListSchedule />, [isEdit]);

	return <div className="doctorConsultations">
		<button className="doctorConsultations__btnSwitch" onClick={() => setIsEdit(!isEdit)}>
			Switch to {isEdit ? "consultations" : "schedule"}
		</button>
		{body}
	</div>;
};

export default DoctorConsultations;