import React, {Fragment, SyntheticEvent, useState} from "react";
import moment from "moment";
import {
	Calendar,
	momentLocalizer,
	stringOrDate,
	ToolbarProps,
	Event,
	NavigateAction
} from "react-big-calendar";
import "./style.scss";
import withDragAndDrop, { DragFromOutsideItemArgs, EventInteractionArgs } from "react-big-calendar/lib/addons/dragAndDrop";
// @ts-ignore
const DragAndDropCalendar = withDragAndDrop(Calendar);

moment.locale("ko", {
	week: {
		dow: 1,
		doy: 1
	}
});
const localizer = momentLocalizer(moment);
export type scheduleEvent = {start: stringOrDate; end: stringOrDate; id?: number; title?: string};

const ColoredDateCellWrapper = ({children}: {children: React.ReactElement}) =>
	React.cloneElement(React.Children.only(children), {
		style: {
			minHeight: 20
		}
	});

const navigateConstants = {
	PREVIOUS: "PREV",
	NEXT: "NEXT",
	TODAY: "TODAY",
	DATE: "DATE"
};

const CustomToolbar: React.ComponentType<ToolbarProps<Event, object>> = ({
	onNavigate,
	date,
	label
}) => {
	const handleNavigate = (action: NavigateAction) => () => {
		onNavigate(action);
	};
	console.log(date);
	return (
		<div className="rbc-toolbar">
			<span className="rbc-btn-group">
				<button type="button" onClick={handleNavigate(navigateConstants.TODAY as NavigateAction)}>
					Current
				</button>
				<button
					disabled={moment(date).isBefore(new Date())}
					type="button"
					onClick={handleNavigate(navigateConstants.PREVIOUS as NavigateAction)}
				>
					Previous week
				</button>
				<button
					//3 weeks not 4 because we have check after render
					disabled={moment(date).isAfter(moment().add(3, "weeks"))}
					type="button"
					onClick={handleNavigate(navigateConstants.NEXT as NavigateAction)}
				>
					Next week
				</button>
			</span>
			<span className="rbc-toolbar-label">{label} </span>
		</div>
	);
};

interface ISchedule {
	data: scheduleEvent[];
	onChange: (data: scheduleEvent[]) => void;
	setViewRange: (range: Date[]) => void;
	onDelete(id: number): void;
}

const Schedule = ({data, onChange, onDelete, setViewRange}: ISchedule) => {
	const [events, setEvents] = useState<scheduleEvent[]>([]);
	const [selected, setSelected] = useState<number | null>(null);
	const [displayDragItemInCell] = useState(true);
	const [draggedEvent, setDraggedEvent] = useState<scheduleEvent | null>(null);

	const handleSelectEvent = (event: any, e: SyntheticEvent): any => {
		console.log(event);
		console.log(e);
		if (typeof event?.id === "number") {
			setSelected(event.id);
		}
	};

	const handleSelectSlot = ({start, end}: scheduleEvent): void => {
		if (moment(start).format("HH:mm") !== moment(end).format("HH:mm")) {
			setEvents((s) => {
				const state = [
					...s,
					{
						start,
						end,
						title: "",
						id: s.length + 1,
						isAllDay: false
					}
				];
				onChange(state as never[]);

				return state;
			});
		}
	};

	const handleDelSelected = () => {
		if (selected) {
			const isNew = events.some((el) => el.id === selected);
			if (isNew) {
				// setEvents(events.filter((el) => el.id !== selected));

				setEvents((events) => {
					const state = events.filter((el) => el.id !== selected);

					onChange(state as never[]);

					return state;
				});

				return setSelected(null);
			}
			onDelete(selected);
		}
	};

	const handleDragStart = (event: object) => setDraggedEvent(event as scheduleEvent);

	const onDropFromOutside = (args: DragFromOutsideItemArgs) => {
		const start = args.start;
		const end = args.end
		if (draggedEvent?.id) {
			const event = {
				id: draggedEvent.id,
				title: draggedEvent.title ?? "",
				start,
				end
			};

			setDraggedEvent(null);
			moveEvent({event, start, end});
		}
	};

	const moveEvent = (args: EventInteractionArgs<object>) => {
		const event = args.event as scheduleEvent;
		const start = args.start;
		const end = args.end;
		setEvents((s) =>
			s.map((existingEvent) => {
				return existingEvent.id === event.id ? {...existingEvent, start, end} : existingEvent;
			})
		);	};

		const dragFromOutsideItem = () => {
			  return (event: object) => draggedEvent?.start as Date; 
		};

	return (
		<Fragment>
			{typeof selected === "number" && (
				<button type="button" onClick={handleDelSelected}>
					Delete selected
				</button>
			)}
			{/*// @ts-ignore */}
			<DragAndDropCalendar
				selectable
				resizable
				events={[...data, ...events]}
				defaultView="week"
				views={["week"]}
				timeslots={4}
				step={15}
				min={new Date(new Date().setHours(9, 0o0, 0o0))}
				max={new Date(new Date().setHours(21, 0o0, 0o0))}
				components={{
					// @ts-ignore
					timeSlotWrapper: ColoredDateCellWrapper,
					toolbar: CustomToolbar
				}}
				defaultDate={new Date()}
				onSelectEvent={handleSelectEvent}
				onSelectSlot={handleSelectSlot}
				localizer={localizer}
				dragFromOutsideItem={displayDragItemInCell ? dragFromOutsideItem : undefined}
				onDropFromOutside={onDropFromOutside}
				handleDragStart={handleDragStart}
				onEventResize={moveEvent}
				onEventDrop={moveEvent}
				onDragStart={console.log}
				onRangeChange={(range) => setViewRange(range as Date[])}
			/>
		</Fragment>
	);
};

export default Schedule;
