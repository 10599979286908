import React from "react";

import "./styles.scss";

interface JumpNavigationProps {
	items: string[];
	shortNames: {
		[key: string]: string;
	};
	names: {
		[key: string]: string;
	};
	onItemClick: (item: string, isSmooth: boolean) => void;
	mode: "products" | "facts";
}

function JumpNavigation({items, shortNames, names, onItemClick, mode}: JumpNavigationProps) {
	return (
		<div className={`jump-to-nav-wrapper ${mode}-categories`}>
			<div className="jump-to-nav">
				<div className="jump-to">Jump To:</div>
				{items.map((category) => (
					<div
						key={category}
						className={`link-to-item ${shortNames[category]}`}
						onClick={() => onItemClick(category, true)}
					>
						{names[category]}
					</div>
				))}
			</div>
		</div>
	);
}

export default JumpNavigation;
