import React, { useState, useEffect, Fragment, useMemo, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	Routes,
	Route,
	useNavigate,
	useLocation,
	Outlet,
	useBlocker,
	Blocker,
} from "react-router-dom";

import * as patientActions from "../../../../redux/patient/handlers";
import { StateType } from "../../../../redux/types";

import {
	setTransferRxProgress,
	getTransferRxProgress
} from "../../../../http/patient";
import { makeSubscriptionWithRx } from "../../../../http/cart";

import ProductQuantity from "../../../../model/ProductQuantity";
import { ServedProductCategories, ProductCategoryName } from "../../../../model/ProductCategory";

import ValidationService from "../../../../service/Validation.service";

import MessageConst from "../../../../const/Message.const";

import ImageUploadComponent from "../../../ImageUpload";
import ImageViewComponent from "../../../ImageView";
import CustomSelect from "../../../CustomSelect";
import ShippingAddresses from "../ShippingAddresses";
import AddShippingAddress from "../AddShippingAddress";
import BillingCards from "../BillingCards";
import AddBillingCard from "../AddBillingCard";
import SuccessTransfer from "../SuccessTransfer";

import IDsvg from "../../../../static/images/icon-id.svg";
import IconConfirmGreen from "../../../../static/images/_confirmGreen.svg";

import "./styles.scss";
import LoaderComponent from "../../../Loader";
import ContinueTransfer from "../ContinueTransfer";
import PopUp from "../../../PopUp";
import Tooltip from "../../../Tooltip";
import { compressImage } from "../../../../utils/helpers";
import PrescriptionStep, { PrescriptionStepRef } from "./PrescriptionStep";

type Option = {
	label: string;
	value: string;
};

const stepName = [
	"Identification and Insurance",
	"Medication Identification",
	"Prescription Details",
	"Shipping Information",
	"Billing Information"
];

const stepUrl = [
	"id-insurance-step",
	"select-product-step",
	"prescription-step",
	"shipping-info-step",
	"billing-info-step"
];



const productCategoryOptions: Option[] = ServedProductCategories.map((category) => ({
	label: ProductCategoryName[category],
	value: category
}));


const TransferRx = () => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	
	const navigate = useNavigate();
	const { profile, products } = useSelector(
		useMemo(
		  () => ({ auth, product }: StateType) => ({
			profile: auth.profile,
			products: product.productItems
		  }),
		  []
		)
	  );
	const [emtyProfileOpen, setemtyProfileOpen] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false);
	const [step, setStep] = useState<number>(1);
	const [productCategory, setProductCategory] = useState<Option | null>(null);
	const [product, setProduct] = useState<Option | null>(null);
	const [quantity, setQuantity] = useState<Option | null>(null);
	const [rxNumber, setRxNumber] = useState<string>("");
	const [pharmacyName, setPharmacyName] = useState<string>("");
	const [pharmacyPhone, setPharmacyPhone] = useState<string>("");
	const [bottleImageId, setBottleImageId] = useState<string | null>(null);
	const [isImageViewShow, setIsImageViewShow] = useState<string | null>(null);
	const [imageType, setImageType] = useState<string>("");
	const [exitModal, setExitModal] = useState<string | null>(null);
	const [validationMessage, setValidationMessage] = useState<string>("");
	const [navVisibility, setNavVisibility] = useState<boolean>(true);
	const [isStateUpdated, setIsStateUpdated] = useState<boolean>(true);
	const childRef = useRef<PrescriptionStepRef>(null);

	const productOptions: Option[] = products
		.filter((product) => product.problemCategory === productCategory?.value)
		.map((product) => ({
			label: product.name,
			value: product.id.toString()
		}));
	let quantityOptions: Option[] = [];
	
	let blocker:Blocker = useBlocker(
		({ currentLocation, nextLocation }) => {
			if(currentLocation.pathname.includes("success-transfer")) {
				return false;
			} else if(currentLocation.pathname.includes("transfer-rx")){
				const isTransferRxPage = nextLocation.pathname.includes("transfer-rx");
				if(childRef.current) {
					childRef.current.setPrescriptionStepData();
				}
				return !isTransferRxPage;
			} else {
				return false;
			}
		}			
	  );	

	if (product) {
		const productObj = products.find((prod) => prod.id === +product.value);

		if (productObj) {
			let quantities = [...productObj.quantities].sort((a, b) => b.price - a.price);
			quantityOptions = quantities				
				.map((quantity: ProductQuantity) => ({
					value: quantity.id.toString(),
					label: quantity.quantity
				}));
		}
	}

	useEffect(() => {
		setStep(1);
	}, []);

	useEffect(() => {
		if (!["success-transfer", "continue-transfer"].some((str) => pathname.includes(str))) {
			navigate(`${stepUrl[step - 1]}`);
		}
	}, [step, pathname]);

	const handleValidationMessage = (message: string) => {
		setValidationMessage(message);
	}

	useEffect(() => {
		const loadProgress = async () => {
			const result = await getTransferRxProgress();

			const progress = result.data;

			if (progress) {
				// navigate(`${pathname}/continue-transfer`);
			}
		};

		loadProgress();
	}, [pathname]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	const resumeTransfer = () => {
		const loadProgress = async () => {
			const result = await getTransferRxProgress();
			const progress = result.data;

			if (progress) {
				loadProgressFromRes(progress);
			}
		};

		loadProgress();
	};

	const handleOpenImgView = (type: string, id: string) => {
		setImageType(type);
		setIsImageViewShow(id);
	};

	const handleCloseImgView = () => {
		setImageType("");
		setIsImageViewShow(null);
	};

	const handleIdImgChange = async (file: File) => {
		compressImage(file, (newFile: Blob) => dispatch(patientActions.uploadIdRequest(newFile)));
		// dispatch(patientActions.uploadIdRequest(file));
	};

	const handleInsuranceImgChange = async (file: File) => {
		compressImage(file, (newFile: Blob) =>
			dispatch(patientActions.uploadInsuranceRequest(newFile))
		);
		// dispatch(patientActions.uploadInsuranceRequest(file));
	};

	const handleBottleImgChange = async (bottleId: string) => {
			setBottleImageId(bottleId);
	};

	const handleStateUpdated = async (state: boolean) => {
		setIsStateUpdated(state);
	};

	const saveProgress = () => {
		const objToSave: {
			step: number;
			productCategory?: Option;
			product?: Option;
			quantity?: Option;
			rxNumber?: string;
			pharmacyName?: string;
			pharmacyPhone?: string;
			bottleImageId?: string;
		} = {
			step: step + 1
		};

		if (productCategory) {
			objToSave.productCategory = productCategory;
		}

		if (product) {
			objToSave.product = product;
		}

		if (quantity) {
			objToSave.quantity = quantity;
		}
		if (rxNumber) {
			objToSave.rxNumber = rxNumber;
		}

		if (pharmacyName) {
			objToSave.pharmacyName = pharmacyName;
		}

		if (pharmacyPhone) {
			objToSave.pharmacyPhone = pharmacyPhone;
		}

		if (bottleImageId) {
			objToSave.bottleImageId = bottleImageId;
		}

		setTransferRxProgress(objToSave);
	};

	const loadProgressFromRes = (savedProgress: {
		step: number;
		productCategory?: Option;
		product?: Option;
		quantity?: Option;
		rxNumber?: string;
		pharmacyName?: string;
		pharmacyPhone?: string;
		bottleImageId?: string;
	}) => {
		navigate(`${pathname}`);

		if (savedProgress?.productCategory?.label && savedProgress?.productCategory.value) {
			setProductCategory(savedProgress.productCategory);
		}

		if (savedProgress?.product?.label && savedProgress?.product.value) {
			setProduct(savedProgress.product);
		}

		if (savedProgress?.quantity?.label && savedProgress?.quantity.value) {
			setQuantity(savedProgress.quantity);
		}

		if (savedProgress?.rxNumber) {
			setRxNumber(savedProgress.rxNumber);
		}

		if (savedProgress?.pharmacyName) {
			setPharmacyName(savedProgress.pharmacyName);
		}

		if (savedProgress?.pharmacyPhone) {
			setPharmacyPhone(savedProgress.pharmacyPhone);
		}

		if (savedProgress?.bottleImageId) {
			setBottleImageId(savedProgress.bottleImageId);
		}

		if (savedProgress?.step) {
			setStep(savedProgress.step);
		}
	};

	useEffect(()=>{
		handleNextClick();
	}, [rxNumber, pharmacyName, pharmacyPhone, bottleImageId])

	const isStepReady = (stepNumber: number) => {
		switch (stepNumber) {
			case 1:
				if (!profile?.idDocument) {
					console.log(!profile?.idDocument);

					setValidationMessage(MessageConst.validation.idError);

					return;
				} else {
					setValidationMessage("");

					return true;
				}
			case 2: {
				if (!productCategory?.value) {
					setValidationMessage(MessageConst.validation.conditionError);

					return;
				}

				if (!product?.value) {
					setValidationMessage(MessageConst.validation.prescribedProductError);

					return;
				}

				if (!quantity?.value) {
					setValidationMessage(MessageConst.validation.quantityError);

					return;
				}

				setValidationMessage("");

				return true;
			}

			case 3: {
				if (childRef.current) {
					setIsStateUpdated(false);
					childRef.current.setPrescriptionStepData();
				}

				if(isStateUpdated) {
					if (!rxNumber) {
						setValidationMessage(MessageConst.validation.rxNumberError);
						return;
					}
	
					if (!pharmacyName) {
						setValidationMessage(MessageConst.validation.pharmacyNameError);
						return;
					}
	
					if (!pharmacyPhone || !ValidationService.validatePhone(pharmacyPhone)) {
						setValidationMessage(MessageConst.validation.pharmacyPhoneError);
						return;
					}
	
					if (!bottleImageId) {
						setValidationMessage(MessageConst.validation.bottleImageError);
						return;
					}
	
					setValidationMessage("");
					setIsStateUpdated(false);
					return true;
				}
				return false;
			}

			case 4: {
				const allShippingAddresses = profile?.shippingAddresses;

				const primaryAddress = allShippingAddresses?.find((address) => address.isPrimary === true);

				if (primaryAddress) {
					setValidationMessage("");
					return true;
				} else {
					setValidationMessage(MessageConst.validation.shippingAddressError);
					break;
				}
			}
			case 5: {
				const allBillingCards = profile?.billingCards;

				const primaryCard = allBillingCards?.find((card) => card.isPrimary === true);

				if (primaryCard) {
					submitRx();
				} else {
					setValidationMessage(MessageConst.validation.billingCardError);
				}

				return false;
			}

			default:
				break;
		}
	};

	const handleNextClick = async () => {
		const currentStep = step;
		if (
			profile?.firstName == null &&
			profile?.lastName == null &&
			profile?.phone == null &&
			profile?.birthday == null) {
			setemtyProfileOpen(true)
			return;
		}

		if (isStepReady(currentStep)) {
			setStep(step + 1);

			saveProgress();
		}
	};

	
	const handleCloseEmtyProfile = () => {
		setemtyProfileOpen(false);
		navigate("/profile/account-info");

	}

	const submitRx = async () => {
		const requestObj = {
			promoCode: "",
			rxDocumentId: +bottleImageId!,
			rxNumber: rxNumber,
			pharmacyNameAndAddress: pharmacyName,
			pharmacyPhone: pharmacyPhone,
			productId: +product!.value,
			productQuantityId: +quantity!.value
		};

		setLoading(true);

		try {
			await makeSubscriptionWithRx(requestObj);
			setStep(1);
			setProductCategory(null);
			setProduct(null);
			setQuantity(null);
			setRxNumber("");
			setPharmacyName("");
			setPharmacyPhone("");
			setBottleImageId(null);
			navigate(`success-transfer`);
		} catch {
			setValidationMessage(MessageConst.error.unknownError);
		}

		setLoading(false);
	};

	const handleBackClick = () => {
		setValidationMessage("");
		setStep(step - 1);
	};

	const handleChangeRxId = useCallback((rxId: string): void => {		
		setRxNumber(rxId);
	}, []);

	const handleChangePharmacyName = useCallback((name: string): void =>{
		setPharmacyName(name);
	},[])

	const handleChangePhone = useCallback((phone: string): void => {
		setPharmacyPhone(phone);
	},[]);

	const IdInsuranceStep = () => {
		return (
			<div className="id-insurance-step">
				<h1 className="title">Thanks for Choosing Winston!</h1>
				<div className="subtitle">
					Please fill in the details below so that we can begin your prescription
					transfer. If you have any questions feel free to contact us on the live chat in
					the bottom right hand corner of your screen.
				</div>

				<div className="id-upload-block">
					<div className="question-block">
						<div className="question__withTooltip">
							<span className="question">
								Upload your Government Issued Photo identification:
							</span>
							<Tooltip
								text={
									<Fragment>
										<b>Why do we need your identification?</b>
										<p>
											To ensure medications prescribed are being used by the individual
											they have been prescribed to, we are required to verify your
											identity before we can release your prescription.
										</p>
									</Fragment>
								}
							/>
						</div>
						<p className="answer">
							Accepted forms : Driver’s License, Health Card, Passport, or any Government
							Issued ID Card.
						</p>
					</div>
					<div className="img-buttons">
						<ImageUploadComponent
							img={!!profile!.idDocument}
							onImgChange={handleIdImgChange}
							mode="ID"
						/>
						{profile!.idDocument && (
							<label
								className="view-img"
								onClick={() => handleOpenImgView("ID", profile!.idDocument!)}
							>
								<img src={IDsvg} alt="" />
								<p className="view-img-text">View image</p>
							</label>
						)}
					</div>
					{profile!.idDocument && (
						<div className="success-upload">
							<span>Success!</span>
							<img src={IconConfirmGreen} alt="" />
						</div>
					)}
				</div>
				<div className="insurance-upload-block">
					<div className="question-block">
						<span className="question">Have healthcare benefits?</span>
						<span className="answer">
							Upload a picture of your insurance card here and our pharmacy partner will
							apply your coverage, if available, to the prescription once approved.
						</span>
					</div>
					<div className="img-buttons">
						<ImageUploadComponent
							img={!!profile!.insuranceDocument}
							onImgChange={handleInsuranceImgChange}
							mode="Insurance"
						/>
						{profile!.insuranceDocument && (
							<label
								className="view-img"
								onClick={() =>
									handleOpenImgView("Insurance", profile!.insuranceDocument!)
								}
							>
								<img src={IDsvg} alt="" />
								<p className="view-img-text">View image</p>
							</label>
						)}
					</div>
					{profile!.insuranceDocument && (
						<div className="success-upload">
							<span>Success!</span>
							<img src={IconConfirmGreen} alt="" />
						</div>
					)}
				</div>
			</div>
		);
	}

	const SelectProductStep = () => {
		return (
			<div className="select-product-step">
				<div className="title">Select the medication to be transferred.</div>
				<div className="content">
					<div className="category">
						<CustomSelect
							label="Condition"
							placeholder="Step 1: Diagnosed condition"
							options={productCategoryOptions}
							value={productCategory}
							onChange={(selected: Option) => {
								if (!Array.isArray(selected) && selected) {
									setProductCategory(selected);
									setProduct(null);
									setQuantity(null);
								}
							}}
						/>
					</div>
					<div className="product-name">
						<CustomSelect
							label="Product Name"
							placeholder="Step 2: Prescribed product"
							options={productOptions}
							value={product}
							isDisabled={!productCategory}
							onChange={(selected: Option) => {
								if (!Array.isArray(selected) && selected) {
									setProduct(selected);
									setQuantity(null);
								}
							}}
						/>
					</div>
					<div className="product-quantity">
						<CustomSelect
							label="Quantity"
							placeholder="Step 3: Product quantity"
							options={quantityOptions}
							value={quantity}
							isDisabled={!product}
							onChange={(selected: Option) => {
								if (!Array.isArray(selected) && selected) {
									setQuantity(selected);
								}
							}}
						/>
					</div>
				</div>
			</div>
		);
	}


	const ShippingInfoStep = () => {
		return (
			<div className="shipping-info-step">
				<div className="title">
					Add your shipping details below. If you have already provided it as part of your
					account setup, please review and ensure that nothing has changed.
				</div>
				<ShippingAddresses />
				<AddShippingAddress
					navVisibility = {navVisibility}
					setNavVisibility={setNavVisibility}
					setValidationMessage={setValidationMessage}
				/>
			</div>
		);
	}

	const BillingInfoStep = () => {
		return (
			<div className="billing-info-step">
				<div className="title">
					You are almost done! Enter your payment details below or verify the details we
					have on file.
				</div>
				<BillingCards />
				<AddBillingCard
					navVisibility = {navVisibility}
					setNavVisibility={setNavVisibility}
					setValidationMessage={setValidationMessage}
					scrollToTop
				/>
			</div>
		);
	}

	const TransferSteps = () => {
		return (
			<>
				<div className="top-block-wrapper">
					 {/* <Prompt
							when={!exitModal}
							message={(nextLocation: any) => {
								const {pathname} = nextLocation;

								if (!pathname.includes("transfer-rx")) {
									setExitModal(pathname);
									return false;
								} else {
									return true;
								}
							}}
						/>  */}
					<div className="title">{`Part ${step}/5 - ${stepName[step - 1]}`}</div>
					<div className="progress-bar">
						<div className="step-progress filled">
							<div className="fill" />
						</div>
						<div className={`step-progress ${step >= 2 ? "filled" : ""}`}>
							<div className="fill" />
						</div>
						<div className={`step-progress ${step >= 3 ? "filled" : ""}`}>
							<div className="fill" />
						</div>
						<div className={`step-progress ${step >= 4 ? "filled" : ""}`}>
							<div className="fill" />
						</div>
						<div className={`step-progress ${step === 5 ? "filled" : ""}`}>
							<div className="fill" />
						</div>
					</div>
				</div>
				<div className="steps-wrapper">
					<Outlet />

					<div className="validation-message">
						<span className="validation-error">{validationMessage}</span>
					</div>
					{navVisibility && (
						<div className="steps-navigation">
							{step !== 1 && (
								<button className="back" onClick={handleBackClick}>
									Back
								</button>
							)}
							<button className="next" onClick={handleNextClick}>
								{step === 5 ? "Submit" : "Next"}
							</button>
						</div>
					)}
				</div>
				{blocker != undefined && blocker?.state === "blocked" ? (
					<PopUp>
						<div className="exit-modal">
							<div className="title">WARNING</div>
							<div className="content">
								<p>
									By exiting this page before completing the process your prescription will not be
									transferred to Winston.
								</p>
								<p>Are you sure you want to exit?</p>
								<div className="button-links">
									<button onClick={() => blocker?.proceed?.()}>Yes</button>
									<button onClick={() => blocker?.reset?.()}>No</button>
								</div>
							</div>
						</div>
					</PopUp>
				):null}
			</>
		);
	}

	return (
		<div className="transfer-rx-wrapper">
			{loading && <LoaderComponent showShadow />}
			{emtyProfileOpen && (
				<PopUp>
					<div className="exit-modal">
						<div className="title">PERSONAL INFORMATION REQUIRED</div>
						<div className="content">
							<p>Please update your Personal Information on your Account
								Profile Page prior to submitting an order</p>
						</div>
						<div className="button-links">
							<button onClick={handleCloseEmtyProfile}>Ok</button>
						</div>
					</div>
				</PopUp>
			)}
			<Routes>
				<Route
					path={`continue-transfer`}
					element={<ContinueTransfer resumeTransfer={resumeTransfer} />}
				/>
				<Route path={`success-transfer`} element={<SuccessTransfer />} />
				<Route path="/" element={<TransferSteps />}>
						<Route path="/id-insurance-step" element={<IdInsuranceStep />} index={true}/>
						<Route path="/select-product-step" element={<SelectProductStep />} />
						<Route path="/prescription-step" element={
						<PrescriptionStep 
							ref={childRef} 
							rxNumber={rxNumber} 
							pharmacyName={pharmacyName} 
							pharmacyPhone={pharmacyPhone} 
							bottleImageId={bottleImageId}
							handleChangeRxId={handleChangeRxId} 
							handleChangePharmacyName={handleChangePharmacyName} 
							handleChangePhone={handleChangePhone}
							handleBottleImgChange={handleBottleImgChange}
							handleStateUpdated={handleStateUpdated}
							handleOpenImgView={handleOpenImgView}/>} />
						<Route path="/shipping-info-step" element={<ShippingInfoStep />} />
						<Route path="/billing-info-step" element={<BillingInfoStep />} />
				</Route>
			</Routes>
			{isImageViewShow && (
				<ImageViewComponent
					imageViewProps={isImageViewShow}
					close={handleCloseImgView}
					title={`${imageType} image`}
				/>
			)}
		</div>
	);
};

export default TransferRx;
