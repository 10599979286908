import React, {useState, useEffect, useRef} from "react";
import Webcam from "react-webcam";

import cameraIcon from "../../static/images/camera.png";
import crossIcon from "../../static/images/cross.png";
import doneIcon from "../../static/images/done.png";

import "./styles.scss";

interface WebcamPhotoProps {
	uploadPhoto: (file: File) => void;
	onClose: () => void;
	mode: string;
}

const WebcamPhoto = (props: WebcamPhotoProps) => {
	const webcamRef = useRef<Webcam & HTMLVideoElement>(null);
	const imgShowRef = useRef<HTMLImageElement>(null);

	const [isWebcamEnabled, setIsWebcamEnabled] = useState<boolean>(true);
	const [base64Img, setBase64Img] = useState<string>("");

	useEffect(() => {
		function detectWebcam(callback: (hasWebcam: boolean) => void) {
			let md = navigator.mediaDevices;
			if (!md || !md.enumerateDevices) return callback(false);
			md.enumerateDevices().then((devices) => {
				callback(devices.some((device) => "videoinput" === device.kind));
			});
		}

		detectWebcam((hasWebcam) => setIsWebcamEnabled(hasWebcam));
	}, []);

	const takeScreenshot = () => {
		if (webcamRef && webcamRef.current) {
			const imageSrc = webcamRef.current.getScreenshot();
			if (imageSrc) {
				setBase64Img(imageSrc);
			}
		}
	};

	const saveScreenshot = () => {
		const imgBlob = b64toBlob(base64Img.split(",")[1], "image/jpeg");

		const imgFile = blobToFile(imgBlob, "screenshot.jpeg");

		props.onClose();
		props.uploadPhoto(imgFile);
	};

	const blobToFile = (theBlob: Blob, fileName: string) => {
		const b: any = theBlob;

		//A Blob() is almost a File() - it's just missing the two properties below which we will add
		b.lastModifiedDate = new Date();
		b.name = fileName;

		//Cast to a File() type
		return theBlob as File;
	};

	const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
		const byteCharacters = atob(b64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);
			const byteNumbers = new Array(slice.length);

			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		return new Blob(byteArrays, {type: contentType});
	};

	if (!isWebcamEnabled) {
		return (
			<div className="webcam-wrapper">
				<div className="work-wrapper">
					<div className="no-webcam">No webcam is enabled</div>
					<div className="close-btn">
						<button className="close" onClick={props.onClose}>
							X
						</button>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="webcam-wrapper">
			{base64Img ? (
				<div className="work-wrapper">
					<img src={base64Img} alt="" ref={imgShowRef} />
					<div className="btns-wrapper">
						<div className="photo-btn" onClick={() => setBase64Img("")}>
							<img src={crossIcon} alt="" />
						</div>
						<div className="photo-btn" onClick={saveScreenshot}>
							<img src={doneIcon} alt="" />
						</div>
					</div>
					<div className="close-btn">
						<button className="close" onClick={props.onClose}>
							X
						</button>
					</div>
				</div>
			) : (
				<div className="work-wrapper">
					<div className="info">
						{props.mode !== "ID" ? (
							<span className="bigger">Take a picture</span>
						) : (
							<>
								<span className="padding-bottom bigger">Take a picture</span>
								<span className="padding-bottom">
									Accepted IDs include drivers license, passport or photo healthcard. As long as
									they include a photo of yourself we will accept it and safely encrypt it.
								</span>
							</>
						)}
					</div>
					<Webcam
						audio={false}
						ref={webcamRef}
						screenshotFormat="image/jpeg"
						minScreenshotWidth={600}
						onUserMediaError={() => {
							setIsWebcamEnabled(false);
						}}
					/>
					<div className="btns-wrapper">
						<div className="photo-btn" onClick={takeScreenshot}>
							<img src={cameraIcon} alt="" />
						</div>
					</div>
					<div className="close-btn">
						<button className="close" onClick={props.onClose}>
							X
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default WebcamPhoto;
