enum OrderStatus {
	waitingDoctor = "WAITING_DOCTOR",
	rejected = "REJECTED",
	cancelled = "CANCELLED",
	waitingPharmacist = "WAITING_PHARMACIST",
	packaging = "PACKAGING",
	delivering = "DELIVERING",
	active = "ACTIVE",
	completed = "COMPLETED", // COMPLETED starts when last order needs to be shipped
	finished = "FINISHED", // FINISHED when pharmacist send last order
	paused = "PAUSED",
	pausedByPatient = "PAUSED_BY_PATIENT",
	pausedRxTransfer = "PAUSED_RX_TRANSFER",
	waitingRxCheck = "WAITING_PHARMACY_RX_CHECK",
	inProgress = "IN_PROGRESS"
}

export default OrderStatus;
