import { createSlice } from '@reduxjs/toolkit';
import {ActionType} from "../types";
import Product from "../../model/Product";

export interface ProductState {
	loadingProducts: boolean;
	productItems: Product[];
	error: string;
}

const initialState: ProductState = {
	loadingProducts: false,
	productItems: [],
	error: ""
};

const productSlice = createSlice({
	name: 'product', 
	initialState,
	reducers: {
		getProductsRequest(state: ProductState) {
			state.loadingProducts = true; 
		},
		getProductsSuccess(state: ProductState, action: ActionType) {
			state.loadingProducts = false;
			state.productItems = action.payload; 
		},
		getProductsFailure(state: ProductState, action: ActionType) {
			state.loadingProducts = false;
			state.error = action.payload; 
		},
		changeProductQuantity(state: ProductState, action: ActionType) {
			const { product, selectedQuantityIndex } = action.payload;
			const productIndex = state.productItems.findIndex((curProduct) => curProduct.id === product.id);
	  
			if (productIndex !== -1) {
			  state.productItems[productIndex].selectedQuantityIndex = selectedQuantityIndex; 
			}
		},

	}
});

export const {
	getProductsRequest,
	getProductsSuccess,
	getProductsFailure,
	changeProductQuantity,
  } = productSlice.actions;
  
export default productSlice.reducer;