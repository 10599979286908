import React, {useState, FormEvent, useEffect} from "react";
import {Link} from "react-router-dom";

import {sendContactMessage} from "../../http/contactUs";

import ValidationService from "../../service/Validation.service";

import MessageConst from "../../const/Message.const";

import LoaderComponent from "../Loader";

import canadaSymbol from "../../static/images/canadaSymbol.png";

import "./styles.scss";
import { Title } from "react-meta-elements";

const ContactUs = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [name, setName] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [phone, setPhone] = useState<string>("");
	const [message, setMessage] = useState<string>("");
	const [successMessage, setSuccessMessage] = useState<string>("");
	const [validationMessage, setValidationMessage] = useState<string>("");

	useEffect(()=>{
		let search = window.location.search;
		if(search!=""){
			window.sessionStorage.setItem("utm_source", search.split('?')[1]);
		}
	}, [])

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();

		setSuccessMessage("");

		if (!ValidationService.validateRequired(name)) {
			setValidationMessage(MessageConst.validation.firstNameError);

			return;
		}

		if (!ValidationService.validateEmail(email)) {
			setValidationMessage(MessageConst.validation.emailError);

			return;
		}

		if (!ValidationService.validatePhone(phone)) {
			setValidationMessage(MessageConst.validation.phoneError);

			return;
		}

		if (!ValidationService.validateRequired(message)) {
			setValidationMessage(MessageConst.validation.messageError);

			return;
		}
		
		setIsLoading(true);

		try {
			await sendContactMessage({
				name,
				email,
				phone,
				message
			});

			setName("");
			setEmail("");
			setPhone("");
			setMessage("");
			setValidationMessage("");
			setSuccessMessage("Message successfully sent");
		} catch {
			setSuccessMessage("");
			setValidationMessage("Something went wrong, please try again");
		}

		setIsLoading(false);
	};

	return (
		<>
			<Title>
			Contact Winston team at 1-888-404-4002 
			</Title>
		
		<div className="contact-us-wrapper" id="prefiles">
			{isLoading && <LoaderComponent showShadow />}
			<h1 className="title">Contact us, we’re here for you.</h1>
			<div className="content-contact-us">
				<div className="left-part">
					<div className="cta">
						Fill out the contact form below and we will be in touch shortly!
					</div>
					<form>
						<input
							type="text"
							name="name"
							placeholder="Your Name"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
						<input
							type="text"
							name="email"
							placeholder="Your Email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<input
							type="text"
							name="phone"
							placeholder="Your Phone Number"
							value={phone}
							onChange={(e) => {
								const phone: string = ValidationService.phoneConverter(e.target.value);

								setPhone(phone);
							}}
						/>
						<textarea
							name="message"
							placeholder="Your Message"
							value={message}
							onChange={(e) => setMessage(e.target.value)}
						/>
						{validationMessage && <div className="validation-error">{validationMessage}</div>}
						{successMessage && <div className="success-message">{successMessage}</div>}
						<button onClick={onSubmit}>Submit</button>
					</form>
				</div>
				<div className="right-part">
					<div className="top-contacts">
						<div className="contact-title">Email us anytime</div>
						<a href="mailto:welisten@askwinston.ca" className="email-link">
							welisten@askwinston.ca
						</a>
						<div className="contact-title">Have more questions?</div>
						<div className="more-questions-answer">
							Visit our{" "}
							<Link to="/faq" className="faq-link">
								FAQ
							</Link>{" "}
							Page
						</div>
						<div className="contact-title">Call us</div>
						<span className="worktime">Mon to Fri: 9:00am – 9:00pm EST</span>
						<span className="phone">1-888-404-4002</span>
					</div>
					<div className="canada-located">
						<span>Located in Canada</span>
						<img src={canadaSymbol} alt="Contact Winston team at 1-888-404-4002" />
					</div>
				</div>
			</div>
		</div>
		
		
		</>
	);
};

export default ContactUs;
