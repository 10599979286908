import { Action, createSlice } from '@reduxjs/toolkit';
import Profile from "../../model/Profile";
import Profileget from "../../model/Profileget"
import { ActionType } from '../types';
import Auth from '../../model/Auth';

type ForgotModeType = "FORGOT" | "EMAIL_SENT" | "RESET";
export interface AuthState {
	token: string | null;
	profile: Profile | null;
	profileget:Profileget |null;
	authLoading: boolean;
	validationMessage: string;
	signInValidationMessage: string;
	signUpValidationMessage: string;
	forgotPwdValidationMessage: string;
	forgotMode: ForgotModeType;
}


const initialState: AuthState = {
	token: localStorage.getItem('token') ?? null,
	profile: null,
	profileget:null,
	authLoading: false,
	validationMessage: "",
	signInValidationMessage: "",
	signUpValidationMessage: "",
	forgotPwdValidationMessage: "",
	forgotMode: "FORGOT"
};

const authSlice = createSlice({
	name: 'auth', 
	initialState,
	reducers: {
		getProfileRequest(state: AuthState, action: ActionType) {
			// No state mutation, just leave it as is
		},
		getProfileSuccess(state: AuthState, action: ActionType) {
			state.profile = action.payload; 
			state.profileget = null;
		},
		getProfileFailure(state: AuthState) {
			state.profile = null;
			state.profileget = null;
		},
		loginRequest(state: AuthState, action: ActionType) {
			state.authLoading = true;
			state.profileget = action.payload;
		},
		loginSuccess(state: AuthState, action: ActionType) {
			state.profile = action.payload;
			state.profileget = action.payload;
			state.authLoading = false;
		},
		loginFailure(state: AuthState, action: ActionType) {
			state.profile = null;
			state.profileget = action.payload;
			state.authLoading = false;
		},
		registerRequest(state: AuthState, action: ActionType) {
			state.authLoading = true;
			state.profileget = action.payload;
		},
		registerSuccess(state: AuthState, action: ActionType) {
			state.profile = action.payload;
			state.profileget = action.payload;
			state.authLoading = false;
		},
		registerFailure(state: AuthState, action: ActionType) {
			state.profile = null;
			state.profileget = action.payload;
			state.authLoading = false;
		},
		forgotPasswordRequest(state: AuthState, action: ActionType) {
			state.authLoading = true;
		},
		forgotPasswordSuccess(state: AuthState) {
			state.authLoading = false;
		},
		forgotPasswordFailure(state: AuthState) {
			state.authLoading = false;
		},
		updateProfile(state: AuthState, action: ActionType) {
			state.profile = action.payload; 
		},
		logout(state: AuthState) {
			localStorage.removeItem('token');
			state.token = null;
			state.profile = null;
		},
		removeProfile(state: AuthState) {
			state.token = null;
			state.profile = null;
		},
		setToken(state: AuthState, action: ActionType) {
			localStorage.setItem('token', action.payload); 
			state.token = action.payload;
		},
		removeToken(state: AuthState) {
			localStorage.removeItem('token');
			state.token = null;
		},
		setValidationMessage(state: AuthState, action: ActionType) {
			state.validationMessage = action.payload;
		},
		setSignInValidationMessage(state: AuthState, action: ActionType) {
			state.signInValidationMessage = action.payload;
		},
		setSignUpValidationMessage(state: AuthState, action: ActionType) {
			state.signUpValidationMessage = action.payload;
		},
		setForgotPwdValidationMessage(state: AuthState, action: ActionType) {
			state.forgotPwdValidationMessage = action.payload;
		},
		clearSignUpValidationMessage(state: AuthState) {
			state.signUpValidationMessage = "";
		},
		clearSignInValidationMessage(state: AuthState) {
			state.signInValidationMessage = "";
		},
		clearForgotPwdValidationMessage(state: AuthState) {
			state.forgotPwdValidationMessage = "";
		},
		clearValidationMessage(state: AuthState) {
			state.validationMessage = "";
		},
		setForgotMode(state: AuthState, action: ActionType) {
			state.forgotMode = action.payload;
		},
	}
});

export const { 
	getProfileRequest,
	getProfileSuccess,
	getProfileFailure,
	loginRequest,
	loginSuccess,
	loginFailure,
	registerRequest,
	registerSuccess,
	registerFailure,
	forgotPasswordRequest,
	forgotPasswordSuccess,
	forgotPasswordFailure,
	updateProfile,
	logout,
	removeProfile,
	setToken,
	removeToken,
	setValidationMessage,
	setSignInValidationMessage,
	setSignUpValidationMessage,
	setForgotPwdValidationMessage,
	clearValidationMessage,
	clearSignUpValidationMessage,
	clearSignInValidationMessage,
	clearForgotPwdValidationMessage,
	setForgotMode,
  } = authSlice.actions;

  export default authSlice.reducer; 