import { createSlice } from '@reduxjs/toolkit';
import {ActionType} from "../types";

export interface ModalState {
	header: string;
	text: string;
	isShown: boolean;
}

const initialState: ModalState = {
	header: "",
	text: "",
	isShown: false,
};

const modalSlice = createSlice({
	name: 'modal', 
	initialState,
	reducers: {
	  showModal(state: ModalState, action: ActionType) {
		state.header = action.payload.header; 
		state.text = action.payload.text;
		state.isShown = true;
	  },
	  hideModal(state: ModalState) {
		state.isShown = false; 
	  },
	},
  });

export const { showModal, hideModal } = modalSlice.actions;

export default modalSlice.reducer;