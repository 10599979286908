import React, {useEffect, useMemo, useState, Fragment} from "react";
import Moment from "moment";
import {DateRange, extendMoment} from "moment-range";
import {getAllDoctorSlots} from "../../http/schedule";
import LoaderComponent from "../Loader";
import "./style.scss";
import Order from "../../model/Order";
import SubscriptionItem from "../AdminDashboard/components/SubscriptionItem";
import ImageViewComponent from "../ImageView";
import {useDoctorOrders} from "../../utils/hooks/doctor";
import DialogComponent from "../Dialog";
import PostConsultComponent from "../DoctorPostConsult";

// @ts-ignore
const moment = extendMoment(Moment);

export type doctorSchedule = {
	[t: string]: [{id: number; subscription: null | Order[]; isFree: boolean; doctor: string}];
};

export type doctorScheduleEvent = {
	date: string;
	schedule: doctorSchedule;
};

const DoctorListSchedule = () => {
	const [events, setEvents] = useState<doctorScheduleEvent[]>([]);
	const [activeIndex, setActiveIndex] = useState<number>(-1);
	const [subscriptions, setSubscriptions] = useState<Order[]>([]);
	const [currentSlot, setCurrentSlot] = useState<string>("");

	const init = async () => {
		const {data} = await getAllDoctorSlots(dateRange);
		console.log(
			data.map((item: {date: doctorScheduleEvent[]}) => ({
				...item,
				date: moment(item.date).format("DD/MM")
			}))
		);
		setEvents(
			data.map((item: {date: doctorScheduleEvent[]}) => ({
				...item,
				date: moment(item.date).format("DD/MM")
			}))
		);

		if (activeIndex >= 0 && currentSlot) {
			const foundedSubscriptions = data[activeIndex].schedule[currentSlot][0].subscription;
			setSubscriptions(foundedSubscriptions);
		}
	};

	const {
		state: {imageViewProps, imageType, isLoading, dialog, postConsultOrderId},
		handleApproveClick,
		handleRejectClick,
		handleOpenViewID,
		handleCloseViewID,
		handlePostConsultClose,
		handlePostConsultFinish,
		setIsLoading
	} = useDoctorOrders(init);

	const dateRange = useMemo(() => {
		const end = moment().add("7", "d");
		const range: DateRange = moment().range(new Date(), end);

		return Array.from(range.by("day")).map((m: Moment.Moment) => m.format("DD/MM/YYYY"));
	}, []);

	useEffect(() => {
		loadEvents();
	}, []);

	const loadEvents = async () => {
		try {
			init();
		} finally {
			setIsLoading(false);
		}
	};

	const handleClickDate = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
		const {index} = e.currentTarget.dataset;
		if (index) {
			setActiveIndex(Number(index));
		}
	};

	const handleClickSlot = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
		const {slot} = e.currentTarget.dataset;
		if (slot) {
			const foundedSubscriptions = events[activeIndex].schedule[slot][0].subscription;
			if (foundedSubscriptions) {
				setCurrentSlot(slot);
				setSubscriptions(foundedSubscriptions);
			}
		}
	};

	const sortTime = (a: string, b: string) => {
		const [startHours, startMinutes] = a.split(":");
		const [endHours, endMinutes] = b.split(":");
		if (+startHours > +endHours) {
			return 1;
		} else if (+startHours < +endHours) {
			return -1;
		} else if (+startMinutes > +endMinutes) {
			return 1;
		} else if (+startMinutes < +endMinutes) {
			return -1;
		} else {
			return 0;
		}
	};

	const renderSlots = useMemo(
		() =>
			activeIndex !== -1 && (
				<div className="doctorListSchedule__slots">
					{Object.values(events[activeIndex].schedule).length ? (
						Object.keys(events[activeIndex].schedule)
							.sort(sortTime)
							.map((e, index) => {
								const {isFree} = events[activeIndex].schedule[e][0];

								return (
									<Fragment key={index}>
										<span
											className={`doctorListSchedule__slot ${
												isFree ? "doctorListSchedule__slot_isFree" : ""
											}`}
											data-slot={e}
											onClick={isFree ? () => {} : handleClickSlot}
										>
											{moment(e, "HH:mm").format("hh:mm a")}
										</span>
										{!!subscriptions.length && currentSlot === e && (
											<div className="doctorListSchedule__subscriptionItemWrapper">
												{subscriptions.map((subscription) => (
													<SubscriptionItem
														key={subscription.id}
														className="doctorListSchedule__subscriptionItem"
														handleOpenViewID={handleOpenViewID}
														subscription={subscription}
														handleApproveClick={handleApproveClick}
														handleRejectClick={handleRejectClick}
													/>
												))}
											</div>
										)}
									</Fragment>
								);
							})
					) : (
						<span className="doctorListSchedule__empty">Empty schedule for selected date</span>
					)}
				</div>
			),
		[activeIndex, subscriptions]
	);

	return isLoading ? (
		<LoaderComponent />
	) : (
		<div className="doctorListSchedule">
			<div className="doctorListSchedule__dates">
				{events.map(({date}, i) => (
					<span
						className={`doctorListSchedule__dateItem ${
							activeIndex === i ? "doctorListSchedule__dateItem_active" : ""
						}`}
						data-index={i}
						onClick={handleClickDate}
						key={date}
					>
						{date}
					</span>
				))}
			</div>
			{activeIndex !== -1 ? (
				renderSlots
			) : (
				<span className="doctorListSchedule__empty">Select available date</span>
			)}
			{postConsultOrderId && (
				<PostConsultComponent
					orderId={postConsultOrderId}
					close={handlePostConsultClose}
					finish={handlePostConsultFinish}
				/>
			)}
			{imageViewProps !== null && (
				<ImageViewComponent
					imageViewProps={imageViewProps}
					close={handleCloseViewID}
					title={`${imageType} image`}
				/>
			)}
			{dialog && <DialogComponent {...dialog} />}
		</div>
	);
};

export default DoctorListSchedule;
