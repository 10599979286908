import React, {useState, useEffect} from "react";
import Order from "../../model/Order";
import LoaderComponent from "../Loader";
import ImageViewComponent from "../ImageView";
import DialogComponent from "../Dialog";
import "./styles.scss";
import PostConsultComponent from "../DoctorPostConsult";
import {getDoctorPendingOrders} from "../../http/doctor";
import SubscriptionItem from "../AdminDashboard/components/SubscriptionItem";
import {useDoctorOrders} from "../../utils/hooks/doctor";

const DoctorPendingOrders = () => {
	const [pendingOrders, setPendingOrders] = useState<Order[]>([]);

	const loadOrders = async () => {
		try {
			const {data} = await getDoctorPendingOrders();
			setPendingOrders(data);
		} catch (e) {
			throw e;
		}
	};
	const {
		state: {
			postConsultOrderId,
			imageViewProps,
			imageType,
			isLoading,
			dialog
		},
		handleApproveClick,
		handleRejectClick,
		handleOpenViewID,
		handlePostConsultClose,
		handleCloseViewID,
		handlePostConsultFinish,
		setIsLoading
	}
		= useDoctorOrders(loadOrders);

	useEffect(() => {
		setIsLoading(true);
		loadOrders().finally(() => setIsLoading(false));
	}, []);

	return (
		<div className="doctor-pending-orders-component">
			{isLoading ? (
				<LoaderComponent/>
			) : (
				<>
					{pendingOrders.length === 0 ? (
						<div className="order-row">There are no pending orders.</div>
					) : (
						pendingOrders.map((order) => (
							<SubscriptionItem
								key={order.id}
								subscription={order}
								handleOpenViewID={handleOpenViewID}
								handleRejectClick={handleRejectClick}
								handleApproveClick={handleApproveClick}
							/>
						))
					)}
				</>
			)}
			{postConsultOrderId && (
				<PostConsultComponent
					orderId={postConsultOrderId}
					close={handlePostConsultClose}
					finish={handlePostConsultFinish}
				/>
			)}
			{imageViewProps !== null && (
				<ImageViewComponent
					imageViewProps={imageViewProps}
					close={handleCloseViewID}
					title={`${imageType} image`}
				/>
			)}
			{dialog && <DialogComponent {...dialog} />}
		</div>
	);
};

export default DoctorPendingOrders;
