import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ActionType } from '../types';
import SelectedProduct from "../../model/SelectedProduct";

export interface Consultation {
	date: string;
	time: string;
}

export interface CartState {
	selectedProducts: SelectedProduct[];
	makingOrder: boolean;
	consultation: Consultation | null;
	lastSuccessSubscription: {
		selectedProducts: SelectedProduct[];
		subscriptionIds: number[];
		consultation: Consultation | null;
	};
	loading: boolean;
	validationMessage: string;
}

const getInitialCart = () => {
	const cart = localStorage.getItem("cartProducts");

	if (cart) {
		try{
			const result = JSON.parse(cart);
			if(result instanceof Array){
				return result;
			}
		}catch(e){
			return [];
		}
	}

	return [];
};

const initialState: CartState = {
	selectedProducts: getInitialCart(),
	makingOrder: false,
	consultation: null,
	lastSuccessSubscription: {
		selectedProducts: [],
		subscriptionIds: [],
		consultation: null,
	},
	loading: false,
	validationMessage: ""
};

const cartSlice = createSlice({
	name: 'cart', // Name of the slice
	initialState,
	reducers: {
		getCartRequest(state: CartState) {
			// No changes to state
		},
		getCartSuccess(state: CartState, action: ActionType) {
			state.selectedProducts = action.payload;
		},
		getCartFailure(state: CartState) {
			// No changes to state
		},
		saveCartRequest(state: CartState, action: ActionType) {
			// No changes to state
		},
		saveCartSuccess(state: CartState, action: ActionType) {
			state.selectedProducts = action.payload;
			localStorage.setItem('cartProducts', JSON.stringify(action.payload));
		},
		saveCartFailure(state: CartState) {

		},
		makeSubscriptionRequest(state: CartState, action: ActionType) {
			state.makingOrder = true;
		},
		makeSubscriptionFailure(state: CartState) {
			state.makingOrder = true;
		},
		changeProductDosage (state: CartState, action: ActionType) {
			const newProducts = state.selectedProducts;
			const {product, dosageId} = action.payload;
			const productIndex = newProducts.findIndex((item) => item.product.id === product.product.id);

			if (productIndex !== -1) {
				const dosage = newProducts[productIndex].product.quantities.find((dos) => dos.id === dosageId);
				if (dosage) {
				  state.selectedProducts[productIndex].quantity = dosage;
				}
			}
		},
		addProductRequest(state: CartState, action: ActionType) {
		},
		addProductSuccess(state: CartState, action: ActionType) {
			state.selectedProducts.push(action.payload);
			localStorage.setItem('cartProducts', JSON.stringify(state.selectedProducts));
		},
		addProductFailure(state: CartState) {
		},
		deleteProductRequest(state: CartState, action: ActionType) {

		},
		deleteProductSuccess(state: CartState, action: ActionType) {
			const productIndex = state.selectedProducts.findIndex((p) => p.product.id === action.payload.product.id);
			if (productIndex !== -1) {
			  state.selectedProducts.splice(productIndex, 1);
			  localStorage.setItem('cartProducts', JSON.stringify(state.selectedProducts));
			}
		},
		deleteProductFailure(state: CartState) {
			
		},		
		updateProductRequest(state: CartState, action: ActionType){
			
		},
		updateProductFailure(state: CartState) {

		},
		addConsultationDateRequest(state: CartState, action: ActionType) {
			state.consultation = action.payload;
		},
		eraseCart(state: CartState) {
			state.selectedProducts = [];
			state.consultation = null;
		},
		makeSubscriptionSuccess(state: CartState, action: ActionType) {
			state.makingOrder = false;
			state.lastSuccessSubscription = {
			  selectedProducts: state.selectedProducts, // Corrected
			  subscriptionIds: action.payload, // Corrected
			  consultation: Object.assign({}, state.consultation),
			};
			state.selectedProducts = [];
			state.consultation = null;
		},	
		updateProductSuccess(state: CartState, action: ActionType) {
			const index = state.selectedProducts.findIndex(
			  (item) => item.product.id === action.payload.product.id
			);
	  
			if (index !== -1) {
			  state.selectedProducts[index] = action.payload; // Direct mutation
			  localStorage.setItem('cartProducts', JSON.stringify(state.selectedProducts));
			}
		},
		setValidationMessage(state: CartState, action: ActionType) {
			state.validationMessage = action.payload;
		},
		clearValidationMessage(state: CartState) {
			state.validationMessage = "";
		}
	}
});

export const {
	getCartRequest,
	getCartSuccess,
	getCartFailure,
	saveCartRequest,
	saveCartSuccess,
	saveCartFailure,
	makeSubscriptionRequest,
	makeSubscriptionFailure,
	changeProductDosage,
	addProductRequest,
	addProductSuccess,
	addProductFailure,
	deleteProductRequest,
	deleteProductSuccess,
	deleteProductFailure,
	updateProductRequest,
	updateProductFailure,
	addConsultationDateRequest,
	eraseCart,
	makeSubscriptionSuccess,
	updateProductSuccess,
	setValidationMessage,
	clearValidationMessage
  } = cartSlice.actions;
  
export default cartSlice.reducer;
