import React, {useState} from "react";
import {Link , useNavigate,useLocation} from "react-router-dom";
import Collapse from 'react-bootstrap/Collapse';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Title } from "react-meta-elements";
import stars from "../../static/images/stars.png";
import arrow_icon from "../../static/images/arrow-icon.png";
import insurance from "../../static/images/insurance.png";
import virtual from "../../static/images/free-virtual.png";
import canadian from "../../static/images/Canadian-produced-products-icon.png";
import handWave_small from "../../static/images/hand-waveing-small.png";
import handWave_large from "../../static/images/hand-waveing-large.png";
import choose_product from "../../static/images/choose-product.jpg";
import connect_doctor from "../../static/images/connect-doctor.jpg";
import take_control from "../../static/images/take-control.jpg";
import licenced_doctor from "../../static/images/licenced_doctor.png";
import refill from "../../static/images/refill.png";
import standard_shipping from "../../static/images/standard_shipping.png";
import whiteDoctor from "../../static/images/white-doctor-circle.png";
import close from "../../static/images/close.png";
// al images
import sneezing from "../../static/images/sneezing.png";
import itchyEyes from "../../static/images/itchy-eyes.png";
import coughing from "../../static/images/coughing.png"
import itchyThroatAndEarCanels from "../../static/images/itchy-throat-and-ear-canals.png";
import runnyNose from "../../static/images/runny-nose.png";
import earCongestion from "../../static/images/ear-congestion.png";
import wateryEyes from "../../static/images/watery-eyes.png"
import shortnessOfBreath from "../../static/images/shortness-of-breath.png";
import wheezing from "../../static/images/wheezing.png";
import headache from "../../static/images/headache.png";
import {useDispatch, useSelector} from "react-redux";
import {StateType} from "../../redux/types";
import * as cartActions from "../../redux/cart/handlers";
import * as appActions from "../../redux/app/handlers";
import Product from "../../model/Product";
import { Modal } from "react-bootstrap";
import {getProductImage, formatProductNameToURL} from "../../utils/helpers";
import "./style.scss";

const ProductAL = () => {

    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
    const location = useLocation();
	const[selectedDose, setSelectedDose] = useState<number>(0);

	const handleAddProductToCart = (productItem: Product, selectedDose:any) => () => {
		const y = window.scrollY;
		const {pathname} = location;

		dispatch(appActions.setCloseSidebarDirection({pathname, y}));

        const quantity = productItem.quantities.find((q)=> q.id == selectedDose);
        const index = productItem.quantities.findIndex((q)=> q.id == selectedDose);
		const product = {...productItem};
        product.qty = {...quantity!};        
        product.selectedQuantityIndex = index!;

           

		navigate("/cart");

		dispatch(cartActions.addProductRequest({product}));        
	};
	const { products } = useSelector(({ product, app }: StateType) => ({
		products: product.productItems,
		// shopScrollTarget: app.shopScrollTarget
	}));

	const [show1, setShow1] = useState(false);
	const [show2, setShow2] = useState(false);
	
	  
	const handleClose1 = () => setShow1(false);
	const handleClose2 = () => setShow2(false);
	
	const [ingredient, setIngredient] = useState<string>("");
	const handleShow1 = (text:string) => {
		setShow1(true)
		setIngredient(text);
	}		
	const [safeInfo, setSafeInfo] = useState<string>("");
	const handleShow2 = (text:string) => {
		setShow2(true)
		setSafeInfo(text);
	}
	const handleSelectChange = (event: any) => {
        setSelectedDose(event.target.value);
    }	

	// Getting utm source and medium from query params
	let search = window.location.search;
	if(search!=""){
		window.sessionStorage.setItem("utm_source", search.split('?')[1]);
		console.log(search.split('?')[1]);
	}

    return (
        <>
				<Title>Allergy  |Winston</Title>
				<div id="factPageAL">
			<div className="page-101" >

			{/* modal starts here  */}
			
			
			{/* modal ends here */}

			<div className="section">
				<div className="container-12 w-container">
				<h1 className="heading">Don't Let Your Nose Run More<br/> Than You do.<br/></h1>
				<div className="w-row">
					<div className="column-109 w-col w-col-7">
					<p className="paragraph">Say goodbye to constant sneezing, runny noses, and itching.<br/></p>
					</div>
					<div className="w-col w-col-5"></div>
				</div>
				<div className="w-row">
					<div className="column-117 w-col w-col-8">
					<div className="columns w-row">
						<div className="column w-col w-col-4"><img src={insurance}  width="40" alt="insurance" className="image-21" />
						<h1 className="heading-3"><strong>Insurance<br/>accepted</strong><br/></h1>
						</div>
						<div className="column w-col w-col-4"><img src={virtual}  width="40" alt="virtual" className="image-23" />
						<h1 className="heading-3"><strong>Free virtual <br/>consultation</strong><br/></h1>
						</div>
						<div className="column w-col w-col-4"><img src={canadian} width="40" alt="canadian" className="image-24" />
						<h1 className="heading-3"><strong>Canadian produced<br/>medications</strong><br/></h1>
						</div>
					</div>
					</div>
					<div className="w-col w-col-4"></div>
				</div>
				</div>
			</div>
			<div className="section-1">
				<div className="container-11 w-container">
				<h1 className="heading-5">About Our Allergy Medications<br/></h1>
				<p className="paragraph-14">The best way to manage your seasonal allergy symptoms is to prepare ahead of time by proactively taking the proper medication, preventing your allergies from ever starting in the first place. As they say, an ounce of prevention is worth more than a pound of cure. Winston's licensed doctors prescribe safe and effective medications such as Rupall for symptoms of allergic rhinitis, hay fever, or seasonal allergies.<br/></p>
				</div>
			</div>
			{
       products.filter(product=> product.problemCategory.includes('AL')).map(productitems=>{
		const sortedQuantities = [...productitems.quantities].sort((a, b) => (a.id > b.id ? 1 : -1));
        return(
			<section id="1st-section" className="section-1" key={productitems.id}>
				<div className="container-12 w-container">
				<div className="w-row">
					<div className="column-109 w-col w-col-6"><img src={getProductImage(productitems.name)}  width="394" alt="product" className="image-3" /></div>
					<div className="column-109 w-col w-col-6">
					<h1 className="heading-14">{productitems.name}<br/></h1>
					<h1 className="heading-11"><strong>{productitems.subName}</strong><br/></h1>
					<div className="paragraph-14 m-30px-b" dangerouslySetInnerHTML={{__html:productitems.landingPageText}} />
					<div className="w-row">
						<div className="w-col w-col-6">
						<span onClick={()=>handleShow1(productitems.ingredient)} className="button-14 w-button">
							<strong className="bold-text">Ingredients</strong></span>
						<Modal show={show1} onHide={handleClose1} animation={false} id="factPageAL">
						<div className="div-block-55">
			             	 <div className="div-block-56">
			                 	<div className="div-block-45" onClick={handleClose1}>
									<img src={close}  width="25" alt="close" />
								</div>
								<h1 className="heading-96">Ingredients</h1>
				                <div dangerouslySetInnerHTML={{__html:ingredient}}/>
				             </div>
		                	</div>
			            </Modal>
						</div>
						<div className="w-col w-col-6">
						<span onClick={()=>handleShow2(productitems.safetyInfo)} className="button-8 w-button">
							<strong>Safety Info</strong></span>
						<Modal show={show2} onHide={handleClose2} animation={false} id="factPageAL">
		                	<div className="div-block-63">
				              <div className="div-block-44">
				                <div className="div-block-45" onClick={handleClose2}><img src={close}  width="25" alt="close" /></div>
								<h1 className="heading-60"><strong>Safety information details</strong><br/></h1>
				                <div dangerouslySetInnerHTML={{__html:safeInfo}}/>
				              </div>
			                </div>	
			            </Modal>
						</div>
					</div>
					<Link to={`/${formatProductNameToURL(productitems.name)}`} className="button-15 w-button" >
                        <span className="">Learn more</span>
                    </Link>
					<div className="div-block-20">
             <h1 className="heading-12">Selects dose and quantity:</h1>
             <div className="form-block-2 w-form">
             <form id="email-form-2" name="email-form-2" data-name="Email Form 2">
                 <select id="field-2" name="field-2" className="select-field-2 w-select" 
                    value={selectedDose>0?selectedDose:sortedQuantities[0].id} 
                    onChange={(event)=>handleSelectChange(event)}>
                    {sortedQuantities.map((index)=>(
                        <option key={index.id} value={index.id}>{index.quantity}</option>
                    ))}
                </select>
            </form>
             <div className="w-form-done">
                 <div>Thank you! Your submission has been received!</div>
             </div>
             <div className="w-form-fail">
                 <div>Oops! Something went wrong while submitting the form.</div>
             </div>
             </div>
             <div className="text-block-7">Auto-refills that can be paused, skipped or cancelled anytime.</div>
             <button onClick={handleAddProductToCart(productitems, selectedDose>0?selectedDose:productitems.quantities[0].id)} className="button-9 w-button">
                 <strong>Add to cart</strong>
            </button>
         </div>
					</div>
				</div>
				</div>
			</section>
		)}
	   )
		}
			<div className="section-2 canada">
				<div className="container-12 w-container">
				<div className="w-row">
					<div className="w-col w-col-9">
					<h1 className="heading-6">Our treatments are proudly sourced from trusted Canadian manufacturers.<br/></h1>
					<h1 className="heading-100">Medexus Pharmaceutics, Inc.<br/></h1>
					</div>
					<div className="column-16 w-col w-col-3"><img src={handWave_small} sizes="100vw"
						srcSet={`${handWave_large} 1024w, ${handWave_large} 767w, ${handWave_small} 479w`}  width="86" alt="hand" /></div>
				</div>
				</div>
			</div>
			<div className="section-4">
				<div className="container-12 w-container">
				<h1 className="heading-75">Common Symptoms of Allergic Rhinitis (Seasonal Allergies or Hay Fever)<br/></h1>
				<p className="paragraph-8">Common symptoms associated with allergic rhinitis include:<br/></p>
				<div className="columns-60 w-row">
					<div className="column w-col w-col-6"><img src={sneezing} width="40" alt="skin" />
					<h1 className="heading-82">Sneezing</h1>
					</div>
					<div className="column-100 w-col w-col-6"><img className="image-25" src={itchyEyes}  width="40" alt="unprotected" />
					<h1 className="heading-82">Itchy eyes</h1>
					</div>
				</div>
				<div className="columns-66 w-row">
					<div className="column-107 w-col w-col-6">
					<img className="image-25" src={coughing}  width="40" alt="unprotected" />
					<h1 className="heading-82">Coughing</h1>
					</div>
					<div className="column-107 w-col w-col-6"><img src={itchyThroatAndEarCanels}  width="40" alt="hiv" />
					<h1 className="heading-82">Itchy throat and ear canals</h1>
					</div>
				</div>
				<div className="columns-66 w-row">
					<div className="column-103 w-col w-col-6"><img src={runnyNose}  width="40" alt="infected" />
					<h1 className="heading-82">A runny nose</h1>
					</div>
					<div className="column-107 w-col w-col-6"><img src={earCongestion}  width="40" alt="kissing" className="image-22" />
					<h1 className="heading-79">Ear congestion</h1>
					</div>
				</div>
                <div className="columns-66 w-row">
					<div className="column-103 w-col w-col-6"><img src={wateryEyes}  width="40" alt="infected" />
					<h1 className="heading-82">Watery eyes</h1>
					</div>
				
				</div>
                <div className="columns-66 w-row">
                    <p className="paragraph-8">Some people may also experience</p>
                </div>
				<div className="columns-66 w-row">
					<div className="column-107 w-col w-col-6"><img src={shortnessOfBreath}  width="40" alt="medication" />
					<h1 className="heading-82">Shortness of breath</h1>
					</div>
					<div className="column-107 w-col w-col-6"><img src={wheezing} width="40"  alt="sharing" />
					<h1 className="heading-80">Wheezing</h1>
					</div>
				</div>
                <div className="columns-66 w-row">
					<div className="column-107 w-col w-col-6"><img src={headache}  width="40" alt="medication" />
					<h1 className="heading-82">Headache</h1>
					</div>
				</div>
				</div>
			
			</div>
			<div className="section-5">
				<div className="container-3 w-container">
				<h1 className="heading-75">How Winston Works<br/></h1>
				<p className="paragraph-13">Our goal is to make healthcare accessible and medications affordable in 3 easy steps.<br/></p>
				<div className="w-row">
					<div className="column-51 w-col w-col-4">
					<div className="div-block-59"><img src={choose_product}  alt="product" />
						<h1 className="heading-95">1. Choose a product<br/></h1>
						<p className="paragraph-12">Pick from our collection of safe and effective medication based on your symptoms and health needs.</p>
					</div>
					</div>
					<div className="column-51 w-col w-col-4">
					<div className="div-block-25"><img src={connect_doctor} alt="doctor" className="image-16" />
						<h1 className="heading-55">2. Connect with a doctor<br/></h1>
						<p className="paragraph-11">A licensed doctor will speak to you about your medical history and symptoms. If appropriate, the doctor will prescribe your medication.<br/></p>
					</div>
					</div>
					<div className="column-109 w-col w-col-4">
					<div className="div-block-26"><img src={take_control} alt="control" />
						<h1 className="heading-55">3. Take control<br/></h1>
						<p className="paragraph-9">You will discreetly receive your prescription right to your desired address. Follow the dosing instructions and take back control.<br/></p>
					</div>
					</div>
				</div>
				</div>
			</div>
			<div className="section-6">
				<div className="container-4 w-container">
				<h1 className="heading-6">With Winston’s Auto-Refill You Get:<br/></h1>
				<h1 className="heading-45 mob-hide">Let Winston be your wingman and he’ll always deliver!</h1>
				<div className="columns-25 w-row">
					<div className="column-48 w-col w-col-4">
					<div className="div-block-38"><img src={licenced_doctor}  alt="doctor" className="image-18" />
						<p className="paragraph-6">Custom treatment plan by licensed doctors</p>
					</div>
					</div>
					<div className="column-48 w-col w-col-4">
					<div className="div-block-38"><img src={refill} alt="refill" />
						<h1 className="heading-44">Skip, pause or cancel your refills at any time</h1>
					</div>
					</div>
					<div className="column-49 w-col w-col-4">
					<div className="div-block-40"><img src={standard_shipping} alt="shipping" />
						<h1 className="heading-44">Free standard shipping right to your door</h1>
					</div>
					</div>
				</div>
				<h1 className="heading-45 mob-show">Let Winston be your wingman and he’ll always deliver!</h1>
				</div>
			</div>
			<div className="slider w-slider">
			<Carousel>
					<Carousel.Item interval={2000}>
						
						<Carousel.Caption>
							<div className="columns-65 w-row">
							<div className="w-col w-col-2"></div>
							<div className="column-14 w-col w-col-8">
								<h1 className="heading-26">Amazing concept and super easy process. End to end!<br/></h1><img src={stars}  alt="stars" className="image-14" />
								<div className="text-block">- Winston member, on Google, November 18, 2020</div>
							</div>
							<div className="w-col w-col-2"></div>
							</div>
						</Carousel.Caption>
					</Carousel.Item>

					<Carousel.Item interval={4000}>
						<Carousel.Caption>
							<div className="w-row">
							<div className="w-col w-col-2"></div>
							<div className="column-16 w-col w-col-8">
								<h1 className="heading-26">Dr. very friendly, answered all my concerns.<br/></h1><img src={stars}  alt="stars" />
								<div className="text-block">- Winston member, In Consultation, November 18, 2020</div>
							</div>
							<div className="w-col w-col-2"></div>
							</div>
						</Carousel.Caption>
					</Carousel.Item>

					<Carousel.Item interval={6000}>
						<Carousel.Caption>
							<div className="w-row">
							<div className="w-col w-col-2"></div>
							<div className="column-16 w-col w-col-8">
								<h1 className="heading-26">Works well and is more classy and discreet compared to the blue pill.<br/></h1><img src={stars}  alt="stars" />
								<div className="text-block">- Winston member, on Google, November 18, 2020</div>
							</div>
							<div className="w-col w-col-2"></div>
							</div>
						</Carousel.Caption>
					</Carousel.Item>

					<Carousel.Item interval={8000}>
						<Carousel.Caption>
							<div className="columns-2 w-row">
							<div className="w-col w-col-2"></div>
							<div className="column-16 w-col w-col-8">
								<h1 className="heading-26">I’m so happy with the results, the doctor and the convenience!<br/></h1><img src={stars}  alt="stars" />
								<div className="text-block">- Winston member, On Google, November 18, 2020</div>
							</div>
							<div className="w-col w-col-2"></div>
							</div>
						</Carousel.Caption>
					</Carousel.Item>

					</Carousel>
			</div>
			<div className="section-7">
				<div className="container-5 w-container collapsePanel">
				<h1 className="heading-39">Common Questions about Allergies<br/></h1>
				<div onClick={() => setOpen(!open)}  aria-expanded={open}  className="div-block-46 imgBlock">
					<h1 className="heading-34"><strong>Can Rupall be taken more than once daily?</strong><br/></h1>
					<img className="imgRotate" src={arrow_icon}  width="35"  alt="arrow-icon" />
				</div>
				<Collapse in={open}>
				<div >
					<p className="paragraph-13">Although Rupall has been indicated as a once daily treatment option for allergic rhinitis, it may be dosed differently depending on individual symptoms and severity of symptoms. It is not recommended to take this medication more than once daily unless recommended by a licensed health care provider.<br/></p>
				</div>
				</Collapse>

				<div className="div-block-34 imgBlock" onClick={() => setOpen1(!open1)}  aria-expanded={open1}>
					<h1 className="heading-37"><strong>Do these treatments provide a cure for my allergy symptoms?</strong><br/></h1>
					<img className="imgRotate" src={arrow_icon}  width="35"  alt="arrow-icon" />
				</div>

				<Collapse in={open1}>
				<div  className="div-block-31">
					<p className="paragraph-14">These treatments can help reduce and manage symptoms. However, it is not a cure for symptoms related to allergies.<br/></p>
				</div>
				</Collapse>

				<div onClick={() => setOpen2(!open2)}  aria-expanded={open2}  className="div-block-30 imgBlock">
					<h1 className="heading-37"><strong>Do I require a prescription for these treatments?</strong><br/></h1>
					<img className="imgRotate" src={arrow_icon}  width="35"  alt="arrow-icon" />
				</div>
				<Collapse in={open2}>
				<div >
					<p className="paragraph-14">Yes, our doctors can provide a prescription to one of our accredited pharmacy partners that can allow for quick and discreet delivery.</p>
				</div>
				</Collapse>

				<div onClick={() => setOpen3(!open3)}  aria-expanded={open3} className="div-block-48 imgBlock">
					<h1 className="heading-37"><strong>What are the best ways to avoid allergens/triggers?</strong><br/></h1>
					<img className="imgRotate" src={arrow_icon}  width="35"  alt="arrow-icon" />
				</div>
				<Collapse in={open3}>
				<div  className="div-block-31">
					<p className="paragraph-14">The best way to manage seasonal allergies, as recommended by experts, is to avoid your allergens. For instance, if you’re allergic to pollen, it is advisable to</p>
                  <div className="faq-list">
                   <ul>
                        <li  className="paragraph-14" >Stay indoors on particularly windy days.</li>
                        <li  className="paragraph-14">Keep your windows and doors closed if the pollen count is high.</li>
                        <li  className="paragraph-14">Delegate mowing and gardening duties, or wear a protective mask if you have to do it</li>
                        <li  className="paragraph-14">Use HEPA (High-efficiency particulate air) filters in your air conditioners.</li>
                        <li  className="paragraph-14">Not hang laundry outside as pollen can stick to them.</li>
                        <li  className="paragraph-14">Take a shower to wash pollen off your skin and hair and change into new clothesimmediately you get in.</li>
                    </ul>
                </div>
                   
				</div>
				</Collapse>

				<div onClick={() => setOpen4(!open4)}  aria-expanded={open4}  className="div-block-48 imgBlock">
					<h1 className="heading-37" ><strong>Am I covered by my insurance?</strong><br/></h1><img className="imgRotate" src={arrow_icon}  width="35" alt="arrow-icon" />
				</div>
				<Collapse in={open4}>
				<div  className="div-block-31">
					<p className="paragraph-14">Coverage varies based on individual insurance providers. If insurance information is uploaded to your account it will be verified by our pharmacy partner who will apply the coverage, if applicable.</p>
				</div>
				</Collapse>
				<Link to="/faq"  className="button-17 w-button"><strong>See more FAQs</strong></Link>
				</div>
			</div>
			<div className="section-2">
				<div className="container-15 w-container">
				<div className="columns-5 w-row">
					<div className="column-114 w-col w-col-9">
					<h1 className="heading-46">Not Sure What Product You Need?<br/></h1>
					<p className="paragraph-14">Book a free consultation call with a Canadian doctor to advise you!<br/></p>
					<Link to="/contact-us"
						// target="_blank"
						// rel="noopener noreferrer"
						className="button-18 w-button"><strong>Let us help you</strong><br/></Link>
					</div>
					<div className="column-113 w-col w-col-3"><img src={whiteDoctor}  width="212" alt="white-doctor" className="image-20" /></div>
				</div>
				</div>
			</div>
			<div className="section-10">
				<div className="container-8 w-container">
				<h1 className="heading-52">Blog Articles<br/></h1>
				<div className="columns-67 w-row">
					<div className="column-115 w-col w-col-6">
					<div className="div-block-60 blogBackground" ></div>
					</div>
					<div className="column-109 w-col w-col-6">
					<div className="div-block-61">
						<h1 className="heading-98">Seasonal Allergies<br/></h1>
						<p className="paragraph-4">As soon as spring or fall is around the corner, many people start to dread the often unavoidable symptoms of allergies that accompany the season. Constant sneezing, a running nose, and itching that comes or worsens during a particular time of the year are known as seasonal allergies, hay fever, or allergic rhinitis. If you're part of the one in six Canadians with seasonal allergies, you already know how frustrating it can be to manage your symptoms.<br/></p>
						<div className="div-block-23">
						<a href="https://learn.askwinston.ca/seasonal-allergies/" className="button-13 w-button">
                         <strong>Learn more</strong><br/>
                        </a>  	
						<div className="text-block-6">5 min read</div>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
			</div>
			</div>
			</>
    )
}
export default ProductAL;

