import React, {useState, forwardRef, Ref, ChangeEvent} from "react";
import ReactDatePicker from "react-datepicker";

import {setPackagingOrderWithRx} from "../../../../http/orders";

import ValidationService from "../../../../service/Validation.service";
import DateService from "../../../../service/Date.service";

import MessageConst from "../../../../const/Message.const";

import PopUp from "../../../PopUp";
import Order from "../../../../model/Order";
import CustomInput from "../../../CustomInput";
import LoaderComponent from "../../../Loader";

import "./styles.scss";

interface ApproveCheckRxOrderPopUpProps {
	order: Order;
	onClose: () => void;
	notify: (msg: string) => void;
	loadOrders: () => void;
}

const CustomDateInput = forwardRef(({value, onClick}: any, ref: Ref<HTMLButtonElement>) => (
	<button className="custom-date-input" onClick={onClick} ref={ref}>
		{value || "Select Date"}
	</button>
));

const ApproveCheckRxOrderPopUp = ({
	order,
	onClose,
	notify,
	loadOrders
}: ApproveCheckRxOrderPopUpProps) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [rxNumber, setRxNumber] = useState<string>("");
	const [refillsLeft, setRefillsLeft] = useState<string>("");
	const [amount, setAmount] = useState<string>("");
	const [toDate, setToDate] = useState<Date | null>(null);
	const [validationMessage, setValidationMessage] = useState<string>("");

	const handleChangeRxNumber = (e: ChangeEvent<HTMLInputElement>) => {
		const rxNumber = ValidationService.numberConverter(e.target.value);

		setRxNumber(rxNumber);
	};

	const handleChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
		const amount = e.target.value;
		if (/^[0-9]*\.?[0-9]*$/.test(amount)) {
			setAmount(amount);
		}
	};

	const handleChangeRefiilsLeft = (e: ChangeEvent<HTMLInputElement>) => {
		const refillsLeft = ValidationService.numberConverter(e.target.value);

		setRefillsLeft(refillsLeft);
	};

	const handleSubmit = async () => {
		if (!rxNumber) {
			setValidationMessage(MessageConst.validation.rxNumberError);

			return;
		}

		if (!!order.user?.insuranceDocument && !amount) {
			setValidationMessage(MessageConst.validation.coPayAmountError);

			return;
		}

		if (!refillsLeft) {
			setValidationMessage(MessageConst.validation.refillsLeftError);

			return;
		}

		if (order.maxRefillsNumber && +refillsLeft > order.maxRefillsNumber - 1) {
			setValidationMessage(MessageConst.validation.refillsQuantityError);

			return;
		}

		if (!toDate) {
			setValidationMessage(MessageConst.validation.transferedRxExpiryDateError);

			return;
		}

		const requestObject = {
			rxNumber: +rxNumber,
			refillsLeft: +refillsLeft,
			toDate: DateService.formatLocalDateForServer(toDate),
			amount: order.cartPrice
		};

		if (amount) {
			requestObject.amount = +(+amount * 100).toFixed(0);
		}

		try {
			setLoading(true);
			const result = await setPackagingOrderWithRx(order.id, requestObject);
			const status = result.data;

			if (status === "OK") {
				notify("Order has been successfuly approved");
			} else if (status === "PAUSED") {
				notify("Payment information is not valid. The order has been paused");
			}
		} catch (err: any) {
			if ([400, 401, 403].includes(err.response.status)) {
				const res = err.response.data;
				notify(res.message);
			} else {
				notify(MessageConst.error.unknownError);
			}
		} finally {
			onClose();
			loadOrders();
		}
	};

	return (
		<PopUp>
			{loading && <LoaderComponent showShadow />}
			<div className="approve-check-rx-wrapper">
				<div className="title">{`Fill the fields for order #${
					order.number
				}-${order.subNumber.toString().padStart(2, "0")}`}</div>
				<CustomInput label="Rx number" value={rxNumber} onChange={handleChangeRxNumber} />
				{!!order.user?.insuranceDocument && (
					<CustomInput label="Co-Pay amount" value={amount} onChange={handleChangeAmount} />
				)}
				<CustomInput
					label={`Remaining refills after this dispensing (max ${
						order.maxRefillsNumber ? order.maxRefillsNumber - 1 : ""
					})`}
					value={refillsLeft}
					onChange={handleChangeRefiilsLeft}
				/>
				<div className="date-label">Transferred Rx expiry date</div>
				<ReactDatePicker
					selected={toDate}
					minDate={new Date()}
					onChange={(date) => {
						if (!Array.isArray(date)) {
							setToDate(date);
						}
					}}
					dateFormat="MM/dd/yyyy"
					customInput={<CustomDateInput />}
				/>
				{validationMessage && <div className="validation-error">{validationMessage}</div>}
				<div className="action-buttons">
					<button onClick={onClose}>Cancel</button>
					<button onClick={handleSubmit}>Submit</button>
				</div>
			</div>
		</PopUp>
	);
};

export default ApproveCheckRxOrderPopUp;
