import React, {useState, useEffect, useRef, Fragment} from "react";
import {useLocation, useNavigate, Link, NavLink} from "react-router-dom";
import {useSelector} from "react-redux";

import LoginComponent from "../Login";
import GoogleLinkComponent from "../Login/GoogleLink"
import RegisterComponent from "../Register";
import OrderProcessComponent, {OrderProcessRef} from "../OrderProcess";
import ForgotPass from "../ForgotPass";

import logoPng from "../../static/images/logo.png";
import crossSvg from "../../static/images/cross.svg";
import emptyCartImg from "../../static/images/empty-cart.jpg";
import confirmImg from "../../static/images/confirm-image-cart.jpg";
import paymentImg from "../../static/images/payment-image-cart.jpg";
import shippingImg from "../../static/images/shipping-image-cart.jpg";
import loginRegisterImg from "../../static/images/login-register-image.jpg";

import {StateType} from "../../redux/types";

import "./styles.scss";

export type SidebarMode = "CART" | "LOGIN";
export type AuthModeType = "LOGIN" | "REGISTER" | "FORGOT"| "RELOGIN";

const Sidebar = () => {
	const sidebarWrapper = useRef<HTMLDivElement>(null);
	const {pathname} = useLocation();
	const navigate = useNavigate();
	const orderProcessRef = useRef<OrderProcessRef | null>(null);
	const [authMode, setAuthMode] = useState<AuthModeType>("LOGIN");
	const [sidebarMode, setSidebarMode] = useState<SidebarMode>("CART");
	const [currentStep, setCurrentStep] = useState<number>(1);

	const {infoStrip, profile, selectedProducts, closeSidebarDirection} = useSelector(
		({app, auth, cart}: StateType) => ({
			infoStrip: app.infoStrip,
			profile: auth.profile,
			selectedProducts: cart.selectedProducts,
			closeSidebarDirection: app.closeSidebarDirection
		})
	);

	useEffect(() => {
		if (pathname === "/login") {
			setAuthMode("LOGIN");
		} else if (pathname === "/register") {
			setAuthMode("REGISTER");
		} else if (pathname.includes("/forgot-password")) {
			setAuthMode("FORGOT");
		}else if(pathname === "/google-link"){
			setAuthMode("RELOGIN")
		}
	}, [pathname]);

	useEffect(() => {
		if (
			["/login", "/register", "/forgot-password","/google-link"].some((substring) => pathname.includes(substring))
		) {
			setSidebarMode("LOGIN");
		}
	}, [pathname]);

	useEffect(() => {
		if (sidebarWrapper?.current) {
			sidebarWrapper.current.scrollTo(0, 0);
		}
	}, [currentStep, sidebarWrapper]);

	useEffect(() => {
		if (sidebarWrapper?.current) {
			sidebarWrapper.current.scrollTop = 0;
		}
	}, []);

	useEffect(() => {
		setCurrentStep(1);

		return () => setCurrentStep(1);
	}, []);

	const handleCurrentStepChange = (step: number): void => {
		setCurrentStep(step);
	};

	const handleCloseSidebar = () => {
		// history.go(goRouteNumber);
		if (closeSidebarDirection) {
			navigate(closeSidebarDirection.pathname);
		} else {
			navigate("/");
		}
	};

	const renderAuthComponents = () => {
		switch (authMode) {
			case "REGISTER":				
				return (
					<RegisterComponent
						isRegisterButtonShown={true}
						handleLoginModeClick={() => navigate("/login")}
					/>
				);
			case "FORGOT":
				return (
					<ForgotPass
						onRegisterModeClick={() => navigate("/login")}
						handleLoginModeClick={() => navigate("/login")}
						isShowResetBtn
					/>
				);
			case "RELOGIN":
				return(
					<GoogleLinkComponent 
					handleLoginModeClick={() => navigate("/login")}
					/>

				)

			default:
				sessionStorage.setItem("checkout", "false");				
				return (
					<LoginComponent
						isLoginButtonShown={true}
						handleLoginModeClick={() => navigate('/login')}
						onRegisterModeClick={() => navigate("/login")}
						handleForgotModeClick={() => navigate("/forgot-password")}
					/>
				);
		}
	};

	return (
		<>
			<div
				className={
					"right-area shown " +
					(infoStrip ? " extra-height" : "") +
					(sidebarMode === "CART" && currentStep === 1 ? " cart-layout" : " process-layout")
				}

				
			>
				<div className="left" >
					{sidebarMode !== "CART" && (
						<Link to="/shop" className="shop">
							<div className="text">Shop</div>
						</Link>
					)}

					<div className="left-images nonstandart">
						<div className="man-img">
							{["CART"].includes(sidebarMode) && selectedProducts.length === 0 && (
								<img src={emptyCartImg} alt="" />
							)}
							{["CART"].includes(sidebarMode) &&
								selectedProducts.length !== 0 &&
								currentStep === 1 && <img src={emptyCartImg} alt="" />}
							{["CART"].includes(sidebarMode) && currentStep === 2 && (
								<img src={loginRegisterImg} alt="" />
							)}
							{["CART"].includes(sidebarMode) && currentStep === 3 && (
								<img src={shippingImg} alt="" />
							)}
							{["CART"].includes(sidebarMode) && currentStep === 4 && (
								<img src={paymentImg} alt="" />
							)}
							{["CART"].includes(sidebarMode) && currentStep === 5 && (
								<img src={confirmImg} alt="" />
							)}
						</div>
						<NavLink to="" className="logo-img">
							<img src={logoPng} alt="" />
						</NavLink>
					</div>
				</div>
				<div className="right" ref={sidebarWrapper}>
					{/* <div className="back" onClick={handleCloseSidebar}>
						<img src={crossSvg} alt="" />
					</div> */}
					{!profile && sidebarMode !== "CART" && (
						<div className="login-register-wrapper">{renderAuthComponents()}</div>
					)}
					{sidebarMode === "CART" && (
						<Fragment>
							<OrderProcessComponent
								onCurrentStepChange={handleCurrentStepChange}
								ref={orderProcessRef}
								sidebarRef={sidebarWrapper}
							/>
						</Fragment>
					)}
				</div>
				{/* {sidebarMode === "CART" && !!selectedProducts.length && currentStep === 1 && (
					<button
						className="right-area__checkoutBtn"
						onClick={() => orderProcessRef.current && orderProcessRef.current.handleNextStep()}
					>
						Checkout
					</button>
				)} */}
			</div>
		</>
	);
};

export default Sidebar;
