import React, {useState, ChangeEvent, useRef, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import * as patientActions from "../../../../redux/patient/handlers";
import {StateType} from "../../../../redux/types";

import {ServedProvinceList, ProvinceName} from "../../../../model/Province";

import ValidationService from "../../../../service/Validation.service";

import MessageConst from "../../../../const/Message.const";

import CustomSelect from "../../../CustomSelect";
import CustomInput from "../../../CustomInput";
import LoaderComponent from "../../../Loader";
import CustomCheckbox from "../../../CustomCheckbox";
import MonerisInput, {MonerisInputRefObject} from "../../../MonerisInput";

import "./styles.scss";
import {useMedia} from "../../../../utils/hooks/use-media";

interface AddBillingCardProps {
	navVisibility?: boolean;
	setNavVisibility?: (b: boolean) => void;
	setValidationMessage?: (s: string) => void;
	scrollToTop?: boolean;
}

type ProvinceOption = {
	label: string;
	value: string;
};

const provinceOptions: ProvinceOption[] = ServedProvinceList.map((province) => ({
	label: ProvinceName[province],
	value: province
}));

const AddBillingCard = ({
	navVisibility = true,
	setNavVisibility,
	setValidationMessage,
	scrollToTop
}: AddBillingCardProps) => {
	const dispatch = useDispatch();
	const monerisInputRef = useRef<MonerisInputRefObject>(null);

	const {loading, addingBillingCard, profile, shippingAddress} = useSelector(
		({patient, auth}: StateType) => ({
			loading: patient.updatingProfile,
			addingBillingCard: patient.addingBillingCard,
			profile: auth.profile,
			shippingAddress: auth.profile?.shippingAddresses.find((adr) => adr.isPrimary)
		})
	);
	const [addCardMode, setAddCardMode] = useState<boolean>(false);

	const [isAddrFromShipping, setIsAddrFromShipping] = useState<boolean>(false);

	const [newCardStreet, setNewCardStreet] = useState<string>("");
	const [newCardSuite, setNewCardSuite] = useState<string>("");
	const [newCardCity, setNewCardCity] = useState<string>("");
	const [newCardProvince, setNewCardProvince] = useState<ProvinceOption>();
	const [newCardCountry] = useState<string>("Canada");
	const [newCardPostal, setNewCardPostal] = useState<string>("");
	const [newCardToPrimary, setNewCardToPrimary] = useState<boolean>(true);
	const [checkingCard, setCheckingCard] = useState<boolean>(false);

	const [addValidationMessage, setAddValidationMessage] = useState<string>("");

	const isMobile = useMedia(["(max-width:531px)"], [true], false);

	useEffect(() => {
		if (isAddrFromShipping && shippingAddress) {
			const {
				addressCity: city,
				addressLine1: line1,
				addressLine2: line2,
				addressPostalCode: postalCode,
				addressProvince: province
			} = shippingAddress;

			setNewCardCity(city);
			setNewCardStreet(line1);
			if (line2) {
				setNewCardSuite(line2);
			}
			setNewCardPostal(postalCode);

			if (province) {
				setNewCardProvince({label: ProvinceName[province], value: province});
			}
		}
	}, [isAddrFromShipping, shippingAddress]);

	useEffect(()=>{
		if(!navVisibility) {
			setAddCardMode(true);
		}
	}, [navVisibility]);

	const handleChangePostalCode = (e: ChangeEvent<HTMLInputElement>) => {
		const cardPostalCode = ValidationService.postalCodeConverter(e.target.value);

		setNewCardPostal(cardPostalCode);
	};

	const handleCancelAddCard = () => {
		setNewCardStreet("");
		setNewCardSuite("");
		setNewCardCity("");
		setNewCardProvince(undefined);
		setNewCardPostal("");
		setNewCardToPrimary(false);
		setAddCardMode(false);
		setAddValidationMessage("");

		if (setNavVisibility) {
			setNavVisibility(true);
		}
	};

	const submit = async (): Promise<void> => {
		if (!ValidationService.validateRequired(newCardStreet)) {
			setAddValidationMessage(MessageConst.validation.address.streetError);
			return;
		}
		if (!ValidationService.validateRequired(newCardCity)) {
			setAddValidationMessage(MessageConst.validation.address.cityError);
			return;
		}
		if (!newCardProvince?.value) {
			setAddValidationMessage(MessageConst.validation.provinceError);
			return;
		}
		if (!ValidationService.validateRequired(newCardPostal)) {
			setAddValidationMessage(MessageConst.validation.address.postalCodeError);
			return;
		}
		if (!ValidationService.validatePostalCode(newCardPostal)) {
			setAddValidationMessage(MessageConst.validation.address.postalCodeError);
			return;
		}
		setAddValidationMessage("");
		setCheckingCard(true);
		monerisInputRef.current?.doMonerisSubmit();
		setIsAddrFromShipping(false);
	};

	const addCardCb = (token: string) => {
		const cb = (err?: string) => {
			if (err) {
				setAddValidationMessage(err);
			} else {
				setAddValidationMessage("");
				setNewCardStreet("");
				setNewCardSuite("");
				setNewCardCity("");
				setNewCardProvince(undefined);
				setNewCardPostal("");
				setAddCardMode(false);

				if (setValidationMessage) {
					setValidationMessage("");
				}

				if (setNavVisibility) {
					setNavVisibility(true);
				}
			}
		};
		const callback = cb;
		dispatch(
			patientActions.addBillingCardRequest({
				mode: "profile",
				setPrimary: newCardToPrimary,
				info: {
					token,
					addressLine1: newCardStreet,
					addressLine2: newCardSuite,
					addressCity: newCardCity,
					addressProvince: newCardProvince!.value,
					addressPostalCode: newCardPostal,
					addressCountry: newCardCountry
				}
			})
		);
		if(callback) {
			callback();
		}
	};

	return (
		<div className="add-billing-card-block">
			{(loading || addingBillingCard || checkingCard) && <LoaderComponent showShadow={true} />}

			{addCardMode ? (
				<div>
					<MonerisInput
						ref={monerisInputRef}
						addCb={addCardCb}
						setCheckingCard={setCheckingCard}
						setValidationMessage={setAddValidationMessage}
						shrink={isMobile}
					/>
					{!!profile?.billingCards.length && (
						<div className="card-form">
							<div className="is-set-to-primary">
								<CustomCheckbox
									checked={newCardToPrimary}
									onChange={(e: ChangeEvent<HTMLInputElement>) =>
										setNewCardToPrimary(e.target.checked)
									}
								/>
								<div className="is-set-to-primary-text"><span>PLEASE NOTE : </span>New card will be set as default primary card</div>
							</div>
						</div>
					)}
					{!!profile?.shippingAddresses.filter((address) => address.isPrimary).length && (
						<label className="radio-input-container">
							<span>Same as shipping address</span>
							<input
								type="checkbox"
								name="billing-shipping"
								checked={isAddrFromShipping}
								onChange={() => {
									setIsAddrFromShipping((s) => !s);
								}}
							/>
							<span className="checkmark" />
						</label>
					)}
					<div className="add-card-address-form">
						<div className="street">
							<CustomInput
								label="Street Address"
								type="text"
								placeholder="Enter street"
								value={newCardStreet}
								onChange={(e: ChangeEvent<HTMLInputElement>) => setNewCardStreet(e.target.value)}
							/>
						</div>
						<div className="suite">
							<CustomInput
								label="Suite / Apt"
								type="text"
								placeholder="Enter suite / apt"
								value={newCardSuite}
								onChange={(e: ChangeEvent<HTMLInputElement>) => setNewCardSuite(e.target.value)}
							/>
						</div>
						<div className="city">
							<CustomInput
								label="City"
								type="text"
								placeholder="Enter city"
								value={newCardCity}
								onChange={(e: ChangeEvent<HTMLInputElement>) => setNewCardCity(e.target.value)}
							/>
						</div>
						<div className="province">
							<CustomSelect
								label="Province"
								placeholder="Select province"
								options={provinceOptions}
								value={newCardProvince}
								onChange={(selected: ProvinceOption) => {
									if (!Array.isArray(selected) && selected) {
										setNewCardProvince(selected);
									}
								}}
							/>
						</div>
						<div className="country">
							<CustomInput label="Country" type="text" disabled value={newCardCountry} />
						</div>
						<div className="postal">
							<CustomInput
								label="Postal Code"
								placeholder="Enter postal code"
								type="text"
								value={newCardPostal}
								onChange={handleChangePostalCode}
							/>
						</div>
					</div>
					{addValidationMessage && <div className="validation-error">{addValidationMessage}</div>}
					<div className="action-buttons">
						<button onClick={handleCancelAddCard}>Cancel</button>
						<button onClick={submit}>Save</button>
					</div>
				</div>
			) : (
				<div
					className="add-card-button"
					onClick={() => {
						setAddCardMode(true);

						if (setNavVisibility) {
							setNavVisibility(false);
						}
					}}
				>
					<div className="plus">+</div>
					<h1 className="">Add New Card</h1>
				</div>
			)}
		</div>
	);
};

export default AddBillingCard;
