import React, {useState, useEffect} from "react";
import moment from "moment-timezone";

import {cancelSubscription} from "../../../../http/cart";

import DateService from "../../../../service/Date.service";

import OrderStatus from "../../../../model/OrderStatus";
import Order from "../../../../model/Order";
import OrderItem from "../../../../model/OrderItem";

import {sortOrdersByDateDecrease} from "../../../../utils/helpers";

import PopUp from "../../../PopUp";

import IDsvg from "../../../../static/images/icon-id.svg";

import "./styles.scss";

interface CancelledSubscriptionsProps {
	handleOpenViewID: (type: string, imageView: string) => void;
	subscriptions: Order[];
	setIsLoading: (l: boolean) => void;
	notify: (l: string) => void;
	loadAllSubscriptions: () => void;
}

const CancelledSubscriptions = ({
	loadAllSubscriptions,
	handleOpenViewID,
	subscriptions,
	setIsLoading,
	notify
}: CancelledSubscriptionsProps) => {
	const [IDSearch, setIDSearch] = useState<string>("");
	const [cancelledSubscriptions, setCancelledSubscriptions] = useState<Order[]>([]);
	const [subscriptionSearch, setSubscriptionSearch] = useState<Order | null>(null);
	const [showCancelDialog, setShowCancelDialog] = useState<Order | null>(null);

	useEffect(() => {
		if (IDSearch) {
			const searchResult = subscriptions.find((s) => +s.id === +IDSearch);
			if (searchResult) {
				setSubscriptionSearch(searchResult);
			} else {
				setSubscriptionSearch(null);
			}
		} else {
			setSubscriptionSearch(null);
		}
	}, [IDSearch, subscriptions]);

	useEffect(() => {
		const cancelledSubscriptions: Order[] = subscriptions.filter(
			(subscription) => subscription.status === OrderStatus.cancelled
		);

		cancelledSubscriptions.sort(sortOrdersByDateDecrease);

		setCancelledSubscriptions(cancelledSubscriptions);
	}, [subscriptions]);

	const handleCancelSubscription = async (id: number) => {
		try {
			setIsLoading(true);
			await cancelSubscription(id);
			await loadAllSubscriptions();
			notify("Subscription was successfully cancelled");
		} catch (err) {
			setIsLoading(false);
			notify("Something went wrong, please try again");
		}
	};

	return (
		<div className="cancelled-orders-admin prefiles">
			<div className="search-section">
				<span className="search-by">Search subscription to cancel:</span>
				<input
					type="text"
					className="search-input"
					value={IDSearch}
					placeholder="Enter ID"
					onChange={(e) => setIDSearch(e.target.value)}
				/>
				{subscriptionSearch && (
					<div className="order-row">
						<div className="content">
							<div className="row1">
								<div className="id">{`#${subscriptionSearch.id}`}</div>
								<div className="date">
									{moment(subscriptionSearch.date).format("MMM D, YYYY, HH:mm")}
								</div>
								<div className="items">
									{subscriptionSearch.items.reduce((acc: string, item: OrderItem) => {
										return acc + (!acc ? "" : ", ") + item.productDosage;
									}, "")}
								</div>
								<div className="document-view-buttons">
									{subscriptionSearch.user?.insuranceDocument && (
										<div>
											<div
												className="view-id"
												onClick={() =>
													handleOpenViewID("Insurance", subscriptionSearch.user!.insuranceDocument!)
												}
											>
												<img src={IDsvg} alt="id" />
												<span>view insurance</span>
											</div>
										</div>
									)}
									{subscriptionSearch.user?.idDocument && (
										<div>
											<div
												className="view-id"
												onClick={() => handleOpenViewID("ID", subscriptionSearch.user!.idDocument!)}
											>
												<img src={IDsvg} alt="id" />
												<span>view ID</span>
											</div>
										</div>
									)}
								</div>
							</div>
							{subscriptionSearch.user && (
								<div className="row2">
									Name{" "}
									<div className="patient-name">
										{subscriptionSearch.user.firstName + " " + subscriptionSearch.user.lastName}
									</div>
									Birthday{" "}
									<div className="patient-birthday">
										{DateService.formatBirthday(subscriptionSearch.user.birthday)}
									</div>
									Phone: <div className="patient-phone">{subscriptionSearch.user.phone}</div>
									Email: <div className="patient-email">{subscriptionSearch.user.email}</div>
								</div>
							)}
							<div className="row3">
								<span className="order-status-text">{subscriptionSearch.status}</span>
							</div>
						</div>
						{subscriptionSearch.status === OrderStatus.active && (
							<div
								className="button cancel"
								onClick={() => setShowCancelDialog(subscriptionSearch)}
							>
								CANCEL
							</div>
						)}
					</div>
				)}
			</div>
			{cancelledSubscriptions.length === 0 ? (
				<div className="order-row">There are no cancelled subscriptions.</div>
			) : (
				cancelledSubscriptions.map((subscription) => {
					const {user} = subscription;

					return (
						<div key={subscription.id}>
							<div className="order-row">
								<div className="content">
									<div className="row1">
										<div className="id">{`#${subscription.id}`}</div>
										<div className="date">
											{moment(subscription.date).format("MMM D, YYYY, HH:mm")}
										</div>
										<div className="items">
											{subscription.items.reduce((acc: string, item: OrderItem) => {
												return acc + (!acc ? "" : ", ") + item.productDosage;
											}, "")}
										</div>
										<div className="document-view-buttons">
											{user?.insuranceDocument && (
												<div>
													<div
														className="view-id"
														onClick={() => handleOpenViewID("Insurance", user!.insuranceDocument!)}
													>
														<img src={IDsvg} alt="" />
														<span>view insurance</span>
													</div>
												</div>
											)}
											{user?.idDocument && (
												<div>
													<div
														className="view-id"
														onClick={() => handleOpenViewID("ID", user!.idDocument!)}
													>
														<img src={IDsvg} alt="id" />
														<span>view ID</span>
													</div>
												</div>
											)}
										</div>
									</div>
									{user && (
										<div className="row2">
											Name{" "}
											<div className="patient-name">{user.firstName + " " + user.lastName}</div>
											Birthday{" "}
											<div className="patient-birthday">
												{DateService.formatBirthday(user.birthday)}
											</div>
											Phone: <div className="patient-phone">{user.phone}</div>
											Email: <div className="patient-email">{user.email}</div>
										</div>
									)}
									<div className="row3">
										<span className="order-status-text">{subscription.status}</span>
									</div>
								</div>
							</div>
						</div>
					);
				})
			)}
			{showCancelDialog && (
				<PopUp>
					<div className="dialog-wrapper">
						<div className="dialog-content">
							<div className="description">
								{`Are you sure you want to cancell subscription #${showCancelDialog.id}`}
							</div>
							<div className="btns">
								<button
									className="yes-btn"
									onClick={() => handleCancelSubscription(showCancelDialog.id)}
								>
									YES
								</button>
								<button className="no-btn" onClick={() => setShowCancelDialog(null)}>
									NO
								</button>
							</div>
						</div>
					</div>
				</PopUp>
			)}
		</div>
	);
};

export default CancelledSubscriptions;
