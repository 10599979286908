import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Collapse from 'react-bootstrap/Collapse';
import Carousel from 'react-bootstrap/Carousel';
import { Title } from "react-meta-elements";
import stars from "../../static/images/stars.png";
import arrow_icon from "../../static/images/arrow-icon.png";
import insurance from "../../static/images/insurance.png";
import virtual from "../../static/images/free-virtual.png";
import canadian from "../../static/images/Canadian-produced-products-icon.png";
import handWave_small from "../../static/images/hand-waveing-small.png";
import handWave_large from "../../static/images/hand-waveing-large.png";
import hlPrevention from "../../static/images/full-hair5x.png";
import hlCrown from "../../static/images/thinning-at-the-crown5x.png";
import hlHairline from "../../static/images/hairline5x.png";
import hlAllover from "../../static/images/thinning-all-over5x.png";
import scalpInfection from "../../static/images/scalp-infections.png";
import stressIcon from "../../static/images/stress-icon.png";
import alocpia from "../../static/images/alopecia.png";
import chemo from "../../static/images/chemo.png";
import diet1 from "../../static/images/diet.png";
import genetics from "../../static/images/genetics.png";
import hormonalChange from "../../static/images/hormonal-changes.png";
import maleBald from "../../static/images/male-baldness.png";
import medicalCondition from "../../static/images/medical-conditions.png";
import medication from "../../static/images/medication.png";
import choose_product from "../../static/images/choose-product.jpg";
import connect_doctor from "../../static/images/connect-doctor.jpg";
import take_control from "../../static/images/take-control.jpg";
import licenced_doctor from "../../static/images/licenced_doctor.png";
import refill from "../../static/images/refill.png";
import standard_shipping from "../../static/images/standard_shipping.png";
import whiteDoctor from "../../static/images/white-doctor-circle.png";
import close from "../../static/images/close.png";
import fhl from "../../static/images/hair-loss-blog.jpg";
// import "../../custom-styles.scss";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
// import {
// 	ServedProductCategories,
// 	ProductCategoryName,
// 	ProductCategoryShortName,
// 	ProductCategoryDescription,
// 	ProductCategory,
// 	ProductCategoryNotes,
// 	ProductCategoryLearnMoreLink
// } from "../../model/ProductCategory";
import { StateType } from "../../redux/types";
import * as cartActions from "../../redux/cart/handlers";
import * as appActions from "../../redux/app/handlers";
import Product from "../../model/Product";
import { getProductImage, formatProductNameToURL } from "../../utils/helpers";
import { Modal } from "react-bootstrap";

const ProductHL = () => {
	const [open, setOpen] = useState(false);
	const [open1, setOpen1] = useState(false);
	const [open2, setOpen2] = useState(false);
	const [open3, setOpen3] = useState(false);
	const [open4, setOpen4] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [selectedDose, setSelectedDose] = useState<number>(0);

	const handleAddProductToCart = (productItem: Product, selectedDose: any) => () => {
		const y = window.scrollY;
		const { pathname } = location;

		dispatch(appActions.setCloseSidebarDirection({ pathname, y }));

		const quantity = productItem.quantities.find((q) => q.id == selectedDose);
		const index = productItem.quantities.findIndex((q)=> q.id == selectedDose);
		const product = { ...productItem };
        product.qty = {...quantity!};        
        product.selectedQuantityIndex = index!;

		// product.qty = { ...quantity! };

		navigate("/cart");

		dispatch(cartActions.addProductRequest({ product }));
	};

	const handleSelectChange = (event: any) => {
		setSelectedDose(event.target.value);
	}

	const { products } = useSelector(({ product, app }: StateType) => ({
		products: product.productItems,
		// shopScrollTarget: app.shopScrollTarget
	}));
	console.log(products)


	const [show1, setShow1] = useState(false);
	const [show2, setShow2] = useState(false);


	const handleClose1 = () => setShow1(false);
	const handleClose2 = () => setShow2(false);


	const [ingredient, setIngredient] = useState<string>("");
	const handleShow1 = (text: string) => {
		setShow1(true)
		setIngredient(text);
	}
	const [safeInfo, setSafeInfo] = useState<string>("");
	const handleShow2 = (text: string) => {
		setShow2(true)
		setSafeInfo(text);
	}

	// Getting utm source and medium from query params
	let search = window.location.search;
	if(search!=""){
		window.sessionStorage.setItem("utm_source", search.split('?')[1]);
		console.log(search.split('?')[1]);
	}


	return (
		<>
			<Title>Hair loss in men  |Winston</Title>
			<div id="factPagesHL">
				<div className="page-101" >

					{/* modal sections starts */}

					{/* modal sections ends here */}

					<div className="section">
						<div className="container-12 w-container">
							<h1 className="heading">Hello to Full and Healthy Hair<br /></h1>
							<div className="w-row">
								<div className="column-109 w-col w-col-8">
									<p className="paragraph">Hair loss treatment tailored specifically for you.<br />Say goodbye to the baseball hats and regain your confidence with full and healthy hair.<br /></p>
								</div>
								<div className="w-col w-col-4"></div>
							</div>
							<div className="w-row">
								<div className="column-120 w-col w-col-8">
									<div className="columns w-row">
										<div className="column w-col w-col-4"><img src={insurance} width="40" alt="insurance" className="image-21" />
											<h1 className="heading-3"><strong>Insurance<br />accepted</strong><br /></h1>
										</div>
										<div className="column w-col w-col-4"><img src={virtual} width="40" alt="virtual" className="image-24" />
											<h1 className="heading-3"><strong>Free virtual <br />consultation</strong><br /></h1>
										</div>
										<div className="column w-col w-col-4"><img src={canadian} width="40" alt="canadian" className="image-24" />
											<h1 className="heading-3"><strong>Canadian produced <br />medications</strong><br /></h1>
										</div>
									</div>
								</div>
								<div className="w-col w-col-4"></div>
							</div>
						</div>
					</div>
					<div className="section-11">
						<div className="container-11 w-container">
							<h1 className="heading-5">About Our Hair Loss Medications<br /></h1>
							<p className="paragraph-14">Hair loss is caused by many factors such as Alopecia areata, male pattern baldness, genetics, medication, and scaffold infection, among many others. Our licensed doctors prescribe hair loss medications depending on the type of hair loss you are experiencing, taking into account the possible cause for your hair loss. Prescribed medications include Minoxidil 5% and Finasteride, both of which work to help grow back your hair.<br /></p>
						</div>
					</div>
					<div className="section-2 canada">
						<div className="container-12 w-container">
							<div className="w-row">
								<div className="w-col w-col-9">
									<h1 className="heading-99">Our treatments are proudly sourced from trusted Canadian manufacturers.<br /></h1>
									<h1 className="heading-8">Pharma Science<br /></h1>
								</div>
								<div className="column-16 w-col w-col-3"><img className="image-25" src={handWave_small} sizes="100vw"
						srcSet={`${handWave_large} 1024w, ${handWave_large} 767w, ${handWave_small} 479w`} width="86" alt="waveing" /></div>
							</div>
						</div>
					</div>
					{
						products.filter(product => product.problemCategory.includes('HL')).map(productitems =>
						(
							<section id="1st-section" className="section-1" key={productitems.id}>
								<div className="container-12 w-container">
									<div className="w-row">
										<div className="column-109 w-col w-col-6"><img alt={'product-img'} src={getProductImage(productitems.name)} width="394" className="image-3" /></div>
										<div className="column-109 w-col w-col-6">
											<h1 className="heading-14">{productitems.name}<br /></h1>
											<h1 className="heading-11"><strong></strong>{productitems.subName}<br /></h1>
											<div className="paragraph-14" dangerouslySetInnerHTML={{ __html: productitems.landingPageText }} />
											<div className="w-row">
												<div className="w-col w-col-6">
													<span onClick={() => handleShow1(productitems.ingredient)} className="button-14 w-button"><strong className="bold-text">Ingredients</strong></span>
													<Modal show={show1} onHide={handleClose1} animation={false} id="factPagesHL">
														<div className="div-block-55">
															<div className="div-block-56">
																<div className="div-block-45" onClick={handleClose1}><img src={close} width="25" alt="close" /></div>
																<h1 className="heading-77">Ingredients</h1>
																<div dangerouslySetInnerHTML={{ __html: ingredient }}></div>
															</div>
														</div>
													</Modal>
												</div>
												<div className="w-col w-col-6">
													<span onClick={() => handleShow2(productitems.safetyInfo)} className="button-8 w-button"><strong>Safety Info</strong></span>
													<Modal show={show2} onHide={handleClose2} animation={false} id="factPagesHL">
														<div className="div-block-43">
															<div className="div-block-44">
																<div className="div-block-45" onClick={handleClose2}><img src={close} width="25" alt="close" /></div>
																<h1 className="heading-60"><strong>Safety information details</strong><br /></h1>
																<div dangerouslySetInnerHTML={{ __html: safeInfo }}></div>
															</div>
														</div>
													</Modal>
												</div>
											</div>
											<Link to={`/${formatProductNameToURL(productitems.name)}`} className="button-15 w-button" >
												<span className="">Learn more</span>
											</Link>
											<div className="div-block-19">
												<h1 className="heading-12">Selects dose and quantity:<br /></h1>
												<div className="form-block-2 w-form">
													<form id="email-form-2" name="email-form-2" data-name="Email Form 2">
														<select id="field-2" name="field-2" className="select-field-2 w-select"
															value={selectedDose > 0 ? selectedDose : productitems.quantities[0].id}
															onChange={(event) => handleSelectChange(event)}>
															{productitems.quantities.map((index) => (
																<option key={index.id} value={index.id}>{index.quantity}</option>
															))}
														</select>
													</form>
													<div className="w-form-done">
														<div>Thank you! Your submission has been received!</div>
													</div>
													<div className="w-form-fail">
														<div>Oops! Something went wrong while submitting the form.</div>
													</div>
												</div>
												<div className="text-block-7">Auto-refills that can be paused, skipped or cancelled anytime.</div>
												<span onClick={handleAddProductToCart(productitems, selectedDose > 0 ? selectedDose : productitems.quantities[0].id)} className="button-9 w-button"><strong>Add to cart</strong></span>
											</div>
										</div>
									</div>
								</div>
							</section>
						)
						)
					}

					<div className="section-12">
						<div className="container-17 w-container">
							<h1 className="heading-99">Types of Hair Loss That We Can Help</h1>
							
							<div className="columns-68 w-row">
								<div className="column-116 w-col w-col-3"><img src={hlPrevention} width="76" alt="prevention" />
									<h1 className="heading-100">Loss Prevention<br /></h1>
									<div>If you still have a head full of hair, now is the right time to make sure it remains that way. Especially if male pattern baldness runs in the family. With the right treatment, you can prevent early balding and keep your hair for much longer.<br /></div>
								</div>
								<div className="column-117 w-col w-col-3"><img src={hlCrown} width="76" alt="crown" />
									<h1 className="heading-101">Thinning of the Crown<br /></h1>
									<div>One of the most popular bald spots in men who have male pattern baldness is on the top of the head. If you're currently experiencing this form of Androgenic Alopecia, our Minoxidil 5% treatment works to prevent further hair loss by stimulating and extending the hair growth phase.<br /></div>
								</div>
								<div className="column-118 w-col w-col-3"><img src={hlHairline} width="76" alt="hairline" />
									<h1 className="heading-102">Receding Hairline<br /></h1>
									<div>Are your hairlines starting to push back just a little bit farther? It may be due to genetics and aging. It is caused when the anagen phase of the hair follicles gets shorter. Our combo pack of Minoxidil 5% foam and Finasteride works great in getting a full head of hair back.<br /></div>
								</div>
								<div className="column-119 w-col w-col-3"><img src={hlAllover} width="76" alt="allover" />
									<h1 className="heading-103">Thinning All Over<br /></h1>
									<div>Have you had problems running your hands through your hair recently due to its reduced volume? Hair thinning all over is quite common as men age. With the right treatment tailored to your hair needs, you can reverse the process and regain your full locks<br /></div>
								</div>
							</div>
						</div>
					</div>
					<div className="section-4">
						<div className="container-8 w-container">
							<h1 className="heading-75">Reasons for Hair Loss<br /></h1>
							<p className="paragraph-8">The most common risk factors include:<br /></p>
							<div className="columns-60 w-row">
								<div className="column w-col w-col-6"><img src={scalpInfection} width="40" alt="infection" />
									<h1 className="heading-96">Scalp Infections</h1>
								</div>
								<div className="column-100 w-col w-col-6"><img src={stressIcon} width="40" alt="stress" />
									<h1 className="heading-96">Stress</h1>
								</div>
							</div>
							<div className="columns-66 w-row">
								<div className="column-107 w-col w-col-6"><img src={alocpia} width="40" alt="alocpia" />
									<h1 className="heading-82">Alopecia areata</h1>
								</div>
								<div className="column-107 w-col w-col-6"><img src={chemo} width="40" alt="chemo" />
									<h1 className="heading-82">Radiation or chemotherapy treatment</h1>
								</div>
							</div>
							<div className="columns-66 w-row">
								<div className="column-103 w-col w-col-6"><img src={diet1} width="40" alt="diet" />
									<h1 className="heading-98">Diet</h1>
								</div>
								<div className="column-107 w-col w-col-6"><img src={genetics} width="40" alt="genetics" />
									<h1 className="heading-83">Genetics</h1>
								</div>
							</div>
							<div className="columns-66 w-row">
								<div className="column-107 w-col w-col-6"><img src={hormonalChange} width="40" alt="hormonal" />
									<h1 className="heading-82">Hormonal changes</h1>
								</div>
								<div className="column-107 w-col w-col-6"><img src={maleBald} width="40" alt="male-baldness" />
									<h1 className="heading-82">Male pattern baldness<br /></h1>
								</div>
							</div>
							<div className="columns-66 w-row">
								<div className="column-107 w-col w-col-6"><img src={medicalCondition} width="40" alt="anemia" />
									<h1 className="heading-82">Chronic medical conditions such as thyroid disease or anemia</h1>
								</div>
								<div className="column-107 w-col w-col-6"><img src={medication} width="40" alt="medications" />
									<h1 className="heading-82">Medications</h1>
								</div>
							</div>
						</div>
					</div>
					<div className="section-5">
						<div className="container-3 w-container">
							<h1 className="heading-75">How Winston Works<br /></h1>
							<p className="paragraph-13">Our goal is to make healthcare accessible and medications affordable in 3 easy steps.<br /></p>
							<div className="w-row">
								<div className="column-51 w-col w-col-4">
									<div className="div-block-59"><img src={choose_product} alt="choose-product" />
										<h1 className="heading-95">1. Choose a product<br /></h1>
										<p className="paragraph-12">Pick from our collection of safe and effective medication based on your symptoms and health needs.</p>
									</div>
								</div>
								<div className="column-51 w-col w-col-4">
									<div className="div-block-25"><img src={connect_doctor} alt="connect-doctor" className="image-16" />
										<h1 className="heading-55">2. Connect with a doctor<br /></h1>
										<p className="paragraph-11">A licensed doctor will speak to you about your medical history and symptoms. If appropriate, the doctor will prescribe your medication.<br /></p>
									</div>
								</div>
								<div className="column-109 w-col w-col-4">
									<div className="div-block-26"><img src={take_control} alt="take-control" />
										<h1 className="heading-55">3. Take control<br /></h1>
										<p className="paragraph-9">You will discreetly receive your prescription right to your desired address. Follow the dosing instructions and take back control.<br /></p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="section-6">
						<div className="container-4 w-container">
							<h1 className="heading-6">With Winston’s Auto-Refill You Get:<br /></h1>
							<h1 className="heading-45 mob-hide">Let Winston be your wingman and he’ll always deliver!</h1>
							<div className="columns-25 w-row">
								<div className="column-48 w-col w-col-4">
									<div className="div-block-38"><img src={licenced_doctor} alt="licenced-doctor" className="image-18" />
										<p className="paragraph-6">Custom treatment plan by licensed doctors</p>
									</div>
								</div>
								<div className="column-48 w-col w-col-4">
									<div className="div-block-38"><img src={refill} alt="refill" />
										<h1 className="heading-44">Skip, pause or cancel your refills at any time</h1>
									</div>
								</div>
								<div className="column-49 w-col w-col-4">
									<div className="div-block-40"><img src={standard_shipping} alt="shipping" />
										<h1 className="heading-44">Free standard shipping right to your door</h1>
									</div>
								</div>
							</div>
							<h1 className="heading-45 mob-show">Let Winston be your wingman and he’ll always deliver!</h1>
						</div>
					</div>

					<div className="slider w-slider">
						<Carousel>
							<Carousel.Item interval={2000}>

								<Carousel.Caption>
									<div className="columns-65 w-row">
										<div className="w-col w-col-2"></div>
										<div className="column-14 w-col w-col-8">
											<h1 className="heading-26">Amazing concept and super easy process. End to end!<br /></h1><img src={stars} alt="stars" className="image-14" />
											<div className="text-block">- Winston member, on Google, November 18, 2020</div>
										</div>
										<div className="w-col w-col-2"></div>
									</div>
								</Carousel.Caption>
							</Carousel.Item>

							<Carousel.Item interval={4000}>
								<Carousel.Caption>
									<div className="w-row">
										<div className="w-col w-col-2"></div>
										<div className="column-16 w-col w-col-8">
											<h1 className="heading-26">Dr. very friendly, answered all my concerns.<br /></h1><img src={stars} alt="stars" />
											<div className="text-block">- Winston member, In Consultation, November 18, 2020</div>
										</div>
										<div className="w-col w-col-2"></div>
									</div>
								</Carousel.Caption>
							</Carousel.Item>

							<Carousel.Item interval={6000}>
								<Carousel.Caption>
									<div className="w-row">
										<div className="w-col w-col-2"></div>
										<div className="column-16 w-col w-col-8">
											<h1 className="heading-26">Works well and is more classy and discreet compared to the blue pill.<br /></h1><img src={stars} alt="stars" />
											<div className="text-block">- Winston member, on Google, November 18, 2020</div>
										</div>
										<div className="w-col w-col-2"></div>
									</div>
								</Carousel.Caption>
							</Carousel.Item>

							<Carousel.Item interval={8000}>
								<Carousel.Caption>
									<div className="columns-2 w-row">
										<div className="w-col w-col-2"></div>
										<div className="column-16 w-col w-col-8">
											<h1 className="heading-26">I’m so happy with the results, the doctor and the convenience!<br /></h1><img src={stars} alt="stars" />
											<div className="text-block">- Winston member, On Google, November 18, 2020</div>
										</div>
										<div className="w-col w-col-2"></div>
									</div>
								</Carousel.Caption>
							</Carousel.Item>

						</Carousel>
					</div>
					<div className="section-7">
						<div className="container-5 w-container">
							<h1 className="heading-39">Common Questions about Hair Loss<br /></h1>

							<div className="div-block-46 imgBlock" aria-controls="example-collapse-text" onClick={() => setOpen(!open)} aria-expanded={open}>
								<h1 className="heading-34"><strong>What are the most common causes for hair loss?</strong><br /></h1>
								<img className="imgRotate" src={arrow_icon} width="35" alt="arrow-icon" />
							</div>

							<Collapse in={open}>
								<div id="example-collapse-text">
									<p className="paragraph-2">The two most common causes for hair loss are male pattern baldness and a medical condition known as Alopecia areata. To learn about additional causes and risk factors for hair loss, visit Hair Loss 101 in the Learn section <br /></p>
								</div>
							</Collapse>

							<div className="div-block-46 imgBlock" aria-controls="q2" onClick={() => setOpen1(!open1)} aria-expanded={open1}>
								<h1 className="heading-34"><strong>How will the doctors be able to determine the best treatment option for me?</strong><br /></h1><img className="imgRotate" src={arrow_icon} width="35" alt="arrow-icon" />
							</div>

							<Collapse in={open1}>
								<div id="q2">
									<p className="paragraph-2">Prior to your consultation, you will complete a brief questionnaire, and attach relevant pictures to assist our doctors with making their diagnosis.<br /></p>
								</div>
							</Collapse>

							<div className="div-block-46 imgBlock" aria-controls="q3" onClick={() => setOpen2(!open2)} aria-expanded={open2}>
								<h1 className="heading-34"><strong>What treatment options do you currently provide for hair loss?</strong><br /></h1><img className="imgRotate" src={arrow_icon} width="35" alt="arrow-icon" />
							</div>

							<Collapse in={open2}>
								<div id="q3">
									<p className="paragraph-2">Currently, we offer two treatments, Finasteride (the active ingredient in Propecia) as well as Minoxidil (the active ingredient in Rogaine).<br /></p>
								</div>
							</Collapse>

							<div className="div-block-46 imgBlock" aria-controls="q4" onClick={() => setOpen3(!open3)} aria-expanded={open3}>
								<h1 className="heading-34"><strong>Are the current treatment options a cure for hair loss?</strong><br /></h1><img className="imgRotate" src={arrow_icon} width="35" alt="arrow-icon" />
							</div>

							<Collapse in={open3}>
								<div id="q4">
									<p className="paragraph-2">Finasteride and Minoxidil are treatment options to help prevent further hair loss and improve hair growth. However, they are not a cure for hair loss. Discontinuing either or both medications will lead to a return to your normal rate of hair loss.<br /></p>
								</div>
							</Collapse>

							<div className="div-block-46 imgBlock" aria-controls="q5" onClick={() => setOpen4(!open4)} aria-expanded={open4}>
								<h1 className="heading-34"><strong>Do I require a prescription for these treatments?</strong><br /></h1><img className="imgRotate" src={arrow_icon} width="35" alt="arrow-icon" />
							</div>

							<Collapse in={open4}>
								<div id="q5">
									<p className="paragraph-2">A prescription is required for Finasteride. Our doctors can provide a prescription to one of our accredited pharmacy partners to allow for quick and discreet delivery.<br /></p>
								</div>
							</Collapse>

							<Link to="/faq" className="button-17 w-button"><strong>See more FAQs</strong></Link>
						</div>
					</div>
					<div className="section-2">
						<div className="container-15 w-container">
							<div className="columns-5 w-row">
								<div className="column-114 w-col w-col-9">
									<h1 className="heading-46">Not Sure What Product You Need?<br /></h1>
									<p className="paragraph-14">Book a free consultation call with a Canadian doctor to advise you!<br /></p>
									<Link to="/contact-us"
										// target="_blank"
										// rel="noopener noreferrer"
										className="button-18 w-button"><strong>Let us help you</strong><br /></Link>
								</div>
								<div className="column-113 w-col w-col-3"><img src={whiteDoctor} width="212" alt="whitedoctor" className="image-20" /></div>
							</div>
						</div>
					</div>
					<div className="section-10">
						<div className="container-8 w-container">
							<h1 className="heading-52">Blog Articles<br /></h1>
							<div className="columns-67 w-row">
								<div className="column-71 w-col w-col-6">
									<div className="div-block-60 blogBackground" ></div>
								</div>
								<div className="column-109 w-col w-col-6">
									<div className="div-block-17">
										<h1 className="heading-48">Hair Loss 101<br /></h1>
										<p className="paragraph-4">Hair loss is more common than you think. 85% of men will experience hair loss at some point in their lives. Together, male pattern baldness and Alopecia are the two most common hair loss conditions that men experience worldwide.<br /></p>
										<div className="div-block-23">
											<a href="https://learn.askwinston.ca/hair-loss-101/" className="button-13 w-button">
												<strong>Learn more</strong><br />
											</a>
											<div className="text-block-6">5 min read</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)

}
export default ProductHL;
