import React, {useState, useEffect, useCallback, Ref, forwardRef} from "react";
import ReactDatePicker from "react-datepicker";
import moment from "moment-timezone";

import DateService from "../../../../service/Date.service";

import OrderStatus from "../../../../model/OrderStatus";
import Order from "../../../../model/Order";
import OrderItem from "../../../../model/OrderItem";

import {sortOrdersByDateDecrease} from "../../../../utils/helpers";

import IDsvg from "../../../../static/images/icon-id.svg";

import "./styles.scss";

interface PastSubscriptionsProps {
	handleOpenViewID: (type: string, imageView: string) => void;
	subscriptions: Order[];
}

const PastSubscriptions = ({handleOpenViewID, subscriptions}: PastSubscriptionsProps) => {
	const [nameSearch, setNameSearch] = useState<string>("");
	const [pastOrders, setPastOrders] = useState<Order[]>([]);
	const [startDate, setStartDate] = useState<Date | null>(null);

	const CustomDateInput = forwardRef(({value, onClick}: any, ref: Ref<HTMLButtonElement>) => (
		<button className="custom-date-input" onClick={onClick} ref={ref}>
			{value || "Select month"}
		</button>
	));

	const searchName = useCallback(
		(order: Order) => {
			const lastName = order.user?.lastName;
			const firstName = order.user?.firstName;

			if (lastName && firstName) {
				const name = (firstName + lastName).toLowerCase();

				return name.search(nameSearch.toLowerCase()) + 1;
			} else {
				return false;
			}
		},
		[nameSearch]
	);

	const searchDate = useCallback(
		(order: Order) => {
			if (startDate) {
				const formattedOrderDate = moment(order.date).format("YYYY MM");
				const formattedFilterDate = moment(startDate).format("YYYY MM");

				return formattedFilterDate === formattedOrderDate;
			} else {
				return true;
			}
		},
		[startDate]
	);

	useEffect(() => {
		const pastOrders: Order[] = [];
		const pastStatuses = [OrderStatus.delivering, OrderStatus.rejected];

		subscriptions.forEach((subscription) => {
			subscription.orders.forEach((order) => {
				if (pastStatuses.includes(order.status) && searchName(subscription) && searchDate(order)) {
					pastOrders.push(order);
				}
			});
		});

		pastOrders.sort(sortOrdersByDateDecrease);

		setPastOrders(pastOrders);
	}, [subscriptions, nameSearch, startDate, searchDate, searchName]);

	return (
		<div className="past-orders-admin prefiles">
			<div className="search-section">
				<span className="search-by">Search by name:</span>
				<input
					type="text"
					className="search-input"
					value={nameSearch}
					placeholder="Enter name"
					onChange={(e) => setNameSearch(e.target.value)}
				/>
				<span className="search-by">Search by month:</span>
				<ReactDatePicker
					selected={startDate}
					onChange={(date) => {
						if (!Array.isArray(date)) {
							setStartDate(date);
						}
					}}
					dateFormat="MM/yyyy"
					showMonthYearPicker
					customInput={<CustomDateInput />}
				/>
				<button
					className="reset-btn"
					onClick={() => {
						setNameSearch("");
						setStartDate(null);
					}}
				>
					Clear Filters
				</button>
			</div>
			{pastOrders.length === 0 ? (
				<div className="order-row">There are no past orders.</div>
			) : (
				pastOrders.map((order) => {
					const user = subscriptions.find((subscription) => +subscription.id === +order.number)
						?.user;

					return (
						<div key={order.id}>
							<div className="order-row">
								<div className="content">
									<div className="row1">
										<div className="id">{`#${order.number}-${order.subNumber}`}</div>
										<div className="date">{moment(order.date).format("MMM D, YYYY, HH:mm")}</div>
										<div className="items">
											{order.items.reduce((acc: string, item: OrderItem) => {
												return acc + (!acc ? "" : ", ") + item.productDosage;
											}, "")}
										</div>
										<div className="document-view-buttons">
											{user?.insuranceDocument && (
												<div>
													<div
														className="view-id"
														onClick={() => handleOpenViewID("Insurance", user!.insuranceDocument!)}
													>
														<img src={IDsvg} alt="id" />
														<span>view insurance</span>
													</div>
												</div>
											)}
											{user?.idDocument && (
												<div>
													<div
														className="view-id"
														onClick={() => handleOpenViewID("ID", user!.idDocument!)}
													>
														<img src={IDsvg} alt="id" />
														<span>view ID</span>
													</div>
												</div>
											)}
										</div>
									</div>
									{user && (
										<div className="row2">
											Name{" "}
											<div className="patient-name">{user.firstName + " " + user.lastName}</div>
											Birthday{" "}
											<div className="patient-birthday">
												{DateService.formatBirthday(user.birthday)}
											</div>
											Phone: <div className="patient-phone">{user.phone}</div>
											Email: <div className="patient-email">{user.email}</div>
										</div>
									)}
									<div className="row3">
										<span className="order-status-text">{order.status}</span>
										{order.status === OrderStatus.delivering && !!order.shippingTrackingNumber && (
											<span>{`Tracking Number: ${order.shippingTrackingNumber}`}</span>
										)}
										{order.status === OrderStatus.delivering && !!order.courier && (
											<span className="courier">{`Courier: ${order.courier}`}</span>
										)}
									</div>
								</div>
							</div>
						</div>
					);
				})
			)}
		</div>
	);
};

export default PastSubscriptions;
