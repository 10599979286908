import React, {
	useState,
	ChangeEvent,
	useRef,
	KeyboardEvent,
	forwardRef,
	useImperativeHandle,
	Ref, useEffect
} from "react";
import { useDispatch, useSelector } from "react-redux";
import BillingCardsComponent from "../BillingCards";
import { StateType } from "../../redux/types";
import * as patientActions from "../../redux/patient/handlers";
import { Province, ServedProvinceList, ProvinceName } from "../../model/Province";
import ValidationService from "../../service/Validation.service";
import MessageConst from "../../const/Message.const";
import LoaderComponent from "../Loader";
import MonerisInputCheckout, { MonerisInputRefObject } from "../MonerisInputCheckout";
import SelectedProduct from "../../model/SelectedProduct";
// import  { ValueType } from "react-select";
// import { actions as cartActions } from "../../redux/cart";
// import ProductQuantity from "../../model/ProductQuantity";
import Collapse from 'react-bootstrap/Collapse';
import { calculateCart, calculateDiscount, formatCartItemPrice, formatMoney, getProductImage } from "../../utils/helpers";

import lock from "../../static/images/lock.png";
import visa from "../../static/images/card_visa.png";
import master from "../../static/images/card_mastercard.png";
import amex from "../../static/images/card_amex.png";
import cardDiscover from "../../static/images/card_discover.png";
import unionpay from "../../static/images/card_unionpay.png";
import "./styles.scss";
import { useMedia } from "../../utils/hooks/use-media";
import BillingCard from "../../model/BillingCard";


interface AddBillingCardProps {
	isProfileMode: boolean;
	isSelectNewCard: boolean;
	onAddedCallback?: () => void;
	mode: "profile" | "checkout" | "checkout-new";
	billingCards: number;
	selectedBillingCard: BillingCard | null;
	setAddCardMode: () => void;
	handleNext: () => void;
	handleEditMode:()=>void;
}

export interface AddBillingCardRefObject {
	submit: () => Promise<void>;
}
type QuantityOption = { label: string; value: number };

const AddBillingCard = forwardRef(
	(props: AddBillingCardProps, ref: Ref<AddBillingCardRefObject>) => {
		const dispatch = useDispatch();
		const { isLoading, shippingAddress, selectedProducts, promoCode,profile, patientValidationMessage } = useSelector(({ patient, auth, cart }: StateType) => ({
			isLoading: patient.addingBillingCard,
			selectedProducts: cart.selectedProducts,
			shippingAddress: auth.profile?.shippingAddresses.find((adr) => adr.isPrimary),
			promoCode: patient.selectedPromoCode,
			profile: auth.profile,
			patientValidationMessage: patient.validationMessage
		}));
		useEffect(()=> {
			setValidationMessage(patientValidationMessage);
		}, [patientValidationMessage])
		// const [addValidationMessage, setAddValidationMessage] = useState<string>("");
		const monerisInputRef = useRef<MonerisInputRefObject>(null);
		const line1Input = useRef<HTMLInputElement>(null);
		const line2Input = useRef<HTMLInputElement>(null);
		const cityInput = useRef<HTMLInputElement>(null);
		const addressPostalCodeInput = useRef<HTMLInputElement>(null);
		const addressProvinceSelect = useRef<HTMLSelectElement>(null);
		// const addressCityInput = useRef<HTMLInputElement>(null);
		const isMobile = useMedia(["(max-width:531px)"], [true], false);

		const [isAddrFromShipping, setIsAddrFromShipping] = useState<boolean>(true);
		const [validationMessage, setValidationMessage] = useState<string>("");
		const [cardValidationMessage, setCardValidationMessage] = useState<string>("");
		const [addressLine1, setAddressLine1] = useState<string>("");
		const [addressLine2, setAddressLine2] = useState<string>("");
		const [addressCity, setAddressCity] = useState<string>("");
		const [addressProvince, setAddressProvince] = useState<Province | undefined>(profile?.province||undefined);
		const [addressPostalCode, setAddressPostalCode] = useState<string>("");
		const [addressCountry] = useState<string>("Canada");
		const [checkingCard, setCheckingCard] = useState<boolean>(false);

		let isExpressDelivery = sessionStorage.getItem("isExpressDelivery") === "true" ? true : false;

		useImperativeHandle(ref, () => ({ submit }));
		// let total = 0;
		// let discountMultiplier = 1.0;
		// let discountAddition = 0.0;
		const handleKeyPress = (
			event: KeyboardEvent<HTMLInputElement> | KeyboardEvent<HTMLSelectElement>
		) => {
			if (event.key === "Enter") {
				submit();
			}
		};
		useEffect(() => {
			if (isAddrFromShipping && shippingAddress) {
				const {
					addressCity: city,
					addressLine1: line1,
					addressLine2: line2,
					addressPostalCode: postalCode,
					addressProvince: province
				} = shippingAddress;
				if (cityInput.current) {
					setAddressCity(city);
					cityInput.current.value = city;
				}
				if (line1Input.current) {
					setAddressLine1(line1);
					line1Input.current.value = line1;
				}
				if (line2Input.current) {
					setAddressLine2(line2);
					line2Input.current.value = line2;
				}
				if (addressPostalCodeInput.current) {
					setAddressPostalCode(postalCode);
					addressPostalCodeInput.current.value = postalCode;
				}
				if (addressProvinceSelect.current) {
					setAddressProvince(province as Province);
					addressProvinceSelect.current.value = province as Province;
				}
			} else {
				clearCard();
			}
		}, [isAddrFromShipping, shippingAddress]);
		// const handleProductQuantityChange = (
		// 	cartItem: SelectedProduct,
		// 	selected: ValueType<QuantityOption>
		// ) => {
		// 	const quantity = cartItem.product.quantities.find(
		// 		(q) => q.id === (selected! as QuantityOption).value
		// 	);
		// 	const newProduct = { ...cartItem };

		// 	newProduct.quantity = { ...quantity! };

		// 	dispatch(cartActions.updateProductRequest(newProduct));
		// };

		const clearCard = () => {
			setValidationMessage("");
			setCardValidationMessage("");
			setAddressLine1("");
			setAddressLine2("");
			setAddressCity("");
			setAddressProvince(profile?.province||undefined);
			setAddressPostalCode("");
		};

	

		const submit = async () => {	
			if (!ValidationService.validateRequired(addressLine1)) {
				if (line1Input.current) {
					line1Input.current.focus();
				}
				setValidationMessage(MessageConst.validation.address.streetError);
				return;
			}

			if (!ValidationService.validateRequired(addressCity)) {
				if (cityInput.current) {
					cityInput.current.focus();
				}
				setValidationMessage(MessageConst.validation.address.cityError);
				return;
			}

			if (!addressProvince) {
				if (addressProvinceSelect.current) {
					addressProvinceSelect.current.focus();
				}
				setValidationMessage(MessageConst.validation.provinceError);
				return;
			}

			if (!ValidationService.validateRequired(addressPostalCode)) {
				if (addressPostalCodeInput.current) {
					addressPostalCodeInput.current.focus();
				}
				setValidationMessage(MessageConst.validation.address.postalCodeError);
				return;
			}

			if (!ValidationService.validatePostalCode(addressPostalCode)) {
				if (addressPostalCodeInput.current) {
					addressPostalCodeInput.current.focus();
				}
				setValidationMessage(MessageConst.validation.address.postalCodeError);
				return;
			}

			setCheckingCard(true);
            monerisInputRef.current?.doMonerisSubmit();		
		};

		const addCardCb = (token: string) => {
			const cb = () => {
				clearCard();
				if (props.onAddedCallback) {
					props.onAddedCallback();
				}
			};
			const callback = cb;
			dispatch(
				patientActions.addBillingCardRequest({
					mode: "profile",
					setPrimary: true,
					selectNewCard: props.isSelectNewCard,
					info: {
						token,
						addressLine1,
						addressLine2,
						addressCity,
						addressProvince,
						addressPostalCode,
						addressCountry
					}
				})
			);
			if(callback) {
				callback();
			}
		};

		const handleLine1Change = (e: ChangeEvent<HTMLInputElement>): void => {
			const addressLine1Val: string = ValidationService.addressConverter(e.target.value);
			setAddressLine1(addressLine1Val);
		};

		const handleLine2Change = (e: ChangeEvent<HTMLInputElement>): void => {
			const addressLine2Val: string = ValidationService.addressConverter(e.target.value);
			setAddressLine2(addressLine2Val);
		};

		const handleCityChange = (e: ChangeEvent<HTMLInputElement>): void => {
			const addressCityVal: string = ValidationService.addressConverter(e.target.value);
			setAddressCity(addressCityVal);
		};

		const handleProvinceChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
			const selectedProvince = event.currentTarget.value as Province;
			setAddressProvince(selectedProvince);	
			if (selectedProvince) {
				setValidationMessage("");
			}
		};

		const handlePostalCodeChange = (e: ChangeEvent<HTMLInputElement>): void => {
			const addressPostalCodeVal = ValidationService.postalCodeConverter(e.target.value);
			setAddressPostalCode(addressPostalCodeVal);
		};
	
		return (
			<div className="add-billing-card-component" id="billing">
				<div className="add-billing-card-sub-component">
				{(isLoading || checkingCard) && <LoaderComponent showShadow isFixed />}
					<div className="section-6">

						<div className="container-10 w-container">
							<div className="w-row">
								<div className="w-col w-col-1"></div>
								<div className="column-66 w-col w-col-6">
									<h1 className="heading-12">Payment Method</h1>
									<div className="text-block-58">You will not be charged until your treatment is shipped.</div>
								</div>
								<div className="column-65 w-col w-col-4">
									<div className="div-block-48"><img src={lock} width="43" alt="" className="image-21" />
										<div className="text-block-59">Veriefied &amp; Secured<br />SSL encryption<br /></div>
									</div>
									<div className="columns-34 w-row">
										<div className="column-47 w-col w-col-7"></div>
										<div className="column-46 w-col w-col-5"></div>
									</div>
								</div>
								<div className="w-col w-col-1"></div>
							</div>
						</div>
						<div className="container-11 w-container">
							<div className="w-row">
								<div className="w-col w-col-1"></div>
								<div className="column-41 w-col w-col-6">
									<h1 className="heading-15">Credit or Debit Card</h1>
									<div className="div-block-36">
										<img src={visa} width="45" alt="" className="image-14" />
										<img src={master} width="45" alt="" className="image-15" />
										<img src={amex} width="45" alt="" className="image-17" />
										<img src={cardDiscover} width="45" alt="" className="image-16" />
										<img src={unionpay} width="45" alt="" className="image-18" />
									</div>
									<div className="w-form payment-card">
										{/* add card column starts here */}
										<div className="form-block-8 w-form">
											<form id="email-form-2" name="email-form-2" data-name="Email Form 2">
												{!!props.billingCards && (
													<div className="billing-cards-wrapper">
														<BillingCardsComponent
															isSelectable={true}
															isDeletable={false}
															mode="checkout"
														/>
													</div>
												)}
												{!!props.billingCards ?
												<label className="radio-button-field-2 w-radio">
													<div className={(!!props.selectedBillingCard ? 'radio-button-2' : 'radio-button') + " w-form-formradioinput w-form-formradioinput--inputType-custom  w-radio-input"}></div>
													<input
														type="radio"
														data-name="Radio"
														id="radio-2"
														name="billing-card"
														checked={!props.selectedBillingCard}
														onChange={props.setAddCardMode}
														style={{ opacity: '0', position: "absolute", zIndex: -1 }} />
													<span className="radio-button-label-2 w-form-label">Add a new card</span>
												</label>: null}
											</form>
											
										</div>

										{!props.selectedBillingCard &&
											<>
												<div className="form-block-2 w-form">
													<MonerisInputCheckout
														ref={monerisInputRef}
														addCb={addCardCb}
														setCheckingCard={setCheckingCard}
														setValidationMessage={setCardValidationMessage}
														shrink={isMobile}
													/>

													{/* validation column */}
													<div className={cardValidationMessage?"w-form-fail-show":"w-form-fail"}>
														<div>{cardValidationMessage}</div>
													</div>
													<div className="is-set-to-primary-text">
							                            <span>PLEASE NOTE : </span>
								                         New card will be set as default primary card</div>
												</div>

												

												<h1 className="heading-14">Billing address</h1>
												<div className="form-block-6 w-form">
													<form id="email-form-2" name="email-form-2" data-name="Email Form 2">
														{shippingAddress && (
															<label className="radio-button-field w-radio">
																<div className={(isAddrFromShipping ? 'radio-button' : 'radio-button-2') + " w-form-formradioinput w-form-formradioinput--inputType-custom w-radio-input"}></div>
																<input type="radio" data-name="Radio" id="radio"
																	name="billing-shipping"
																	checked={isAddrFromShipping}
																	onChange={() => {
																		setIsAddrFromShipping((s) => !s);
																	}}
																	style={{ opacity: '0', position: "absolute", zIndex: -1 }} />
																<span className="radio-button-label w-form-label">Same as Shipping Address</span>
															</label>
														)}

														<label className="radio-button-field-2 w-radio">
															<div className={(!isAddrFromShipping ? 'radio-button' : 'radio-button-2') + " w-form-formradioinput w-form-formradioinput--inputType-custom  w-radio-input"}></div>
															<input
																type="radio"
																data-name="Radio"
																id="radio-2"
																name="billing-shipping"
																checked={!isAddrFromShipping}
																onChange={() => {
																	setIsAddrFromShipping((s) => !s);
																}}
																style={{ opacity: '0', position: "absolute", zIndex: -1 }}
															/>
															<span className="radio-button-label-2 w-form-label">Use a different address</span>
														</label>
													</form>
												</div>
												<Collapse in={!isAddrFromShipping}>
													<div className="form-block-7 w-form">
														<form id="email-form-3" name="email-form-3" data-name="Email Form 3">
															<select
																ref={addressProvinceSelect}
																onChange={handleProvinceChange}
																value={addressProvince}
																required
																className="select-field-4 w-select"
																onKeyDown={handleKeyPress}
															>
																{/* <option key={"undefined"} value={undefined} disabled={!!addressProvince}>
														Please select the province
													</option> */}
																{ServedProvinceList.map((item) => (
																	<option key={item} value={item}>
																		{ProvinceName[item]}
																	</option>
																))}
															</select>

															<input type="text" className="text-field-19 w-input" name="name-2" data-name="Name 2" placeholder="Street and number" id="name-2"
																ref={line1Input}
																value={addressLine1}
																onChange={handleLine1Change}
																onKeyDown={handleKeyPress}
															/>
															<div className="w-row">
																<div className="w-col w-col-6">
																	<div className="div-block-32">
																		<input
																			type="text"
																			className="text-field-20 w-input"
																			name="field-3"
																			data-name="Field 3"
																			placeholder="Postal code"
																			id="field-3"
																			ref={addressPostalCodeInput}
																			value={addressPostalCode}
																			onChange={handlePostalCodeChange}
																			onKeyDown={handleKeyPress} /></div>
																</div>
																<div className="w-col w-col-6">
																	<div className="div-block-33">
																		<input
																			type="text"
																			className="text-field-21 w-input"
																			name="field-4"
																			data-name="Field 4"
																			placeholder="Town/City"
																			id="field-4"
																			ref={cityInput}
																			value={addressCity}
																			onChange={handleCityChange}
																			onKeyDown={handleKeyPress}
																		/>
																	</div>
																</div>
															</div>
															<input
																type="text"
																className="text-field-26 w-input"
																name="field-8"
																data-name="Field 8"
																placeholder="Suit/Apt/Unit"
																id="field-8"
																ref={line2Input}
																value={addressLine2}
																onChange={handleLine2Change}
																onKeyDown={handleKeyPress}
															/>
														</form>
													</div>			
												</Collapse>
												<div className={validationMessage?"w-form-fail-show":"w-form-fail"}>
										       <div>{validationMessage}</div>
									               </div>

											</>
										}


									</div>

									{/* validation column */}								

									{/* <div className="w-form-done">
										<div>Thank you! Your submission has been received!</div>
									</div>
									<div className={validationMessage ? "w-form-fail-show" : 'w-form-fail'}>
										<div>Oops! Something went wrong while submitting the form.</div>
									</div> */}
									{props.isProfileMode ? (
										<button onClick={submit} className="button-20 w-button"><strong>Continue</strong></button>
									) : (
										<button onClick={props.handleNext} className="button-20 w-button"><strong>Continue</strong></button>
									)}


								</div>
								<div className="column-67 w-col w-col-4">
									<div className="div-block-11">
										<div className="columns-37 w-row">
											<div className="w-col w-col-6">
												<h1 className="heading-16">In your bag</h1>
											</div>
											<div className="w-col w-col-6">
												<div onClick={props.handleEditMode} className="text-block-23">Edit</div>
												<div className="text-block-23">
												{/* <Link to="/cart"   className="text-block-23">Edit</Link> */}
												</div>
												
											</div>
										</div>
									</div>

									{/* {selectedProducts.map((item: SelectedProduct)=>{
								<div className="w-col w-col-4">
									<img src={getProductImage(item.product.name)}  width="97" alt="" className="image-2" /></div>
								})} */}
									{selectedProducts.map((item: SelectedProduct, index) => {
										// total += item.quantity.price * item.count;
										// const options: { value: number; label: string }[] = item.product.quantities
										// 	.sort((a, b) => b.price - a.price)
										// 	.map((quantity: ProductQuantity) => ({
										// 		value: quantity.id,
										// 		label: quantity.quantity
										// 	}));

										// const selectedDose = item.product.quantities.find(
										// 	(quantity) => quantity.id === item.quantity.id
										// );
										return (
											<div className="div-block-12" key={item.product.id}>
												<div className="w-row">
													<div className="w-col w-col-4"><img src={getProductImage(item.product.name)} width="97" alt="" className="image-2" /></div>
													<div className="w-col w-col-8">
														<h1 className="heading-17">{item.product.name}</h1>
														<div className="text-block-22">
															{item.quantity.quantity}</div>
													</div>
												</div>
												<div className="columns-12 w-row">
													<div className="w-col w-col-7">
														<div className="text-block-60"><span className="text-span">Auto-refill </span>
															<span className="text-span-4"><br />(you can pause, skip refill or cancel anytime)</span>
														</div>
													</div>
													<div className="w-col w-col-5">
														<div className="text-block-21"><strong>{formatCartItemPrice(item.quantity, item.count)}</strong></div>
													</div>
												</div>

											</div>
										);
									})}

									<div className="div-block-13">
										<div className="columns-15 w-row">
											<div className="w-col w-col-7">
												<div>Online doctor consultation</div>
											</div>
											<div className="column-13 w-col w-col-5">
												<div>FREE</div>
											</div>
										</div>
										<div className="columns-13 w-row">
											<div className="w-col w-col-7">
												<div>{!isExpressDelivery ? 'Standard Shipping (2-3 days)' : 'Express Shipping'}</div>
											</div>
											<div className="column-14 w-col w-col-5">
												<div>{!isExpressDelivery ? 'FREE' : '$9.99'}</div>
											</div>
										</div>
										<div className="columns-14 w-row">
											<div className="w-col w-col-6">
												<div>Tax</div>
											</div>
											<div className="column-15 w-col w-col-6">
												<div>{formatMoney(0)}</div>
											</div>
										</div>
										{promoCode &&
											<div className="columns-14 w-row">
												<div className="w-col w-col-6">
													<div>Discount</div>
												</div>
												<div className="column-15 w-col w-col-6">
													<div>{formatMoney(calculateDiscount(selectedProducts, promoCode))}</div>
												</div>
											</div>
										}
										<div className="columns-16 w-row">
											<div className="w-col w-col-8">
												<div>Total due when prescribed and shipped</div>
											</div>
											<div className="column-16 w-col w-col-4">
												<div>{formatMoney(calculateCart(selectedProducts, promoCode, isExpressDelivery))}</div>
											</div>
										</div>
									</div>
									<div className="div-block-14">
										<div className="columns-17 w-row">
											<div className="w-col w-col-6">
												<h1 className="heading-18">Due Now</h1>
											</div>
											<div className="column-17 w-col w-col-6">
												<h1 className="heading-32">{formatMoney(0)}</h1>
											</div>
										</div>
									</div>
								</div>
								<div className="w-col w-col-1"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
);

export default AddBillingCard;
