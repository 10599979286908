import HttpService from "./Http.service";
import Profile from "../model/Profile";

export default class UserService {

	public static getProfile(): Promise<Response> {
		return HttpService.get("/user/profile");
	}

	public static getProfileById(id: number): Promise<Response> {
		return HttpService.get("/user/" + id + "/profile");
	}

	public static requestToChangePassword(email: string): Promise<Response> {
		return HttpService.post("/user/forgot-password/" + email, "");
	}

	public static requestToSetNewPassword(token: string, password: string): Promise<Response> {
		return HttpService.post("/user/reset-password/" + token, JSON.stringify({ text: password }));
	}
	public static validateToken(token: string): Promise<Response> {
		return HttpService.get("/token/" + token + "/validate");
	}
	
	public static fullName(user: Profile): string {
		return user.firstName + " " + user.lastName;
	}
}
