import React from "react";

export const Sildenafil2 = () => {
	return (
		<><h4><strong>Sildenafil Generic vs. Viagra</strong></h4>
		<br></br>
			<p>Viagra is a brand name medication invented by Pfizer, while Sildenafil (or Sildenafil Citrate) is the active ingredient in Viagra that’s responsible for its positive effects on treating erectile dysfunction.</p></>

	);
};

export const Tadalafil2 = () => {
	return (
		<><h4><strong>Cialis 5mg vs 20mg</strong></h4>
		<br></br>
			<p>Did you know that when it comes to choosing the right dose, none is superior?</p>
			<p>One factor that may influence your recommended dosage is how frequently you have sex. If you have sex as often as three times a week at least, a daily dose of 5mg might be the right choice for you.</p>
			<p>On the other hand, if you have sex less frequently, then the 20mg dose may be a better choice to use in the moment.</p></>
	);
};

export const Foam2 = () => {
	return (
		<><h4><strong>Male pattern baldness</strong></h4>
		<br></br>
			<p>Androgenic alopecia, also known as &quot;Male pattern baldness,&quot; is a condition characterized by a loss of hair in men leading to baldness. This baldness usually occurs at the top and front of the head.</p>
			<p>Minoxidil 5% Foam treats this condition by increasing blood flow to your follicles, widening them and stimulating the growth of long and thick hair.</p></>
	);
};

export const Finasteride2 = () => {
	return (
		<><h4><strong>Miniaturization of Follicles</strong></h4>
		<br></br>
			<p>Did you know that the male hormone dihydrotestosterone (DHT) is responsible for miniaturization of follicles?</p>
			<p>Miniaturization of follicles happens when DHT binds to receptors in men&#x27;s hair follicles, causing them to shrink, get weak, and die. When this happens, hair falls off the follicles leading to baldness.</p>
			<p>Finasteride treats hair loss by blocking DHT.<br></br></p></>
	);
};

export const Minoxidil_FoamAndFinasteride2 = () => {
	return (
		<><h4><strong>Minoxidil 5% Foam + Finasteride Combo </strong></h4>
		<br></br>
			<p>Did you know that the Minoxidil 5% Foam + Finasteride Combo works effectively in treating most forms of hair loss in men?</p>
			<p>When used as prescribed by your doctor, this combo can reverse the effects of hair loss and give you thick, healthy hair.</p></>
	);
};

export const Tretinoin2 = () => {
	return (
		<><h4><strong>Adult acne</strong></h4>
		<br></br>
			<p>Did you know that another name for adult acne is post-adolescent acne? While most people believe acne to be an adolescent condition, it is quite common to see acne continue into adulthood. The causes of both adolescent and post-adolescent acne are essentially the same too. However, other causes of adulthood acne may be hormones, physical and/or emotional stress.</p></>
	);
};

export const Valacyclovir2 = () => {
	return (
		<><h4><strong>Two categories of Herpes Simplex Virus (HSV)</strong></h4>
		<br></br>
			<p>Did you know that there are two categories for HSV? HSV-1 and HSV-2.</p>
			<p>Cold sores are usually caused by HSV-1, which is easier to transmit through oral-to-oral contact such as kissing and sharing cutlery.</p></>
	);
};

export const Acyclovir_Topical2 = () => {
	return (
		<><h4><strong>Acyclovir Topical Vs Valacyclovir</strong></h4>
		<br></br>
			<p>Essentially, both Acyclovir and Valacyclovir are similar drugs that treat oral herpes. Their mode of action is the same as Valacyclovir is eventually converted to Acyclovir in the body.</p><p>Your choice of product is dependent on your drug usage preference. Topical Acyclovir might be the right option for you if you would rather not swallow medication.</p></>
	);
};
export const Rupal_Topic2 = ()=>{
	return(
		<>
		<h4>Allergic Rhinitis</h4>
		<p>Allergic rhinitis results in symptoms associated with seasonal and year-round allergies, and other skin conditions/disorders including chronic hives and itching. Symptoms can include sneezing, runny nose, itching, and tearing and redness of the eyes.</p>
		<p>Rupall (Rupatadine) is an oral medication which belongs to the class of medications called
second-generation antihistamines. Rupall helps to manage and treat symptoms associated with allergic rhinitis.

</p>

		</>
	)
}

export const Sildenafil3 = () => {
	return (
		<strong>Sildenafil Generic vs. Viagra<br /></strong>
	);
};
export const Tadalafil3 = () => {
	return (
		<strong>Cialis 5mg vs 20mg<br /></strong>
	);
};
export const Tretinoin3 = () => {
	return (
		<strong>Adult acne<br /></strong>
	);
};
export const Valacyclovir3 = () => {
	return (
		<strong>HSV-1 and HSV-2<br /></strong>
	);
};
export const Acyclovir_Topical3 = () => {
	return (
		<strong>Acyclovir Topical vs. Valacyclovir<br /></strong>
	);
};
export const Foam3 = () => {
	return (
		<strong>Male pattern baldness<br /></strong>
	);
};
export const Finasteride3 = () => {
	return (
		<strong>Miniaturization of Follicles<br /></strong>
	);
};
export const Minoxidil_FoamAndFinasteride3 = () => {
	return (
		<strong>Male pattern baldness<br /></strong>
	);
};
export const Rupall3 =()=>{
	return(
		<strong>Allergic Rhinitis</strong>
	)
}

