import React from "react";

const MessageConst = {
	validation: {
		phoneError: "Please enter a valid phone number",
		emailError: "Please enter a valid email address",
		emailRequired: "Email address is required.",
		passwordError: "Password must be at least 6 characters long",
		passwordBlankError: "New password field cannot be blank",
		passwordErrorEquality: "Please enter the same password for confirmation",
		authError: 'Invalid account credentials. <br/>Please use the "Forgot your password?" button',
		firstNameError: "Please enter your first name",
		lastNameError: "Please enter your last name",
		nameError: "Please enter your name",
		registerError: "Sorry, an error occurred. Please try again",
		birthdayError: "Please enter your date of birth",
		messageError: "Please enter your message",
		govIdError: "Only digits, letters and dashes allowed",
		idError: "Please upload your id",
		conditionError: "Please select your diagnosed condition",
		prescribedProductError: "Please select your prescribed product",
		quantityError: "Please select product quantity",
		rxNumberError: "Please enter rx number",
		trackNumberError: "Please enter track number",
		pharmacyNameError: "Please enter pharmacy name",
		pharmacyPhoneError: "Please enter pharmacy phone",
		bottleImageError: "Please upload an image of your prescription bottle",
		shippingAddressError: "Please provide your shipping address",
		billingCardError: "Please provide your billing card",
		generalCardError: "Card data is invalid",
		coPayAmountError: "Please enter Co-Pay Amount",
		refillsLeftError: "Please enter the number of refills remaining",
		refillsQuantityError: "Please enter correct number of refills remaining",
		transferedRxExpiryDateError: "Please provide expiry date",
		pauseReasonError: "Please provide pause reason",
		courierError: "Please select courier",
		currentPasswordError: "Current Password field is mandatory",
		address: {
			streetError: "Please enter your street address",
			cityError: "Please enter your city",
			postalCodeError: "Please enter a valid postal code",
			nameError: "Please enter a cardholder name"
		},
		provinceError: "Please select a province",
		termsOfUseError: "Please review and agree to the Terms of Use and Privacy Policy",
		doctorConsult: {
			time: "Please select consult time"
		}
	},
	error: {
		promoCode: "Please enter a valid promo code",
		promoCodeType: "This promocode doesn't match cart products categories",
		uploadingImageError: "Error with uploading image please try again",
		unknownError: "Something went wrong please try again"
	},
	orderStatus: {
		WAITING_DOCTOR: "Awaiting doctor",
		REJECTED: "Rejected",
		ACTIVE: "Active",
		WAITING_PHARMACIST: "Processing by pharmacist",
		PACKAGING: "Packaging",
		DELIVERING: "Shipped",
		CANCELLED: "Cancelled",
		FINISHED: "Finished",
		COMPLETED: "Completed",
		PAUSED: "Paused - credit card failure",
		PAUSED_RX_TRANSFER: "Paused - credit card failure",
		PAUSED_BY_PATIENT: "Paused",
		WAITING_PHARMACY_RX_CHECK: "Waiting Rx Check",
		IN_PROGRESS: "In Progress"
	},
	appointment: {
		done: "Thank you for your order! A Winston doctor will contact you for your consultation on "
	},
	infoStrip: "Free Doctor Consultations",
	promoCode: {
		done: "Your promo code has been added successfully"
	}
};

export default MessageConst;
