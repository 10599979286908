import React, { useEffect } from "react";

import aboutUsStart from "../../static/images/about-us-start.png";
import doctorNaveed from "../../static/images/dr-Naveed.png";
import meetWinstonMobImg from "../../static/images/about-us/meet-winston-mob.jpg";
import integritySvg from "../../static/images/about-us/check.svg";
import learningSvg from "../../static/images/about-us/light.svg";
import qualitySvg from "../../static/images/about-us/quality.svg";
import divercitySvg from "../../static/images/about-us/geometry.svg";
import safetySvg from "../../static/images/about-us/safety.svg";
import experienceSvg from "../../static/images/about-us/man.svg";
import placeToBeSvg from "../../static/images/about-us/hands.svg";
import canadianSvg from "../../static/images/about-us/maple.svg";

import "./styles.scss";
import { Title } from "react-meta-elements";

const AboutUs = () => {
	
	useEffect(()=>{
		let search = window.location.search;
		if(search!=""){
			window.sessionStorage.setItem("utm_source", search.split('?')[1]);
		}
	}, [])
	return(	
		<>
		<Title>
			About Us | Winston - Men's clinic
		</Title>
		<div className="about-us-component">
			<div className="start">
				<div className="left-part">
					<div className="title">
						<h1>About us</h1>
						<div className="horizontal-line"></div>
					</div>
					<div className="content">
						<p>Creating a more confident world.</p>
					</div>
					<div className="bottom-text">
						<p>
							At the heart of Winston is a mission to empower everyone to be the best version of
							themselves while changing the stigma behind men’s health concerns by improving
							accessibility, education, and convenience without ever compromising care.
						</p>
					</div>
				</div>
				<div className="right-part">
					<img src={aboutUsStart} alt="About Us-Winston - Men's clinic" />
				</div>
			</div>
			<div className="our-goal">
				<div>
					Currently, there are many obstacles in the traditional health care system which causes a
					growing number of men to suffer in silence.
				</div>
				<div>
					Nearly <span className="bold">65%</span> of men avoid going to the doctor as long as
					possible, and&nbsp;<span className="bold">37%</span> withhold information from their
					doctors.
				</div>
				<div>
					Our goal is to break down those barriers by creating a process that is safe, discreet,
					quick, and simple for men to get the help they need, when they need it.
				</div>
			</div>
			<div className="meet-winston-wrapper">
				<div className="meet-winston">
					<div className="meet-winston-image">
						<img src={meetWinstonMobImg} alt="About Us-Winston - Men's clinic" />
					</div>
					<div className="meet-winston-content">
						<h2 className="header">Meet Winston</h2>
						<p>
							No more wait times, awkward conversations or taking time off work to get the help you
							need.
						</p>
					</div>
				</div>
			</div>
			<div className="principles">
				<div className="title">
					<h2>Our Guiding Principles</h2>
				</div>
				<div className="content">
					<div className="left-part">
						<div className="block">
							<div className="img-wrapper">
								<img src={integritySvg} alt="About Us-Winston - Men's clinic" />
							</div>
							<div>
								<p className="title">Integrity.</p>
								<p>We do what’s right, all the time.</p>
							</div>
						</div>
						<div className="block">
							<div className="img-wrapper">
								<img src={learningSvg} alt="About Us-Winston - Men's clinic" />
							</div>
							<div>
								<p className="title">Continuous Learning.</p>
								<p>
									We are life long learners that will continue to evolve, improve, and meet the needs
									of our stakeholders.
								</p>
							</div>
						</div>
						<div className="block">
							<div className="img-wrapper">
								<img src={qualitySvg} alt="About Us-Winston - Men's clinic" />
							</div>
							<div>
								<p className="title">Quality.</p>
								<p>
									We only provide quality products combined with exceptional service to deliver
									premium value to our customers.
								</p>
							</div>
						</div>
						<div className="block">
							<div className="img-wrapper">
								<img src={divercitySvg} alt="About Us-Winston - Men's clinic" />
							</div>
							<div>
								<p className="title">Diversity.</p>
								<p>
									We believe diversity is the backbone of creativity and we are inclusive in all that
									we do.
								</p>
							</div>
						</div>
					</div>
					<div className="right-part">
						<div className="block">
							<div className="img-wrapper">
								<img src={safetySvg} alt="About Us-Winston - Men's clinic" />
							</div>
							<div>
								<p className="title">Safety.</p>
								<p>We take every step we can to protect our customers.</p>
							</div>
						</div>
						<div className="block">
							<div className="img-wrapper">
								<img src={experienceSvg} alt="About Us-Winston - Men's clinic" />
							</div>
							<div>
								<p className="title">Customer Experience.</p>
								<p>We won’t tell you what you want. We will listen to what you need.</p>
							</div>
						</div>
						<div className="block">
							<div className="img-wrapper">
								<img src={placeToBeSvg} alt="About Us-Winston - Men's clinic" />
							</div>
							<div>
								<p className="title">The Place to Be.</p>
								<p>
									In addition to being a company that customers love, we want to be a company that our
									employees love, our partners respect and our shareholders always believe in.
								</p>
							</div>
						</div>
						<div className="block">
							<div className="img-wrapper">
								<img src={canadianSvg} alt="About Us-Winston - Men's clinic" />
							</div>
							<div>
								<p className="title">Proudly Canadian.</p>
								<p>
									We are a Canadian company with a Canadian spirit. We play nice but we are committed
									to winning.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="doctor-part">
				<div className="doctor-content">
					<div className="left-part">
						<img src={doctorNaveed} alt="About Us-Winston - Men's clinic" />
					</div>
					<div className="right-part">
						<div className="title">
							<h2>Meet our Chief Medical Officer</h2>
							<div className="horizontal-line"></div>
						</div>
						<div className="content">
							<p>
								Dr. Naveed is a prominent family physician who graduated from Dalhousie University's
								Family Medicine Residency Program. She has been providing comprehensive care in both
								urban and rural settings, including the emergency department, inpatient department and
								outpatient clinics. Her special interests include mental health, men and women's
								health, vaccinations/immunizations, and preventative medicine. Dr. Naveed also has
								many years of experience providing virtual visits through telemedicine to treat
								patients in the comfort and privacy of their own homes.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
	);
}

export default AboutUs;
