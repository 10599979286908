import React, {useEffect, useRef, forwardRef, Ref, useImperativeHandle, useState} from "react";

import MessageConst from "../../const/Message.const";
import visaCard from "../../static/images/card_visa.png";
import amexCard from "../../static/images/card_amex.png";
import mastercardCard from "../../static/images/card_mastercard.png";
import unionpayCard from "../../static/images/card_unionpay.png";
import discoverCard from "../../static/images/card_discover.png";
import CSVHelp from "../../static/images/CSV-help.png";
import crossSvg from "../../static/images/cross.svg";

import "./styles.scss";
import PopUp from "../PopUp";

export interface MonerisInputRefObject {
	doMonerisSubmit: () => void;
}

interface MonerisInputProps {
	addCb: (token: string) => void;
	setValidationMessage: (msg: string) => void;
	setCheckingCard: (b: boolean) => void;
	shrink: boolean;
}

const MonerisInput = forwardRef(
	(
		{addCb, setValidationMessage, setCheckingCard, shrink}: MonerisInputProps,
		ref: Ref<MonerisInputRefObject>
	) => {
		const [showCSVHelp, setShowCSVHelp] = useState<boolean>(false);

		useImperativeHandle(ref, () => ({doMonerisSubmit}));

		const monFrameRef = useRef<HTMLIFrameElement>(null);

		const doMonerisSubmit = () => {
			const refObj = monFrameRef.current!.contentWindow;
			refObj!.postMessage("tokenize", process.env.REACT_APP_MONERIS_URL!);
			// change link according to table above
		};

		useEffect(() => {
			const respMsg = (e: any) => {
				if (e?.data && typeof e.data === "string") {
					const result = JSON.parse(e.data);
					console.log(result);
					if (
						result?.responseCode &&
						result.responseCode.some((code: string) => ["943", "944", "945"].includes(code))
					) {
						setValidationMessage(MessageConst.validation.generalCardError);
					} else if (
						result?.responseCode &&
						result.responseCode.some((code: string) => ["940", "941", "942"].includes(code))
					) {
						setValidationMessage(MessageConst.error.unknownError);
					} else {
						addCb(result.dataKey);
						setValidationMessage("");
					}

					setCheckingCard(false);
				}
			};

			window.addEventListener("message", respMsg, false);

			return () => {
				window.removeEventListener("message", respMsg);
			};
		}, [addCb, setValidationMessage, setCheckingCard]);

		return (
			<div className="card-number">
				<div className="card-number__cardIcons">
					<img className="card-number__cardImg" src={visaCard} alt="visa" />
					<img className="card-number__cardImg" src={mastercardCard} alt="mastercard" />
					<img className="card-number__cardImg" src={amexCard} alt="amex" />
					<img className="card-number__cardImg" src={discoverCard} alt="discover" />
					<img className="card-number__cardImg" src={unionpayCard} alt="unionpay" />
				</div>
				<div className="titles">
					<div className={`card-number-title ${shrink ? "shrinked" : ""}`}>Card Number</div>
					<div className={`expiry-date-title ${shrink ? "shrinked" : ""}`}>
						{shrink ? "Exp.Date" : "Expiration Date"}
					</div>
					<div className="cvd-title">
						{shrink ? "CVC" : "Security Code"}{" "}
					</div>
				</div>
				<div className="card-number-wrapper">
					<div id="monerisResponse" />
					<iframe
						id="monerisFrame"
						ref={monFrameRef}
						title="Moneris"
						src={`${process.env.REACT_APP_MONERIS_URL}?id=${
							process.env.REACT_APP_MONERIS_PROFILE
						}&pmmsg=true&
						css_body=background:white;width:100%;display: flex;
						align-items: center; padding-left: 3px; box-sizing: border-box;
						&css_textbox=height: 40px; margin-right: 10px; border:1px solid rgb(187,145,87);
						outline-color: black; box-sizing: border-box; font-size: 14px; 
						padding: 8px 10px; border-radius: 8px;
						&css_textbox_pan=width:${shrink ? "150px" : "197px"};
						font-size: 14px;&enable_exp=1&display_labels=2&
						css_textbox_exp=width:70px;margin-right: ${shrink ? "10px" : "50px"};
						&enable_cvd=1&
						css_textbox_cvd=width:52px&exp_label=MMYY&cvd_label=CVC`}
						frameBorder="0"
						width="100%"
						height="44px"
						style={{position: "relative", left: "-3px"}}
					/>
				</div>
				{showCSVHelp && (
					<PopUp>
						<div className="show-csv-help-wrapper">
							<div className="close" onClick={() => setShowCSVHelp(false)}>
								<img src={crossSvg} alt="" />
							</div>
							<div className="title">
								<span>
									Your security code (CVV or CVC) is 3 or 4 digit code found on your credit card in
									the following locations:
								</span>
							</div>
							<img src={CSVHelp} alt="" className="help-image" />
						</div>
					</PopUp>
				)}
			</div>
		);
	}
);

export default MonerisInput;
