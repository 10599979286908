import React, {Fragment} from "react";
import moment from "moment-timezone";
import IDsvg from "../../../../static/images/icon-id.svg";
import DateService from "../../../../service/Date.service";
import Order from "../../../../model/Order";
import OrderItem from "../../../../model/OrderItem";
import "./style.scss";
import OrderStatus from "../../../../model/OrderStatus";

interface ISubscriptionItemProps {
	subscription: Order;
	handleOpenViewID: (t: string, v: string) => void;
	className?: string;
	handleRejectClick: (id: number) => void;
	handleApproveClick: (id: number) => void;
}

const SubscriptionItem = (
	{
		subscription,
		handleOpenViewID,
		className,
		handleApproveClick,
		handleRejectClick,
	}: ISubscriptionItemProps) => (
	<div key={subscription.id} className={`SubscriptionItem ${className || ""}`}>
		<div className="content prefiles">
			<div className="row1">
				<div className="id">#{subscription.id}</div>
				<div className="date">{moment(subscription.date).format("MMM D, YYYY, HH:mm")}</div>
				<div className="items">
					{subscription.items.reduce((acc: string, item: OrderItem) => {
						return acc + (!acc ? "" : ", ") + item.productDosage;
					}, "")}
				</div>
				<div className="document-view-buttons">
					{subscription.user?.insuranceDocument && (
						<div>
							<div
								className="view-id"
								onClick={() =>
									handleOpenViewID("Insurance", subscription.user!.insuranceDocument!)
								}
							>
								<img src={IDsvg} alt="id"/>
								<span>view insurance</span>
							</div>
						</div>
					)}
					{subscription.user?.idDocument && (
						<div>
							<div
								className="view-id"
								onClick={() => handleOpenViewID("ID", subscription.user!.idDocument!)}
							>
								<img src={IDsvg} alt="id"/>
								<span>view ID</span>
							</div>
						</div>
					)}
				</div>
			</div>
			{subscription.user && (
				<div className="row2">
					Name{" "}
					<div className="patient-name">
						{subscription.user.firstName + " " + subscription.user.lastName}
					</div>
					Birthday{" "}
					<div className="patient-birthday">
						{DateService.formatBirthday(subscription.user.birthday)}
					</div>
					Phone: <div className="patient-phone">{subscription.user.phone}</div>
				</div>
			)}
			<div className="row3">
											<span className="address">{`${subscription.shippingAddressLine1}, ${
												subscription.shippingAddressLine2 ? `${subscription.shippingAddressLine2},` : ""
											} ${subscription.shippingAddressCity}, ${subscription.shippingAddressProvince}, ${
												subscription.shippingAddressPostalCode
											}, ${subscription.shippingAddressCountry}`}</span>
			</div>
		</div>
		{
			![OrderStatus.active, OrderStatus.rejected].some(status => status === subscription.status) && <Fragment>
				<div className="button reject" onClick={() => handleRejectClick(subscription.id || -1)}>
					REJECT
				</div>
				<div
					className="button approve"
					onClick={() => handleApproveClick(subscription.id || -1)}
				>
					APPROVE
				</div>
			</Fragment>
		}
	</div>
);

export default SubscriptionItem;