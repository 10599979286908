import React, {useEffect,useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate, useLocation, useParams} from "react-router-dom";

import {StateType} from "../../redux/types";
import * as cartActions from "../../redux/cart/handlers";
import * as appActions from "../../redux/app/handlers";

import {getProductImage, formatProductNameToURL} from "../../utils/helpers";

import {
	ServedProductCategories,
	ProductCategoryName,
	ProductCategoryShortName,
	ProductCategoryDescription,
	ProductCategory,
	ProductCategoryNotes,
	ProductCategoryLearnMoreLink
} from "../../model/ProductCategory";
import Product from "../../model/Product";

import JumpNavigation from "../JumpNavigation";

import accreditedBusiness from "../../static/images/accredited-business.svg";
import monerisLogo from "../../static/images/monerisLogo.png";

import "./styles.scss";
import {Title } from "react-meta-elements";

function Shop() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const {category} = useParams<{category: ProductCategory}>();
	const[selectedDose, setSelectedDose] = useState<number>(0);


	const {products, shopScrollTarget} = useSelector(({product, app}: StateType) => ({
		products: product.productItems,
		shopScrollTarget: app.shopScrollTarget
	}));

	useEffect(() => {
		if (shopScrollTarget) {
			scrollToCategory(shopScrollTarget, false);
		} else {
			window.scrollTo(0, 0);
		}

		return () => {
			dispatch(appActions.setShopScrollTarget(null));
		};
	}, [shopScrollTarget, dispatch]);

	useEffect(()=>{
		let search = window.location.search;
		if(search!=""){
			window.sessionStorage.setItem("utm_source", search.split('?')[1]);			
		}
	}, [])

	useEffect(() => {
		if (category && ServedProductCategories.includes(category)) {
			scrollToCategory(category, false);
		} else if (category) {
			navigate("/shop");
		}
	}, [category, navigate]);

	const scrollToCategory = (category: string, isSmooth: boolean) => {
		const element = document.getElementById(category);

		if (element) {
			window.scrollTo({top: element.offsetTop - 150, behavior: isSmooth ? "smooth" : "auto"});
		}
	};
	const handleSelectChange = (event: any) => {
        setSelectedDose(event.target.value);
    }	

	const handleAddProductToCart = (productItem: Product, selectedDose:any) => () => {
		const y = window.scrollY;
		const {pathname} = location;

		dispatch(appActions.setCloseSidebarDirection({pathname, y}));

        const quantity = productItem.quantities.find((q)=> q.id == selectedDose);
        const index = productItem.quantities.findIndex((q)=> q.id == selectedDose);
		const product = {...productItem};
        product.qty = {...quantity!};        
        product.selectedQuantityIndex = index!;

           

		navigate("/cart");

		dispatch(cartActions.addProductRequest({product}));        
	};
   
	const renderCategories = () => {
		const renderCategoryItems = (category: ProductCategory) => {
			return products
				.filter(
					(product: Product) =>
						product.problemCategory === ProductCategoryShortName[category].toUpperCase()
				)
				.sort((a: Product, b: Product) => {
					if (a.combo && !b.combo) {
						return 1;
					} else if (b.combo && !a.combo) {
						return -1;
					} else return 0;
				})
				.map((product) => {
					const sortedQuantities = [...product.quantities].sort((a, b) => (a.id > b.id ? 1 : -1));
					return (
						<div key={product.id} className={`product-item ${product.combo && "combo"}`}>
							<div className="top-block">
								<div className={`form-image ${product.combo ? "combo" : ""}`}>
									<img src={getProductImage(product.category)} alt="Shop on Winston men's products|Quick and discreet delivery" />
								</div>
								<h3 className="product-name">{product.name}</h3>
								{product.combo && <div className="combo-text">Combo Pack</div>}
								{product.subName && <div className="product-subname">{product.subName}</div>}
								<div className="product-description">{product.description}</div>
							</div>
							<div className="bottom-block">
								<div className="starting-at">Starting as low as</div>
								<div className="price">
									<div className="dollar-sign">$</div>
									<span className="digits">{product.startingAtPrice || 0}</span>
									<div className="d-none">
									 <form id="email-form-2" name="email-form-2" data-name="Email Form 2">
                                       <select id="field-2" name="field-2" className="select-field-2 w-select" 
                                       value={selectedDose>0?selectedDose:sortedQuantities[0].id} 
                                       onChange={(event)=>handleSelectChange(event)}>
                                      {sortedQuantities.map((index)=>(
                                     <option key={index.id} value={index.id}>{index.quantity}</option>
                                       ))}
                                    </select>
				                    </form>
									</div>
									{/* <span className="per-form">{`per ${product.startingAtForm}`}</span> */}
								</div>
								<div className="products-buttons">
									<Link
										to={`/${formatProductNameToURL(product.name)}`}
										className="learn-more-btn-link"
									>
										<button className="learn-more-btn">LEARN MORE</button>
									</Link>
									<button className="get-started-btn" onClick={handleAddProductToCart(product,selectedDose>0? selectedDose:product.quantities[0].id)}>
										ADD TO CART
									</button>
								</div>
							</div>
						</div>
					);
				});
		};

		const renderBlocks = ServedProductCategories.map((category) => (
			<div className="category-item" key={category} id={category}>
				<div className="category-description">
					<h2 className="title">{ProductCategoryName[category]}</h2>
					<div className="description">
						{ProductCategoryDescription[category]}

						<p>
							Want to learn more?{" "}
							<Link
								to={`/article/${ProductCategoryLearnMoreLink[category]}`}
								className="link-learn-more"
							>
								Click here.
							</Link>
						</p>

						<div className="category-notes">
							{ProductCategoryNotes[category].map((note) => (
								<p key={note}>{note}</p>
							))}
						</div>
					</div>
				</div>
				{renderCategoryItems(category)}
			</div>
		));

		const legitscriptUrl = window.location.protocol + "//static.legitscript.com/seals/5468581.png";

		const trustedName = (
			<div className="trusted-name" key="trustedName">
				<h2 className="title">The Trusted Name in Men's Health</h2>
				<div className="trusted-brands">
					<img src={legitscriptUrl} alt="Shop on Winston men's products|Quick and discreet delivery" />
					<img src={accreditedBusiness} alt="Shop on Winston men's products|Quick and discreet delivery" className="accredited-business" />
					<img src={monerisLogo} alt="Shop on Winston men's products|Quick and discreet delivery" />
				</div>
			</div>
		);

		renderBlocks.splice(2, 0, trustedName);
		return renderBlocks;
	};

	return (
		<>
		<Title>Shop on Winston men's products|Quick and discreet delivery</Title>
		<div className="shop-page">
			<div className="top-header">
				<div className="text-info">
					<div>
						<h1 className="title">The right product for every man.</h1>
						<span className="subtitle">
							Winston is here to help you improve your health and boost your confidence.
						</span>
						<span className="subtitle">Need help choosing the right product?</span>
					</div>
					<Link to="/contact-us" className="consult-btn">
						Free Doctor Consultation
					</Link>
				</div>
			</div>
			<JumpNavigation
				items={ServedProductCategories}
				shortNames={ProductCategoryShortName}
				names={ProductCategoryName}
				onItemClick={scrollToCategory}
				mode="products"
			/>
			<div className="categories-section">{renderCategories()}</div>
		</div>
		</>
	);
}

export default Shop;
