import { ChangeEvent, forwardRef, useCallback, useImperativeHandle, useState } from "react";
import ValidationService from "../../../../../service/Validation.service";
import React from "react";
import CustomInput from "../../../../CustomInput";
import ImageUploadComponent from "../../../../ImageUpload";
import IDsvg from "../../../../../static/images/icon-id.svg";
import IconConfirmGreen from "../../../../../static/images/_confirmGreen.svg";
import { uploadDocument } from "../../../../../http/patient";
import transferDetailsImage from "../../../../../static/images/transferRxBottleDetails.png";
import MessageConst from "../../../../../const/Message.const";

interface PrescriptionStepProps {
	rxNumber: string,
	pharmacyName: string;
	pharmacyPhone: string;
	bottleImageId: string | null;
	handleChangeRxId: (rxNumber: string) => void;
	handleChangePharmacyName: (name: string) => void;
	handleChangePhone: (name: string) => void;
	handleBottleImgChange: (bottleId: string ) => void;
    handleOpenImgView: (type: string, id: string) => void;
    handleStateUpdated: (state: boolean) => void;
}

export interface PrescriptionStepRef {
	setPrescriptionStepData: () => void;
}


const PrescriptionStep = forwardRef<PrescriptionStepRef, PrescriptionStepProps>(
    ({rxNumber, pharmacyPhone, pharmacyName, bottleImageId, 
        handleChangeRxId, handleChangePharmacyName, handleChangePhone, 
        handleBottleImgChange, handleOpenImgView, handleStateUpdated}:PrescriptionStepProps, ref) => {
    const [rxNumberLocal, setRxNumberLocal] = useState(rxNumber);
    const [phoneLocal, setPhoneLocal] = useState(pharmacyPhone);
    const [pharmacyNameLocal, setPharmacyNameLocal] = useState(pharmacyName);
    const [bottleImageIdLocal, setBottleImageIdLocal] = useState(bottleImageId);
    const [validationMessage, setValidationMessage] = useState("");
    
    const onChangeRx = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        const rxId = ValidationService.numberConverter(event.target.value);
        setRxNumberLocal(rxId);
    }, []);

    const onChangePhone = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        const phone: string = ValidationService.phoneConverter(event.target.value);
        setPhoneLocal(phone);
    }, []);

    const onChangePharmacyName = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setPharmacyNameLocal(event.target.value);
    }, []);

    const onChangeBottleImage = useCallback(async (file: File): Promise<void> => {
        const formData = new FormData();
		formData.append("file", file);
		try {
			// setLoading(true);

			const result = await uploadDocument(formData);
			const bottleId = result.data;

			setBottleImageIdLocal(bottleId);
			setValidationMessage("");
		} catch {
			setValidationMessage(MessageConst.error.uploadingImageError);
		} finally {
			// setLoading(false);
		}
    }, []);

    useImperativeHandle(ref, () => ({
        setPrescriptionStepData: () => {
            console.log(rxNumberLocal);
            handleChangeRxId(rxNumberLocal);
            handleChangePharmacyName(pharmacyNameLocal);
            handleChangePhone(phoneLocal);
            handleBottleImgChange(bottleImageIdLocal!);
            handleStateUpdated(true);
        }        
    }));

    
    return (
        <div className="prescription-step">
            <div className="content">
                <div className="title">
                    Input you current prescription and pharmacy details.
                </div>

                <div className="rx-number">
                    <CustomInput label="Rx Number" value={rxNumberLocal} onChange={onChangeRx}/>
                </div>
                <div className="pharmacy-name">
                    <CustomInput
                        label="Pharmacy Name"
                        value={pharmacyNameLocal}
                        onChange={onChangePharmacyName}
                    />
                </div>
                <div className="pharmacy-phone">
                    <CustomInput
                        label="Pharmacy Phone Number"
                        value={phoneLocal}
                        onChange={onChangePhone}
                    />
                </div>
                <div className="bottle-image-upload-block">
                    <div className="subtitle">
                        To ensure the accuracy of your prescription details, please upload an image
                        of your prescription bottle.
                    </div>
                    <div className="img-buttons">
                        <ImageUploadComponent
                            img={!!bottleImageId}
                            onImgChange={onChangeBottleImage}
                            mode="Bottle"
                        />
                        {bottleImageIdLocal && (
                            <label
                                className="view-img"
                                onClick={() => handleOpenImgView("Bottle", bottleImageIdLocal)}
                            >
                                <img src={IDsvg} alt="" />
                                <p className="view-img-text">View image</p>
                            </label>
                        )}
                    </div>
                    {bottleImageIdLocal && (
                        <div className="success-upload">
                            <span>Image uploaded successfully!</span>
                            <img src={IconConfirmGreen} alt="" />
                        </div>
                    )}
                </div>
            </div>
            <div className="help-image">
                <img src={transferDetailsImage} alt="" />
            </div>
            <div className="validation-message">
                <span className="validation-error">{validationMessage}</span>
            </div>
        </div>
    );
})

export default PrescriptionStep;