import React from "react";
import {useSelector} from "react-redux";
import { Link } from "react-router-dom";
import {StateType} from "../../../../redux/types";
import "./styles.scss";


interface PatientCheckoutDetailsProps {
	useLastUsedData: boolean;
	doctorConsultDate: string | undefined;
}

const PatientCheckoutDetails = (props: PatientCheckoutDetailsProps) => {
	const {selectedShippingAddress, selectedBillingCard} = useSelector(
		({patient, auth}: StateType) => ({
			selectedShippingAddress: props.useLastUsedData ?
				patient.lastUsedShippingAddress :
				patient.selectedShippingAddress,

			selectedBillingCard: props.useLastUsedData ?
				patient.lastUsedBillingCard :
				patient.selectedBillingCard,

			profile: auth.profile
		})
	);

	return (
		<div id="confirm">

				<div className="div-block-17">
					
					{selectedShippingAddress && (
					<div>
						<div className="column-title-edit w-row">
							<div className="w-col w-col-6">
								<h1 className="heading-23">Shipping Address</h1>
							</div>
							<div className="w-col w-col-6">
								<Link to="/shipping" className="edit-link">
									<div className="text-block-30">Edit</div>
								</Link>
							</div>
							
						</div>
						
						<div className="text-block-31">
							{selectedShippingAddress.addressLine1}
							{selectedShippingAddress.addressLine2 ? ", " : ""}
							{selectedShippingAddress.addressLine2}, 
							{selectedShippingAddress.addressCity}<br/>
							{selectedShippingAddress.addressProvince},{" "} 
							{selectedShippingAddress.addressPostalCode}
						</div>
					</div>
					)}
					{selectedBillingCard && (
						<div>
							<div className="column-title-edit w-row">
								<div className="w-col w-col-6">
									<h1 className="heading-24">Credit Card</h1>
								</div>
								<div className="w-col w-col-6">
									<Link to="/payment" className="edit-link">
										<div className="text-block-30">Edit</div>
									</Link>
								</div>
							</div>

							{/* <h1 className="heading-24">Credit Card</h1> */}
							<div className="text-block-47">**** {selectedBillingCard.last4}</div>					
							<h1 className="heading-25">Billing Address</h1>
							<div className="text-block-48">{selectedBillingCard.addressLine1}
								{selectedBillingCard.addressLine2 ? ", " : ""}
								{selectedBillingCard.addressLine2}, 
								{selectedBillingCard.addressCity}, {selectedBillingCard.addressProvince},{" "}
								{selectedBillingCard.addressPostalCode}
							</div>
						</div>
					)}
					{!!props.doctorConsultDate && (
					<div>
						<h1 className="heading-26">Doctor Consult</h1>
						<div className="text-block-49">{props.doctorConsultDate}</div>
					</div>
					)}
				</div>
		</div>
	);
};

PatientCheckoutDetails.defaultProps = {
	useLastUsedData: false,
	doctorConsultDate: undefined,
};

export default PatientCheckoutDetails;
