import React from "react";

import {getOrderReceipt} from "../../../../http/patient";

import Order from "../../../../model/Order";

import MessageConst from "../../../../const/Message.const";

import DownloadIcon from "../../../../static/images/downloadIcon.jpg";

import "./styles.scss";
import OrderStatus from "../../../../model/OrderStatus";

interface OrderInfoItemProps {
	order: Order;
	soloItem?: boolean;
}

const OrderInfoItem = ({order, soloItem}: OrderInfoItemProps) => {
	const handleGetReceipt = async () => {
		const result = await getOrderReceipt(order.id);

		const url = window.URL.createObjectURL(new Blob([result.data]));
		const link = document.createElement("a");

		link.href = url;
		link.setAttribute("download", `order-${order.id}-receipt.pdf`);
		document.body.appendChild(link);
		link.click();
	};

	return (
		<div className={`order-item ${soloItem ? "solo" : ""}`}>
			<div className="top-row">
				<div className="status">
					<div className="status-title">Order Status:</div>
					<div className="status-text">{MessageConst.orderStatus[order.status]}</div>
				</div>
			</div>
			<div className="center-row">
				<div className="id">
					<div className="id-title">Order ID:</div>
					<div className="id-text">{`${order.number} - ${order.subNumber
						.toString()
						.padStart(2, "0")}`}</div>
				</div>

				<div className="product-name">
					<div className="product-name-title">Product:</div>
					<div className="product-name-text">
						{order.items.map((item, index) => (
							<div key={index}>{item.productDosage}</div>
						))}
					</div>
				</div>
			</div>
			{soloItem && (
				<div className="bottom-row">
					{order.status !== OrderStatus.rejected && (
						<div className="get-order-receipt" onClick={handleGetReceipt}>
							<img src={DownloadIcon} alt="" />
							<span>Download Receipt</span>
						</div>
					)}

					<div className="order-price">
						<div className="order-price-title">Order Price</div>
						<div className="order-price-text">{`$${(order.cartPrice / 100).toFixed(2)}`}</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default OrderInfoItem;
