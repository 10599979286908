import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Navigate, useNavigate, useLocation, Link } from "react-router-dom";
import Product from "../../model/Product";
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StateType } from "../../redux/types";
import * as cartActions from "../../redux/cart/handlers";
import * as appActions from "../../redux/app/handlers";
//acne images
import pores from "../../static/images/pores.png";
import bacteria from "../../static/images/bacteria.png";
import hormones from "../../static/images/hormones.png";
import medications from "../../static/images/medication.png";
import cosmetics from "../../static/images/cosmetics.png";
import oil from "../../static/images/oil.png";
import sweating from "../../static/images/sweating.png";
import stressIcon from "../../static/images/stress-icon.png";
import diet1 from "../../static/images/diet.png";
// cs images
import skinContact from "../../static/images/skin-contact.png";
import unprotected from "../../static/images/unprotected-sex.png";
import immunosuppression from "../../static/images/immunosuppression.png";
import infectedContact from "../../static/images/infected-contact.png";
import kissing from "../../static/images/kissing.png";
import sharing1 from "../../static/images/sharing.png";
//ED images
import diabetes from "../../static/images/diabetes.png";
import kidney from "../../static/images/kidney.png";
import recreational from "../../static/images/recreational.png";
import cardiovascular from "../../static/images/cardiovascular.png";
import smoking from "../../static/images/smoking.png";
import sideEffect from "../../static/images/side-effect.png";
import ejaculatory from "../../static/images/ejaculatory.png";
import psychosocial from "../../static/images/psychosocial.png";
import physicalTrauma from "../../static/images/physical-trauma.png";
//Allergy Images
import sneezing from "../../static/images/sneezing.png";
import itchyEyes from "../../static/images/itchy-eyes.png";
import coughing from "../../static/images/coughing.png"
import itchyThroatAndEarCanels from "../../static/images/itchy-throat-and-ear-canals.png";
import runnyNose from "../../static/images/runny-nose.png";
import earCongestion from "../../static/images/ear-congestion.png";
import wateryEyes from "../../static/images/watery-eyes.png"
import shortnessOfBreath from "../../static/images/shortness-of-breath.png";
import wheezing from "../../static/images/wheezing.png";
import headache from "../../static/images/headache.png";
//HL images
import infectionPng from "../../static/images/scalp-infections.png";
import stressPng from "../../static/images/stress-icon.png";
import alopecia from "../../static/images/alopecia.png";
import chemo from "../../static/images/chemo.png";
import diet from "../../static/images/diet.png";
import genetics from "../../static/images/genetics.png";
import homoral from "../../static/images/hormonal-changes.png";
import baldness from "../../static/images/male-baldness.png";
import mediCondition from "../../static/images/medical-conditions.png";
import medication from "../../static/images/medication.png";
import stars from "../../static/images/stars.png";
import hairLoss from "../../static/images/hair-loss-bottle-and-pill.png";
import anceBottle from "../../static/images/acne-bottle-and-cream.png";
import placeSvg from "../../static/images/treatment-product-imagerySildenafil-and-Tadalafil.png";
import choose_product from "../../static/images/choose-product.jpg";
import connect_doctor from "../../static/images/connect-doctor.jpg";
import take_control from "../../static/images/take-control.jpg";
import licenced_doctor from "../../static/images/licenced_doctor.png";
import refill from "../../static/images/refill.png";
import standard_shipping from "../../static/images/standard_shipping.png";
import arrow_icon from "../../static/images/arrow-icon.png";
import arrow_iconr from "../../static/images/arrow-iconr.png";
import doctorArt from "../../static/images/white-doctor-circle.png";
import close from "../../static/images/close.png";
import viagara from "../../static/images/viagara-and-cialis.jpg";
import ed from "../../static/images/ed-101.jpg";
import fhl from "../../static/images/hair-loss-blog.jpg";
import facne from "../../static/images/facne-blog.jpg";
import fcd from "../../static/images/fcd-blog.jpg";
import { getProductImage, formatProductNameToURL, getMalePatternBaldness, getMalePatternButtonName } from "../../utils/helpers";
import "./styles.scss";
import { Accordion, Button, Modal } from "react-bootstrap";


function Shop() {
  const [open, setOpen] = useState<number[]>([]);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleClose2 = () => setShow2(false);
  const handleClose3 = () => setShow3(false);
  const handleShow1 = () => setShow1(true);
  const handleShow2 = () => setShow2(true);
  const handleShow3 = () => setShow3(true);
  const [isActive] = useState(false);
  const [selectedDose, setSelectedDose] = useState<number>(0);
  const [validationMessage] = useState<string>("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { products } = useSelector(({ product }: StateType) => ({
    products: product.productItems
  }));
  const  {productName}  = useParams();
  const pageProduct = products.find((prod) => {    
    return formatProductNameToURL(prod.name) === productName;
  });


  if (!pageProduct) {
    return <Navigate to="/" />;
  }

  let search = window.location.search;
	if(search!=""){
		window.sessionStorage.setItem("utm_source", search.split('?')[1]);
	}


  const handleSelectChange = (event: any) => {
    setSelectedDose(event.target.value);
  }

  const handleOpenClick = (id: number) => {
    let newArr = [...open];
    var index = newArr.indexOf(id);
    if (index > -1) {
      newArr.splice(index, 1);
    } else {
      newArr.push(id);
    }
    setOpen(newArr);
  }


  const handleAddProductToCart = (productItem: Product, selectedDose: any) => () => {
    const y = window.scrollY;
    const { pathname } = location;

    dispatch(appActions.setCloseSidebarDirection({ pathname, y }));

    const quantity = productItem.quantities.find((q) => q.id == selectedDose);
     const index = productItem.quantities.findIndex((q)=> q.id == selectedDose);
        const product = { ...productItem };
        product.qty = {...quantity!};        
        product.selectedQuantityIndex = index!;


    navigate("/cart");

    dispatch(cartActions.addProductRequest({ product }));
  };




  return (
    <>

      <div id="product">

        {/* modal starts here */}
        <Modal show={show1} onHide={handleClose1} animation={false} id="product">
          <div className="div-block-55">
            <div className="div-block-56">
              <div className="div-block-45" onClick={handleClose1}><img src={close} width="25" alt="close" /></div>
              <h1 className="heading-95">Ingredients</h1>
              <div dangerouslySetInnerHTML={{ __html: pageProduct.ingredient }} />
            </div>
          </div>
        </Modal>

        <Modal show={show2} onHide={handleClose2} animation={false} id="product">
          <div className="div-block-43">
            <div className="div-block-44">
              <div className="div-block-45" onClick={handleClose2}><img src={close} width="25" alt="close" /></div>
              <h1 className="heading-60"><strong>Safety information details</strong><br /></h1>
              <div dangerouslySetInnerHTML={{ __html: pageProduct.safetyInfo }} />
            </div>
          </div>
        </Modal>

        <Modal show={show3} onHide={handleClose3} animation={false} id="product">
          <div className="div-block-43">
            <div className="div-block-44 pf-modal">
              <div className="div-block-45" onClick={handleClose3}><img src={close} width="25" alt="close" /></div>
              <div>{getMalePatternBaldness(pageProduct.name)}</div>
            </div>
          </div>
        </Modal>
        {/* modal ends here */}
        <section id="1st-section" className="section product">
          <div className="container-6 w-container">
            <div className="columns-30 w-row">
              <div className="column-89 w-col w-col-6"><img src={getProductImage(pageProduct.name)} alt={pageProduct.name} width="394" className="image-2" />
                <div className="text-block-info">Branded packaging displayed may be different <br />when delivered due to pharmaceutical guidelines.</div>
              </div>
              <div className="column-45 w-col w-col-6" id="safety-info">
                <h1 className="heading-2">{pageProduct.name}</h1>
                <div className="subname">{pageProduct.subName}</div>
                <p className="paragraph" dangerouslySetInnerHTML={{ __html: pageProduct.productPageText }}></p>
                <h1 className="heading-4">Dosing tips</h1>
                <p className="paragraph-7" dangerouslySetInnerHTML={{ __html: pageProduct.dosingTips }}></p>
                <div className="w-row">
                  <div className="w-col w-col-6">
                    <span onClick={handleShow1} className="button-14 w-button"><strong className="bold-text">Ingredients</strong></span>
                  </div>
                  <div className="w-col w-col-6">
                    <span onClick={handleShow2} className="button-8 w-button"><strong>Safety Info</strong></span>
                  </div>
                </div>
                <span onClick={handleShow3} className="button-15 w-button">{getMalePatternButtonName(pageProduct.name)}</span>

                {pageProduct.problemCategory == "CS" || pageProduct.problemCategory == "AC" ? <div className="div-block-19">
                  <h1 className="heading-5">Monthly supply starting at  ${pageProduct.startingAtPrice || 0}</h1>
                  <div className="form-block-2 w-form">
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                  <div className="text-block-7">Auto-refills that can be paused, skipped or cancelled anytime.</div>
                  <span onClick={handleAddProductToCart(pageProduct, selectedDose > 0 ? selectedDose : pageProduct.quantities[0].id)} className="button-9 w-button">
                    <strong>Add to cart</strong>
                  </span>
                </div>
                  : null}
                {pageProduct.problemCategory == "ED" || pageProduct.problemCategory == "HL" ? <div className="div-block-19">
                  <h1 className="heading-5">Selects dose and quantity:</h1>
                  <div className="form-block-2 w-form">
                    <form id="email-form-2" name="email-form-2" data-name="Email Form 2">
                      <select id="field-2" name="field-2" className="select-field-2 w-select"
                        onChange={(event) => handleSelectChange(event)} value={selectedDose > 0 ? selectedDose : pageProduct.quantities[0].id}>
                        {pageProduct.quantities.map((index) => (
                          <option key={index.id} value={index.id}>{index.quantity}</option>
                        ))}
                      </select>
                    </form>
                    
                    {/* validation column */}
										<div className={validationMessage?"w-form-fail-show":"w-form-fail"}>
												<div>{validationMessage}</div>
										</div>
                    
                  </div>
                  <div className="text-block-7">Auto-refills that can be paused, skipped or cancelled anytime.</div>
                  <span onClick={handleAddProductToCart(pageProduct, selectedDose > 0 ? selectedDose : pageProduct.quantities[0].id)} className="button-9 w-button">
                    <strong>Add to cart</strong>
                  </span>
                </div>
                  : null}
                     {pageProduct.problemCategory == "AL" ? <div className="div-block-19">
                  <h1 className="heading-5">Selects dose and quantity:</h1>
                  <div className="form-block-2 w-form">
                    <form id="email-form-2" name="email-form-2" data-name="Email Form 2">
                      <select id="field-2" name="field-2" className="select-field-2 w-select"
                        onChange={(event) => handleSelectChange(event)} value={selectedDose > 0 ? selectedDose : pageProduct.quantities[0].id}>
                        {pageProduct.quantities.map((index) => (
                          <option key={index.id} value={index.id}>{index.quantity}</option>
                        ))}
                      </select>
                    </form>
                    
                    {/* validation column */}
										<div className={validationMessage?"w-form-fail-show":"w-form-fail"}>
												<div>{validationMessage}</div>
										</div>
                    
                  </div>
                  <div className="text-block-7">Auto-refills that can be paused, skipped or cancelled anytime.</div>
                  <span onClick={handleAddProductToCart(pageProduct, selectedDose > 0 ? selectedDose : pageProduct.quantities[0].id)} className="button-9 w-button">
                    <strong>Add to cart</strong>
                  </span>
                </div>
                  : null}
              </div>
            </div>
          </div>
        </section>

        {pageProduct.problemCategory == "ED" ?
          <div className="section-3">
            <div className="container-8 w-container">
              <h1 className="heading-6">What Causes Erectile Dysfunction?<br /></h1>
              <p className="paragraph-8">The most common risk factors include:<br /></p>
              <div className="columns-60 w-row">
                <div className="column-99 w-col w-col-6"><img src={diabetes} width="40" alt="diabetes" />
                  <h1 className="heading-89">Diabetes</h1>
                </div>
                <div className="column-100 w-col w-col-6"><img src={stressPng} width="40" alt="stress" />
                  <h1 className="heading-89">Stress</h1>
                </div>
              </div>
              <div className="columns-67 w-row">
                <div className="column-107 w-col w-col-6"><img src={kidney} width="40" alt="kidney" />
                  <h1 className="heading-91">Chronic kidney disease</h1>
                </div>
                <div className="column-107 w-col w-col-6"><img src={recreational} width="40" alt="recreational" />
                  <h1 className="heading-91">Recreational drug use</h1>
                </div>
              </div>
              <div className="columns-67 w-row">
                <div className="column-103 w-col w-col-6"><img src={cardiovascular} width="40" alt="cardio" />
                  <h1 className="heading-88">Cardiovascular disease</h1>
                </div>
                <div className="column-107 w-col w-col-6"><img src={smoking} width="40" alt="smoking" />
                  <h1 className="heading-90">Smoking</h1>
                </div>
              </div>
              <div className="columns-67 w-row">
                <div className="column-107 w-col w-col-6"><img src={sideEffect} width="40" alt="side-effect" />
                  <h1 className="heading-91">Side effects of medications</h1>
                </div>
                <div className="column-107 w-col w-col-6"><img src={ejaculatory} width="40" alt="ejaculatory" />
                  <h1 className="heading-91">Ejaculatory disorders<br /></h1>
                </div>
              </div>
              <div className="columns-67 w-row">
                <div className="column-107 w-col w-col-6"><img src={psychosocial} width="40" alt="psychosocial" />
                  <h1 className="heading-91">Psychosocial factors</h1>
                </div>
                <div className="column-107 w-col w-col-6"><img src={physicalTrauma} width="40" alt="physicalTrauma" />
                  <h1 className="heading-91">Physical trauma</h1>
                </div>
              </div>
            </div>
          </div>
          : null
        }

        {pageProduct.problemCategory == "HL" ?
          <div className="section-3">
            <div className="container-8 w-container">
              <h1 className="heading-6">Reasons for Hair Loss<br /></h1>
              <p className="paragraph-8">The most common risk factors include:<br /></p>
              <div className="columns-60 w-row">
                <div className="column-99 w-col w-col-6"><img src={infectionPng} width="40" alt="infectionPng" />
                  <h1 className="heading-89">Scalp Infections</h1>
                </div>
                <div className="column-100 w-col w-col-6"><img src={stressPng} width="40" alt="stressPng" />
                  <h1 className="heading-89">Stress</h1>
                </div>
              </div>
              <div className="columns-67 w-row">
                <div className="column-107 w-col w-col-6"><img src={alopecia} width="40" alt="alopecia" />
                  <h1 className="heading-91">Alopecia areata</h1>
                </div>
                <div className="column-107 w-col w-col-6"><img src={chemo} width="40" alt="chemo" />
                  <h1 className="heading-91">Radiation or chemotherapy treatment</h1>
                </div>
              </div>
              <div className="columns-67 w-row">
                <div className="column-103 w-col w-col-6"><img src={diet} width="40" alt="diet" />
                  <h1 className="heading-88">Diet</h1>
                </div>
                <div className="column-107 w-col w-col-6"><img src={genetics} width="40" alt="genetics" />
                  <h1 className="heading-90">Genetics</h1>
                </div>
              </div>
              <div className="columns-67 w-row">
                <div className="column-107 w-col w-col-6"><img src={homoral} width="40" alt="homoral" />
                  <h1 className="heading-91">Hormonal changes</h1>
                </div>
                <div className="column-107 w-col w-col-6"><img src={baldness} width="40" alt="baldness" />
                  <h1 className="heading-91">Male pattern baldness<br /></h1>
                </div>
              </div>
              <div className="columns-67 w-row">
                <div className="column-107 w-col w-col-6"><img src={mediCondition} width="40" alt="mediCondition" />
                  <h1 className="heading-91">Chronic medical conditions such as thyroid disease or anemia</h1>
                </div>
                <div className="column-107 w-col w-col-6"><img src={medication} width="40" alt="medication" />
                  <h1 className="heading-91">Medications</h1>
                </div>
              </div>
            </div>
          </div>
          : null
        }

        {pageProduct.problemCategory == "AC" ?
          <div className="section-3">
            <div className="container-8 w-container">
              <h1 className="heading-6">What Causes Acne?<br /></h1>
              <p className="paragraph-8">The most common risk factors include:<br /></p>
              <div className="columns-60 w-row">
                <div className="column-99 w-col w-col-6"><img src={stressIcon} width="40" alt="stressIcon" />
                  <h1 className="heading-89">Stress</h1>
                </div>
                <div className="column-100 w-col w-col-6"><img src={pores} width="40" alt="pores" />
                  <h1 className="heading-89">Clogged pores</h1>
                </div>
              </div>
              <div className="columns-67 w-row">
                <div className="column-107 w-col w-col-6"><img src={bacteria} width="40" alt="bacteria" />
                  <h1 className="heading-91">Bacteria that lives on your skin</h1>
                </div>
                <div className="column-107 w-col w-col-6"><img src={hormones} width="40" alt="hormones" />
                  <h1 className="heading-91">Hormones</h1>
                </div>
              </div>
              <div className="columns-67 w-row">
                <div className="column-103 w-col w-col-6"><img src={diet1} width="40" alt="diet1" />
                  <h1 className="heading-88">Diet</h1>
                </div>
                <div className="column-107 w-col w-col-6"><img src={medications} width="40" alt="medications" />
                  <h1 className="heading-90">Medications</h1>
                </div>
              </div>
              <div className="columns-67 w-row">
                <div className="column-107 w-col w-col-6"><img src={cosmetics} width="40" alt="cosmetics" />
                  <h1 className="heading-91">Cosmetics</h1>
                </div>
                <div className="column-107 w-col w-col-6"><img src={oil} width="40" alt="oil" />
                  <h1 className="heading-91">Excessive sweating<br /></h1>
                </div>
              </div>
              <div className="columns-67 w-row">
                <div className="column-107 w-col w-col-6"><img src={sweating} width="40" alt="sweating" />
                  <h1 className="heading-91">Excess oil production</h1>
                </div>
                <div className="column-107 w-col w-col-6">
                </div>
              </div>
            </div>
          </div>
          : null
        }

        {pageProduct.problemCategory == "CS" ?
          <div className="section-3">
            <div className="container-8 w-container">
              <h1 className="heading-6">Causes and Risk Factors of Cold Sores<br /></h1>
              <p className="paragraph-8">The most common risk factors include:<br /></p>
              <div className="columns-60 w-row">
                <div className="column-99 w-col w-col-6"><img src={skinContact} width="40" alt="skinContact" />
                  <h1 className="heading-89">Prolonged skin to skin contact</h1>
                </div>
                <div className="column-100 w-col w-col-6"><img src={unprotected} width="40" alt="unprotected" />
                  <h1 className="heading-89">Unprotected sex</h1>
                </div>
              </div>
              <div className="columns-67 w-row">
                <div className="column-103 w-col w-col-6">
                  <h1 className="heading-77"><strong className="bold-text-3">HIV </strong></h1>
                  <h1 className="heading-91">Human immunodeficiency viruses</h1>
                </div>
                <div className="column-107 w-col w-col-6"><img src={immunosuppression} width="40" alt="immunosuppression" />
                  <h1 className="heading-91">Immunosuppression</h1>
                </div>
              </div>
              <div className="columns-67 w-row">
                <div className="column-103 w-col w-col-6"><img src={infectedContact} width="40" alt="infectedContact" />
                  <h1 className="heading-88">Contact with infected saliva,<br />mucous membranes, skin</h1>
                </div>
                <div className="column-107 w-col w-col-6"><img src={kissing} width="40" alt="kissing" />
                  <h1 className="heading-90">Kissing</h1>
                </div>
              </div>
              <div className="columns-67 w-row">
                <div className="column-107 w-col w-col-6"><img src={medication} width="40" alt="medication" />
                  <h1 className="heading-91">Certain medications i.e. drugs that<br />can suppress your immune system</h1>
                </div>
                <div className="column-107 w-col w-col-6"><img src={sharing1} width="40" alt="sharing1" />
                  <h1 className="heading-91">Sharing items like razors<br /></h1>
                </div>
              </div>
            </div>
          </div>
          : null
        }
        {pageProduct.problemCategory == "AL" ? 
          <div className="section-3">
          <div className="container-8 w-container">
            <h1 className="heading-6">Common Symptoms of Allergic Rhinitis aka Seasonal Allergies, Hay Fever<br /></h1>
            <p className="paragraph-8">Common symptoms associated with allergic rhinitis include:<br /></p>
            <div className="columns-60 w-row">
              <div className="column-99 w-col w-col-6"><img src={sneezing} width="40" alt="skinContact" />
                <h1 className="heading-89">Sneezing</h1>
              </div>
              <div className="column-100 w-col w-col-6"><img src={itchyEyes} width="40" alt="unprotected" />
                <h1 className="heading-89">Itchy eyes</h1>
              </div>
            </div>
            <div className="columns-67 w-row">
              <div className="column-103 w-col w-col-6">
                <img src={coughing} width="40" alt="unprotected" />
                <h1 className="heading-91">Coughing</h1>
              </div>
              <div className="column-107 w-col w-col-6"><img src={itchyThroatAndEarCanels} width="40" alt="immunosuppression" />
                <h1 className="heading-91">Itchy throat and ear canals</h1>
              </div>
            </div>
            <div className="columns-67 w-row">
              <div className="column-103 w-col w-col-6"><img src={runnyNose} width="40" alt="infectedContact" />
                <h1 className="heading-88">A runny nose</h1>
              </div>
              <div className="column-107 w-col w-col-6"><img src={earCongestion} width="40" alt="kissing" />
                <h1 className="heading-90">Ear congestion</h1>
              </div>
            </div>
            <div className="columns-67 w-row">
              <div className="column-107 w-col w-col-6"><img src={wateryEyes} width="40" alt="medication" />
                <h1 className="heading-91">Watery eyes</h1>
              </div>
            </div>
            <div className="columns-67 w-row">
                <p className="paragraph-8">Some people may also experience</p>
            </div>
            <div className="columns-67 w-row">
              <div className="column-103 w-col w-col-6"><img src={shortnessOfBreath} width="40" alt="infectedContact" />
                <h1 className="heading-88">Shortness of breath</h1>
              </div>
              <div className="column-107 w-col w-col-6"><img src={wheezing} width="40" alt="kissing" />
                <h1 className="heading-90">Wheezing</h1>
              </div>
            </div>
            <div className="columns-67 w-row">
              <div className="column-103 w-col w-col-6"><img src={headache} width="40" alt="infectedContact" />
                <h1 className="heading-88">Headache</h1>
              </div>
            
            </div>
          </div>
        </div>
        : null}



        <div className="section-4">
          <div className="container-3 w-container">
            <h1 className="heading-6">How Winston Works<br /></h1>
            <p className="paragraph-2">Our goal is to make healthcare accessible and medications affordable in 3 easy steps.<br /></p>
            <div className="w-row">
              <div className="column-51 w-col w-col-4">
                <div className="div-block-59"><img src={choose_product} alt="product" />
                  <h1 className="heading-94">1. Choose a product<br /></h1>
                  <p className="paragraph-12">Pick from our collection of safe and effective medication based on your symptoms and health needs.</p>
                </div>
              </div>
              <div className="column-51 w-col w-col-4">
                <div className="div-block-25"><img src={connect_doctor} alt="doctor" className="image-16" />
                  <h1 className="heading-55">2. Connect with a doctor<br /></h1>
                  <p className="paragraph-11">A licensed doctor will speak to you about your medical history and symptoms. If appropriate, the doctor will prescribe your medication.<br /></p>
                </div>
              </div>
              <div className="column-52 w-col w-col-4">
                <div className="div-block-26"><img src={take_control} alt="control" />
                  <h1 className="heading-55">3. Take control<br /></h1>
                  <p className="paragraph-9">You will discreetly receive your prescription right to your desired address. Follow the dosing instructions and take back control.<br /></p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="section-11">
          <div className="container-4 w-container">
            <h1 className="heading-53">With Winston’s Auto-Refill You Get:<br /></h1>
            <h1 className="heading-45 mob-hide">Let Winston be your wingman and he’ll always deliver!</h1>
            <div className="columns-25 w-row">
              <div className="column-48 w-col w-col-4">
                <div className="div-block-38"><img src={licenced_doctor} alt="doctor" className="image-18" />
                  <p className="paragraph-6">Custom treatment plan by licensed doctors</p>
                </div>
              </div>
              <div className="column-48 w-col w-col-4">
                <div className="div-block-38"><img src={refill} alt="refill" />
                  <h1 className="heading-44">Skip, pause or cancel your refills at any time</h1>
                </div>
              </div>
              <div className="column-49 w-col w-col-4">
                <div className="div-block-49"><img src={standard_shipping} alt="shipping" />
                  <h1 className="heading-44">Free standard shipping right to your door</h1>
                </div>
              </div>
            </div>
            <h1 className="heading-45 mob-show">Let Winston be your wingman and he’ll always deliver!</h1>
          </div>
        </div>


        {/* <div className="section-7">
          <div className="container-5 w-container">
            <h1 className="heading-39">Common FAQs about {pageProduct.name}<br /></h1>
            {pageProduct.frequentlyAskedQuestions.map((each) => (

              <><div className="div-block-46" aria-controls="example-collapse-text" key={each.id} onClick={() =>handleOpenClick()}>
                <h1 className="heading-34"><strong>{each.question}</strong><br /></h1><img src={arrow_icon} width="35" alt="" />
              </div>

                <Collapse in={open}>
                  <div id="example-collapse-text">
                    <p className="paragraph-2">{each.answer}<br /></p>
                  </div>
                </Collapse></>
            ))}
            <Link to="/faq"  className="button-17 w-button"><strong>See more FAQs</strong></Link>
          </div>
        </div> */}


        <div className="section-7">
          <div className="container-5 w-container">
            <h1 className="heading-39">Common FAQs about {pageProduct.name === 'Tretinoin 0.025%'?'Tretinoin':pageProduct.name}<br /></h1>

            {pageProduct.frequentlyAskedQuestions.map((each, i) => (
              <><Accordion>
                  <Accordion.Item eventKey={i.toString()} className="faqAccordion">
                    <Accordion.Header onClick={() => handleOpenClick(i)} className={isActive ? 'div-block-46 rotateImg p-0px' : 'div-block-46 p-0px'}>
                    <h1 className="heading-34"><strong>{each.question}</strong><br /></h1>
                    {open.some(item => i == Number(item)) ? <img src={arrow_iconr} width="35" alt="arrow-icon" /> : <img src={arrow_icon} width="35" alt="arrow-icon" />}

                  </Accordion.Header>

                  <Accordion.Body>
                    <div className="paragraph-2"><div dangerouslySetInnerHTML={{ __html: each.answer }} /></div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion></>
            ))}

            <Link to="/faq" className="button-17 w-button"><strong>See more FAQs</strong></Link>
          </div>
        </div>


        <div className="slider w-slider">
          <Carousel>
            <Carousel.Item interval={2000}>

              <Carousel.Caption>
                <div className="columns-65 w-row">
                  <div className="w-col w-col-2"></div>
                  <div className="column-14 w-col w-col-8">
                    <h1 className="heading-26">Amazing concept and super easy process. End to end!<br /></h1><img src={stars} alt="stars" className="image-14" />
                    <div className="text-block">- Winston member, on Google, November 18, 2020</div>
                  </div>
                  <div className="w-col w-col-2"></div>
                </div>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={4000}>
              <Carousel.Caption>
                <div className="w-row">
                  <div className="w-col w-col-2"></div>
                  <div className="column-16 w-col w-col-8">
                    <h1 className="heading-92">Dr. very friendly, answered all my concerns.<br /></h1><img src={stars} alt="stars" />
                    <div className="text-block-2">- Winston member, In Consultation, November 18, 2020</div>
                  </div>
                  <div className="w-col w-col-2"></div>
                </div>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={6000}>
              <Carousel.Caption>
                <div className="columns-2 w-row">
                  <div className="w-col w-col-2"></div>
                  <div className="column-16 w-col w-col-8">
                    <h1 className="heading-23">Works well and is more classy and discreet compared to the blue pill.<br /></h1><img src={stars} alt="stars" />
                    <div className="text-block-4">- Winston member, on Google, November 18, 2020</div>
                  </div>
                  <div className="w-col w-col-2"></div>
                </div>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={8000}>
              <Carousel.Caption>
                <div className="columns-2 w-row">
                  <div className="w-col w-col-2"></div>
                  <div className="column-16 w-col w-col-8">
                    <h1 className="heading-23">I’m so happy with the results, the doctor and the convenience!<br /></h1><img src={stars} alt="stars" />
                    <div className="text-block-4">- Winston member, On Google, November 18, 2020</div>
                  </div>
                  <div className="w-col w-col-2"></div>
                </div>
              </Carousel.Caption>
            </Carousel.Item>

          </Carousel>
        </div>

        {pageProduct.problemCategory == "ED" ?
          <div className="section-5">
            <div className="container-10 w-container">
              <h1 className="heading-27">Other Products Frequently Bought Together</h1>
              <div className="w-row">
                <div className="w-col w-col-6">
                <Link to="/hair-loss">
                  <div className="div-block-5"><img src={hairLoss} width="172" height="195" alt="hair-loss" className="image-6" />
                    <h1 className="heading-49">Hair Loss</h1>
                    <p>You shouldn&#x27;t have to lose hair with age. There is a remedy. Our medically safe and effective hereditary hair loss products are perfect for receding hairlines and thinning hair. Start your treatment now.<br /></p>
                  </div>
                  </Link>
                </div>
                <Link to="/acne">
                <div className="w-col w-col-6">
                  <div className="div-block-5"><img src={anceBottle} width="172" height="195" alt="acne-bottle" className="image-6" />
                    <h1 className="heading-49">Acne</h1>
                    <p>Having clear skin makes you more confident. Acne shouldn&#x27;t stand in the way of that. Get our tested and proven acne treatments prescribed to you by our skincare experts and tailored to your needs.</p>
                  </div>
                </div>
                </Link>
              </div>
            </div>
          </div>
          : null
        }
        {pageProduct.problemCategory == "HL" ?
          <div className="section-5">
            <div className="container-10 w-container">
              <h1 className="heading-27">Other Products Frequently Bought Together</h1>
              <div className="w-row">
              <Link to="/erectile-dysfunction">
                <div className="w-col w-col-6">
                  <div className="div-block-5"><img src={placeSvg} width="172" height="195" alt="erectile" className="image-6" />
                    <h1 className="heading-49">Erectile Dysfunction</h1>
                    <p>Boost your performance at any age. Erectile dysfunction should not hold you back. At Winston, we prescribe safe and effective ED treatments -Tadalafil and Sildenafil. Begin your treatment here.<br /></p>
                  </div>
                </div>
                </Link>
                <Link to="/acne">
                <div className="w-col w-col-6">
                  <div className="div-block-5"><img src={anceBottle} width="172" height="195" alt="acne" className="image-6" />
                    <h1 className="heading-49">Acne</h1>
                    <p>Having clear skin makes you more confident. Acne shouldn&#x27;t stand in the way of that. Get our tested and proven acne treatments prescribed to you by our skincare experts and tailored to your needs.</p>
                  </div>
                </div>
                </Link>
              </div>
            </div>
          </div>
          : null
        }
        {pageProduct.problemCategory == "AC" ?
          <div className="section-5">
            <div className="container-10 w-container">
              <h1 className="heading-27">Other Products Frequently Bought Together</h1>
              <div className="w-row">
              <Link to="/hair-loss">
                <div className="w-col w-col-6">
                  <div className="div-block-5"><img src={hairLoss} width="172" height="195" alt="hair-loss" className="image-6" />
                    <h1 className="heading-49">Hair Loss</h1>
                    <p>You shouldn&#x27;t have to lose hair with age. There is a remedy. Our medically safe and effective hereditary hair loss products are perfect for receding hairlines and thinning hair. Start your treatment now.<br /></p>
                  </div>
                </div>
                </Link>
                <Link to="/erectile-dysfunction">
                <div className="w-col w-col-6">
                  <div className="div-block-5"><img src={placeSvg} width="172" height="195" alt="erectile" className="image-6" />
                    <h1 className="heading-49">Erectile Dysfunction</h1>
                    <p>Boost your performance at any age. Erectile dysfunction should not hold you back. At Winston, we prescribe safe and effective ED treatments -Tadalafil and Sildenafil. Begin your treatment here.<br /></p>
                  </div>
                </div>
                </Link>
              </div>
            </div>
          </div>
          : null
        }

        {pageProduct.problemCategory == "CS" ?
          <div className="section-5">
            <div className="container-10 w-container">
              <h1 className="heading-27">Other Products Frequently Bought Together</h1>
              <div className="w-row">
              <Link to="/hair-loss">
                <div className="w-col w-col-6">
                  <div className="div-block-5"><img src={hairLoss} width="172" height="195" alt="hair-loss" className="image-6" />
                    <h1 className="heading-49">Hair Loss</h1>
                    <p>You shouldn&#x27;t have to lose hair with age. There is a remedy. Our medically safe and effective hereditary hair loss products are perfect for receding hairlines and thinning hair. Start your treatment now.<br /></p>
                  </div>
                </div>
                </Link>
                <Link to="/erectile-dysfunction">
                <div className="w-col w-col-6">
                  <div className="div-block-5"><img src={placeSvg} width="172" height="195" alt="ercetile" className="image-6" />
                    <h1 className="heading-49">Erectile Dysfunction</h1>
                    <p>Boost your performance at any age. Erectile dysfunction should not hold you back. At Winston, we prescribe safe and effective ED treatments -Tadalafil and Sildenafil. Begin your treatment here.<br /></p>
                  </div>
                </div>
                </Link>
              </div>
            </div>
          </div>
          : null
        }
          {pageProduct.problemCategory == "AL" ?
          <div className="section-5">
            <div className="container-10 w-container">
              <h1 className="heading-27">Other Products Frequently Bought Together</h1>
              <div className="w-row">
              <Link to="/acne">
                <div className="w-col w-col-6">
                  <div className="div-block-5"><img src={anceBottle} width="172" height="195" alt="acne" className="image-6" />
                    <h1 className="heading-49">Acne</h1>
                    <p>Having clear skin makes you more confident. Acne shouldn&#x27;t stand in the way of that. Get our tested and proven acne treatments prescribed to you by our skincare experts and tailored to your needs.</p>
                  </div>
                </div>
                </Link>
                <Link to="/erectile-dysfunction">
                <div className="w-col w-col-6">
                  <div className="div-block-5"><img src={placeSvg} width="172" height="195" alt="ercetile" className="image-6" />
                    <h1 className="heading-49">Erectile Dysfunction</h1>
                    <p>Boost your performance at any age. Erectile dysfunction should not hold you back. At Winston, we prescribe safe and effective ED treatments -Tadalafil and Sildenafil. Begin your treatment here.<br /></p>
                  </div>
                </div>
                </Link>
              </div>
            </div>
          </div>
          : null
        }

        <div className="section-12">
          <div className="container-11 w-container">
            <div className="columns-5 w-row">
              <div className="column-109 w-col w-col-9">
                <h1 className="heading-46">Not Sure What Product You Need?<br /></h1>
                <p className="paragraph">Book a free consultation call with a Canadian doctor to advise you!<br /></p>
                <Link to="/contact-us"
                  // target="_blank"
                  // rel="noopener noreferrer"
                  className="button-11"><strong>Let us help you</strong><br /></Link>
              </div>
              <div className="column-108 w-col w-col-3"><img src={doctorArt} width="212" alt="white-doctor" className="image-20" /></div>
            </div>
          </div>
        </div>

        {/* hl column */}
        {pageProduct.problemCategory == "HL" ?
          <div className="section-10">
            <div className="container-8 w-container">
              <h1 className="heading-52">Blog Articles<br /></h1>
              <div className="columns-66 w-row">
                <div className="column-71 w-col w-col-6">
                  <div className="div-block-60 backgroundFhl "></div>
                </div>
                <div className="column-52 w-col w-col-6">
                  <div className="div-block-17">
                    <h1 className="heading-48">Hair Loss 101<br /></h1>
                    <p className="paragraph-4">Hair loss is more common than you think. 85% of men will experience hair loss at some point in their lives. Together, male pattern baldness and Alopecia are the two most common hair loss conditions that men experience worldwide.<br /></p>
                    <div className="div-block-23">
                      <a href="https://learn.askwinston.ca/hair-loss-101/" className="button-13"><strong>Learn more</strong><br /></a>
                      <div className="text-block-6">5 min read</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null
        }
        {/* ed columns */}
        {pageProduct.problemCategory == "ED" ?
          <div className="section-10">
            <div className="container-8 w-container">
              <h1 className="heading-52">Blog Articles<br /></h1>
              <div className="columns-66 w-row">
                <div className="column-71 w-col w-col-6">
                  <div className="div-block-21 backgroundED" ></div>

                  <div className="div-block-17">
                    <h1 className="heading-48">ED 101<br /></h1>
                    <p className="paragraph-4">Sexual dysfunction can include erectile dysfunction, decreased libido and abnormal ejaculation. Specifically, erectile dysfunction is the difficulty of achieving or maintaining an erection to an extent that it affects sexual intercourse…<br /></p>
                    <div className="div-block-22">
                      <a href="https://learn.askwinston.ca/ed-101/" className="button-13 w-button"><strong>Learn more</strong><br /></a>
                      <div className="text-block-6">5 min read</div>
                    </div>
                  </div>
                </div>

                <div className="column-52 w-col w-col-6">
                  <div className="div-block-21 backgroundViagra" ></div>
                  <div className="div-block-17">
                    <h1 className="heading-48">Viagra vs. Cialis<br /></h1>
                    <p className="paragraph-4">At Winston, we want you to understand not only what ED treatments we carry but also how they work. This video will give you a basic overview of the differences between Sildenafil (the active ingredient in Viagra) and Tadalafil (the active ingredient in Cialis) to help you choose the best treatment…<br /></p>
                    <div className="div-block-23">
                      <a href="https://learn.askwinston.ca/viagra-vs-cialis/" className="button-13 w-button"><strong>Learn more</strong><br /></a>
                      <div className="text-block-6">5 min read</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null
        }
        {/* cs column */}
        {pageProduct.problemCategory == "CS" ?
          <div className="section-10">
            <div className="container-8 w-container">
              <h1 className="heading-52">Blog Articles<br /></h1>
              <div className="columns-66 w-row">
                <div className="column-71 w-col w-col-6">
                  <div className="div-block-60 backgroundFCD" ></div>
                </div>
                <div className="column-52 w-col w-col-6">
                  <div className="div-block-17">
                    <h1 className="heading-48">Cold Sores 101<br /></h1>
                    <p className="paragraph-4">Although there is no cure for the herpes virus, there are oral medications to help ease the symptoms as well as reduce the risk of spreading the virus. These treatments come under a group of medications known as antivirals. One of the most common antivirals used to treat herpes is Valacyclovir which is the generic form of Valtrex. <br /></p>
                    <div className="div-block-23">
                      <a href="https://learn.askwinston.ca/cold-sores-101/" className="button-13"><strong>Learn more</strong><br /></a>
                      <div className="text-block-6">5 min read</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null
        }
        {/* acne column */}
        {pageProduct.problemCategory == "AC" ?
          <div className="section-10">
            <div className="container-8 w-container">
              <h1 className="heading-52">Blog Articles<br /></h1>
              <div className="columns-66 w-row">
                <div className="column-71 w-col w-col-6">
                  <div className="div-block-60 backgroundFacne" ></div>
                </div>
                <div className="column-52 w-col w-col-6">
                  <div className="div-block-17">
                    <h1 className="heading-48">Acne 101<br /></h1>
                    <p className="paragraph-4">According to the Canadian Dermatology Association, acne affects 5.6 million Canadians, which is about 20% of the population.<br /><br />Acne Vulgaris, also known as Acne, is one of the most common conditions affecting young adults. It occurs when pores in the skin get clogged with dead skin cells, oil, and debris. <br /></p>
                    <div className="div-block-23">
                      <a href="https://learn.askwinston.ca/acne-101/" className="button-13"><strong>Learn more</strong><br /></a>
                      <div className="text-block-6">5 min read</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null
        }
        {/* allergy column */}
        {pageProduct.problemCategory == "AL" ?
          <div className="section-10">
            <div className="container-8 w-container">
              <h1 className="heading-52">Blog Articles<br /></h1>
              <div className="columns-66 w-row">
                <div className="column-71 w-col w-col-6">
                  <div className="div-block-60 backgroundAllergy" ></div>
                </div>
                <div className="column-52 w-col w-col-6">
                  <div className="div-block-17">
                  <h1 className="heading-48">Seasonal Allergies<br/></h1>
					       	<p className="paragraph-4">As soon as spring or fall is around the corner, many people start to dread the often unavoidable symptoms of allergies that accompany the season. Constant sneezing, a running nose, and itching that comes or worsens during a particular time of the year are known as seasonal allergies, hay fever, or allergic rhinitis. If you're part of the one in six Canadians with seasonal allergies, you already know how frustrating it can be to manage your symptoms.<br/></p>
					       	<div className="div-block-23">
                      <a href="https://learn.askwinston.ca/seasonal-allergies/" className="button-13"><strong>Learn more</strong><br /></a>
                      <div className="text-block-6">5 min read</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null
        }
      </div>

    </>
  );

}
export default Shop;


