import React, {useState, useEffect} from "react";

import {getPharmacistPendingOrders, getCouriers} from "../../http/pharmacist";

import Order from "../../model/Order";
import OrderStatus from "../../model/OrderStatus";

import DialogComponent, {DialogProps} from "../Dialog";
import ImageViewComponent from "../ImageView";
import LoaderComponent from "../Loader";
import PharmacistPendingOrderItem from "../PharmacistPendingOrdersItem";
import ApproveCheckRxOrderPopUp from "./components/ApproveCheckRxOrderPopUp";
import RejectOrderPopUp from "./components/RejectOrderPopUp";
import ApproveOrderPopUp from "./components/ApproveOrderPopUp";
import ShipOrderPopUp from "./components/ShipOrderPopUp";

import "./styles.scss";

const PharmacistPendingOrders = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [pendingOrders, setPendingOrders] = useState<Order[]>([]);
	const [dialog, setDialog] = useState<DialogProps | null>(null);
	const [imageViewProps, setImageViewProps] = useState<string | null>(null);
	const [imageType, setImageType] = useState<string>("");
	const [approveRxModal, setApproveRxModal] = useState<Order | null>(null);
	const [approveModal, setApproveModal] = useState<Order | null>(null);
	const [shipModal, setShipModal] = useState<Order | null>(null);
	const [rejectModal, setRejectModal] = useState<Order | null>(null);
	const [couriers, setCouriers] = useState<string[]>([]);

	useEffect(() => {
		const loadData = async () => {
			await loadOrders();
			await loadCouriers();
		};

		loadData();
	}, []);

	const renderOrders = () => {
		const pendingApprovalOrders = pendingOrders.filter(
			({status}) => status === OrderStatus.waitingPharmacist
		);
		const pendingShipmentOrders = pendingOrders.filter(
			({status}) => status === OrderStatus.packaging
		);
		const pendingRxCheckOrders = pendingOrders.filter(
			({status}) => status === OrderStatus.waitingRxCheck
		);

		return (
			<div>
				{!!pendingApprovalOrders.length && (
					<>
						<div className="top-title">
							<p>Pending Approval</p>
							<div className="horizontal-line" />
						</div>
						{pendingApprovalOrders.map((order) => (
							<PharmacistPendingOrderItem
								key={order.id}
								order={order}
								handleOpenViewID={handleOpenViewID}
								handleApproveShipping={() => setApproveModal(order)}
								handleShipped={() => setShipModal(order)}
								handleReject={() => setRejectModal(order)}
								isWaitingApprove
							/>
						))}
					</>
				)}
				{!!pendingRxCheckOrders.length && (
					<>
						<div className="top-title">
							<p>Pending Transferred Prescription</p>
							<div className="horizontal-line" />
						</div>
						{pendingRxCheckOrders.map((order) => (
							<PharmacistPendingOrderItem
								key={order.id}
								order={order}
								handleOpenViewID={handleOpenViewID}
								handleApproveShipping={() => setApproveRxModal(order)}
								handleShipped={() => setShipModal(order)}
								handleReject={() => setRejectModal(order)}
								isWaitingApprove
							/>
						))}
					</>
				)}
				{!!pendingShipmentOrders.length && (
					<>
						<div className="top-title">
							<p>Pending Shipment</p>
							<div className="horizontal-line" />
						</div>
						{pendingShipmentOrders.map((order) => (
							<PharmacistPendingOrderItem
								key={order.id}
								order={order}
								handleOpenViewID={handleOpenViewID}
								handleApproveShipping={() => setApproveModal(order)}
								handleShipped={() => setShipModal(order)}
								handleReject={() => setRejectModal(order)}
							/>
						))}
					</>
				)}
			</div>
		);
	};

	const loadOrders = async (): Promise<void> => {
		try {
			const result = await getPharmacistPendingOrders();
			const pendingOrders = result.data;

			setPendingOrders(pendingOrders);
		} catch (err) {
			console.log(err);
		}
	};

	const loadCouriers = async (): Promise<void> => {
		try {
			const result = await getCouriers();
			const couriers = result.data;

			setCouriers(couriers);
		} finally {
			setIsLoading(false);
		}
	};

	const handleOpenViewID = (type: string, imageView: string) => {
		setImageType(type);
		setImageViewProps(imageView);
	};

	const handleCloseViewID = (): void => {
		setImageType("");
		setImageViewProps(null);
	};

	const notify = (text: string) => {
		setDialog({
			text,
			buttons: {
				OK: {
					class: "dark",
					callback: () => {
						setDialog(null);
					}
				}
			}
		});
	};

	return (
		<div className="pharmacist-pending-orders-component">
			{isLoading && <LoaderComponent />}
			{!isLoading && pendingOrders.length === 0 && (
				<div className="no-orders">There are no pending orders.</div>
			)}
			{!isLoading && pendingOrders.length !== 0 && renderOrders()}
			{dialog && <DialogComponent {...dialog} />}
			{imageViewProps !== null && (
				<ImageViewComponent
					imageViewProps={imageViewProps}
					close={handleCloseViewID}
					title={`${imageType} image`}
				/>
			)}
			{approveModal && (
				<ApproveOrderPopUp
					order={approveModal}
					onClose={() => setApproveModal(null)}
					notify={notify}
					loadOrders={loadOrders}
				/>
			)}
			{approveRxModal && (
				<ApproveCheckRxOrderPopUp
					order={approveRxModal}
					onClose={() => setApproveRxModal(null)}
					notify={notify}
					loadOrders={loadOrders}
				/>
			)}
			{shipModal && (
				<ShipOrderPopUp
					order={shipModal}
					onClose={() => setShipModal(null)}
					notify={notify}
					loadOrders={loadOrders}
					couriers={couriers}
				/>
			)}
			{rejectModal && (
				<RejectOrderPopUp
					order={rejectModal}
					onClose={() => setRejectModal(null)}
					notify={notify}
					loadOrders={loadOrders}
				/>
			)}
		</div>
	);
};

export default PharmacistPendingOrders;
