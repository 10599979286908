import React from "react";
import {Link} from "react-router-dom";

import edStart from "../static/images/FactArticles/ed-start.png";
import edGoldbar from "../static/images/FactArticles/ed-goldbar.png";
import csStart from "../static/images/FactArticles/cs-start.png";
import csGoldbar from "../static/images/FactArticles/cs-goldbar.png";
import hlStart from "../static/images/FactArticles/hl-start.png";
import hlGoldbar from "../static/images/FactArticles/hl-goldbar.png";
import acneStart from "../static/images/FactArticles/acne-start.png";
import acneGoldbar from "../static/images/FactArticles/acne-goldbar.png";
import cialisDosagePoster from "../static/videos/cialisDosagePoster.jpg";
import consultExpPoster from "../static/videos/consultExpPoster.jpg";
import viagraVsCialisPoster from "../static/videos/viagraVsCialisPoster.jpg";
import { Title } from "react-meta-elements";


//"MH" was empty
export type FactsCategory = "ED" | "HL" | "AC" | "CS" | "DC";

export const FactsCategoriesList: FactsCategory[] = ["ED", "HL", "AC", "CS", "DC"];

export const ServedFactsCategories: FactsCategory[] = ["ED", "HL", "AC", "CS", "DC"];

export const FactsCategoryName = {
	ED: "Erectile Dysfunction",
	HL: "Hair Loss",
	AC: "Acne",
	CS: "Cold Sores",
	MH: "Mental Health",
	DC: "Doctor Consultation"
};

export const FactsCategoryCircles = [
	<div className="circles-bg">
		<div
			className="circle opacity-2"
			style={{
				width: "360px",
				height: "360px",
				bottom: "150px",
				left: "-140px"
			}}
		/>
		<div
			className="circle opacity-1"
			style={{
				width: "310px",
				height: "310px",
				bottom: "0px",
				left: "20px"
			}}
		/>
	</div>,
	<div className="circles-bg">
		<div
			className="circle opacity-2"
			style={{
				width: "170px",
				height: "170px",
				top: "-40px",
				left: "-30px"
			}}
		/>
		<div
			className="circle opacity-1"
			style={{
				width: "270px",
				height: "270px",
				top: "-30px",
				left: "112px"
			}}
		/>
		<div
			className="circle opacity-2"
			style={{
				width: "150px",
				height: "150px",
				bottom: "0px",
				left: "220px"
			}}
		/>
	</div>,
	<div className="circles-bg">
		<div
			className="circle opacity-2"
			style={{
				width: "230px",
				height: "230px",
				top: "-25px",
				left: "120px"
			}}
		/>
		<div
			className="circle opacity-1"
			style={{
				width: "300px",
				height: "300px",
				bottom: "-50px",
				left: "-45px"
			}}
		/>
	</div>
];

export const FactsCategoryShortName = {
	ED: "ed",
	HL: "hl",
	AC: "ac",
	CS: "cs",
	MH: "mh",
	DC: "dc"
};

export const FactsCategoryArticles = {
	ED: [
		{title: "ED 101", description: "Get the basics on erectile dysfunction.", link: "ed-101"},
		{
			title: "Viagra vs Cialis",
			description: "Understand which one is right for you.",
			link: "viagra-vs-cialis"
		},
		{
			title: "Cialis 5mg vs 20mg",
			description: "Which is the right dose for your needs?",
			link: "cialis-dosage"
		}
	],
	HL: [
		{
			title: "Hair Loss 101",
			description: "Educate yourself on hair loss and how to treat it.",
			link: "hair-loss-101"
		}
	],
	AC: [
		{
			title: "Acne 101",
			description: "Get schooled about acne and its treatments.",
			link: "acne-101"
		}
	],
	CS: [
		{
			title: "Cold Sores 101",
			description: "Understand more about cold sores and how to fight the herpes virus.",
			link: "cold-sores-101"
		}
	],
	DC: [
		{
			title: "What to Expect During Your Consultation",
			description: "Find out how it works before you start your consultation.",
			link: "consultation-expectations"
		}
	]
};

export const ArticleHtmlPages = [
	{
		link: "ed-101",
		html: (
			<>
			<Title>Erectile Dysfunction(ED) med |Winston</Title>
 
			<div className="page-101 prefiles">
				<div className="start">
					<div className="left-part">
						<div className="title">
							<h1>ED 101</h1>
							<div className="horizontal-line"></div>
						</div>
						<div className="content">
							<p>
								Did you know that erectile dysfunction is the most common sexual condition affecting
								men?
							</p>
							<p className="padding-top answer">
								Worldwide it affects more than 30 million men. Fortunately, through advances in
								science and technology it has become better understood and as a result more
								effectively treated.
							</p>
							<p className="padding-top">
								Sexual dysfunction can include erectile dysfunction, decreased libido and abnormal
								ejaculation. Specifically, erectile dysfunction is the difficulty of achieving or
								maintaining an erection to an extent that it affects sexual intercourse.
							</p>
							<p className="padding-top">
								Occasional ED is very common. However, it is important to have general health
								assessments completed on a regular basis to ensure the symptoms are not secondary to
								further underlying health issues.
							</p>
						</div>
					</div>
					<div className="right-part">
						<img src={edStart} alt="" />
					</div>
				</div>
				<div className="limit-line-wrapper">
					<div className="limit-line"></div>
				</div>
				<div className="causes">
					<div className="causes-title">
						<p>
							We know more about the causes of erectile dysfunction than ever before.
							<br />
							The most common risk factors include:
						</p>
					</div>
					<div className="list">
						<div className="box">
							<ul>
								<li>
									<div className="dot"></div>
									<p>diabetes</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>chronic kidney disease</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>cardiovascular disease</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>side effects of medications</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>psychosocial factors</p>
								</li>
							</ul>
						</div>
						<div className="box">
							<ul>
								<li>
									<div className="dot"></div>
									<p>stress</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>recreational drug use</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>smoking</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>ejaculatory disorders</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>physical trauma</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="stress-image-wrapper">
					<div className="stress-image ed">
						<div className="image">
							<img className="narrow" src={edGoldbar} alt="" />
						</div>
						<div className="stress-image-content">
							<p>
								Stress has become one of the leading causes of ED, especially amongst men between
								the ages of 25 and 40.
							</p>
							<p>Winston has the right products to help your ED.</p>
							<div className="buttons">
								<Link to="/shop">
									<button>What We Treat</button>
								</Link>
								<Link to="/contact-us">
									<button>Talk to a Doctor</button>
								</Link>
							</div>{" "}
						</div>
					</div>
				</div>
				<div className="end">
					<div className="top-text">
						<p>
							Although medications may not directly help with stress it can assist with improving
							sexual intimacy and confidence while continuing to work towards overall stress
							management and self improvement.
						</p>
						<p className="padding-top">
							When treating erectile dysfunction it is important to address the underlying cause. A
							combination of different treatment options will likely be the most successful. For
							example, stress as a cause for ED will likely be best treated with a combination of
							lifestyle changes, therapy and medication use.
						</p>
					</div>
					<div className="bottom-text">
						<p>
							Fortunately, there are medications to assist with ED. PDE5 inhibitors are a class of
							medications that help increase blood flow to the penis. The most common medications
							belonging to this class are Sildenafil (commonly known as Viagra) and Tadalafil
							(commonly known as Cialis).
						</p>
					</div>
				</div>
			</div>
			</>
		)
	},
	{
		link: "cold-sores-101",
		html: (
			<>
				<Title>Cold Sores meds  |Winston</Title>
			<div className="page-101 prefiles">
				<div className="start">
					<div className="left-part">
						<div className="title">
							<h1>Cold Sores 101</h1>
							<div className="horizontal-line"></div>
						</div>
						<div className="content">
							<p>
								Oral herpes, also known as cold sores, are caused by a viral infection and are
								highly contagious.
							</p>
							<p className="padding-top">
								Once infected by the herpes virus it typically follows{" "}
								<span className="bold">3 stages</span>:
							</p>
							<p className="padding-top">
								During the <span className="bold">initial phase</span>, often referred to as the
								primary infection, a person contracts the virus through contact with infected skin,
								saliva or mucous membranes. Most people may develop symptoms including sores in and
								around the mouth and lips. Other symptoms with an initial infection may include
								fever, muscle aches and pain, and mild cold or flu symptoms. Some people may remain
								asymptomatic and they may not know that they have been infected by the virus.
							</p>
							<p className="padding-top">
								The <span className="bold">second phase</span>, often referred to as the latency
								phase, is a period where the virus travels from the infection site to a collection
								of nerve cells located near the spinal cord where it will stay dormant until
								reactivation.
							</p>
							<p className="padding-top">
								During the <span className="bold">third phase</span>, known as recurrence, the virus
								will reactivate as a result of certain stressors: emotional, physical, illness. This
								will result in the development of a new outbreak of blisters and symptoms, likely
								appearing at the initial site of infection.
							</p>
						</div>
					</div>
					<div className="right-part">
						<img src={csStart} alt="" />
					</div>
				</div>
				<div className="limit-line-wrapper">
					<div className="limit-line"></div>
				</div>
				<div className="causes">
					<div className="causes-title">
						<p>Some of the causes and risk factors of cold sores include:</p>
					</div>
					<div className="list">
						<div className="box">
							<ul>
								<li>
									<div className="dot"></div>
									<p>
										Contact with infected saliva,
										<br /> mucous membranes, skin
									</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Unprotected sex</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Kissing</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Prolonged skin to skin contact</p>
								</li>
							</ul>
						</div>
						<div className="box">
							<ul>
								<li>
									<div className="dot"></div>
									<p>Sharing items like razors</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Immunosuppression</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>
										Certain medications i.e. drugs that <br />
										can suppress your immune system
									</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>HIV</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="causes">
					<div className="causes-title">
						<p>Some of the causes and risk factors for reactivating the herpes virus include:</p>
					</div>
					<div className="list">
						<div className="box">
							<ul>
								<li>
									<div className="dot"></div>
									<p>Stress (emotionally or physically)</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Increased sun or UV exposure</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Exposure to extreme heat or cold</p>
								</li>
							</ul>
						</div>
						<div className="box">
							<ul>
								<li>
									<div className="dot"></div>
									<p>Infection or an illness</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Hormonal changes</p>
								</li>
								<li>
									<div className="dot"></div>
									<p> Fatigue i.e. lack of sleep</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="stress-image-wrapper">
					<div className="stress-image cs">
						<div className="image">
							<img className="narrow" src={csGoldbar} alt="" />
						</div>
						<div className="stress-image-content">
							<p>
								If you are dealing with recurring outbreaks of cold sores it is important to start
								treatment as soon as you feel the tell-tale signs of an outbreak such as itching or
								burning in the area typically affected.
							</p>
							<p>Winston has the right products to help you with your cold sores.</p>
							<div className="buttons">
								<Link to="/shop">
									<button>What We Treat</button>
								</Link>
								<Link to="/contact-us">
									<button>Talk to a Doctor</button>
								</Link>
							</div>{" "}
						</div>
					</div>
				</div>
				<div className="end">
					<div className="bottom-text">
						<p>
							Although there is no cure for the herpes virus, there are oral medications to help
							ease the symptoms as well as reduce the risk of spreading the virus. These treatments
							come under a group of medications known as antivirals. One of the most common
							antivirals used to treat herpes is Valacyclovir which is the generic form of Valtrex.
							A topical treatment that is also available for those who are either unable to take the
							oral medication or prefer a topical agent is Acyclovir cream or ointment which is the
							generic form of Zovirax. In addition to the medications, you can reduce the rate of
							transmission or recurrences by limiting the risk factors and exposure to the many
							causes.
						</p>
					</div>
				</div>
			</div>
			</>
		)
	},
	{
		link: "hair-loss-101",
		html: (
			<>
				<Title>Hair loss in men  |Winston</Title>
			<div className="page-101 prefiles">
				<div className="start">
					<div className="left-part">
						<div className="title">
							<h1>Hair Loss 101</h1>
							<div className="horizontal-line"></div>
						</div>
						<div className="content">
							<p>
								Hair loss is more common than you think. 85% of men will experience hair loss at
								some point in their lives. Together, male pattern baldness and Alopecia are the two
								most common hair loss conditions that men experience worldwide.
							</p>
							<p className="padding-top">
								Male pattern baldness has a more predictable pattern, it tends to be gradual and
								often starts with a receding frontal hairline with thinner strands around the crown
								of the head. Over time, the hair grows back finer, thinner and shorter. Eventually,
								the hair follicles will shrink resulting in no further hair growth. This condition
								can affect men as early as their teen years.
							</p>
							<p className="padding-top">
								Alopecia areata, also known as spot baldness, is an autoimmune condition. Typically,
								it will lead to “patches” of hair loss and will often grow back slowly.
							</p>
						</div>
					</div>
					<div className="right-part">
						<img src={hlStart} alt="" />
					</div>
				</div>
				<div className="limit-line-wrapper">
					<div className="limit-line"></div>
				</div>
				<div className="causes">
					<div className="causes-title">
						<p>Some of the most common reasons for hair loss include:</p>
					</div>
					<div className="list">
						<div className="box">
							<ul>
								<li>
									<div className="dot"></div>
									<p>Male pattern baldness</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Alopecia areata</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Chronic medical conditions such as thyroid disease or anemia</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Medications</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Scalp Infections</p>
								</li>
							</ul>
						</div>
						<div className="box">
							<ul>
								<li>
									<div className="dot"></div>
									<p>Radiation or chemotherapy treatment</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Stress</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Genetics</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Hormonal changes</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Diet</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Certain types of hairstyles</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="stress-image-wrapper">
					<div className="stress-image hl">
						<div className="image">
							<img className="narrow" src={hlGoldbar} alt="" />
						</div>
						<div className="stress-image-content">
							<p>
								Treating hair loss has been shown to increase new hair growth, slow thinning of
								existing hair and thicken the hair shaft so your hair grows back thicker.
							</p>
							<p>Winston has the right products to help you with your hair loss.</p>
							<div className="buttons">
								<Link to="/shop">
									<button>What We Treat</button>
								</Link>
								<Link to="/contact-us">
									<button>Talk to a Doctor</button>
								</Link>
							</div>{" "}
						</div>
					</div>
				</div>
				<div className="end">
					<div className="bottom-text">
						<p>
							Fortunately, there are several medications and treatments available that can help
							address hair loss. Two of the most common treatment options include topical agents
							such as Minoxidil (the active ingredient in Rogaine) and oral medications such as
							Finasteride (the active ingredient in Propecia).
						</p>
					</div>
				</div>
			</div>
			</>
		)
	},
	{
		link: "acne-101",
		html: (
			<>
				<Title>Acne treatment  |Winston</Title>
			<div className="page-101 prefiles">
				<div className="start">
					<div className="left-part">
						<div className="title">
							<h1>Acne 101</h1>
							<div className="horizontal-line"></div>
						</div>
						<div className="content">
							<p>
								According to the Canadian Dermatology Association, acne affects 5.6 million
								Canadians, which is about 20% of the population.
							</p>
							<p className="padding-top">
								Acne Vulgaris, also known as Acne, is one of the most common conditions affecting
								young adults. It occurs when pores in the skin get clogged with dead skin cells,
								oil, and debris. This can often lead to the formation of pimples, blackheads,
								whiteheads and can even lead to scarring. Beyond the physical symptoms, acne can
								lead to lower self-esteem, increased anxiety, and overall damage to your self-image.
							</p>
						</div>
					</div>
					<div className="right-part">
						<img src={acneStart} alt="" />
					</div>
				</div>
				<div className="limit-line-wrapper">
					<div className="limit-line"></div>
				</div>
				<div className="causes">
					<div className="causes-title">
						<p>Some of the causes and/or risk factors for acne can often surprise people.</p>
						<p>The most common risk factors include:</p>
					</div>
					<div className="list">
						<div className="box">
							<ul>
								<li>
									<div className="dot"></div>
									<p>Excess oil production</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Bacteria that lives on your skin</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Clogged pores</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Stress</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Diet</p>
								</li>
							</ul>
						</div>
						<div className="box">
							<ul>
								<li>
									<div className="dot"></div>
									<p>Hormones</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Cosmetics</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Medications</p>
								</li>
								<li>
									<div className="dot"></div>
									<p>Excessive sweating</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="stress-image-wrapper">
					<div className="stress-image acne">
						<div className="image">
							<img className="narrow" src={acneGoldbar} alt="" />
						</div>
						<div className="stress-image-content">
							<p>
								Believe it or not, eating greasy food, tanning or increased sun exposure, and poor
								personal hygiene have very little effect on acne.
							</p>
							<p>Winston has the right products to help you with your acne.</p>
							<div className="buttons">
								<Link to="/shop">
									<button>What We Treat</button>
								</Link>
								<Link to="/contact-us">
									<button>Talk to a Doctor</button>
								</Link>
							</div>{" "}
						</div>
					</div>
				</div>
				<div className="end">
					<div className="bottom-text">
						<p>
							Luckily, there are many methods to help you control acne, avoid new scarring/skin
							damage, and minimize the appearance of existing scars.
						</p>
						<p className="padding-top">
							There are a variety of topical creams either over the counter or prescribed that can
							help control acne. Some of these include retinoids or retinoid-type drugs, salicylic
							acid-based products and antibiotics. The use of these products can depend on several
							factors, including the stage of your acne i.e. mild, moderate or severe.
						</p>
					</div>
				</div>
			</div>
			</>
		)
	},
	{
		link: "cialis-dosage",
		html: (
			<div className="page-video prefiles">
				<div className="title"><h1>Cialis 5mg vs Cialis 20mg</h1></div>
				<div className="video-block">
					<div className="description">
						<p>
							What dose will work best for me? This is a common question we hear at Winston. The
							answer depends on how you use the medication. This video will provide you with more
							information on the different Tadalafil (the active ingredient in Cialis) dose options
							available so that you can make an informed decision.{" "}
						</p>
						<p>
							However, if you still need help you can connect with one of our doctors through a free
							consultation at any time. Our doctors will ensure you are prescribed the correct dose
							to match your needs and lifestyle.
						</p>
					</div>
					<video poster={cialisDosagePoster} controls>
						<source src="https://storage.googleapis.com/askwinston-static/cialisDosage.mp4" />
					</video>
				</div>
				<div className="stress-image-wrapper">
					<div className="stress-image-content">
						<p>Winston has the right products to help you.</p>
						<div className="buttons">
							<Link to="/shop">
								<button>What We Treat</button>
							</Link>
							<Link to="/contact-us">
								<button>Talk to a Doctor</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		)
	},
	{
		link: "consultation-expectations",
		html: (
			<div className="page-video prefiles">
				<div className="title"><h1>What to Expect During a Doctor Consultation</h1></div>
				<div className="video-block">
					<div className="description">
						<p>
							We want you to have an exceptional experience. To ensure that you are getting the best
							treatment available and the support you need to succeed, we provide you with a free
							online doctor consultation. This video will walk you through what happens during a
							consultation. Our goal is to make sure you have all of the information you need to
							make the best decisions for your health.
						</p>
					</div>
					<video poster={consultExpPoster} controls>
						<source src="https://storage.googleapis.com/askwinston-static/consultExp.mp4" />
					</video>
				</div>
				<div className="stress-image-wrapper">
					<div className="stress-image-content">
						<p>Winston has the right products to help you.</p>
						<div className="buttons">
							<Link to="/shop">
								<button>What We Treat</button>
							</Link>
							<Link to="/contact-us">
								<button>Talk to a Doctor</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		)
	},
	{
		link: "viagra-vs-cialis",
		html: (
			<div className="page-video prefiles">
				<div className="title"><h1>Viagra vs Cialis</h1></div>
				<div className="video-block">
					<div className="description">
						<p>
							At Winston, we want you to understand not only what ED treatments we carry but also
							how they work. This video will give you a basic overview of the differences between
							Sildenafil (the active ingredient in Viagra) and Tadalafil (the active ingredient in
							Cialis) to help you choose the best treatment.
						</p>
						<p>
							If you still aren't sure after watching this, that's ok too. You can schedule a
							consultation with one of our doctors at any time and they can answer your questions
							and provide guidance based on your symptoms and lifestyle.
						</p>
					</div>
					<video poster={viagraVsCialisPoster} controls>
						<source src="https://storage.googleapis.com/askwinston-static/viagraVsCialis.mp4" />
					</video>
				</div>
				<div className="stress-image-wrapper">
					<div className="stress-image-content">
						<p>Winston has the right products to help you.</p>
						<div className="buttons">
							<Link to="/shop">
								<button>What We Treat</button>
							</Link>
							<Link to="/contact-us">
								<button>Talk to a Doctor</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		)
	}
];
