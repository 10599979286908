import moment from "moment-timezone";

/*
 * All communication with server is in UTC timezone in "2000-01-01T00:00:00Z" format
 */

export default class DateService {
	private static patientTimezone = "America/Toronto";
	private static doctorTimezone = "America/Toronto";
	// private static pharmacistTimezone = "America/Toronto";
	// private static adminTimezone = "America/Toronto";

	private static serverDateFormat = "YYYY-MM-DD";

	private static dateFormat = "MMM D, YYYY";
	private static timeFormat = "h:mm A";
	private static dateTimeFormat = "MMM D, YYYY h:mm A";
	private static doctorsDayHeaderFormat = "ddd. | MMM.DD";

	public static addDays(date: Date, daysCount: number): Date {
		return moment(date).add(daysCount, "days").toDate();
	}

	public static addYears(date: Date, yearsCount: number): Date {
		return moment(date).add(yearsCount, "years").toDate();
	}

	public static areDaysEqual(date1: Date, date2: Date): boolean {
		return moment(date1).format("YYYY-MM-DD") === moment(date2).format("YYYY-MM-DD");
	}

	public static getDoctorsTodayMidnight(): Date {
		return moment()
			.hour(0)
			.minute(0)
			.second(0)
			.millisecond(0)
			.add(moment().utcOffset() - moment().tz(this.doctorTimezone).utcOffset(), "minutes")
			.toDate();
	}

	public static formatDoctorsDayHeader(date: Date): string {
		return moment(date).format(this.doctorsDayHeaderFormat);
	}

	public static formatDoctorsTime(dateTime: string): string {
		return moment(dateTime).tz(this.doctorTimezone).format(this.timeFormat);
	}

	public static formatPatientsDateTime(dateTime: string): string {
		return moment(dateTime).tz(this.patientTimezone).format(this.dateTimeFormat);
	}

	public static formatDoctorsDateTime(dateTime: string): string {
		return moment(dateTime).tz(this.doctorTimezone).format(this.dateTimeFormat);
	}

	public static formatDoctorsDate(dateTime: string): string {
		return moment(dateTime).tz(this.doctorTimezone).format(this.dateFormat);
	}

	public static parseDoctorsDate(date: string): Date {
		return moment(date).tz(this.doctorTimezone).toDate();
	}

	// Does moment apply timezone to input date?
	public static getPatientsNow(): Date {
		return moment().tz(this.patientTimezone).toDate();
	}

	public static escapePatientsDatePickerInput(date: string | Date): Date {
		return moment(date)
			.subtract(moment().utcOffset() - moment().tz(this.patientTimezone).utcOffset(), "minutes")
			.toDate();
	}

	public static escapePatientsDatePickerOutputForServer(date: Date): string {
		return moment(date)
			.add(moment().utcOffset() - moment().tz(this.patientTimezone).utcOffset(), "minutes")
			.utc()
			.format();
	}

	public static formatLocalDateForServer(dateTime: Date): string {
		return moment(dateTime).format(this.serverDateFormat);
	}

	public static formatBirthday(birthday: string): string {
		return moment(birthday).format(this.dateFormat);
	}

	public static parseLocalDateFromServer(date: string): Date {
		if (date) {
			return moment(date).toDate();
		}

		return moment().toDate();
	}
}
