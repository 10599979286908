import http from "../index";

import ShippingAddress from "../../model/ShippingAddress";
import Profile from "../../model/Profile";
import BillingCard from "../../model/BillingCard";

type Option = {
	label: string;
	value: string;
};

export const addShippingAddress = (address: ShippingAddress) =>
	http.post("/patient/shipping-address", address);

export const addBillingCard = (card: BillingCard) => http.post("/patient/billing-card", card);

export const deleteShippingAddress = (id: number | string) =>
	http.delete("/patient/shipping-address/" + id);

export const setPrimaryShippingAddress = (id: number | string) =>
	http.put("/patient/shipping-address/primary/" + id);

export const setPrimaryBillingCard = (id: number | string) =>
	http.put("/patient/billing-card/primary/" + id);

export const deleteBillingCard = (id: number | string) =>
	http.delete("/patient/billing-card/" + id);

export const verifyPromoCode = (code: string) => http.get("/promo-code/" + code);

export const updateProfile = (profile: Profile) => http.put("/patient/profile", profile);

export const changePassword = (passwordsObj: {oldPassword: string; newPassword: string}) =>
	http.put("/user/change-password", passwordsObj);

export const uploadId = (formData: FormData) => http.put("/patient/upload/id", formData);

export const uploadInsurance = (formData: FormData) =>
	http.put("/patient/upload/insurance", formData);

export const uploadDocument = (formData: FormData) => http.post("/document", formData);

export const getPatientSubscriptions = () => http.get("/subscription");

export const getOrderReceipt = (orderId: number) =>
	http.get(`/order/${orderId}/prescription`, {responseType: "blob"});

export const getTransferRxProgress = () => http.get("/rx-transfer");

export const deleteTransferRxProgress = () => http.delete("/rx-transfer");

export const setTransferRxProgress = (progressObj: {
	step: number;
	productCategory?: Option;
	product?: Option;
	quantity?: Option;
	rxNumber?: string;
	pharmacyName?: string;
	pharmacyPhone?: string;
	bottleImageId?: string;
}) => http.put("/rx-transfer", progressObj);
