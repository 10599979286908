import React, {Component} from "react";
import "./styles.scss";

/* Copyright: http://ajaxloadingimages.net/svg */

interface LoaderProps {
	showShadow?: boolean;
	isFixed?: boolean;
}

export default class LoaderComponent extends Component<LoaderProps, {}> {
	public render() {
		return (
			<div
				className={
					"loader" +
					(this.props.showShadow ? " shadowed" : "") +
					(this.props.isFixed ? " fixed" : "")
				}
			></div>
		);
	}
}
