import React, { ChangeEvent, useEffect, useState, useMemo } from "react";
import "./style.scss";
import Schedule, { scheduleEvent } from "../Schedule";
import { createDoctorSchedule, deleteDoctorSlot, getFreeDoctorSlots } from "../../http/schedule";
import CustomCheckbox from "../CustomCheckbox";
import LoaderComponent from "../Loader";
import { DateRange, extendMoment } from "moment-range";
import Moment from "moment";
import { doctorScheduleEvent } from "../DoctorListSchedule";
// @ts-ignore
const moment = extendMoment(Moment);

const DoctorSchedule = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isRepeated, setIsRepeated] = useState<boolean>(false);
	const [events, setEvents] = useState<scheduleEvent[]>([]);
	const [newEvents, setNewEvents] = useState<scheduleEvent[]>([]);
	const [viewRange, setViewRange] = useState<Date[]>([moment().startOf("week").toDate(), moment().endOf("week").toDate()]);
	const dateRange = useMemo(() => {
		const start = moment().startOf("week");
		const end = moment(moment(start).add("1", "month")).endOf("week");
		const range: DateRange = moment().range(start, end);

		return Array.from(range.by("day")).map((m: Moment.Moment) => m.format("DD/MM/YYYY"));
	}, []);

	useEffect(() => {
		loadEvents();
	}, []);

	const parseData = (data: doctorScheduleEvent[]) => {
		return (data).reduce((arr, item) => {
			if (Object.values(item.schedule).length) {
				const newItem = Object.keys(item.schedule).map((time) => ({
					start: new Date(moment(`${item.date} ${time}`).format()),
					end: new Date(moment(`${item.date} ${time}`).add(15, "minutes").format()),
					title: "",
					id: item.schedule[time][0].id
				}));
				return [...arr, ...newItem];
			}
			return arr;
		}, [] as scheduleEvent[]);
	};

	const loadEvents = async () => {
		try {
			const { data } = await getFreeDoctorSlots(dateRange);
			setEvents(parseData(data));
		} finally {
			setIsLoading(false);
		}
	};

	const handleSave = async () => {

		let eventsToCreate: any = [];

		if (!!newEvents.length) {
			eventsToCreate = newEvents.map(({ start, end }) => ({
				start: moment(start).format("YYYY-MM-DD HH:mm:ss"),
				end: moment(end).format("YYYY-MM-DD HH:mm:ss")
			}))
		}

		if (isRepeated) {
			const startDate = viewRange[0];
			const endDate = moment(viewRange[viewRange.length - 1]).endOf("day").toDate();
			const repeatOldEvents = events
				.filter(e => (e.start > startDate && e.end < endDate))
				.map(e => (
					{ 
						start:moment(e.start).format("YYYY-MM-DD HH:mm:ss"), 
						end: moment(e.end).format("YYYY-MM-DD HH:mm:ss") 
					}
				));

			eventsToCreate.push(...repeatOldEvents);
		}

		if (!!eventsToCreate.length) {
			setIsLoading(true);

			await createDoctorSchedule({
				events: eventsToCreate,
				recurring: isRepeated
			}).then(() => loadEvents()).finally(() => setIsLoading(false));
		};
	}

	const handleDeleteSlot = async (id: number) => {
		setIsLoading(true);
		await deleteDoctorSlot([id])
			.then(() => setEvents(events.filter(event => event.id !== id)))
			.finally(() => setIsLoading(false));
	};

	return (
		<div className="doctorSchedule prefiles">
			{isLoading ? <LoaderComponent /> : <Schedule data={events} onChange={setNewEvents} onDelete={handleDeleteSlot} setViewRange={setViewRange} />}
			<div className="doctorSchedule__actions">
				<div className="doctorSchedule__checkbox">
					<CustomCheckbox
						checked={isRepeated}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							setIsRepeated(e.target.checked)
						}
					/>
					<span>Repeat every week</span>
				</div>
				<button disabled={isLoading} className="doctorSchedule__submit" onClick={handleSave}>
					Save
				</button>
			</div>
		</div>
	);
};

export default DoctorSchedule;