import React, {useState, useEffect} from "react";

import {getRegisteredPendingUsers, getConnectedPendingUsers} from "../../../../http/admin";

import DateService from "../../../../service/Date.service";

import "./styles.scss";
import Profile from "../../../../model/Profile";

const PendingUsers = () => {
	const [pendingRegisteredUsers, setPendingRegisteredUsers] = useState<Profile[]>([]);
	const [pendingConnectedUsers, setPendingConnectedUsers] = useState<
		{name: string; email: string}[]
	>([]);

	useEffect(() => {
		const loadPendingUsers = async () => {
			const resultRegistered = await getRegisteredPendingUsers();
			const resultConnected = await getConnectedPendingUsers();

			setPendingRegisteredUsers(resultRegistered.data);
			setPendingConnectedUsers(resultConnected.data);
		};

		loadPendingUsers();
	}, []);

	return (
		<div className="pending-users-admin prefiles">
			{pendingRegisteredUsers.length === 0 && pendingConnectedUsers.length === 0 ? (
				<div className="user-row">There are no pending users.</div>
			) : (
				<>
					{pendingRegisteredUsers.map((user) => {
						return (
							<div key={user.id}>
								<div className="user-row">
									<div className="content">
										{user && (
											<div className="row1">
												Name:{" "}
												<div className="patient-name">{user.firstName + " " + user.lastName}</div>
												Birthday:{" "}
												<div className="patient-birthday">
													{DateService.formatBirthday(user.birthday)}
												</div>
												Phone: <div className="patient-phone">{user.phone}</div>
												Email: <div className="patient-email">{user.email}</div>
											</div>
										)}
									</div>
								</div>
							</div>
						);
					})}
					{pendingConnectedUsers.map((user) => {
						return (
							<div key={user.email}>
								<div className="user-row">
									<div className="content">
										{user && (
											<div className="row1">
												Name: <div className="patient-name">{user.name}</div>
												Email: <div className="patient-email">{user.email}</div>
											</div>
										)}
									</div>
								</div>
							</div>
						);
					})}
				</>
			)}
		</div>
	);
};

export default PendingUsers;
