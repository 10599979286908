import GeneralConst from "../const/General.const";

export default class HttpService {
	public static get(endpoint: string): Promise<Response> {
		const headers = HttpService.getAuthorizationHeader();
		return fetch((GeneralConst.apiBaseUrl ? GeneralConst.apiBaseUrl : "") + endpoint, {
			method: "GET",
			headers: {
				...headers
			}
		});
	}

	public static post(endpoint: string, body: string): Promise<Response> {
		const headers = HttpService.getAuthorizationHeader();
		return fetch((GeneralConst.apiBaseUrl ? GeneralConst.apiBaseUrl : "") + endpoint, {
			method: "POST",
			body,
			headers: {
				"Content-Type": "application/json",
				...headers
			}
		});
	}

	public static put(endpoint: string, body: string): Promise<Response> {
		const headers = HttpService.getAuthorizationHeader();
		return fetch((GeneralConst.apiBaseUrl ? GeneralConst.apiBaseUrl : "") + endpoint, {
			method: "PUT",
			body,
			headers: {
				"Content-Type": "application/json",
				...headers
			}
		});
	}

	public static delete(endpoint: string): Promise<Response> {
		const headers = HttpService.getAuthorizationHeader();
		return fetch((GeneralConst.apiBaseUrl ? GeneralConst.apiBaseUrl : "") + endpoint, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				...headers
			}
		});
	}

	private static getAuthorizationHeader() {
		try {
			const token = localStorage.getItem("token");
			const header: {[key: string]: string} = {};
			if (token) {
				header.Authorization = "Bearer " + token;
			}
			return header;
		} catch (e) {
			console.log(e);
			alert(
				"Failed to read the 'localStorage' property: " +
				"Access is denied for this document. " +
				"Please, Unblock third-party cookies and site data in your browser configuration"
			);
		}

		return {};
	}
}
