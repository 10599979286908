import http from "../index";

interface UserInfo {
	name: string;
	email: string;
	phone: string;
	message: string;
}

export const sendContactMessage = (creds: UserInfo) => http.post("/contact-us-record", creds);
