export type Province =
	| "AB"
	| "BC"
	| "MB"
	| "NB"
	| "NL"
	| "NS"
	| "NT"
	| "NU"
	| "ON"
	| "PE"
	| "QC"
	| "SK"
	| "YT";

export const ProvinceList: Province[] = [
	"AB",
	"BC",
	"MB",
	"NB",
	"NL",
	"NS",
	"NT",
	"NU",
	"ON",
	"PE",
	"QC",
	"SK",
	"YT"
];
export const ServedProvinceList: Province[] = ["ON", "BC", "NL", "NU", "PE", "NS", "QC"];

export const ProvinceName = {
	AB: "Alberta",
	BC: "British Columbia",
	MB: "Manitoba",
	NB: "New Brunswick",
	NL: "Newfoundland and Labrador",
	NS: "Nova Scotia",
	NT: "Northwest Territories",
	NU: "Nunavut",
	ON: "Ontario",
	PE: "Prince Edward Island",
	QC: "Quebec",
	SK: "Saskatchewan",
	YT: "Yukon"
};
