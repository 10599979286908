import React, {Component} from "react";
import crossSvg from "../../static/images/cross.svg";
import "./styles.scss";
import {Link} from "react-router-dom";

export type PrivacyPolicyTermsOfUseMode = "PrivacyPolicy" | "TermsOfUse";

interface PrivacyPolicyProps {
	isStatic: boolean;
	onClose?: () => void;
	mode: PrivacyPolicyTermsOfUseMode;
}

export default class PrivacyPolicyTermsOfUseComponent extends Component<PrivacyPolicyProps, {}> {
	public render() {
		return (
			<div
				className={
					"privacy-policy-terms-of-use-component" + (this.props.isStatic ? " static" : " pop-up")
				}
			>
				<div className={"box" + (this.props.isStatic ? " static" : " pop-up")}>
					<div
						className={"close" + (this.props.isStatic ? " static" : " pop-up")}
						onClick={this.props.onClose}
					>
						<img src={crossSvg} alt="" />
					</div>
					<div className="app-content">
						{this.props.mode === "PrivacyPolicy" && (
							<>
								<div className="refresh-date">February 1, 2020</div>
								<div className="title">
									<h1>Privacy Policy</h1>
								</div>
								<div className="content">
									<p>
										At XY Health Inc. (“Winston”), we are committed to protecting your privacy and
										safeguarding your personal information. The purpose of this Privacy Statement is
										to inform you about the types of Personal Information Winston (as follows, “we”
										or “us”) collects, uses and discloses in connection with the Winston website
										(the “Winston Platform”). It explains how we use and disclose that information,
										the choices you have regarding such use and disclosure, and how you may correct
										that information.
									</p>
									<p>
										We show our commitment to your privacy, by complying with the laws and
										regulations under applicable privacy laws in Canada, and specifically the
										Personal Information Protection and Electronic Documents Act (Canada) and the
										Personal Health Information Protection Act (Ontario) and the regulations
										thereunder. From time to time, we may make changes to this Privacy Statement.
										The Privacy Statement is current as of the “last revised” date which appears at
										the top of this page. We will treat Personal Information in a manner consistent
										with the Privacy Statement under which it was collected, unless we have your
										consent to treat it differently.
									</p>
									<p>The following topics will be covered in this Privacy Statement:</p>
									<ol className="ordered-list">
										<li>What is Personal Information?</li>
										<li>How do we collect your Personal Information?</li>
										<li>Where do we store your Personal Information?</li>
										<li>How do we use your Personal Information?</li>
										<li>To whom do we provide your Personal Information?</li>
										<li>When and how do we obtain your consent?</li>
										<li>
											How do we ensure the privacy of your Personal Information when dealing with
											our affiliates and other third parties?
										</li>
										<li>How long will we utilize, disclose or retain your Personal Information?</li>
										<li>
											How can you review your Personal Information that we have collected, utilized
											or disclosed?
										</li>
										<li>
											How do you know that the Personal Information we have on you is accurate?
										</li>
										<li>What if the Personal Information we have on you is inaccurate?</li>
										<li>How fast will we respond to your written requests?</li>
										<li>
											Are there any costs to you for requesting information about your Personal
											Information or our privacy practices?
										</li>
										<li>
											How do we know that it is really you requesting your Personal Information?
										</li>
										<li>
											What safeguards have we implemented to protect your Personal Information?
										</li>
										<li>
											How do you contact us regarding access to your Personal Information or our
											privacy practices?
										</li>
									</ol>
									<div className="sub-title">
										<h2>1. What is Personal Information?</h2>
									</div>
									<p>
										“Personal Information” is any information that is identifiable with you, as an
										individual. This information may include but is not limited to your name,
										contact information, information that relates to your physical or mental health,
										including information that consists of the health history of your family,
										information related to the providing of healthcare to you, including the
										identification of a person as a provider of healthcare to you, and Winston
										usernames and passwords. Personal Information, however, does not include your
										name, business title or business address and business telephone number in your
										capacity as an employee of an organization.
									</p>
									<div className="sub-title">
										<h2>2. How do we collect your Personal Information?</h2>
									</div>
									<p>
										We will always collect your Personal Information by fair and lawful means. We
										may collect Personal Information from you directly and/or from third parties,
										where we (and/or those third parties) have obtained your consent, or as
										otherwise required or permitted by law.
									</p>
									<div className="sub-title">
										<h2>3. Where do we store your Personal Information?</h2>
									</div>
									<p>
										We will keep the Personal Information that we collect either at the Winston
										offices or our service provider’s data centres in Canada.
									</p>
									<div className="sub-title">
										<h2>4. How do we use your Personal Information?</h2>
									</div>
									<p>
										We identify the purposes for which we use your Personal Information at the time
										we collect such information from you and obtain your consent, in any case, prior
										to such use. We generally use your Personal Information for the following
										purposes (the “Purposes”):
									</p>
									<ul className="unordered-list">
										<li>
											to facilitate and administer your access to the Winston Platform, and your use
											of the Winston Platform’s functionality;
										</li>
										<li>
											to track your purchases through the Winston Platform and arrange for refill;
										</li>
										<li>
											to facilitate communications with third-party service providers with whom we
											have a contractual relationship such as, Physicians, Nurse Practitioners and
											Pharmacists;
										</li>
										<li>
											to advise you about new programs and services that may be of interest to you;
										</li>
										<li>to respond to your inquiries, complaints or requests;</li>
										<li>to collect opinions and comments in regard to Winston’s operations;</li>
										<li>to investigate legal claims;</li>
										<li>such purposes for which Winston may obtain consent from time to time;</li>
										<li>such other uses as may be permitted or required by applicable law.</li>
									</ul>
									<div className="sub-title">
										<h2>5. To whom do we provide your Personal Information?</h2>
									</div>
									<p>
										Generally, we will only make disclosures of Personal Information to such persons
										for which you provide your consent. Notwithstanding the foregoing:
									</p>
									<ul className="unordered-list">
										<li>
											We may transfer your Personal Information to third party service providers
											with whom we have a contractual agreement that includes appropriate privacy
											standards, where such third parties are assisting us with the Purposes – such
											as, Physicians, Nurse Practitioners, Pharmacists and service providers that
											provide telephone support or data storage or processing.
										</li>
										<li>
											We may also make disclosures of Personal Information to a potential acquiror
											in connection with a transaction involving the sale of some or all of the
											business of Winston (in which case the use of your personal information by the
											new entity would continue to be limited by applicable law), or as otherwise
											permitted or required by law. In addition, we may send Personal Information
											outside of the country for the purposes set out above, including for process
											and storage by service providers in connection with such purposes. However,
											you should note that to the extent that any Personal Information is out of the
											country, it is subject to the laws of the country in which it is held, and may
											be subject to disclosure to the governments, courts or law enforcement or
											regulatory agencies of such other country, pursuant to the laws of such
											country.
										</li>
									</ul>
									<div className="sub-title">
										<h2>6. When and how do we obtain your consent?</h2>
									</div>
									<p>
										We generally obtain your consent prior to collecting, and in any case, prior to
										using or disclosing your Personal Information for any purpose. You may provide
										your consent to us either orally, electronically or in writing. The form of
										consent that we seek, including whether it is express or implied, will largely
										depend on the sensitivity of the personal information and the reasonable
										expectations you might have in the circumstances. In limited circumstances, we
										may rely on a third party to obtain your consent to the sharing of your personal
										information with us. You may withdraw your consent by providing us notice. You
										may expressly instruct that your personal health information not be used or
										disclosed for healthcare purposes without your consent.
									</p>
									<div className="sub-title">
										<h2>
											7. How do we ensure the privacy of your Personal Information when dealing with
											our affiliates and other third parties?
										</h2>
									</div>
									<p>
										We ensure that all affiliates and other third parties that are engaged to
										perform services on our behalf and are provided with Personal Information are
										contractually required to observe the intent of this Privacy Statement and our
										privacy practices.
									</p>
									<div className="sub-title">
										<h2>8. How long will we use, disclose or retain your Personal Information?</h2>
									</div>
									<p>
										We will use, disclose or retain your Personal Information for as long as
										necessary to fulfill the purposes for which that Personal Information was
										collected and as permitted or required by law.
									</p>
									<div className="sub-title">
										<h2>
											9. How can you review your Personal Information that we have collected, used
											or disclosed?
										</h2>
									</div>
									<p>
										If you make a written request to review any Personal Information about you that
										we have collected, used or disclosed, we will provide you with any such Personal
										Information to the extent required by law. We will make such Personal
										Information available to you in a form that is generally understandable, and
										will explain any abbreviations or codes.
									</p>
									<div className="sub-title">
										<h2>
											10. How do you know that the Personal Information we have on you is accurate?
										</h2>
									</div>
									<p>
										We will ensure that your Personal Information is kept as accurate, complete and
										up-to-date as possible. We will not routinely update your Personal Information,
										unless such a process is necessary. We expect you, from time to time, to supply
										us with written updates to your Personal Information, when required.
									</p>
									<div className="sub-title">
										<h2>11. What if the Personal Information we have on you is inaccurate?</h2>
									</div>
									<p>
										At any time, you can challenge the accuracy or completeness of your Personal
										Information in our records. If you successfully demonstrate that your Personal
										Information in our records is inaccurate or incomplete, we will amend the
										Personal Information as required. Where appropriate, we will transmit the
										amended information to third parties having access to your Personal Information.
									</p>
									<div className="sub-title">
										<h2>12. How fast will we respond to your written requests?</h2>
									</div>
									<p>
										We will attempt to respond to each of your written requests not later than 30
										days after receipt of such requests. We will advise you in writing if we cannot
										meet your requests within this time limit. You have the right to make a
										complaint to the Privacy Commissioner of Canada and/or the Information and
										Privacy Commissioner of Ontario in respect of this time limit.
									</p>
									<div className="sub-title">
										<h2>
											13. Are there any costs to you for requesting information about your Personal
											Information or our privacy practices?
										</h2>
									</div>
									<p>
										We will not charge any costs for you to access your Personal Information in our
										records or to access our privacy practices without first providing you with an
										estimate of the approximate costs, if any.
									</p>
									<div className="sub-title">
										<h2>
											14. How do we know that it is really you requesting your Personal Information?
										</h2>
									</div>
									<p>
										We may request that you provide sufficient identification to permit access to
										the existence, use or disclosure of your Personal Information. Any such
										identifying information shall be used only for this purpose.
									</p>
									<div className="sub-title">
										<h2>
											15. What safeguards have we implemented to protect your Personal Information?
										</h2>
									</div>
									<p>
										We have implemented physical, organizational, contractual and technological
										security measures to protect your Personal Information from loss or theft,
										unauthorized access, disclosure, copying, use or modification. The only
										employees, who are granted access to your Personal Information, are those with a
										business ‘need-to-know’ or whose duties reasonably require such information.
									</p>
									<div className="sub-title">
										<h2>
											16. How do you contact us regarding access to your Personal Information or our
											privacy practices?
										</h2>
									</div>
									<p>
										All comments, questions, concerns or complaints regarding your Personal
										Information, this Privacy Statement or our privacy practices, should be
										forwarded to our Privacy Officer at the following email address:{" "}
										<a href="mailto: vweylagro@askwinston.ca">vweylagro@askwinston.ca</a>
									</p>
								</div>
							</>
						)}
						{this.props.mode === "TermsOfUse" && (
							<>
								<div className="refresh-date">February 1, 2020</div>
								<div className="title">
									<h1>Terms Of Use</h1>
								</div>
								<div className="content">
									<div className="sub-title">
										<h2>1. ACCEPTANCE OF TERMS</h2>
									</div>
									<p>
										XY Health Inc. (“Winston”, “us”, “we”, “our”) maintains the Winston website
										located at www.askwinston.ca (the “Winston Health Platform” or the “Platform”).
										Your access and use of the Winston Health Platform is subject to these Terms of
										Use (“TOU”). These TOU form an agreement between Winston and you. The term “you”
										refers to the person or entity visiting the Winston Platform, browsing or
										otherwise using the Winston Platform, or receiving any Winston Platform
										Healthcare Services, as hereinafter defined. A copy of these TOU may be
										downloaded, saved and printed for your reference.
									</p>
									<p>
										BY ACCESSING AND USING THE WINSTON PLATFORM, YOU UNDERSTAND, ACCEPT AND AGREE TO
										BE BOUND BY AND COMPLY WITH THESE TOU. IF YOU DO NOT ACCEPT AND AGREE TO BE
										BOUND BY THESE TOU, PLEASE DO NOT ACCESS OR USE THE WINSTON PLATFORM.
									</p>
									<p>
										DO NOT USE THE PLATFORM FOR MEDICAL EMERGENCIES. IF YOU HAVE A MEDICAL
										EMERGENCY, CALL 911 IMMEDIATELY OR ATTEND THE EMERGENCY ROOM OF THE NEAREST
										HOSPITAL OR HEALTH CENTRE. UNDER NO CIRCUMSTANCES SHOULD YOU ATTEMPT
										SELF-TREATMENT BASED ON ANY CONTENT (AS DEFINED BELOW).
									</p>
									<div className="sub-title">
										<h2>2. MODIFICATIONS TO THE TOU</h2>
									</div>
									<p>
										Except where prohibited by applicable law, Winston reserves the right to change
										these TOU at any time without notice. Such modifications shall become effective
										immediately upon the posting thereof. Your continued access to or use of the
										Winston platform after any changes to these TOU indicates your acceptance of
										such changes. It is your responsibility to review these TOU regularly.
									</p>
									<p>
										Winston reserves the right to change any information, material or content
										(including, but not limited to, pricing, features and availability of platform
										services (as defined herein) contained on or provided through the platform (the
										“content”) at any time, and from time to time, without notice.
									</p>
									<p>
										The TOU is current as of the “last revised” date which appears at the top of
										this page. You can find the most recent version of the TOU at:
										www.askwinston.ca/terms-of-use.
									</p>
									<div className="sub-title">
										<h2>3. AVAILABILITY AND USE OF WINSTON PLATFORM</h2>
									</div>
									<p>
										The Winston Platform is only available to you if you are a temporary or
										permanent resident of the province of Ontario, British Columbia, Nova Scotia,
										Newfoundland, Nunavut, or Prince Edward Island. As a condition of your use of
										the Winston platform, you warrant that: (1) you have reached the age of majority
										in your jurisdiction of residence; (2) you possess the legal authority to create
										a binding legal obligation; (3) you will use the platform in accordance with
										these TOU; (4) all information supplied by you on the platform is true,
										accurate, current and complete; and (5) if you are accessing or using the
										platform on behalf of another person or a corporate entity, you represent and
										warrant that you have the authority to bind such person or entity to these TOU.
									</p>
									<p>
										The Winston Platform is provided solely (the “Permitted Use”) to: (1) assist you
										in gathering information about the various services offered by Winston through
										the Winston Platform; (2) if and as permitted by Winston and these TOU, enable
										you to use the Winston Platform and receive any services offered to you through
										the Winston Platform including the Healthcare Services (as defined below); (3)
										if applicable, facilitate any payments from you in respect of your use of the
										Winston Platform or the receipt of any services through the Winston Platform;
										and (4) if and as permitted by Winston and these TOU, provide feedback and
										otherwise communicate with Winston in connection with any of the foregoing ((1)
										through (4) collectively, in these Terms of Use defined as “Platform Services”).
									</p>
									<p>
										Winston retains the right, at its sole discretion, to deny access to anyone to
										the Winston Platform or the services it offers, at any time and for any reason,
										including, but not limited to, for violation of these TOU. You will cease and
										desist from any such access or use immediately upon request by Winston.
									</p>
									<div className="sub-title">
										<h2>4. GRANT OF LICENSE</h2>
									</div>
									<p>
										If you are a temporary or permanent resident of the province of Ontario, British
										Columbia, Nova Scotia, Newfoundland, Nunavut, or Prince Edward Island and
										subject to these TOU, Winston grants you a personal, limited, revocable,
										non-exclusive, non-assignable and non-transferable license to access the Winston
										Platform for your own use in accordance with these TOU. Use of the Winston
										Platform beyond the scope of the authorized access granted to you by Winston
										immediately terminates this license.
									</p>
									<p>
										Subject to these TOU and the terms of Winston’s privacy policy, you grant to
										Winston a perpetual, transferrable, irrevocable, royalty-free, fully paid-up,
										worldwide and fully sublicensable license to access, collect, store and use any
										data, information, records and files that: (1) you load, transmit to or enter
										into the Winston Platform, or (2) Winston or the Winston Platform collects,
										retrieves or obtains from your local computer system or from third parties with
										your permission or on your behalf, and (in each case) including all results from
										processing such data, including compilations, and derivative works thereof (the
										“User Content”) including for the purpose of: (A) providing the Winston Platform
										Services, (B) complying with applicable law, and (C) Winston’s reasonable audit
										and data retention policies.
									</p>
									<div className="sub-title">
										<h2>5. SECURE LOG-IN</h2>
									</div>
									<p>
										Although certain parts of the Winston Platform are accessible to the public, in
										order to access the Healthcare Services (as set out below) users must be issued
										log-in credentials (e.g., log-in identification and password) to access the
										secure, non-public areas of the Winston Platform (“Registered Users”). In order
										to do so, a Registered User must register for and maintain an active personal
										account (“User Account”). In order to qualify for a User Account, you must be at
										least 18 years of age. Once granted a User Account, a Registered User is only
										permitted to request Healthcare Services for themselves. Registered Users may
										include temporary or permanent residents of the province of Ontario, British
										Columbia, Nova Scotia, Newfoundland, Nunavut, Prince Edward Island, drug
										manufacturers and suppliers, healthcare professionals and healthcare decision
										makers. If you have been issued log-in credentials by Winston, you are
										responsible for maintaining the confidentiality of your password, and are fully
										responsible for all activities that occur under your password.
									</p>
									<p>
										You must not share your password with any other person. You agree to (a)
										immediately notify Winston of any unauthorized use of your password or account
										or any other breach of the security of the Winston Platform of which you
										reasonably suspect or become aware, and (b) ensure that you exit from your
										account at the end of each session. Winston cannot and will not be liable for
										any loss or damage arising from your failure to comply with this section, and
										you will indemnify Winston in respect of any losses suffered by Winston or
										claims made against Winston that are attributable to any loss or damage arising
										from your failure to comply with this section.
									</p>
									<p>
										You are responsible for all activity that occurs under your User Account, and
										you agree to maintain the security and secrecy of your User Account username and
										password at all times. Unless otherwise permitted by Winston in writing, you may
										only possess one User Account. You are responsible for changing your password
										promptly if you think it has been compromised. You may not transfer or share
										your password with anyone, or create more than one account. You may not use
										anyone else’s account at any time. You may not collect or harvest personal data
										of any other user of the Winston Platform, including user account names or login
										credentials. Winston explicitly disclaims liability for any and all losses and
										damages arising from your failure to comply with this section.
									</p>
									<p>
										Note that an Authorized Healthcare Professional (as defined below) reserves the
										right to assess whether a person has the capacity to provide consent to his or
										her Healthcare Services on a case-by-case basis.
									</p>
									<div className="sub-title">
										<h2>6. SERVICES</h2>
									</div>
									<p>
										Authorized physicians and authorized non-physician providers such as nurse
										practitioners and pharmacists (collectively, “Authorized Healthcare
										Professionals”) may provide healthcare services, including telemedicine services
										(“Healthcare Services”) through the Winston Platform.
									</p>
									<p>
										You understand that telemedicine involves the delivery of healthcare services
										using electronic communications, information technology, or other means between
										a healthcare provider and a patient who are not in the same physical location.
										Telemedicine may be used for diagnosis, treatment, follow-up and/or related
										patient education, and may include, but is not limited to:
									</p>
									<p>
										Electronic transmission of medical records, photo images, personal health
										information, or other data between a patient and healthcare provider;
										Interactions between a patient and healthcare provider via audio, video, and/or
										data communications; and use of output data from medical devices, sound, and
										video files. The electronic systems used in the services will incorporate
										network and there are restrictions on the Healthcare Services that can be
										provided through the Winston Platform.
									</p>
									<p>
										YOU MUST NOT USE THE WINSTON PLATFORM FOR EMERGENCY NEEDS. IF YOU BELIEVE THAT
										YOU ARE DEALING WITH A MEDICAL EMERGENCY, CALL 911 IMMEDIATELY OR ATTEND YOUR
										NEAREST EMERGENCY ROOM.
									</p>
									<p>
										By becoming a Registered User, you consent to Authorized Healthcare
										Professionals providing Healthcare Services through the Winston Platform.
									</p>
									<p>
										The following symptoms are specifically excluded from the Healthcare Services:
										chest pains, difficulty breathing, fever in children under the age of 3 months,
										blackouts or feeling like you may blackout, any impairment in level of
										consciousness, stroke like symptoms such as impaired sensation or paralysis to
										one side of your body, inability to speak or comprehend language, any abdominal
										pain severe enough that you are unable to comfortably walk.
									</p>
									<p>
										In addition to the limitations set out above, Authorized Healthcare
										Professionals are not permitted to prescribe certain kinds of medication
										including narcotics, controlled substances or other types of controlled
										medications, non-therapeutic drugs and other drugs which may be harmful because
										of their potential for abuse.
									</p>
									<p>
										YOU AGREE THAT ANY PRESCRIPTIONS YOU ACQUIRE FROM AN AUTHORIZED PHYSICIAN OR AN
										AUTHORIZED NON-PHYSICIAN PROVIDER ARE SOLELY FOR THE PERSONAL USE OF THE
										INDIVIDUAL NAMED ON THE PRESCRIPTION. YOU AGREE TO FULLY AND CAREFULLY READ ALL
										PROVIDED PRODUCT INFORMATION AND LABELS, AND TO CONTACT A PHYSICIAN OR
										PHARMACIST IF YOU HAVE ANY QUESTIONS REGARDING THE PRESCRIPTION OR MEDICATION.
									</p>
									<p>
										You should report any medication reactions, side effects or other adverse events
										to your family physician or, if you do not have one, to the nearest walk-in
										clinic or emergency room or call 911 if any reaction is an emergency situation.
										Do not report drug reactions, side effects or adverse events through the Winston
										Platform.
									</p>
									<p>
										Authorized Healthcare Professionals have the discretion to refuse or discontinue
										the provision of Healthcare Services to any Registered User at any time,
										including for actual or potential misuse of the Healthcare Services by a
										Registered User.
									</p>
									<div className="sub-title">
										<h2>7. DEALINGS WITH OTHER REGISTERED USERS</h2>
									</div>
									<p>
										If there is a dispute between Registered Users of this site, or between
										Registered Users and any third party, you understand and agree that Winston is
										under no obligation to become involved. In the event that you have a dispute
										with one or more other Registered Users, you hereby release Winston and its
										representatives (and their respective officers, employees, agents and successors
										in rights) from claims, demands and damages (actual and consequential) of every
										kind or nature, known or unknown, suspected and unsuspected, disclosed and
										undisclosed, arising out of or in any way related to such disputes and / or the
										Winston Platform.
									</p>
									<div className="sub-title">
										<h2>8. PRIVACY AND INFORMATION DISCLOSURE</h2>
									</div>
									<p>
										Please{" "}
										<Link to="/privacy-policy" target="_blank">
											click here
										</Link>{" "}
										to review our current Privacy Policy, which contains important information about
										our practices in collecting, storing, using and disclosing your personal
										information, and which is hereby incorporated into and forms a part of these
										TOU. Your use of the Winston Platform signifies acknowledgement of and agreement
										to our Privacy Statement.
									</p>
									<div className="sub-title">
										<h2>9. CONDUCT</h2>
									</div>
									<p>
										You agree that a Registered User is not permitted to have more than one
										Registered User profiles on the Winston Platform. You further agree that you
										will not, in connection with you as a Registered User do anything in connection
										with the Winston Platform that:
									</p>
									<ol className="ordered-list">
										<li>is unlawful;</li>
										<li>
											is harmful, threatening, abusive, harassing, defamatory, pornographic, profane
											or otherwise inappropriate;
										</li>
										<li>
											includes personal or identifying information about another person without that
											person’s explicit consent;
										</li>
										<li>
											impersonates any person or entity, including, but not limited to, a Winston
											employee, or falsely states or otherwise misrepresents an affiliation with a
											person or entity;
										</li>
										<li>
											infringes any patent, trade-mark, trade secret, copyright or other proprietary
											rights of any person, or that you do not have a right to make available under
											any law or under contractual or fiduciary relationships;
										</li>
										<li>
											contains software viruses or any other computer code, files or programs
											designed to interrupt, destroy or limit the functionality of any computer
											software or hardware or telecommunications equipment.
										</li>
									</ol>
									<div className="sub-title">
										<h2>10. LIMITATIONS ON SERVICE</h2>
									</div>
									<p>
										You agree that Winston has no responsibility or liability for the deletion or
										failure to store or provide any content maintained or transmitted by the Winston
										Platform. You acknowledge Winston reserves the right at any time to modify or
										discontinue the Winston Platform (or any part thereof) with or without notice,
										and that Winston shall not be liable to you or to any third party for any
										modification, suspension or discontinuance of the Winston Platform.
									</p>
									<div className="sub-title">
										<h2>11. TERM AND TERMINATION OF SERVICE</h2>
									</div>
									<p>
										These TOU will commence on the day you first use the Winston Platform and will
										continue into force until terminated by either party as described below (the
										“Term”). Either party may terminate these TOU as follows: (1) Winston may
										terminate these TOU at any time and with immediate effect by giving notice to
										you, at Winston’s discretion, by email (at your current email address on file
										with Winston) or through the Winston Platform; (2) you may terminate these TOU
										at any time and with immediate effect by requesting (by email or through any
										then-available interfaces on the Winston Platform) that your User Account be
										deleted or deactivated and ceasing use of the Winston Platform.
									</p>
									<p>
										Further, you agree that Winston shall not be liable to you or any third-party
										for any termination of your access to the Winston Platform. Further, you agree
										not to attempt to use the Winston Platform after said termination. The
										disclaimers, limitations on liability and indemnity clauses shall survive
										termination of the TOU, as shall any other provision which by its nature ought
										to survive termination.
									</p>
									<div className="sub-title">
										<h2>12. OWNERSHIP AND PROPRIETARY RIGHTS</h2>
									</div>
									<p>
										All Content, including (without limitation) all designs, infrastructure
										graphics, pictures, illustrations, software, artwork, video, music, sound,
										names, words, titles, phrases, logos and marks displayed on the Winston
										Platform, are owned or licensed by Winston and are protected by copyright,
										trade-mark and other intellectual property laws.
									</p>
									<p>
										Winston expressly reserves all rights in the Winston Platform, and all materials
										provided by Winston in connection with these TOU that are not specifically
										granted to you. You acknowledge that all right, title and interest in the
										Winston Platform, all materials provided by Winston in connection with these TOU
										(including the Content), and any update, adaptation, translation, customization
										or derivative work thereof, and all intellectual property rights therein will
										remain with Winston (or third party suppliers, if applicable), and that the
										Winston Platform, and all materials provided by Winston hereunder are licensed
										and not “sold” to you.
									</p>
									<p>
										OTHER THAN FOR YOUR PERSONAL CONFIDENTIAL USE, YOU FURTHER AGREE NOT TO
										REPRODUCE, DUPLICATE OR COPY WHETHER BY PRINTING OR SAVING TO A DESKTOP, CONTENT
										FROM THE WINSTON PLATFORM WITHOUT THE EXPRESS WRITTEN CONSENT OF WINSTON, AND
										AGREE TO ABIDE BY ANY AND ALL COPYRIGHT NOTICES DISPLAYED ON THE WINSTON
										PLATFORM. YOU MAY NOT DECOMPILE OR DISASSEMBLE, REVERSE ENGINEER OR OTHERWISE
										ATTEMPT TO DISCOVER ANY SOURCE CODE CONTAINED IN THE WINSTON PLATFORM.
									</p>
									<p>
										Without limiting the foregoing, you agree not to reproduce, duplicate, copy,
										sell, resell or exploit for any commercial purposes, any aspect of the Winston
										Platform.
									</p>
									<div className="sub-title">
										<h2>13. NO UNLAWFUL OR PROHIBITED USE</h2>
									</div>
									<p>
										You will not, without Winston’s prior written permission, use the Winston
										Platform and the Content for purposes other than the Permitted Use. Without
										limiting the generality of the foregoing, you will not, and will not permit
										anyone else to: (1) 'frame', 'mirror' or otherwise incorporate the Winston
										Platform or the Content or any part thereof on any commercial or non-commercial
										website; (2) access, monitor or copy any part of the Winston Platform or the
										Content using any robot, spider, scraper or other automated means or any manual
										process for any purpose without our express written permission; (3) violate the
										restrictions in any robot exclusion headers on the Content or the Winston
										Platform or bypass or circumvent other measures employed to prevent or limit
										access to the Winston Platform; (4) take any action that imposes, or may impose,
										in Winston’s discretion, an unreasonable or disproportionately large load on the
										Winston Platform; (5) deep-link to any portion of the Winston Platform for any
										purpose; (6) remove (or permit anyone else to remove) any watermarks, labels or
										other legal or proprietary notices included in the Winston Platform or the
										Content; (7) modify or attempt to modify (or permit anyone else to modify or
										attempt to modify) the Winston Platform or any Content, including any
										modification for the purpose of disguising or changing any indications of the
										ownership or source of the Winston Platform or the Content; (8) use the Winston
										Platform or the Content as part of any service for sharing, lending or
										multi-person use, or for the purpose of any other institution, except as
										specifically permitted by Winston and only in the exact manner specified and
										enabled by Winston; (9) attempt to, assist, authorize or encourage others to
										circumvent, disable or defeat any of the security features or components, such
										as digital rights management software or encryption, that protect the Winston
										Platform or the Content; (10) copy, reproduce, modify, distribute, transfer,
										sell, publish, broadcast, perform, transmit, publish, license or circulate in
										any form any part of the Winston Platform or the Content; (11) create derivative
										works based on the Winston Platform or the Content, in whole or in part, or to
										decompile, disassemble, reverse engineer or other exploit any part of the
										Winston Platform or the Content; (12) use the Winston Platform in a manner that
										violates the rights (including to intellectual property rights) of any third
										party, including by providing, uploading or transmitting any User Content that
										violate such third-party rights; or (13) upload to or transmit through the
										Winston Platform any User Content that is offensive, hateful, obscene,
										defamatory or violates any applicable laws, in each case as determined by
										Winston in its sole discretion.
									</p>
									<div className="sub-title">
										<h2>14. THIRD PARTY WEBSITES</h2>
									</div>
									<p>
										The Winston Platform may provide links to third party websites. Winston does not
										endorse the information contained on those web sites or guarantee their quality,
										accuracy, reliability, completeness, currency, timeliness, non-infringement,
										merchantability or fitness for any purpose. The content in any linked web site
										is not under Winston's control, and if you choose to access any such web site,
										you do so entirely at your own risk.
									</p>
									<div className="sub-title">
										<h2>15. VIRUSES</h2>
									</div>
									<p>
										The downloading and viewing of Content is done at your own risk. Winston cannot
										and does not guarantee or warrant that the Winston Platform or the Content are
										compatible with your computer system or that the Winston Platform or the
										Content, or any links from the Winston Platform or the Content, will be free of
										viruses, worms, trojan horses or disabling devices or other code that manifests
										contaminating or destructive properties. You are responsible for implementing
										safeguards to protect the security and integrity of your computer system, and
										you are responsible for the entire cost of any service, repairs or connections
										of and to your computer system that may be necessary as a result of your use of
										the Winston Platform.
									</p>
									<div className="sub-title">
										<h2>16. COMMUNICATIONS NOT CONFIDENTIAL OR SECURE</h2>
									</div>
									<p>
										Winston does not guarantee the confidentiality of any communications made by you
										through the Winston Platform. Although Winston generally adheres to the accepted
										industry practices in securing the transmission of data to, from and through the
										Winston Platform, you understand, agree and acknowledge that Winston cannot and
										does not guarantee the security of data transmitted over the Internet or public
										networks in connection with your use of the Winston Platform.
									</p>
									<div className="sub-title">
										<h2>17. DISCLAIMER AND LIMITATION OF LIABILITY</h2>
									</div>
									<p>
										The laws of certain jurisdictions, including Quebec, do not allow the exclusion
										or limitation of legal warranties, liability or certain damages or limitations
										of representations made concerning goods or services. If these laws apply to
										you, some or all of the below exclusions or limitations may not apply to you.
									</p>
									<p>
										Although we strive to update and keep accurate as much as possible the Content
										contained on the website, errors and/or omissions may occur. Accordingly, the
										Winston Platform and the Content are provided “as is” without warranty or
										condition of any kind. Use of the Winston Platform or the Content is at your own
										risk. Winston does not make any representations, warranties or conditions about
										the quality, accuracy, reliability, completeness, currency, or timeliness of the
										Winston Platform or the content. The Platform and the Content may include
										errors, omissions and inaccuracies, including, without limitation, pricing
										errors. Winston does not assume any responsibility for any errors, omissions or
										inaccuracies in the Winston Platform or the Content. In addition, Winston
										expressly reserves the right to correct any pricing errors on the Winston
										Platform.
									</p>
									<p>
										To the fullest extent permitted by law, Winston disclaims all warranties,
										representations and conditions of any kind with respect to the Winston Platform
										and the content whether express, implied, statutory or collateral, including any
										implied warranties and conditions of merchantability, merchantable quality,
										fitness for a particular purpose or non-infringement, that arise out of a course
										of dealing or trade, or that the Winston Platform or the Content are or will be
										error-free or will operate without interruption. In no event will Winston be
										liable, whether based on warranty, contract, tort, negligence, strict liability
										or any other legal theory, for any damages of any kind (including, without
										limitation, direct, indirect, incidental, consequential, special, exemplary or
										punitive damages, lost profits, loss of use, loss of data, personal injury,
										fines, fees, penalties or other liabilities), whether or not Winston is advised
										of the possibility of such damages, resulting from or related to the use of, or
										the inability to make use of, the Winston Platform or the Content.
									</p>
									<p>
										You acknowledge and agree that Winston does not provide medical advice,
										diagnosis, or treatment, and is strictly a technology platform and
										infrastructure for connecting patients with independent third-party Authorized
										Healthcare Practitioners, including nurse practitioners, physicians and other
										Authorized Healthcare Practitioners in Winston’s network. You acknowledge and
										agree that the Healthcare Practitioners using the Winston Platform are solely
										responsible for and will have complete authority, responsibility, supervision,
										and control over the provision of all medical and clinical services, including
										diagnosis, treatment and medication decisions, and other professional healthcare
										services performed, and that all diagnoses, treatments, prescriptions and other
										professional healthcare services will be provided and performed exclusively by
										or under the supervision of Authorized Healthcare Practitioners, in their sole
										discretion, as they deem appropriate.
									</p>
									<p>
										To the extent that the foregoing limitation does not apply, in no event will the
										total aggregate liability of Winston in connection with or under these TOU or
										your use of, or inability to make use of, the Winston platform or the content,
										or for any other claim related in any way to your use of, or inability to make
										use of, the Winston platform or the Content exceed the aggregate amount of the
										fees paid by you for a one year period. For greater certainty, the existence of
										one or more claims under these TOU will not increase the maximum liability
										amount.
									</p>
									<p>
										The limitation above reflects the allocation of risk between the parties. The
										limitations specified in this section will survive and apply even if any limited
										remedy specified in these terms is found to have failed of its essential
										purpose.
									</p>
									<div className="sub-title">
										<h2>18. INDEMNITY</h2>
									</div>
									<p>
										You will defend, indemnify and hold harmless Winston and all of its officers,
										directors, employees and agents from and against any claims, causes of action,
										demands, recoveries, losses, damages, fines, penalties or other costs or
										expenses of any kind or nature including but not limited to reasonable legal and
										accounting fees, brought in connection with or as a result of:
									</p>
									<ol className="ordered-list">
										<li>
											your breach of any of your warranties, representations or obligations under
											these TOU;
										</li>
										<li>
											your violation of any applicable law or the rights of a third party (including
											intellectual property rights);
										</li>
										<li>your use of the Winston Platform or the Content.</li>
									</ol>
									<p>
										In particular, you agree to indemnify and hold harmless Winston, its affiliates,
										members, officers, employees, agents, sponsors, and licensors for any injury,
										including but not limited to bodily harm or death, to you or any third party
										that may result from the use of or reliance on any Content.
									</p>
									<div className="sub-title">
										<h2>19. GEOGRAPHIC APPLICATION OF THE WINSTON PLATFORM</h2>
									</div>
									<p>
										Not all of the products and services described on the Winston Platform are
										available in all jurisdictions. Furthermore, nothing on the Winston Platform
										constitutes an offer or solicitation to buy or sell any product or service to
										anyone in any jurisdiction in which such an offer or solicitation is prohibited
										by law. Specifically, you acknowledge and agree that the products, services
										and/or material described on the Winston Platform, or the Healthcare Services
										available through the Winston Platform, are not appropriate or available for use
										in locations outside Canada, and may not function in all locations within
										Canada.
									</p>
									<div className="sub-title">
										<h2>20. DISCLOSURES</h2>
									</div>
									<p>
										All Authorized Healthcare Practitioners on the Winston Platform are licensed or
										accredited to practice their applicable regulated health profession by the
										applicable regulatory authority in the province or territory of Canada where
										they practice.
									</p>
									<p>
										You can report a complaint relating to Healthcare Services provided by a
										Healthcare Practitioner on the Platform by contacting the professional
										regulatory college(s) in the province(s) where the Healthcare Services were
										received. In a professional relationship, sexual intimacy is never appropriate
										and should be reported to the professional regulatory college(s) that licenses,
										registers, or certifies the licensee.
									</p>
									<div className="sub-title">
										<h2>21. GOVERNING LAW AND JURISDICTION</h2>
									</div>
									<p>
										Unless the applicable laws of your jurisdiction, such as Quebec, require that
										the laws of your jurisdiction govern, you hereby agree that (a) all aspects of
										the relationship between you and Winston (as well as its agents, delegates,
										employees, and any Authorized Healthcare Practitioners or PHAs), including
										without limitation any Healthcare Services provided to you, and (b) the
										resolution of any and all disputes arising from or in connection with that
										relationship, including any disputes arising under or in connection with these
										TOU, shall be governed by and construed in accordance with the laws of the
										province of Ontario (other than conflict of laws rules) and the laws of Canada
										applicable therein. You hereby acknowledge that the Healthcare Services received
										by you will be provided in Canada and that the Courts of Ontario shall have
										exclusive jurisdiction to hear any complaint, demand, claim, proceeding or cause
										of action, whatsoever arising from or in connection with those Healthcare
										Services, or from any other aspect of the relationship between you and Winston.
									</p>
									<p>
										These laws apply to your access to or use of the Winston Platform or the
										Content, notwithstanding your domicile, residency or physical location. The
										Winston Platform and the Content are intended for use only in jurisdictions
										where they may be lawfully offered for use. If any provision of the present TOU
										shall be unlawful, void, or for any reason unenforceable, then such provision
										shall be severable from these TOU and shall not affect the validity and
										enforceability of any remaining provisions. These TOU shall not be governed by
										the United Nations Convention on Contracts for the International Sale of Goods,
										which is expressly excluded and you also expressly agree to exclude the
										applicability of the Uniform Computer Information Transactions Act. A printed
										copy of these TOU and of any notice in electronic form shall be admissible in
										any judicial or administrative proceedings to the same extent and under the same
										conditions as other business documents and records originally generated and
										maintained in printed form. You agree that you will only sue us as an
										individual. You agree that you will not file a class action, or participate in a
										class action.
									</p>
									<div className="sub-title">
										<h2>22. ENTIRE AGREEMENT, WAIVER AND SEVERABILITY</h2>
									</div>
									<p>
										These TOU constitute the entire agreement between Winston and you pertaining to
										the subject matter hereof and supersede all prior or contemporaneous
										communications and proposals, whether electronic, oral or written, between you
										and Winston with respect to the Winston Platform. A printed version of these TOU
										and of any notice given in electronic form will be admissible in judicial or
										administrative proceedings based upon or relating to these TOU to the same
										extent and subject to the same conditions as other business documents and
										records originally generated and maintained in printed form.
									</p>
									<p>
										Headings are inserted for convenience of reference only and will not affect the
										construction or interpretation of these TOU. The word 'including' or 'includes'
										means 'including (or includes) without limitation'.
									</p>
									<p>
										Winston 's failure to insist upon or enforce strict performance of any provision
										of these TOU will not be construed as a waiver of any provisions or right.
									</p>
									<p>
										If any of the provisions contained in these TOU are determined to be void,
										invalid or otherwise unenforceable by a court of competent jurisdiction, such
										determination will not affect the remaining provisions contained herein.
									</p>
									<p>
										You agree that it is the express wish of the parties that these TOU and all
										related documents be drawn up in English. Vous êtes d'accord que c’est la
										volonté expresse des parties que la présente convention ainsi que les documents
										qui s’y rattachent soient rédigés en anglais.
									</p>
									<div className="sub-title">
										<h2>23. GENERAL PROVISIONS</h2>
									</div>
									<p>
										The TOU constitutes the entire agreement between you and Winston and govern your
										use of the Winston Platform, superseding any prior agreements between you and
										Winston in respect of the Winston Platform. The TOU and the relationship between
										you and Winston shall be governed by the laws of the Province of Ontario without
										regard to its conflict of law provisions. You and Winston agree to submit to the
										non-exclusive jurisdiction of the courts located in Toronto, Ontario. The
										failure of Winston to exercise or enforce any right or provision of the TOU
										shall not constitute a waiver of such right or provision. If any provision of
										the TOU is found by a court of competent jurisdiction to be invalid, the parties
										nevertheless agree that the court should endeavour to give effect to the
										parties’ intentions as reflected in the provision, and the other provisions of
										the TOU remain in full force and effect.
									</p>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		);
	}
}
