import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Routes, Route, Link, useLocation, Navigate, useResolvedPath, Outlet} from "react-router-dom";
import moment from "moment-timezone";

import {getPatientSubscriptions} from "../../http/patient";

import * as appActions from "../../redux/app/handlers";
import {StateType} from "../../redux/types";

import Order from "../../model/Order";

import LoaderComponent from "../Loader";

import "./styles.scss";

const Profile = () => {
	const dispatch = useDispatch();
	const {pathname} = useLocation();
	const path = useResolvedPath("").pathname;
	const {profile, profilePageToOpen} = useSelector(({auth, app}: StateType) => ({
		profile: auth.profile,
		profilePageToOpen: app.profilePageToOpen
	}));

	const [loading, setLoading] = useState<boolean>(false);
	const [subscriptions, setSubscriptions] = useState<Order[]>([]);

	const loadSubscriptions = async () => {
		setLoading(true);

		try {
			const result = await getPatientSubscriptions();

			setSubscriptions(result.data);
		} catch (err) {
			console.log(err);
		}

		setLoading(false);
	};

	useEffect(() => {
		loadSubscriptions();
	}, []);

	useEffect(() => {
		if (profilePageToOpen !== null) {
			dispatch(appActions.setProfilePageToOpen(null));
		}
	}, [dispatch, profilePageToOpen]);

	const getActiveSubscriptions = () => {
		const activeSubscriptions = subscriptions.filter(
			(subscription) => subscription.status === "ACTIVE"
		);

		if (!activeSubscriptions.length) {
			return <div className="no-subscriptions">There is no active subscriptions yet</div>;
		}

		return activeSubscriptions.map((subscription) => (
			<div className="active-subscription-item" key={subscription.id}>
				<div className="subscription-item-info">
					<div className="item-product-name">
						<span className="product-name-title">Product Name:</span>
						<span className="product-name">
							{subscription.items.map((item, index) => (
								<span key={index}>{`${item.productDosage.split(" ")[0]} `}</span>
							))}
						</span>
					</div>
					<div className="item-orders">
						<span className="orders-title">Orders:</span>
						<span className="orders">{`${
							subscription.prescription!.refills - subscription.prescription!.refillsLeft
						}/${subscription.prescription!.refills}`}</span>
					</div>
					<div className="item-prescription-expires">
						<span className="prescription-expires-title">Prescription Expires:</span>
						<span className="expires">
							{moment(subscription.prescription?.toDate).format("MMMM D, YYYY")}
						</span>
					</div>
				</div>
				<div className="progress-bar">
					<div
						className="fill"
						style={{
							width: `${
								((subscription.prescription!.refills - subscription.prescription!.refillsLeft) *
									100) /
								subscription.prescription!.refills
							}%`
						}}
					/>
				</div>
			</div>
		));
	};

	return (
		<div className="profile-page-wrapper">
			{loading && <LoaderComponent showShadow isFixed/>}
			<div className="profile-page-navigation">
				<ul>
					<Link
						to={`account-info`}
						className={`account-info-link ${pathname === "/profile/account-info" && "active"}`}
					>
						<li>Account Information</li>
					</Link>
					<Link
						to={`billing-info`}
						className={`payment-billing-link ${pathname === "/profile/billing-info" && "active"}`}
					>
						<li>Payment & Billing</li>
					</Link>
					<Link
						to={`order-history`}
						className={`order-history-link ${pathname === "/profile/order-history" && "active"}`}
					>
						<li>Order History</li>
					</Link>
					<Link
						to={`transfer-rx`}
						className={`transfer-rx-link ${pathname.includes("/profile/transfer-rx") && "active"}`}
					>
						<li>Transfer Prescription</li>
					</Link>
				</ul>
			</div>
			{pathname.includes("account-info") && (
				<div className="profile-active-subscriptions">
					<div className="profile-welcome">
						<h1><span className="welcome">Welcome, </span>
						<span className="welcome-name">{`${profile!.firstName} ${profile!.lastName}`}</span>
						</h1>
					</div>
					{getActiveSubscriptions()}
				</div>
			)}
			<Outlet />
		</div>
	);
};

export default Profile;
