import http from "../index";

import Cart from "../../model/Cart";
import CartItem from "../../model/CartItem";

export const getCart = () => http.get("/user-cart");

export const saveCart = (cart: CartItem[]) => http.put("/user-cart", cart);

export const deleteProduct = (data: CartItem) => http.delete("/user-cart", {data});

export const makeSubscription = (cart: Cart) => http.post("/subscription", cart);

export const resumeSubscription = (id: number | string) => http.put(`/subscription/${id}/resume`);

export const cancelSubscription = (id: number | string) => http.put(`/subscription/${id}/cancel`);

export const makeSubscriptionWithRx = (subscription: {
	promoCode: string;
	rxDocumentId: number;
	rxNumber: number | string;
	pharmacyNameAndAddress: string;
	pharmacyPhone: string;
	productId: number;
	productQuantityId: number;
}) => http.post(`/subscription/with-rx`, subscription);
