import {combineReducers} from "redux";

import authReducer  from "./auth/handlers";
import appReducer from "./app/handlers";
import modalReducer from "./modal/handlers";
import productReducer from "./product/handlers";
import patientReducer from "./patient/handlers";
import cartReducer from "./cart/handlers";

const rootReducer = combineReducers({
	app: appReducer,
	modal: modalReducer,
	auth: authReducer,
	product: productReducer,
	patient: patientReducer,
	cart: cartReducer
});

export default rootReducer;
