import React from "react";
import "./style.scss";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {StateType} from "../../../../redux/types";
import moment from "moment";

import call from "../../../../static/images/call.png";
import deliver from "../../../../static/images/deliver.png";

const CheckoutSuccess = () => {
	const { consultation} = useSelector(
		({cart}: StateType) => ({
			// subscriptionIds: cart.lastSuccessSubscription.subscriptionIds,
			consultation: cart.lastSuccessSubscription.consultation,
		})
	);
	// const currentDate = new Date();
	const formattedConsultationDate =
		`${moment(consultation?.date).format("MMMM Do, YYYY")} 
		at ${consultation?.time.toLocaleUpperCase()}`;

	return (
		<div id="success">
			<div className="checkoutSuccess__content">
			
			<div className="section-8">
				<div className="container-23 w-container">
				<div className="w-row">
					<div className="w-col w-col-3"></div>
					<div className="column-37 w-col w-col-6">
					<div className="div-block-24">
						<h1 className="heading-27">Thank you, we’ve received your order.</h1>
						<div className="text-block-36">An email will be sent shortly with your order and appointment details.</div>
					</div>
					<h1 className="heading-28">What happens next?</h1>
					<div className="div-block-49"><img src={call}  width="59" alt="call" />
						<div className="text-block-39">Status: <br/>Complete your doctor consultation on<br/><strong>{formattedConsultationDate}</strong></div>
					</div>
					<h1 className="heading-29">Please note:</h1>
					<div className="text-block-40">The doctor may call from a private number so please ensure your phone settings do not block these types of calls. You will not be charged until your treatment is shipped.</div>
					<div className="div-block-39"><img src={deliver}  width="59" alt="deliver" />
						<div className="text-block-41">Once the doctor consultation is complete, we will process and deliver your order</div>
					</div>
					<div className="text-block-43">We will email you with all changes and updates. You can also keep track of the status of your order through your Profile under Order History, and check if all steps have been completed.</div>
					<Link to="/profile/order-history" className="button-15 w-button">Track my order</Link>
					<div className="text-block-42">Need to make a change? <strong><br/>Contact us:</strong> <span className="text-span-2"><a href="mailto:WeListen@askwinston.ca">WeListen@askwinston.ca</a></span></div>
					</div>
					<div className="w-col w-col-3"></div>
				</div>
				</div>
			</div>
			</div>
		</div>
	);
};

export default CheckoutSuccess;
