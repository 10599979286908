import React, {Component} from "react";
import "./styles.scss";
import howItWorksStart from "../../static/images/how-it-works-start.png";
import howItWorksStep1 from "../../static/images/how-it-works-step1.png";
import howItWorksStep2 from "../../static/images/how-it-works-step2.png";
import howItWorksStep3 from "../../static/images/how-it-works-step3.png";
import iconStepSvg01 from "../../static/images/icon-step01.svg";
import iconStepSvg02 from "../../static/images/icon-step02.svg";
import iconStepSvg03 from "../../static/images/icon-step03.svg";
import {Link} from "react-router-dom";
import { Title } from "react-meta-elements";

export default class HowItWorksComponent extends Component<{}, {}> {
	public render() {
		let search = window.location.search;
		if(search!=""){
			window.sessionStorage.setItem("utm_source", search.split('?')[1]);
		}
		return (
			<>
					<Title>
					How it works| Winston|Discreet Consultation
					</Title>
					
			<div className="how-it-works-component prefiles">
				<div className="start">
					<div className="left-part">
						<div className="title">
							<h1>How It works</h1>
							<div className="horizontal-line"></div>
						</div>
						<div className="content">
							<p>
								Let’s face it, your time is valuable and wasting it sitting in a congested doctor’s
								office or in a lineup at your pharmacy is not how you want to spend it.
							</p>
							<p className="padding-top">
								Winston is your personal healthcare concierge. Not only does Winston make it simple
								and convenient to access the care and medication you need, when you need it, but
								Winston also understands the importance of being discreet.
							</p>
							<p className="padding-top">Winston works for you!</p>
						</div>
					</div>
					<div className="right-part">
						<img src={howItWorksStart} alt="How it works- Winston|Discreet Consultation" />
					</div>
				</div>
				<div className="steps">
					<div className="step">
						<div className="left-part">
							<img src={howItWorksStep1} alt="How it works- Winston|Discreet Consultation" />
						</div>
						<div className="right-part">
							<div className="title">
								<div className="number">
									<p>01</p>
								</div>
								<div className="text">
									<div className="text-line">
										<h3>Choose</h3>
										<img src={iconStepSvg01} alt="How it works- Winston|Discreet Consultation" />
									</div>
									<h3>Your Product</h3>
								</div>
							</div>
							<div className="content">
								<p>Choose from the most reputable men’s health medications on the market.</p>
							</div>
						</div>
					</div>
					<div className="step-even">
						<div className="left-part">
							<div className="title">
								<div className="number">
									<p>02</p>
								</div>
								<div className="text">
									<div className="text-line">
										<h3>Discreet</h3>
										<img src={iconStepSvg02} alt="How it works- Winston|Discreet Consultation" />
									</div>
									<h3>Consultation</h3>
								</div>
							</div>
							<div className="content">
								<p>
									Fill out a short questionnaire about your medical history and our doctor will
									determine a personalized treatment plan.
								</p>
							</div>
						</div>
						<div className="right-part">
							<img src={howItWorksStep2} alt="How it works- Winston|Discreet Consultation" />
						</div>
					</div>
					<div className="step">
						<div className="left-part">
							<img src={howItWorksStep3} alt="How it works- Winston|Discreet Consultation" />
						</div>
						<div className="right-part">
							<div className="title">
								<div className="number">
									<p>03</p>
								</div>
								<div className="text">
									<div className="text-line">
									<h3>Free</h3>
										<img src={iconStepSvg03} alt="How it works- Winston|Discreet Consultation" />
									</div>
									<h3>Delivery</h3>
								</div>
							</div>
							<div className="content">
								<p>
									The medication will be conveniently delivered in a discreet package, right to your
									door. Shipping is included at no charge with all Winston orders.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="get-started-section">
					<div className="title">Ready to Get Started?</div>
					<div className="buttons">
						<Link to="/shop">
							<button>What We Treat</button>
						</Link>
						<Link to="/contact-us">
							<button>Talk to a Doctor</button>
						</Link>
					</div>
				</div>
			</div>
			</>
		);
	}
}
