import http from "../index";
import {ProductCategory} from "../../model/ProductCategory";

const addDatesQuery = (dates: string[]) => dates.length ? `?dates=${dates.join("&dates=")}` : "";

export const getAvailable = (
	{dates, problemCategory}: {dates: string[], problemCategory: ProductCategory}) =>
	http.get(`/schedule/available${addDatesQuery(dates)}`, {params: {problemCategory}});

export const getMyBooking = () => http.get("/schedule/my");

export const bookSlot = (data: {
	slotId: number;
	subscriptionId: number[];
}) => http.put("/schedule/book", data);

export const createDoctorSchedule = (data: {
	events: {start: string, end: string}[],
	recurring: boolean,
}) => {
	return http.post("/schedule/doctor", data);
};

export const getAllDoctorSlots = (dates: string[]) => http.get(`/schedule/doctor/all${addDatesQuery(dates)}`);
export const getFreeDoctorSlots = (dates: string[]) => http.get(`/schedule/doctor/free${addDatesQuery(dates)}`);

export const deleteDoctorSlot = (slotList: number[]) => http.delete(`/schedule/doctor/slot`, {data: slotList});