import React from "react";
import SelectedProduct from "../../../../model/SelectedProduct";
import "./styles.scss";
import CartSummary from "../CartSummary";
import PatientCheckoutDetails from "../PatientCheckoutDetails";

interface ConfirmProps {
	selectedProducts: SelectedProduct[];
	doctorConsultDate: string | undefined;
}

const Confirm = (props: ConfirmProps) => {

	return (
		<div className="confirm-component" id="confirm">
			<div className="content">
				{/* <div className="header">Your order summary</div>
				<div className="subtitle">Please confirm your order.</div>
				<div className="cart-summary">
					<CartSummary />
				</div>

				<PatientCheckoutDetails doctorConsultDate={props.doctorConsultDate} /> */}

				
				<div className="section-7">
					<div className="container-22 w-container">
					<div className="w-row">
						<div className="w-col w-col-3"></div>
						<div className="column-68 w-col w-col-6">
						<h1 className="heading-22">Please review and confirm your order</h1>
						{/* <div className="text-block-61">Please confirm your order</div> */}

						{/* cart summary */}
						<CartSummary />

						{/* patient checkout */}

						<PatientCheckoutDetails doctorConsultDate={props.doctorConsultDate}/>
						
						{/* <a href="/confirmation-page" className="button-12 w-button">Process Order</a> */}
						</div>
						<div className="w-col w-col-3"></div>
					</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Confirm;
