import React, {useState,useRef,forwardRef, ChangeEvent,useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import moment from "moment-timezone";

import {changePassword} from "../../../../http/patient";

import * as patientActions from "../../../../redux/patient/handlers";
import {StateType} from "../../../../redux/types";

import ValidationService from "../../../../service/Validation.service";

import MessageConst from "../../../../const/Message.const";

import {compressImage} from "../../../../utils/helpers";

import ImageUploadComponent from "../../../ImageUpload";
import ImageViewComponent from "../../../ImageView";
import CustomInput from "../../../CustomInput";
import LoaderComponent from "../../../Loader";
import PopUp from "../../../PopUp";
import AddShippingAddress from "../AddShippingAddress";
import ShippingAddresses from "../ShippingAddresses";

import IDsvg from "../../../../static/images/icon-id.svg";
import IconConfirmGreen from "../../../../static/images/_confirmGreen.svg";
import WarningImg from "../../../../static/images/warning.png";

import "./styles.scss";

interface AccountInfoProps{
}

const AccountInfo =forwardRef ((props:AccountInfoProps) => {
	const dispatch = useDispatch();
	const {profile, loading} = useSelector(({patient, auth}: StateType) => ({
		profile: auth.profile,
		loading: patient.updatingProfile
	}));
	const phoneInput = useRef<HTMLInputElement>(null);

	const [editMode, setEditMode] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	

	const [phone, setPhone] = useState<string>('');
	const [firstName, setFirstName] = useState<string>('');
	const [lastName, setLastName] = useState<string>('');

	const [dob, setDOB]= useState<string>('')

	const [changePassModalOpen, setChangePassModalOpen] = useState<boolean>(false);
	const [emtyProfileOpen,setemtyProfileOpen]= useState<boolean>(false);
	const [emtyProfileContent,setemtyProfileContent]= useState<boolean>(false);
	const [showEmail,setShowEmail]= useState<boolean>(true);
	const [isImageViewShow, setIsImageViewShow] = useState<string | null>(null);
	const [imageType, setImageType] = useState<string>("");

	const [oldPass, setOldPass] = useState<string>("");
	const [newPass, setNewPass] = useState<string>("");
	const [confirmNewPass, setConfirmNewPass] = useState<string>("");

	const [validationMessage, setValidationMessage] = useState<string>("");
	const [editValidationMessage, setEditValidationMessage] = useState<string>("");	

	useEffect(()=>{
		setFirstName(profile!.firstName);
		setLastName(profile!.lastName);
		setPhone(profile!.phone);
		setDOB(profile!.birthday);
	}, [profile]);

	const handleOpenImgView = (type: string, id: string) => {
		setImageType(type);
		setIsImageViewShow(id);
	};

	const handleCloseImgView = () => {
		setImageType("");
		setIsImageViewShow(null);
	};
	const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
		const firstName: string = ValidationService.nameConverter(e.target.value);
		setFirstName(firstName)
	}
	const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
		const lastName: string = ValidationService.nameConverter(e.target.value);
		setLastName(lastName)
	}
	const handleDOBChange = (e: ChangeEvent<HTMLInputElement>) => {
		const dob: string = e.target.value;
		setDOB(dob)
	}

	const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
		const phone: string = ValidationService.phoneConverter(e.target.value);

		setPhone(phone);
	};

	const handleIdImgChange = async (file: File) => {
		compressImage(file, (newFile: Blob) => dispatch(patientActions.uploadIdRequest(newFile)));
		// dispatch(patientActions.uploadIdRequest(file));
	};

	const handleInsuranceImgChange = async (file: File) => {
		compressImage(file, (newFile: Blob) =>
			dispatch(patientActions.uploadInsuranceRequest(newFile))
		);
		// dispatch(patientActions.uploadInsuranceRequest(file));
	};
	useEffect(() => {
		let popup = sessionStorage.getItem('emtyPopup')
		if(popup == "open"){
		if(
			profile?.firstName==null &&
			profile?.lastName==null &&
			profile?.phone==null &&
			profile?.birthday==null){
			setemtyProfileOpen(true)
			sessionStorage.setItem("emtyPopup","close")		
		}
		}		
	  }, []);
	useEffect(()=> {
		handleOpenEmtyPrfileContent();	
	}, [profile]);
	
	const handleCloseEmtyProfile=()=>{
		setemtyProfileOpen(false)
	}
	const handleOpenEmtyPrfileContent = () =>{
		if(
			profile?.firstName==null &&
			profile?.lastName==null &&
			profile?.phone==null &&
			profile?.birthday==null){
				setemtyProfileContent(true)	
		}else{
			setemtyProfileContent(false)
		}
	}
	const handleProfileChange = async () => {
		
		if(!ValidationService.validateRequired(firstName)){
			
			setEditValidationMessage(MessageConst.validation.firstNameError)
			return;
		}
		if(!ValidationService.validateRequired(lastName)){
			
			setEditValidationMessage(MessageConst.validation.lastNameError)
			return;
		}
				
		if(!ValidationService.validateBirthday(dob)){
		
			setEditValidationMessage(MessageConst.validation.birthdayError)
			return;
		}
		if (!ValidationService.validatePhone(phone)) {
			
			setEditValidationMessage(MessageConst.validation.phoneError);

			return;
		}
	
		
		let birthday= dob

		const newProfile = {
			...profile,
			 phone,
			 firstName,
			 lastName,
			 birthday
			};

		dispatch(patientActions.updateProfileRequest({profile: newProfile, setValidationMessage}));

		setEditValidationMessage("");
		setEditMode(false);
		setShowEmail(true);
		setemtyProfileContent(false)	
	};

	const handleClosePassModal = () => {
		setChangePassModalOpen(false);
		setOldPass("");
		setNewPass("");
		setConfirmNewPass("");
		setValidationMessage("");
	};

	const handleSubmitChangePassword = async () => {
		if (!oldPass) {
			setValidationMessage(MessageConst.validation.currentPasswordError);

			return;
		}

		if (!ValidationService.validatePassword(newPass)) {
			if (newPass.length === 0) {
				setValidationMessage(MessageConst.validation.passwordBlankError);
			} else {
				setValidationMessage(MessageConst.validation.passwordError);
			}
			return;
		}

		if (!ValidationService.validatePasswordEquality(newPass, confirmNewPass)) {
			setValidationMessage(MessageConst.validation.passwordErrorEquality);

			return;
		}

		try {
			setIsLoading(true);
			await changePassword({oldPassword: oldPass, newPassword: newPass});
			setChangePassModalOpen(false);
			setOldPass("");
			setNewPass("");
			setConfirmNewPass("");
			setValidationMessage("");
		} catch (err: any) {
			if (err?.response?.data?.message) {
				setValidationMessage(err.response.data.message);
			} else {
				setValidationMessage(MessageConst.error.unknownError);
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="account-info-wrapper">
			{(loading || isLoading) && <LoaderComponent showShadow isFixed />}
			<h1 className="title">Account Information</h1>
			{emtyProfileContent?
			<div className="emtyPrfileContent">
				<img alt="img" src={WarningImg} />
			<p>Please update your Personal Information prior to submitting an order</p>
			</div>: null
			}
			<div className="user-info">
				<div className="user-name">
					<div className="user-name-title">Name</div>
					<div className="user-name-text">
					{editMode ? (
						<div className="input-edit">
							<CustomInput
							value={firstName}
							onChange={handleFirstNameChange}
							placeholder="Enter your First Name" />
							<CustomInput
							value={lastName}
							onChange={handleLastNameChange}
							placeholder="Enter your Last Name" />
							</div>
						):(profile?.firstName+" "+profile?.lastName)}</div>
				</div>
				<div className="user-birthdate">
					<div className="user-birthdate-title">Birthdate</div>
					<div className="user-birthdate-text">
						{editMode ? (
							<input	
							className="input-date"					
							type="date"
							
							value={dob}
							onChange={handleDOBChange}
							placeholder="Enter your phone" />
						):(
							moment(profile?.birthday).format("MMM D, YYYY")
						)}
						
					</div>
				</div>
				{showEmail &&
				<div className="user-email">
					<div className="user-email-title">Email Address</div>
					<div className="user-email-text">{profile?.email}</div>
				</div>
				}

				<div className="user-phone">
					<div className="user-phone-title">Phone Number</div>
					<div className="user-phone-text">
						{editMode ? (
							<CustomInput
								value={phone}
								onChange={handlePhoneChange}
								placeholder="Enter your phone"
							/>
						) : (
							profile?.phone
						)}
					</div>
				</div>
			</div>
			<div className="action-btns">
				{editMode ? (
					<button onClick={handleProfileChange}>Save Account Information</button>
				) : (
					<button onClick={() => {setEditMode(true);setShowEmail(false)}}>Edit Account Information</button>
				)}
				<button onClick={() => setChangePassModalOpen(true)}>Change Password</button>
				{editValidationMessage && <div className="validation-error">{editValidationMessage}</div>}
			</div>

			<div className="id-upload-block">
				<div className="title">Identification</div>
				<div className="subtitle">Upload a photo of your valid government I.D. Ex/ </div>
				<div className="img-buttons">
					<ImageUploadComponent
						img={!!profile!.idDocument}
						onImgChange={handleIdImgChange}
						mode="ID"
					/>
					{profile!.idDocument && (
						<label
							className="view-img"
							onClick={() => handleOpenImgView("ID", profile!.idDocument!)}
						>
							<img src={IDsvg} alt="" />
							<p className="view-img-text">View image</p>
						</label>
					)}
				</div>
				{profile!.idDocument && (
					<div className="success-upload-id">
						<span>ID uploaded successfully!</span>
						<img src={IconConfirmGreen} alt="" />
					</div>
				)}
			</div>
			<div className="insurance-upload-block">
				<div className="title">Insurance Card</div>
				<div className="subtitle">Upload a photo of the front of your insurance card.</div>
				<div className="img-buttons">
					<ImageUploadComponent
						img={!!profile!.insuranceDocument}
						onImgChange={handleInsuranceImgChange}
						mode="Insurance"
					/>
					{profile!.insuranceDocument && (
						<label
							className="view-img"
							onClick={() => handleOpenImgView("Insurance", profile!.insuranceDocument!)}
						>
							<img src={IDsvg} alt="" />
							<p className="view-img-text">View image</p>
						</label>
					)}
				</div>
				{profile!.insuranceDocument && (
					<div className="success-upload-id">
						<span>Insurance uploaded successfully!</span>
						<img src={IconConfirmGreen} alt="" />
					</div>
				)}
			</div>
			<div className="title">Shipping Address</div>
			<ShippingAddresses />
			<AddShippingAddress />
			{changePassModalOpen && (
				<PopUp>
					<div className="change-pass-modal">
						<div className="title">Change Password</div>
						<CustomInput
							label="Current password"
							type="password"
							placeholder="Enter your current password"
							value={oldPass}
							onChange={(e: ChangeEvent<HTMLInputElement>) => setOldPass(e.target.value)}
						/>
						<CustomInput
							label="New password"
							type="password"
							placeholder="Enter your new password"
							value={newPass}
							onChange={(e: ChangeEvent<HTMLInputElement>) => setNewPass(e.target.value)}
						/>
						<CustomInput
							label="Confirm new password"
							type="password"
							placeholder="Re-enter your new password"
							value={confirmNewPass}
							onChange={(e: ChangeEvent<HTMLInputElement>) => setConfirmNewPass(e.target.value)}
						/>
						{validationMessage && <div className="validation-error">{validationMessage}</div>}
						<div className="action-buttons">
							<button onClick={handleClosePassModal}>Cancel</button>
							<button onClick={handleSubmitChangePassword}>Submit</button>
						</div>
					</div>
				</PopUp>
			)}
			{emtyProfileOpen &&(
				<PopUp>
				<PopUp>
					<div className="exit-modal">
					<div className="title">PERSONAL INFORMATION REQUIRED</div>
					<div className="content">
					<p>Please update your Personal Information on your Account
					 Profile Page prior to submitting an order</p>
						 </div>
					<div className="button-links">
							<button  onClick={handleCloseEmtyProfile}>Ok</button>
						</div>
						</div>
				</PopUp>
				</PopUp>
			)}
			{isImageViewShow && (
				<ImageViewComponent
					imageViewProps={isImageViewShow}
					close={handleCloseImgView}
					title={`${imageType} image`}
				/>
			)}
		</div>
	);
});

export default AccountInfo;
