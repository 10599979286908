import moment from "moment";
import Compressor from "compressorjs";

import SelectedProduct from "../model/SelectedProduct";
import Product from "../model/Product";
import CartItem from "../model/CartItem";
import Order from "../model/Order";

import pillsProduct from "../static/images/pillsProduct.png";
import creamProduct from "../static/images/creamProduct.png";
import gelProduct from "../static/images/gelProduct.png";
import foamProduct from "../static/images/foamProduct.png";
import gelAndPillsProduct from "../static/images/gelAndPillsProduct.png";

import Sildenafil from "../static/images/sildenafil-bottle-and-box-no-background.png";
import Tadalafil from "../static/images/tadalifil-box-and-pills-no-background.png";
import Foam from "../static/images/hair-loss-foam.png";
import Finasteride from "../static/images/Rectangle-3.png";
import Minoxidil_FoamAndFinasteride from "../static/images/hair-loss-cream-and-pill.png";
import Valacyclovir from "../static/images/cold-sore-pill.png";
import Acyclovir_Topical from "../static/images/acne-cream.png";
import Rupall from "../static/images/allergy-product-img.png"

import visa from "../static/images/CardBrands/visa.png";
import americanExpress from "../static/images/CardBrands/american-express.png";
import dinersClub from "../static/images/CardBrands/diners-club.png";
import discover from "../static/images/CardBrands/discover.png";
import JCB from "../static/images/CardBrands/jcb.png";
import masterCard from "../static/images/CardBrands/mastercard.png";
import unionPay from "../static/images/CardBrands/unionpay.png";
import unknownCard from "../static/images/CardBrands/unknown.png";
import ProductQuantity from "../model/ProductQuantity";
import ShippingAddress from "../model/ShippingAddress";

//MalePatternBaldness popup
import {Sildenafil2, Tadalafil2, Acyclovir_Topical2,Valacyclovir2,
	Tretinoin2, Finasteride2, Foam2,Rupal_Topic2, Sildenafil3, Tadalafil3, Acyclovir_Topical3, Valacyclovir3, Minoxidil_FoamAndFinasteride3,
Tretinoin3, Finasteride3,Foam3,Rupall3} from "../components/MalePatternPopUp";

export const formatProductToCart = (selectedProduct: Product): SelectedProduct => {
	return {
		product: selectedProduct,
		quantity: selectedProduct.quantities[selectedProduct.selectedQuantityIndex],
		count: 1
	} as SelectedProduct;
};

export const formatCartItemToSave = (cartItem: SelectedProduct): CartItem => ({
	productId: cartItem.product.id,
	productQuantityId: cartItem.quantity.id,
	productCount: 1
});

export const getProductImage = (form: string) => {
	switch (form) {
		case "Sildenafil":
			return Sildenafil;
		case "Tadalafil":
			return Tadalafil;
		case "Minoxidil 5% Foam":
			return Foam;
		case "Finasteride":
			return Finasteride;
		case "Minoxidil 5% Foam and Finasteride":
			return Minoxidil_FoamAndFinasteride;
		case "Minoxidil 5% Foam and Finasteride Combo":
			return Minoxidil_FoamAndFinasteride;
		case "Tretinoin 0.025%":
			return Foam;
		case "Clindamycin + Benzoyl Peroxide":
			return Foam;
		case "Valacyclovir":
			return Valacyclovir;
		case "Acyclovir Topical":
			return Acyclovir_Topical;
		case "PILLS":
			return pillsProduct;
		case "FOAM":
			return foamProduct;
		case "CREAM":
			return creamProduct;
		case "GEL":
			return gelProduct;
		case "COMBO_PACK":
			return gelAndPillsProduct;
		case "Rupall":
			return Rupall


	}
};

export const getMalePatternBaldness = (form: string) => {
	switch (form) {
		case "Sildenafil":
			return Sildenafil2();
		case "Tadalafil":
			return Tadalafil2();
		case "Minoxidil 5% Foam":
			return Foam2();
		case "Finasteride":
			return Finasteride2();
		case "Minoxidil 5% Foam and Finasteride":
			return Foam2();
		case "Minoxidil 5% Foam and Finasteride Combo":
			return Foam2();
		case "Tretinoin 0.025%":
			return Tretinoin2();
		case "Clindamycin + Benzoyl Peroxide":
			return Tretinoin2();
		case "Valacyclovir":
			return Valacyclovir2();
		case "Acyclovir Topical":
			return Acyclovir_Topical2();
		case "Rupall":
			return Rupal_Topic2();

	}
};

export const getMalePatternButtonName = (form: string) => {
	switch (form) {
		case "Sildenafil":
			return Sildenafil3();
		case "Tadalafil":
			return Tadalafil3();
		case "Minoxidil 5% Foam":
			return Foam3();
		case "Finasteride":
			return Finasteride3();
		case "Minoxidil 5% Foam and Finasteride":
			return Minoxidil_FoamAndFinasteride3();
		case "Minoxidil 5% Foam and Finasteride Combo":
			return Minoxidil_FoamAndFinasteride3();
		case "Tretinoin 0.025%":
			return Tretinoin3();
		case "Clindamycin + Benzoyl Peroxide":
			return Tretinoin3();
		case "Valacyclovir":
			return Valacyclovir3();
		case "Acyclovir Topical":
			return Acyclovir_Topical3();
		case "Rupall":
			return 	Rupall3();

	}
};

export const refreshTokenSetup = (res:any) => {
	// Timing to renew access token
	let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;
  
	const refreshToken = async () => {
	  const newAuthRes = await res.reloadAuthResponse();
	  refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
	  console.log('newAuthRes:', newAuthRes);
	  // saveUserToken(newAuthRes.access_token);  <-- save new token
	  localStorage.setItem('authToken', newAuthRes.id_token);
  
	  // Setup the other timer after the first one
	  setTimeout(refreshToken, refreshTiming);
	};
  
	// Setup first refresh timer
	setTimeout(refreshToken, refreshTiming);
  };

export const formatCartItemPrice = (productQuantity: ProductQuantity, count: number): string => {
	return formatMoney(
		parseFloat((productQuantity.price * count / 100).toFixed(2))
	);
};

export const formatMoney = (amount: number): string => {
	return `$${amount}`;
};

let discountMultiplier = 1.0;
let discountAddition = 0.0;
export const calculateCart = (products: SelectedProduct[], promoCode: any, isExpressDelivery:boolean): number => {
	let total: number = 0;
	products.forEach((selectedProduct: SelectedProduct) => {
		total += selectedProduct.quantity.price * selectedProduct.count;
	});
	if (promoCode) {
		switch (promoCode.type.toString()) {
			case "AMOUNT":
				discountAddition = -promoCode.value;
				break;
			case "PERCENT":
				discountMultiplier = 1.0 - promoCode.value / 100.0;
				break;
		}
	}
	total = total * discountMultiplier + discountAddition;
	if(isExpressDelivery){
		total = total + (9.99 * 100);
	}
	return parseFloat((total / 100).toFixed(2));
};

export const calculateDiscount = (products: SelectedProduct[], promoCode:any):number =>{
	let discountMultiplier = 1.0;
	let discountAddition = 0.0;
	
	let total: number = 0;
	products.forEach((selectedProduct: SelectedProduct) => {
		total += selectedProduct.quantity.price * selectedProduct.count;
	});
	if (promoCode) {
		switch (promoCode.type.toString()) {
			case "AMOUNT":
				discountAddition = -promoCode.value;
				break;
			case "PERCENT":
				discountMultiplier = 1.0 - promoCode.value / 100.0;
				break;
		}
	}
	total = total - (total * discountMultiplier + discountAddition);
	return parseFloat((total / 100).toFixed());
}

export const formatProductNameToURL = (name: string) => {
	const url = name.toLowerCase().replace(/[^a-zA-Z]+/g, "-");
	const lastCharacter = url.charAt(url.length - 1);

	return lastCharacter === "-" ? url.substring(0, url.length - 1) : url;
};

export const formatBillingAddress = (address: ShippingAddress) => {
	let formattedAddr: string = "";

	formattedAddr += address.addressLine1;
	if (address.addressLine2) {
		formattedAddr += `, ${address.addressLine2}`;
	}
	formattedAddr += `, ${address.addressCity}`;
	formattedAddr += `, ${address.addressProvince}`;
	formattedAddr += `, ${address.addressPostalCode}`;

	return formattedAddr;
};

export const getCardBrandImage = (brand?: string) => {
	switch (brand) {
		case "V": {
			return visa;
		}
		case "AX": {
			return americanExpress;
		}
		case "D": {
			return dinersClub;
		}
		case "NO": {
			return discover;
		}
		case "C1": {
			return JCB;
		}
		case "M": {
			return masterCard;
		}
		case "U": {
			return unionPay;
		}
		default: {
			return unknownCard;
		}
	}
};

export const sortOrdersByDateDecrease = (a: Order, b: Order) => {
	const firstDate = +moment(a.date).format("X");
	const secondDate = +moment(b.date).format("X");

	return secondDate - firstDate;
};

type compressCb = (newFile: Blob) => void;

export const compressImage = (file: Blob, cb: compressCb) => {
	if (file.type === "application/pdf") {
		cb(file);
	} else {
		// tslint:disable-next-line:no-unused-expression
		new Compressor(file, {
			quality: 0.6,
			maxWidth: 2000,
			maxHeight: 2000,
			success(newFile) {
				cb(newFile);
			},
			error(err) {
				console.log(err.message);
			}
		});
	}
};
 

