import React, {useState} from "react";

import WebcamPhoto from "../WebcamPhoto";

import iconUploadSvg from "../../static/images/upload-icon.png";
import takePhotoIcon from "../../static/images/take-photo-icon.png";

import "./styles.scss";

interface ImageUploadProps {
	img: boolean;
	onImgChange: (file: File) => void;
	mode: string;
}

const ImageUpload = (props: ImageUploadProps) => {
	const [isWebCamOpen, setIsWebcamOpen] = useState<boolean>(false);

	const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length !== 0) {
			props.onImgChange(e.target.files[0]);
		}
	};

	const handleTakeScreenshot = (file: File) => {
		props.onImgChange(file);
	};

	return (
		<>
			<span className={`upload`}>
				<label className="photo webcam" onClick={() => setIsWebcamOpen(true)}>
					<p className="photo-text">Take photo</p>
					<img src={takePhotoIcon} alt="" />
				</label>
				<label className="photo">
					<p className="photo-text">Upload Image</p>
					<img src={iconUploadSvg} alt="" />
					<input type="file" onChange={handleFileInputChange} />
				</label>
			</span>
			{isWebCamOpen && (
				<WebcamPhoto
					uploadPhoto={handleTakeScreenshot}
					onClose={() => setIsWebcamOpen(false)}
					mode={props.mode}
				/>
			)}
		</>
	);
};

export default ImageUpload;
