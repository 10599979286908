import {call, put, takeLatest, Effect, select, all} from "redux-saga/effects";

import * as types from "./handlers";
import * as cartTypes from "../cart/handlers";

import {getProfile, getSelectedProducts} from "../selectors";

import * as httpProducts from "../../http/product";

import Product from "../../model/Product";
import SelectedProduct from "../../model/SelectedProduct";
import ProductQuantity from "../../model/ProductQuantity";

function* onGetProducts(): Generator<any, void, any> {
	try {
		const result = yield call(httpProducts.getProducts);
		const products = result.data;
		const profile = yield select(getProfile);
		const cart = yield select(getSelectedProducts);

		products.forEach((product: Product) => {
			product.quantities.sort((q1, q2) => q1.ordinal - q2.ordinal);
			product.selectedQuantityIndex = product.quantities.findIndex(
				(quantity) => quantity.isDefault === 1
			);
			if (product.selectedQuantityIndex === -1) {
				product.selectedQuantityIndex = 0;
			}
		});

		if (cart) {
			const productsToDelete: SelectedProduct[] = [];
			cart.forEach((product: SelectedProduct) => {
				const productInDB = products.find((p: Product) => p.id === product.product.id);
				if (productInDB) {
					const quantityInDB = productInDB.quantities.find(
						(q: ProductQuantity) => q.id === product.quantity.id
					);
					if (!quantityInDB) {
						productsToDelete.push(product);
					}
				} else {
					productsToDelete.push(product);
				}
			});

			yield all(productsToDelete.map((p) => put(cartTypes.deleteProductSuccess(p))));
		}

		if (profile?.authority === "PATIENT") {
			yield put(cartTypes.getCartRequest());
		}

		yield put(types.getProductsSuccess(products));
	} catch (error) {
		console.log(error);
		yield put(types.getProductsFailure(error));
	}
}

const productSagas: Effect[] = [takeLatest(types.getProductsRequest, onGetProducts)];

export default productSagas;
