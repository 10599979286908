import {ReactElement} from "react";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import MessageConst from "../../const/Message.const";
import {ProductCategory} from "../../model/ProductCategory";
import { ActionType } from "../types";

export interface AppState {
	loading: boolean;
	infoStrip: string;
	shopScrollTarget: ProductCategory | null;
	isSubscribed: string | null;
	profilePageToOpen: string | null;
	closeSidebarDirection: {pathname: string, y: number} | null;
	isCheckout:boolean;
	nextPath: string
}

const initialState: AppState = {
	loading: true,
	infoStrip: MessageConst.infoStrip,
	shopScrollTarget: null,
	isSubscribed: localStorage.getItem("isSubscribed"),
	profilePageToOpen: null,
	closeSidebarDirection: null,
	isCheckout: true,
	nextPath: ""
};

const appSlice = createSlice({
	name: 'app', 
	initialState,
	reducers: {
		setLoading(state: AppState, action: ActionType) {
		  state.loading = action.payload; 
		},
		showPopup(state: AppState, action: ActionType) {
		  state.loading = action.payload;
		},
		setIsSubscribed(state: AppState, action: ActionType) {
		  localStorage.setItem('isSubscribed', action.payload || '');
		  state.isSubscribed = action.payload;
		},
		setShopScrollTarget(state: AppState, action: ActionType) {
		  state.shopScrollTarget = action.payload;
		},
		setProfilePageToOpen(state: AppState, action: ActionType) {
		  state.profilePageToOpen = action.payload;
		},
		setCloseSidebarDirection(
		  state: AppState,
		  action: ActionType
		) {
		  state.closeSidebarDirection = action.payload;
		},
		setIsCheckout(state: AppState, action: ActionType) {
		  state.isCheckout = action.payload;
		},
		setNextPath(state, action) {
			state.nextPath = action.payload;
		  },
		clearNextPath(state) {
			state.nextPath = ""; 
		},
	  },
});

export const {
	setLoading,
	showPopup,
	setIsSubscribed,
	setShopScrollTarget,
	setProfilePageToOpen,
	setCloseSidebarDirection,
	setIsCheckout,
	setNextPath,
	clearNextPath
  } = appSlice.actions;
  
export default appSlice.reducer;