import React, {useEffect, useRef, forwardRef, Ref, useImperativeHandle } from "react";
import MessageConst from "../../const/Message.const";
import "./styles.scss";

export interface MonerisInputRefObject {
	doMonerisSubmit: () => void;
}

interface MonerisInputProps {
	addCb: (token: string) => void;
	setValidationMessage: (msg: string) => void;
	setCheckingCard: (b: boolean) => void;
	shrink: boolean;
}

const MonerisInput = forwardRef(
	(
		{addCb, setValidationMessage, setCheckingCard, shrink}: MonerisInputProps,
		ref: Ref<MonerisInputRefObject>
	) => {
		// const [showCSVHelp, setShowCSVHelp] = useState<boolean>(false);

		useImperativeHandle(ref, () => ({doMonerisSubmit}));

		const monFrameRef = useRef<HTMLIFrameElement>(null);

		const doMonerisSubmit = () => {
			const refObj = monFrameRef.current!.contentWindow;
			refObj!.postMessage("tokenize", process.env.REACT_APP_MONERIS_URL!);
			// change link according to table above
		};

		useEffect(() => {
			const respMsg = (e: any) => {
				if (e?.data && typeof e.data === "string") {
					const result = JSON.parse(e.data);
					console.log(result);
					if (
						result?.responseCode &&
						result.responseCode.some((code: string) => ["943", "944", "945"].includes(code))
					) {
						setValidationMessage(MessageConst.validation.generalCardError);
					} else if (
						result?.responseCode &&
						result.responseCode.some((code: string) => ["940", "941", "942"].includes(code))
					) {
						setValidationMessage(MessageConst.error.unknownError);
					} else {
						addCb(result.dataKey);
						setValidationMessage("");
					}

					setCheckingCard(false);
				}
			};

			window.addEventListener("message", respMsg, false);

			return () => {
				window.removeEventListener("message", respMsg);
			};
		}, [addCb, setValidationMessage, setCheckingCard]);

		return (
			<div className="paymentform">

				<div className="paymentLabels">
					<div className="cardLabel"><span>Credit card number</span></div>
					<div className="expiry"><span>Expiry date</span></div>
					<div className="cvc"><span>CVC</span></div>
				</div>

				<div className="paymentInputs">
				<div id="monerisResponse" />
				<iframe
						id="monerisFrame"
						ref={monFrameRef}
						title="Moneris"
						src={`${process.env.REACT_APP_MONERIS_URL}?id=${
							process.env.REACT_APP_MONERIS_PROFILE
						}&pmmsg=true&
						css_body=background:white;width:100%;
						align-items: center; padding-left: 3px; box-sizing: border-box;height: 180px;
						&css_textbox=padding: 30px 10px;
						border-radius: 5px;margin-top:50px;
						font-family: "Avenir-Book",sans-serif; display: block;
						width: 100%;
						height: 38px; 
						margin-bottom: 10px;
						font-size: 14px;
						line-height: 1.428571429; 
						vertical-align: middle;
						background-color: white;
						border: 1px solid rgb(204, 204, 204); 
						color: rgb(51, 51, 51);
						
						&css_textbox_pan=width:100%;
						font-size: 14px;&enable_exp=1&display_labels=2&
						css_textbox_exp=width:48%;margin-right: ${shrink ? "10px" : "50px"};
						&enable_cvd=1&css_input_label=font-family: "Avenir-Book",sans-serif;
						font-size: 16px;font-weight: 500;&
						css_textbox_cvd=width: 48%;
						position: relative;
						top: -112px;
						left: 52%;&pan_label=1234 5678 9012 3456&exp_label=MM/ YY&cvd_label=123`}
						frameBorder="0"						
						style={{position: "relative", left: "-3px"}}
					/>
				</div>
				
			</div>
		);
	}
);

export default MonerisInput;
