import React, {useState, ChangeEvent} from "react";

import {rejectOrder} from "../../../../http/orders";

import MessageConst from "../../../../const/Message.const";

import PopUp from "../../../PopUp";
import Order from "../../../../model/Order";
import CustomInput from "../../../CustomInput";
import LoaderComponent from "../../../Loader";

import "./styles.scss";

interface RejectOrderPopUpProps {
	order: Order;
	onClose: () => void;
	notify: (msg: string) => void;
	loadOrders: () => void;
}

const RejectOrderPopUp = ({order, onClose, notify, loadOrders}: RejectOrderPopUpProps) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [reason, setReason] = useState<string>("");

	const handleChangeReason = (e: ChangeEvent<HTMLInputElement>) => {
		setReason(e.target.value);
	};

	const handleSubmit = async () => {
		try {
			setLoading(true);
			await rejectOrder(order.id, reason);
			notify("Order has been successfuly rejected");
		} catch (err: any) {
			if ([400, 401, 403].includes(err.response.status)) {
				const res = err.response.data;

				notify(res.message);
			} else {
				notify(MessageConst.error.unknownError);
			}
		} finally {
			onClose();
			loadOrders();
		}
	};

	return (
		<PopUp>
			{loading && <LoaderComponent showShadow />}
			<div className="reject-order-wrapper">
				<div className="title">
					<span>
						{`Do you really want to cancel order #
						${order.number + "-" + order.subNumber.toString().padStart(2, "0")}?`}
					</span>
					<span>If so, please explain the cancellation reason:</span>
				</div>
				<CustomInput label="Reason" value={reason} onChange={handleChangeReason} />

				<div className="action-buttons">
					<button onClick={onClose}>Cancel</button>
					<button onClick={handleSubmit}>Submit</button>
				</div>
			</div>
		</PopUp>
	);
};

export default RejectOrderPopUp;
