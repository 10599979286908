import {Province, ServedProvinceList} from "../model/Province";

export default class ValidationService {
	public static validateRequired(text: string): boolean {
		return text.length !== 0; // X
	}

	public static validatePhone(phone: string): boolean {
		return this.phoneConverter(phone).length === 14; // (XXX) XXX-XXXX
	}

	public static validatePassword(password: string): boolean {
		return password.length >= 6;
	}

	public static validatePostalCode(postalCode: string): boolean {
		return postalCode.length === 7; // wdw dwd
	}

	public static validatePasswordEquality(password1: string, password2: string): boolean {
		return password1 === password2;
	}

	public static validateProvince(province: Province): boolean {
		return ServedProvinceList.includes(province);
	}

	public static validateBirthday(birthday: string): boolean {
		return /^\d{4}-\d{2}-\d{2}$/.test(birthday);
	}

	public static validateEmail(email: string): boolean {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	public static validateGovId(id: string): boolean {
		return /^[\d\w-]{3,30}$/i.test(id);
	}

	public static phoneConverter(value: string): string {
		return value
			.replace(/\D/g, "")
			.replace(/^(?=\d)/, "(")
			.replace(/^(\(\d{3})(?=\d)/, "$1) ")
			.replace(/^(\(\d{3}\)\x20\d{3})(?=\d)/, "$1-")
			.replace(/^(\(\d{3}\)\x20\d{3}-\d{4}).*/, "$1");
	}

	public static numberConverter(value: string): string {
		return value.replace(/[^0-9]/g, "");
	}

	public static nameConverter(value: string): string {
		return value;
	}
    public static trackingNumberConverter (value: string): string {
		return value
		.replace(/[^a-zA-Z0-9]/g,"")
	}
	public static postalCodeConverter(value: string): string {
		return value
			.replace(/[^A-Za-z\d]/g, "")
			.toUpperCase()
			.replace(/([A-Z])[A-Z]+/g, "$1")
			.replace(/(\d)\d+/g, "$1")
			.replace(/^\d/, "")
			.replace(/^(\S{3})(?=\S)/, "$1 ")
			.replace(/^(\S{3}\x20\S{3}).*/, "$1");
	}

	public static addressConverter(value: string): string {
		return value.replace(/,/g, "").replace(/\.{2,}/g, ".");
	}
}
