import React, {Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as patientActions from "../../../../redux/patient/handlers";
import {StateType} from "../../../../redux/types";
import LoaderComponent from "../../../Loader";
import ImageUploadComponent from "../../../ImageUploadCheckout";
import {compressImage} from "../../../../utils/helpers";
import IconConfirmGreen from "../../../../static/images/_confirmGreen.svg";
import "./styles.scss";

interface VerificationProps {
	showValidationMessage?: boolean;
	doctorConsultDate: string | undefined;	
	}

const Verification = ({showValidationMessage}:VerificationProps, props: VerificationProps) => {	
	const dispatch = useDispatch();
	const {profile, updatingId} = useSelector(({auth, patient}: StateType) => ({
		profile: auth.profile,
		updatingId: patient.updatingProfile
	}));	

	const handleIdImgChange = async (file: File) => {
		// console.log(updatingId);
		compressImage(file, (newFile: Blob) => dispatch(patientActions.uploadIdRequest(newFile)));
		// dispatch(patientActions.uploadIdRequest(file));
	};

	const handleInsuranceImgChange = async (file: File) => {
		compressImage(file, (newFile: Blob) =>
			dispatch(patientActions.uploadInsuranceRequest(newFile))
		);
		// dispatch(patientActions.uploadInsuranceRequest(file));
	};

	


	return (
		<Fragment>			
			{updatingId && <LoaderComponent showShadow={true} isFixed />}

			<div id="cschdule">
			<section className="section-9">
					<div className="container-14 w-container">
					<div className="columns-35 w-row">
						<div className="w-col w-col-3"></div>
						<div className="column-57 w-col w-col-6">
						<div className="div-block-27">
							<h1 className="heading-7">Appointment Date<br/>{sessionStorage.getItem("doctorConsultation")}</h1>
						</div>
						<h1 className="heading-5">Upload your Government Issued Photo Identification</h1>
						<div className="text-block-53">Accepted form: Driver’s License, Health card, Passport or any Government Issued ID Card.</div>
						
						

						<div className="columns-29 w-row">
							{profile!.idDocument && (
							<div className="success-upload-id">
								<span>Success!</span>
								<img src={IconConfirmGreen} alt="" />
							</div>
							)}
							<div className="">
							<ImageUploadComponent
							img={!!profile!.idDocument}
							onImgChange={handleIdImgChange}
							mode="ID"
							/>
							</div>
						</div>
						

						<div>
							<h1 className="heading-31">Have healthcare benefits? (Optional)</h1>
							<div className="text-block-63">Upload a picture of your insurance card here and our pharmacy partner will apply your coverage, if available, to the prescription once approved.</div>
							<div className="columns-30 w-row">
							{profile!.insuranceDocument && (
							<div className="success-upload-id">
							<span>Success!</span>
							<img src={IconConfirmGreen} alt="" />
							</div>
							)}
							<div className="">								
								<ImageUploadComponent
								img={!!profile!.insuranceDocument}
								onImgChange={handleInsuranceImgChange}
								mode="Insurance"
								/>
							</div>														
							</div>
						</div>
						{/* <div className="form-block-5 w-form">
							<form id="email-form-2" name="email-form-2" data-name="Email Form 2">
								<label className="field-label-5">Additonal info</label>
								<textarea 
								placeholder="Anything the doctor should know?" 
								id="field-2" 
								name="field-2" 
								className="textarea w-input"
								value={additionalinfo}
								onChange={(e) => setAdditionalinfo(e.target.value)}></textarea>
							</form>

							validation column							
							<div className={validationMessage?"w-form-fail-show":"w-form-fail"}>
								<div>{validationMessage}</div>
							</div>
							
						</div> */}						
						</div>
						<div className="w-col w-col-3"></div>
					</div>
					</div>
				</section>
			</div>		


		</Fragment>
	);
};

export default Verification;
