import http from "../index";

export const setPackagingOrderWithRx = (
	orderId: string | number,
	payload: {rxNumber: number; refillsLeft: number; toDate: string; amount: number}
) => http.put(`/order/${orderId}/packaging-rx`, payload);

export const setPackagingOrder = (
	orderId: string | number,
	payload: {text: string; amount: number}
) => http.put(`/order/${orderId}/packaging`, payload);

export const setTrackOrder = (
	orderId: string | number,
	payload: {trackingNumber: string; courier: string}
) => http.put(`/order/${orderId}/delivering`, payload);

export const rejectOrder = (orderId: string | number, reason: string) =>
	http.put(`/order/${orderId}/reject`, {text: reason});
