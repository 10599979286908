import React, {Component} from "react";
import "./styles.scss";
import endNarrowJpg from "../../static/images/end-why-winston-mob.jpg";
import {Link} from "react-router-dom";
import { Title } from "react-meta-elements";

export default class WhyWinstonComponent extends Component<{}, {}> {
	
	public render() {
		let search = window.location.search;
		if(search!=""){
			window.sessionStorage.setItem("utm_source", search.split('?')[1]);
		}
		return (
			<>
					<Title>
					Why choose Winston | Licensed Physician
					</Title>
					
			<div className="why-winston-component prefiles">
				<div className="main">
					<div className="main-title">
						<h1>Why Winston?</h1>
					</div>
					<div className="content-box">
						<div className="box">
							<div className="left-part">
								<div className="number">
									<p>1</p>
								</div>
								<div className="title">
									<h3>Convenience</h3>
								</div>
							</div>
							<div className="right-part">
								<p>
									Skip doctor waiting rooms and pharmacy lineups. Winston will prescribe men’s
									lifestyle meds from the comfort of your home and deliver it directly to your door.
									It can’t get much more convenient than that.
								</p>
							</div>
						</div>
						<div className="box">
							<div className="left-part">
								<div className="number">
									<p>2</p>
								</div>
								<div className="title">
									<h3>Competitive Pricing</h3>
								</div>
							</div>
							<div className="right-part">
								<p>
									Our goal is to have these treatments accessible to all Canadians at a price they
									can afford. At Winston, we are proud to offer pricing that is comparable or lower
									than your current pharmacy.
								</p>
							</div>
						</div>
						{/* <div className="drug-table">
							<div className="drug-box first">
								<div className="drug-title">
									<p>Medication</p>
								</div>
								<div className="example">
									<p>Sildenafil 100mg</p>
								</div>
								<div className="example">
									<p>Tadalafil 20mg</p>
								</div>
							</div>
							<div className="drug-box">
								<div className="drug-title">
									<p>Leading Pharmacy Chain</p>
								</div>
								<div className="example">
									<p>8 pills=$93.96 ($11.75)</p>
								</div>
								<div className="example">
									<p>8 pills=$125.40 ($15.68)</p>
								</div>
							</div>
							<div className="drug-box">
								<div className="drug-title">
									<p>Independent</p>
								</div>
								<div className="example">
									<p>4 pills=$55 ($13.75)</p>
								</div>
								<div className="example">
									<p>4 pills=$75 ($18.75)</p>
								</div>
							</div>
							<div className="drug-box last">
								<div className="drug-title">
									<p>Winston</p>
								</div>
								<div className="example">
									<p>8 pills=$96 ($12)</p>
								</div>
								<div className="example">
									<p>8 pills=$123.92 ($15.49)</p>
								</div>
							</div>
						</div> */}
						<div className="box">
							<div className="left-part">
								<div className="number">
									<p>3</p>
								</div>
								<div className="title">
									<h3>Flexible Doctor Consultations</h3>
								</div>
							</div>
							<div className="right-part">
								<p>
									No more waiting for a doctor’s appointment. We provide doctor consults that fit
									your schedule.
								</p>
							</div>
						</div>
						<div className="box">
							<div className="left-part">
								<div className="number">
									<p>4</p>
								</div>
								<div className="title">
									<h3>Licensed Physician & Accredited Pharmacy</h3>
								</div>
							</div>
							<div className="right-part">
								<p>
									Many medications found online are counterfeit. At Winston, you can have peace of
									mind knowing that your medication is prescribed by a Canadian board-certified
									doctor and is filled by our network of approved and accredited Canadian
									pharmacies.
								</p>
							</div>
						</div>
						<div className="box">
							<div className="left-part">
								<div className="number">
									<p>5</p>
								</div>
								<div className="title">
									<p>Discreet &amp; Private</p>
								</div>
							</div>
							<div className="right-part">
								<p>
									We take your privacy very seriously. Winston complies with the Personal Health
									Information Protection Act (PHIPA) and the Personal Information Protection and
									Electronic Documents Act (PIPEDA) to keep your information safe and secure.
								</p>
							</div>
						</div>
						<div className="box about-us">
							For more information on Winston, we encourage you to visit our{" "}
							<Link to="/about-us">About&nbsp;Us</Link> page.
						</div>
					</div>
				</div>
				<div className="get-started-section">
					<h2 className="title">Ready to Get Started?</h2>
					<div className="buttons">
						<Link to="/shop">
							<button>What We Treat</button>
						</Link>
						<Link to="/contact-us"
						>
							<button>Talk to a Doctor</button>
						</Link>
					</div>
				</div>
				<div className="end-wrapper">
					<div className="end">
						<div className="end-content">
							<p>
								Men’s health medication, prescribed from the comfort of your home, delivered
								discreetly
							</p>
						</div>
						<div className="image">
							<img className="narrow" src={endNarrowJpg} alt="Why choose Winston- Licensed Physician" />
						</div>
					</div>
				</div>
			</div>
			</>
		);
	}
}
