import http from "../index";

export const getFailedSubscriptions = () => http.get("/subscription/paused");

export const getAllSubscriptions = (statusList: string[]) => {
    const statusQueryParam = statusList.join(',');
    return http.get(`/subscription/all?statusList=${statusQueryParam}`);
};

export const getRegisteredPendingUsers = () => http.get("/patient/admin");

export const getConnectedPendingUsers = () => http.get("/stay-connected");

export const getShippedOrdersReport = (startDate: string, endDate: string) =>
	http.get(`/statistics/shipped?from=${startDate}&to=${endDate}`, {responseType: "blob"});

export const getUsersNoOrdersReport = () =>
	http.get("/statistics/new-users", {responseType: "blob"});
export const getSubscriptionExpiryReport = (startDate: string, endDate: string) =>
	http.get(`/statistics/subscription-expiring?from=${startDate}&to=${endDate}`, {responseType: "blob"});
export const getSubscriptionRenewedReport = (startDate: string, endDate: string) =>
	http.get(`/statistics/subscription-renewed?from=${startDate}&to=${endDate}`, {responseType: "blob"});