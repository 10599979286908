import React, {useEffect} from "react";

import "./styles.scss";

interface PopUpProps {
	children: any;
}

const PopUp = (props: PopUpProps) => {
	useEffect(() => {
		document.body.style.overflow = "hidden";

		return () => {
			document.body.style.overflow = "auto";
		};
	}, []);

	return (
		<div className="custom-pop-up-wrapper">
			<div className="custom-pop-up">{props.children}</div>
		</div>
	);
};
export default PopUp;
