import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Routes, Route, useLocation, Navigate, useNavigate, RouteProps, Outlet} from "react-router-dom";
import Rollbar from "rollbar";

import {StateType} from "../../redux/types";
import * as authActions from "../../redux/auth/handlers";
import * as appActions from "../../redux/app/handlers";

import AboutUsComponent from "../AboutUs";
import AdminDashboardComponent from "../AdminDashboard";
import DoctorDashboardComponent from "../DoctorDashboard";
import TheFacts from "../Learn";
import FaceComponent from "../Face";
import FaqComponent from "../FAQ";
import FooterComponent from "../Footer";
import HeaderComponent from "../Header";
import HowItWorksComponent from "../HowItWorks";
import LoaderComponent from "../Loader";
import PharmacistDashboardComponent from "../PharmacistDashboard";
import PrivacyPolicyTermsOfUseComponent from "../PrivacyPolicyTermsOfUse";
import Profile from "../Profile";
import Sidebar from "../Sidebar";
import WhyWinstonComponent from "../WhyWinston";
import Shop from "../Shop";
import ProductPage from "../ProductPage";
import FactPage from "../FactPage";
import ContactUs from "../ContactUs";
import TransferRxPage from "../TransferRxPage";
import CheckoutSuccess from "../../components/OrderProcess/components/CheckoutSuccess";
// import Relogin from "../Login/Re-login"
import "react-datepicker/dist/react-datepicker.css";
import "../../static/fonts/fonts.scss";
import "./styles.scss";
import Modal from "../Shared/Modal";
import ProductED from "../ProductED";
import ProductCS from "../ProductCS";
import ProductHL from "../ProductHL";
import ProductACNE from "../ProductACNE";
import ProductAL from "../ProductAL";
import PopUp from "../PopUp";
import close from "../../static/images/close.png";
import popUpimg from  "../../static/images/blog-allergies.png"
import AccountInfo from "../Profile/components/AccountInfo";
import BillingInfo from "../Profile/components/BillingInfo";
import OrdersInfo from "../Profile/components/OrdersInfo";
import TransferRx from "../Profile/components/TransferRx";
declare global {
	interface Window {
		Intercom: any;
		fbq: any;
		rollbar: Rollbar;
	}
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}


const AuthRoute = () => {
	const {profile} = useSelector(({auth}: StateType) => ({
		profile: auth.profile
	}));
	return (
		profile ? <Outlet/> : <Navigate to='/login' replace/>
	  )
};

const NonAuthRoute = () => {
	const {profile} = useSelector(({auth}: StateType) => ({
		profile: auth.profile
	}));

	return (
		profile ? <Navigate to='/' replace/>: <Outlet/>
	  )	;
};

const Main = () => {
	const query = useQuery();
	const {pathname, key: locationKey} = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
    // const [firstVisit, setFirstVisit] = useState(false);
	const {loading, isModalShown, loadingProducts, profile, selectedProducts, closeSidebarDirection} = useSelector(
		({app, modal, auth, cart, product}: StateType) => ({
			loading: app.loading,
			isModalShown: modal.isShown,
			selectedProducts: cart.selectedProducts,
			profile: auth.profile,
			loadingProducts: product.loadingProducts,
			shopScrollTarget: app.shopScrollTarget,
			isSubscribed: app.isSubscribed,
			closeSidebarDirection: app.closeSidebarDirection
		})
	);

	const nextPath = useSelector((state:StateType) => state.app.nextPath); 
	
	useEffect(() => {
		if (nextPath && nextPath !== "" && nextPath !== null) {
			navigate(nextPath);
		}
	}, [nextPath, navigate]);
	  
	useEffect(() => {
		if (nextPath && location.pathname === nextPath) {
			console.log("Clearing nextPath as it matches the current path");
			dispatch(appActions.clearNextPath());
		}
	}, [location.pathname, nextPath, dispatch]);

	const [, setScrolled] = useState<boolean>(false);

	useEffect(() => {
		const handleScrolled = () => {
			if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 300) {
				setScrolled(true);
			} else {
				setScrolled(false);
			}
		};

		window.addEventListener("scroll", handleScrolled);
		return () => {
			window.removeEventListener("scroll", handleScrolled);
		};
	}, []);

	useEffect(() => {
		const fbq = window.fbq || null;

		if (fbq) {
			fbq("track", "PageView");
		}
	}, [pathname]);

	useEffect(() => {
		const isLogout = query.get("logout");

		if (isLogout) {
			dispatch(authActions.logout());

			navigate("/login");
		}
	}, [dispatch, navigate, query]);

	useEffect(() => {
		const Intercom = window.Intercom || null;

		if (Intercom && (profile?.authority === "PHARMACIST" || profile?.authority === "DOCTOR")) {
			Intercom("update", {
				hide_default_launcher: true
			});
		} else if (Intercom) {
			Intercom("update", {
				hide_default_launcher: false
			});
		}
	}, [profile]);

	useEffect(() => {
		if (authActions.getProfileRequest) {
			dispatch(authActions.getProfileRequest({mode: ""}));
		} else {
			if (typeof(window.rollbar) !== "undefined") {
				/*
				window.rollbar.warning(
					"getProfileRequest action from authActions is not initialized!"
				);*/
			}
			
		}
	}, [dispatch]);

	useEffect(() => {
		if (closeSidebarDirection && pathname === closeSidebarDirection.pathname) {
			window.scrollTo(0, closeSidebarDirection.y);
			dispatch(appActions.setCloseSidebarDirection(null));
		} else if (pathname !== "/shop" && !pathname.includes("profile/transfer-rx")) {
			window.scrollTo(0, 0);
		}
		
	}, [locationKey, pathname, dispatch]);

	const handleLogoutClick = () => {
		dispatch(authActions.logout());
	};
	// useEffect(()=>{
	// 	let visited =sessionStorage.getItem("visited");
	// 	if(visited === null){
	// 		setFirstVisit(true);
	// 	}			
				
	// },[])
	// const handleClose =()=>{
	// 	setFirstVisit(false);
	// 	sessionStorage.setItem('visited',"true");
	// }

	const renderRoutes = () => {
		if (profile) {
			switch (profile.authority) {
				case "DOCTOR":
					return (
						<DoctorDashboardComponent profile={profile} onLogoutClick={handleLogoutClick} />
					);
				case "PHARMACIST":
					return (
						<PharmacistDashboardComponent profile={profile} onLogoutClick={handleLogoutClick}
						/>
					);
				case "ADMIN":
					return (<AdminDashboardComponent />);
			}
		}

		return (
			<div className="main-wrapper">
				<HeaderComponent cartNumber={selectedProducts.length} />
				{/* {firstVisit ?
						<div id="infoPopup">
						<PopUp>
							<div className="container-8 w-container">
								<div className="closeBtn">
									<div onClick={handleClose}><img className="closeBtnImg" src={close} alt="close" /></div>
								</div>
								<div className="columns-67 w-row">
									<div className="column-115 w-col w-col-6 popup-img-col">
									 <img className="popup-left-img" src={popUpimg} height="100%" width="100%"  alt="image" />
									</div>
									<div className="column-109 w-col w-col-6 ">
										<div className="popupContent">
											<h1 className="popupContent-heading">Lorem Ipsum is simply dummy text</h1>
											<p className="popupContent-info">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

											<a className="button-popup" href="#"><strong>Yes, Lorem Ipsum is simply</strong></a>
										</div>	
									</div>
								</div>
							</div>
						</PopUp>
						</div>
						: null} */}
				<Routes>					
					<Route path="/faq" element={<FaqComponent />} />
					<Route path="/erectile-dysfunction" element={<ProductED />} />
					<Route path="/hair-loss" element={<ProductHL />} />
					<Route path="/cold-sores" element={<ProductCS />} />
					<Route path="/acne" element={<ProductACNE />} />
					<Route path="/allergies" element={<ProductAL />} />
					<Route path="/about-us" element={<AboutUsComponent />} />
					<Route path="/contact-us" element={<ContactUs />} />
					<Route path="/learn" element={<TheFacts />} />
					<Route path="/how-it-works" element={<HowItWorksComponent />} />
					<Route path="/transfer-rx" element={<TransferRxPage />} />
					<Route path="/why-winston" element={<WhyWinstonComponent />} />
					{/* <Route exact path="/re-login" component={Relogin}/> */}
					<Route
						path="/terms-of-use"
						element={<PrivacyPolicyTermsOfUseComponent mode={"TermsOfUse"} isStatic />}
					/>
					<Route
						path="/privacy-policy"
						element={<PrivacyPolicyTermsOfUseComponent mode={"PrivacyPolicy"} isStatic />}
					/>
					<Route path="/" element={<FaceComponent />} />
					<Route path="/shop/:category" element={<Shop />} />
					<Route path="/shop" element={<Shop />} />
					<Route path="/article/:articleName" element={<FactPage />} />
					<Route element={<AuthRoute />}> 
						<Route path="/profile" element={<Profile />}>
							<Route path={`/profile`} element={<Navigate to="/profile/account-info" />} />
							<Route path={`/profile/account-info`} element={<AccountInfo  />} />
							<Route path={`/profile/billing-info`} element={<BillingInfo />} />
							<Route path={`/profile/order-history`} element={<OrdersInfo />} />
							<Route path={`/profile/transfer-rx/*`} element={<TransferRx />}>
							</Route>							
						</Route>
						<Route path="/checkout-success" element={<CheckoutSuccess />} />
					</Route>
					<Route element={<NonAuthRoute />}> 
						<Route path="/login" element={<Sidebar />} />
						<Route path="/forgot-password/:token" element={<Sidebar />} />
						<Route path="/forgot-password" element={<Sidebar />} />
						<Route path="/google-link" element={<Sidebar />} />
					</Route>
					<Route path="/cart" element={<Sidebar />}/>
					<Route path="/signin" element={<Sidebar />}/>
					<Route path="/account" element={<Sidebar />}/>
					<Route path="/shipping" element={<Sidebar />}/>
					<Route path="/payment" element={<Sidebar />}/>
					<Route path="/id" element={<Sidebar />}/>
					<Route path="/doctor-consult" element={<Sidebar />}/>
					<Route path="/confirm" element={<Sidebar />}/>
					<Route path="/:productName" element={<ProductPage />}/>
				</Routes>
				<FooterComponent />
			</div>
		);
	};

	return (
		<div className="main-component">
			{isModalShown && (<Modal />)}
			{loading || loadingProducts ? <LoaderComponent /> : renderRoutes()}
		</div>
	);
};

export default Main;
