import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	createBrowserRouter,
	createRoutesFromElements,
	RouterProvider,
  } from "react-router-dom";
import Main from "../components/Main";

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path = "*" element={<Main />} />
	)
  );

export const AppRouter = () => (
	<RouterProvider router={router} />
);

export default AppRouter;
