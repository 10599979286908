import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "core-js";
import "url-search-params-polyfill";
import 'whatwg-fetch';

import reduxStore from "./redux/configureStore";
import AppRouter from "./router";

import "./styles.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";


console.log(process.env.REACT_APP_PROFILE, "- REACT_APP_PROFILE ENV");
console.log(process.env.REACT_APP_SERVER_API_URL, "- SERVER_API_URL");

const rootElement = document.getElementById('root');

if (rootElement) {
	const root = ReactDOM.createRoot(rootElement);
	root.render(
		<Provider store={reduxStore}>
			<GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID!} >
				<AppRouter />
			</GoogleOAuthProvider>
		</Provider>
	);
}