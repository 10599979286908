import http from "../index";
import Profile from "../../model/Profile";

interface Credentials {
	email: string;
	password: string;
}

export const getProfile = () => http.get("/user/profile");

export const login = (creds: Credentials) => http.post("/auth/login", creds);

export const googlelink = (creds:any)=> http.post("auth/link-to-google",creds)

export const googleLogin = (token: any) => http.post("/auth/google/login", token);

export const googleSignup = (token: any) => http.post("/auth/google/register", token);

export const register = (profile: Profile) => http.post("/patient", profile);

export const forgotPassword = (email: string) => http.post("/user/forgot-password/" + email, "");

export const validateToken = (token: string) => http.get("/token/" + token + "/validate");

export const setNewPassword = (token: string, password: string) =>
	http.post("/user/reset-password/" + token, {text: password});
